import './wdyr';

import { createRoot } from 'react-dom/client';

import App from './App';
import configureSentryWeb from './configureSentry';
import reportWebVitals from './reportWebVitals';

// Sentry should be configured and initialized as soon as possible to not lose any error events
configureSentryWeb();

createRoot(document.getElementById('root') as HTMLElement).render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
