import { useMutation } from 'urql';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

import { IdFn } from '@serenityapp/core-id';
import {
  ChannelCreateEditFormValues,
  SelectorItemProps,
} from '@serenityapp/components-react-common';
import { ConversationKind, ConversationSubjectKind } from '@serenityapp/domain';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ClientApi } from '@serenityapp/api-client-graph';
import { snackAdd } from '@serenityapp/redux-store';
import { selectorItemPropsToMembershipInput } from '@serenityapp/client-data';

import ChannelCreateEditForm from './components/ChannelCreateEditForm';

const ChannelCreateDrawer = () => {
  const makeTestId = useMakeTestId('ChannelCreateDrawer');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const goBack = () => navigate('..');

  const [, createChannel] = useMutation<
    ClientApi.Conversation.Api.Create.MutationResult,
    ClientApi.Conversation.Api.Create.Variables
  >(ClientApi.Conversation.Api.Create.Mutation);

  const navigateBack = () => navigate('..');

  const handleFormSubmit = async (values: ChannelCreateEditFormValues) => {
    setIsSubmitting(true);

    const channelId = IdFn.new();

    const conversationToAdd = {
      id: channelId,
      isDraft: false,
      kind: ConversationKind.CHANNEL,
      subject: ConversationSubjectKind.GENERAL,
      name: values.name?.trim(),
      description: values.description?.trim(),
      familyAndFriends: selectorItemPropsToMembershipInput(
        values.familyAndFriends as Array<SelectorItemProps>,
        channelId,
      ),
      staff: selectorItemPropsToMembershipInput(
        values.staff as Array<SelectorItemProps>,
        channelId,
      ),
      locations: (values.locations as Array<SelectorItemProps>)?.map((item) => item.id),
      isOneWayChannel: values.isOneWayChannel || false,
    };

    createChannel({
      input: conversationToAdd,
    }).then((result) => {
      setIsSubmitting(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(snackAdd({ message: 'Error creating channel', type: 'error' }));
      } else {
        goBack();
        dispatch(snackAdd({ message: 'Channel successfully created', type: 'success' }));
      }
    });
  };

  return (
    <ChannelCreateEditForm
      isOwnerAdmin
      dataTestId={makeTestId('')}
      handleFormClose={navigateBack}
      handleFormSubmit={handleFormSubmit}
      isLoading={false}
      isSaving={isSubmitting}
      title="Create new general channel"
    />
  );
};

export default ChannelCreateDrawer;
