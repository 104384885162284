export const containerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
};

export const cardHeaderSx = {
  px: 3,
};

export const cardActionsSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  pt: 0,
  pb: 8,
  px: 3,
};

export const cardSx = {
  width: 399,
};

export const logoWrapperSx = {
  display: 'flex',
  justifyContent: 'center',
  pt: 8,
  pb: 3,
};

export const buttonSx = {
  color: 'text.primary',
  mx: 0,
  mt: 2,

  '& .MuiSvgIcon-root': {
    color: 'action.active',
  },
};
