import { FormikValues } from 'formik';
import { RetentionPolicyCreateEditForm } from './components';
import { useDispatch } from 'react-redux';
import { retentionPolicyCreate, snackAdd } from '@serenityapp/redux-store';
import { IdFn } from '@serenityapp/core-id';
import { useCurrentUser } from '../common/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RetentionPolicyCreateDrawer = () => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  const onSuccess = () => {
    setIsSaving(false);
    navigate('../');
    dispatch(snackAdd({ message: 'Retention policy successfully added.', type: 'success' }));
  };

  const onFailed = () => {
    setIsSaving(false);
    dispatch(snackAdd({ message: 'Error adding retention policy.', type: 'error' }));
  };

  const handleSubmit = (values: FormikValues) => {
    const orgId = currentUser?.orgId;
    if (!orgId) return;

    setIsSaving(true);

    dispatch(
      retentionPolicyCreate({
        onSuccess,
        onFailed,
        variables: {
          input: {
            organization: {
              id: orgId,
            },
            retentionPolicy: {
              id: IdFn.new(),
              name: values.name,
              skipArchive: false,
              retainForever: values.daysToRetain === '0',
              daysToRetain: values.daysToRetain !== '0' ? Number(values.daysToRetain) : undefined,
            },
          },
        },
      }),
    );
  };

  return (
    <RetentionPolicyCreateEditForm
      handleSubmit={handleSubmit}
      isSaving={isSaving}
      title="Add retention policy"
    />
  );
};

export default RetentionPolicyCreateDrawer;
