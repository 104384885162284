import { useState } from 'react';
import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';

import { checkInPolicyCreate, snackAdd } from '@serenityapp/redux-store';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { App } from '@serenityapp/core';
import { IdFn } from '@serenityapp/core-id';
import { KeyValuePair } from '@serenityapp/domain';

import { useCurrentUser } from '../../../common/hooks';
import CheckInPolicyCreateEditForm from './CheckInPolicyCreateEditForm';
import { getCheckInSchedules } from './utils';

type CheckInPolicyCreateDrawerContentProps = {
  returnToCheckInAppDetail: () => void;
  onCheckInPolicyFormStateChanged: (isDirty: boolean) => void;
  onConfirmDialogOpen: (isOpen: boolean) => void;
};

const CheckInPolicyCreateDrawerContent = ({
  returnToCheckInAppDetail,
  onCheckInPolicyFormStateChanged,
  onConfirmDialogOpen,
}: CheckInPolicyCreateDrawerContentProps) => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [folderValidationFailed, setFolderValidationFailed] = useState(false);

  const initialErrors = folderValidationFailed
    ? { reportFolderId: 'Either the folder is missing or we do not have access to it.' }
    : {};

  const handleSuccess = () => {
    setIsSaving(false);
    onCheckInPolicyFormStateChanged(false);
    dispatch(snackAdd({ message: 'Check-In policy successfully added.', type: 'success' }));
    returnToCheckInAppDetail();
  };

  const handleFailed = () => {
    setIsSaving(false);
    dispatch(snackAdd({ message: 'Error adding Check-In policy.', type: 'error' }));
  };

  const handleFolderValidationFailed = () => {
    setIsSaving(false);
    setFolderValidationFailed(true);
  };

  const handleSubmit = (values: FormikValues) => {
    const orgId = currentUser?.orgId;
    if (!orgId) return;
    setFolderValidationFailed(false);
    setIsSaving(true);

    const cloudStorageSettings: KeyValuePair[] = [];

    if (values.useCloudStorage && values.cloudStorageName) {
      cloudStorageSettings.push({
        key: 'cloudStorageName',
        stringValue: values.cloudStorageName,
        type: 'string',
      });

      if (values.cloudStorageName === App.Names.Box && values.reportFolderId) {
        cloudStorageSettings.push({
          key: 'reportFolderId',
          stringValue: values.reportFolderId,
          type: 'string',
        });
      }
    } else {
      cloudStorageSettings.push({
        key: 'cloudStorageName',
        stringValue: 'S3',
        type: 'string',
      });
    }

    dispatch(
      checkInPolicyCreate({
        onSuccess: handleSuccess,
        onFailed: handleFailed,
        onFolderValidationFailed: handleFolderValidationFailed,
        variables: {
          input: {
            organization: {
              id: orgId,
            },
            checkInPolicy: {
              id: IdFn.new(),
              name: values.name,
              viewers: values.viewers.map((viewer: SelectorItemProps) => viewer.id),
              serviceLevels: values.serviceLevels.map(
                (serviceLevel: SelectorItemProps) => serviceLevel.id,
              ),
              includeOptedOut: values.includeOptedOut,
              approvers: values.approvers.map((approver: SelectorItemProps) => approver.id),
              managers: values.managers.map((manager: SelectorItemProps) => manager.id),
              schedules: getCheckInSchedules(values.name, values.timeSliderValue),
              cloudStorageSettings,
              allowAutoMove: values.allowAutoMove,
            },
          },
        },
      }),
    );
  };

  return (
    <CheckInPolicyCreateEditForm
      handleSubmit={handleSubmit}
      initialErrors={initialErrors}
      isSaving={isSaving}
      returnToCheckInAppDetail={returnToCheckInAppDetail}
      title="New Check-In"
      onCheckInPolicyFormStateChanged={onCheckInPolicyFormStateChanged}
      onConfirmDialogOpen={onConfirmDialogOpen}
    />
  );
};

export default CheckInPolicyCreateDrawerContent;
