export const sectionHeaderSx = {
  display: 'flex',
  pb: 2,
};

export const fieldBottomSpaceSx = {
  mb: 2,
};

export const nameFieldSpacingSx = {
  mt: 2,
};

export const actionsButtonSx = {
  color: 'text.primary',
  textTransform: 'capitalize',
  mr: 1,
};

export const actionsButtonIconSx = {
  color: 'action.active',
};

export const headerInfoSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '11rem',
  flexGrow: 1,
};

export const secondaryHeaderLineSx = {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  gap: 1,
};

export const headerDividerSx = {
  height: '40px',
};

export const activeChipSx = {
  bgcolor: 'success.outlinedRestingBorder',
};
