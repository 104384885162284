import { Header, View } from '@serenityapp/components-react-web';

const HomePage = () => {
  const title = 'Welcome to Serenity!';

  return (
    <View>
      <View.Header>
        <Header title={title} />
      </View.Header>
    </View>
  );
};

export default HomePage;
