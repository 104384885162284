import { Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MakeTestIdFn } from './types';

export const Footer = ({
  dirty,
  isLoading,
  isSaving,
  makeTestId,
  onDrawerCloseClick,
  submitForm,
}: {
  dirty: boolean;
  isLoading: boolean;
  isSaving: boolean;
  makeTestId: MakeTestIdFn;
  onDrawerCloseClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  submitForm?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <>
      <Button data-testid={makeTestId('close')} disabled={isSaving} onClick={onDrawerCloseClick}>
        Close
      </Button>
      <LoadingButton
        data-testid={makeTestId('save')}
        disabled={isLoading || !dirty}
        loading={isSaving}
        variant="contained"
        onClick={submitForm}
      >
        Save
      </LoadingButton>
    </>
  );
};

export default Footer;
