import { AdministrationHeader, useMakeTestId, View } from '@serenityapp/components-react-web';
import { GridColDef, GridRowParams, GridRowProps } from '@mui/x-data-grid-pro';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

import { Schema } from '@serenityapp/domain';
import { useCurrentUser, useDevices } from '@serenityapp/client-data';

import { DEFAULT_DEVICES_PAGE_SIZE, PAGE_SIZE_OPTIONS } from './utils';
import QuickSearchToolbarDevices from './QuickSearchToolbarDevices';
import { useAdminBreadcrumbs } from '../hooks';
import { AdminDataGrid } from '../components';
import CustomGridRow from '../components/CustomGridRow';

const DevicesPage = () => {
  const title = 'Devices';
  const makeTestId = useMakeTestId('DevicesPage');
  const mainTestId = makeTestId('');

  const { user } = useCurrentUser();
  const organizationId = user?.orgId;

  const { devices, fetching } = useDevices(organizationId, DEFAULT_DEVICES_PAGE_SIZE);

  const navigate = useNavigate();

  const goToResourceDetails = (params: GridRowParams) => {
    const { id } = params;
    navigate(id.toString());
  };

  const crumbs = useAdminBreadcrumbs(title);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Device name',
        flex: 1,
      },
      {
        field: 'serialNumber',
        headerName: 'Serial number',
        flex: 1,
      },
      {
        field: 'location',
        headerName: 'Location',
        flex: 1,
        valueFormatter: (params: Schema.Location.Item) => {
          if (!params) return '-';
          return params.name;
        },
      },
    ],
    [],
  );

  return (
    <View data-testid={mainTestId}>
      <View.Header>
        <AdministrationHeader dataTestId={mainTestId} crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <AdminDataGrid
          disableColumnSelector
          disableRowSelectionOnClick
          ignoreDiacritics
          disableColumnMenu
          initialState={{
            pagination: { paginationModel: { pageSize: DEFAULT_DEVICES_PAGE_SIZE } },
          }}
          loading={fetching && !devices.length}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onRowClick={goToResourceDetails}
          pagination
          columns={columns}
          rows={devices}
          slots={{
            toolbar: QuickSearchToolbarDevices,
            row: (props: GridRowProps) => <CustomGridRow {...props} dataTestId={mainTestId} />,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton',
            },
          }}
        />
        <Outlet />
      </View.Content>
    </View>
  );
};

export default DevicesPage;
