import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PushPin } from '@mui/icons-material';
import { Skeleton, Button } from '@mui/material';
import { usePinnedMessages } from '../../hooks';

type PinnedMessagesControlProps = {
  conversationId: string;
  canDisplayControl?: boolean;
};

const PinnedMessagesControl = ({
  conversationId,
  canDisplayControl,
}: PinnedMessagesControlProps) => {
  const navigate = useNavigate();
  const { pinnedMessages, isLoading: isLoadingMessages } = usePinnedMessages(conversationId);
  const hasPinnedMessages = pinnedMessages.length > 0;

  const onClick = useCallback(() => {
    navigate('details/pinned-messages');
  }, [navigate]);

  // We display Skeleton in two cases:
  // 1. when permission is loaded or passed in props and is set to true,
  // but the conversation details are still loading and no cached data exists
  // 2. when we are waiting for permission to load
  if (
    (canDisplayControl && isLoadingMessages && !hasPinnedMessages) ||
    canDisplayControl === undefined
  )
    return <Skeleton sx={pinnedMessagesSkeletonSx} width="100px" />;

  // We return null if permission to see the data is set to false OR when there is no data to display after loading
  if (canDisplayControl === false || !hasPinnedMessages) return null;

  return (
    <Button
      color="neutral"
      startIcon={<PushPin aria-hidden />}
      sx={pinnedMessagesButtonSx}
      variant="text"
      onClick={onClick}
    >
      {pinnedMessages.length} Pinned
    </Button>
  );
};

const pinnedMessagesButtonSx = {
  display: { xs: 'none', sm: 'flex' },
  mr: 2,
  color: 'action.active',
};

const pinnedMessagesSkeletonSx = {
  mr: 2,
};

export default PinnedMessagesControl;
