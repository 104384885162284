import { BasicMenu } from '@serenityapp/components-react-web';
import { userAssignRole } from '@serenityapp/redux-store';
import { useDispatch } from 'react-redux';

type Props = {
  anchorEl: HTMLElement | null;
  onClose?: () => void;
  userId: string;
  organizationId: string;
};

/**
 * Actions menu for users table in organization detail page
 */
const UserActionsMenu = ({ anchorEl, onClose, userId, organizationId }: Props) => {
  const dispatch = useDispatch();

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClose?.();
  };

  const handleMakeAdminClick = () => {
    dispatch(
      userAssignRole({
        variables: {
          userIds: [userId],
          organizationId,
          roleName: 'admin',
        },
      }),
    );
  };

  const menuSections = [
    [
      {
        key: 'makeAdmin',
        label: 'Assign an admin role',
        handleCloseMenu,
        onClick: handleMakeAdminClick,
      },
    ],
  ];

  return (
    <BasicMenu
      key="basic-menu"
      keepMounted
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      sections={menuSections}
      onClose={handleCloseMenu}
    />
  );
};

export default UserActionsMenu;
