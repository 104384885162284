import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { SelectInputFilter } from '../../components';

const locationTypes = [
  { value: 'Building', label: 'Building' },
  { value: 'LocationGroup', label: 'Location Group' },
  { value: 'Unit', label: 'Unit' },
];

const QuickSearchToolbarLocations = () => (
  <GridToolbarContainer>
    <GridToolbarQuickFilter variant="outlined" sx={quickSearchFieldSx} debounceMs={200} />
    <SelectInputFilter items={locationTypes} fieldName="kind" fieldLabel="Location Type" />
  </GridToolbarContainer>
);

const quickSearchFieldSx = {
  width: 220,
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
};

export default QuickSearchToolbarLocations;
