import { Box, Skeleton } from '@mui/material';
import { AppLayout } from '@serenityapp/components-react-web';

const AppSkeleton = () => (
  <AppLayout>
    <AppLayout.AppBar>
      <Box sx={appBarSx}>
        <Skeleton width="15%" />
        <Box sx={accountWrapperSx}>
          <Box sx={infoWrapperSx}>
            <Skeleton width="80%" />
            <Skeleton width="70%" />
          </Box>
          <Skeleton height="40px" variant="circular" width="40px" />
        </Box>
      </Box>
    </AppLayout.AppBar>
  </AppLayout>
);

const appBarSx = {
  mx: 2,
  height: 64,
  display: 'flex',
  justifyContent: 'space-between',
};

const accountWrapperSx = {
  width: 180,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
};

const infoWrapperSx = {
  flex: 1,
  display: {
    xs: 'none',
    sm: 'flex',
  },
  alignItems: 'flex-end',
  flexDirection: 'column',
  mr: 1,
};

export default AppSkeleton;
