export const iconSx = {
  color: 'primary.main',
  m: 1,
  mr: 0,
};

export const containerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
};

export const cardContentSx = {
  p: 0,
};

export const cardHeaderSx = {
  px: 3,
};

export const cardSx = {
  width: 448,
};

export const logoWrapperSx = {
  display: 'flex',
  justifyContent: 'center',
  pt: 8,
  pb: 3,
};

export const cardActionsSx = {
  pt: 2,
  pb: 8,
  px: 3,
};

export const listItemSx = {
  color: 'text.primary',
};

export const buttonSx = {
  border: '1px solid',
  borderColor: 'neutral.outlinedRestingBorder',
  color: 'text.primary',

  '& .MuiSvgIcon-root': {
    color: 'action.active',
  },
};
