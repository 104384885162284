import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as R from 'ramda';

import { AssertFn, EnumFn, PhoneNumberFn, ServiceLevel } from '@serenityapp/core';
import { IdFn } from '@serenityapp/core-id';
import { UserCreateEditFormValuesLegacy } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';
import {
  adminActions,
  getCurrentUser,
  getIsUserAdding,
  snackAdd,
  userAdd,
} from '@serenityapp/redux-store';
import {
  ConversationKind,
  ConversationSubjectKind,
  UserAccountCreateOperation,
  UserCreateInputNext,
  UserKind,
  UserProps,
  UserType,
  UserTypeFn,
} from '@serenityapp/domain';

import { UserCreateEditFormLegacy } from './components';

const DISPLAY_NAME = 'UserCreateDrawerLegacy';

const UserCreateDrawerLegacy = () => {
  const makeTestId = useMakeTestId('UserCreateDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const isUserAdding = useSelector(getIsUserAdding);

  const currentUser: UserProps | undefined = useSelector(getCurrentUser, R.equals);

  const goBack = () => navigate('..');

  const handleFormSubmit = ({
    department,
    description,
    email,
    jobTitle,
    userName,
    phoneNumber,
    fullName,
    userType,
    workerCategory,
    generalChannels,
    createAccount,
    sendInvitationEmail,
    invitationSender,
    staff,
    residentChannels,
    familyAndFriends,
    locations,
    temporaryPassword,
    checkIn,
    serviceLevel,
    room,
  }: UserCreateEditFormValuesLegacy) => {
    const isUserResident = UserTypeFn.isResident(userType);
    const userKind = UserTypeFn.toKind(userType as UserType);
    const isCreateAccount = !isUserResident && createAccount;
    const creator = IdFn.isValid(invitationSender)
      ? { userId: invitationSender as string }
      : undefined;
    const userId = IdFn.new();
    const residentConversationId = IdFn.new();
    const memberRoleId = IdFn.fromString('member');

    const selectedGeneralChannels = isUserResident ? [] : generalChannels;
    const selectedResidentChannels = isUserResident ? [] : residentChannels;

    const selectedLocations = !isUserResident ? [] : locations;
    const selectedFamilyAndFriends = !isUserResident ? [] : familyAndFriends;
    const selectedStaff = !isUserResident ? [] : staff;

    const isInvitation = isCreateAccount && sendInvitationEmail;

    const channelsInput = [
      ...(selectedResidentChannels || []),
      ...(selectedGeneralChannels || []),
    ].map(({ id }) => ({ conversationId: id }));

    const locationsInput = (selectedLocations || []).map(({ id }) => ({ locationId: id }));

    const trimmedTempPassword = temporaryPassword && temporaryPassword.trim();
    const finalTempPassword =
      !isUserResident && trimmedTempPassword && trimmedTempPassword.length > 0
        ? trimmedTempPassword
        : undefined;

    let operation = UserAccountCreateOperation.NONE;
    if (isCreateAccount) operation = UserAccountCreateOperation.CREATE;
    if (isInvitation) operation = UserAccountCreateOperation.INVITE;

    AssertFn.isDefined(currentUser, DISPLAY_NAME, 'currentUser');

    const input: UserCreateInputNext = {
      id: userId,
      account: {
        operation,
        password: finalTempPassword,
      },
      conversations: channelsInput,
      creator,
      // TODO: [{groupId, userId}]
      groups: [],
      checkInOptOut: !checkIn,
      serviceLevel: !serviceLevel ? ServiceLevel.NONE : EnumFn.parse(ServiceLevel, serviceLevel),
      roomId: room,
      organizations: [
        {
          orgId: currentUser?.orgId,
          kind: userKind,

          department: department ? department?.trim() : undefined,
          description: description?.trim(),
          jobTitle: jobTitle ? jobTitle?.trim() : undefined,
          workerCategory: workerCategory ? workerCategory?.trim() : undefined,
        },
      ],
      profile: {
        email,
        phoneNumber: PhoneNumberFn.getInternationalNumber(phoneNumber),
        fullName: fullName?.trim(),
        userName,
      },
      resident: {
        locations: locationsInput,
        staff: selectedStaff?.map((item) => ({
          conversationId: residentConversationId,
          kind: ConversationKind.CHANNEL,
          roleId: memberRoleId,
          subject: ConversationSubjectKind.RESIDENT,
          userId: item.id,
        })),
        familyAndFriends: selectedFamilyAndFriends?.map((item) => ({
          conversationId: residentConversationId,
          kind: ConversationKind.CHANNEL,
          roleId: memberRoleId,
          subject: ConversationSubjectKind.RESIDENT,
          userId: item.id,
        })),
      },
    };

    dispatch(
      userAdd({
        variables: { input },
        onFailed: () => {
          dispatch(snackAdd({ message: 'Error adding user', type: 'error' }));
        },
        onSuccess: () => {
          if (userKind === UserKind.RESIDENT) {
            dispatch(adminActions.fetchAdminData());
          }
          dispatch(snackAdd({ message: 'User successfully added', type: 'success' }));
          goBack();
        },
      }),
    );
  };

  return (
    <UserCreateEditFormLegacy
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      isSaving={isUserAdding}
      title="Create new user"
    />
  );
};

UserCreateDrawerLegacy.displayName = DISPLAY_NAME;

export default UserCreateDrawerLegacy;
