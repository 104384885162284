import type { EditorThemeClasses } from 'lexical';

const LexicalEditorTheme: EditorThemeClasses = {
  paragraph: 'LexicalEditorTheme__paragraph',
  link: 'LexicalEditorTheme__link',

  emoji: 'LexicalEditorTheme__emoji',
};

export default LexicalEditorTheme;
