import { StringFn } from '@serenityapp/core';

type Crumb = {
  title: string;
  to?: string;
  id: string;
};

export default function useAdminBreadcrumbs(title: string) {
  const adminCrumb: Crumb = {
    title: 'Admin',
    to: '..',
    id: 'admin',
  };

  const crumbs = [adminCrumb];

  crumbs.push({
    title,
    id: StringFn.generateKey(title),
  });

  return crumbs;
}
