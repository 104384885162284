import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import { HorizontalSerenityColorLogo } from '@serenityapp/components-react-web';
import { useDidMount } from '@serenityapp/components-react-common';

import { useAnalytics } from '../../../providers';
import {
  buttonSx,
  cardActionsSx,
  cardContentSx,
  cardHeaderSx,
  cardSx,
  containerSx,
  linkSx,
  listItemButtonSx,
  listItemIconSx,
  listItemSx,
  logoWrapperSx,
  spanSx,
} from './needHelpPageStyles';

const NeedHelpPage = () => {
  const analytics = useAnalytics();

  const navigate = useNavigate();

  const navigateToSignIn = () => navigate('/sign-in');
  const navigateToForgotPassword = () => navigate('/forgot-password');
  const navigateToResetPassword = () => navigate('/reset-password');

  useDidMount(() => {
    analytics.track({ name: 'auth/need-help/enter' });
  });

  return (
    <Container sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader sx={cardHeaderSx} title="Having trouble signing in?" />
        <CardContent sx={cardContentSx}>
          <List disablePadding>
            <ListItemButton sx={listItemButtonSx} onClick={navigateToForgotPassword}>
              <ListItemText
                primary="Forgot password?"
                secondary="Don't worry, happens to the best of us."
              />
              <ListItemIcon sx={listItemIconSx}>
                <ArrowForward color="primary" />
              </ListItemIcon>
            </ListItemButton>
            <ListItemButton sx={listItemButtonSx} onClick={navigateToResetPassword}>
              <ListItemText
                primary="Have a verification code?"
                secondary="Enter your verification code to change your password."
              />
              <ListItemIcon sx={listItemIconSx}>
                <ArrowForward color="primary" />
              </ListItemIcon>
            </ListItemButton>
            <ListItem sx={listItemSx}>
              <ListItemText>
                <Typography>Contact us </Typography>
                <Typography>
                  <Link href="tel:833.550.5280" sx={linkSx} underline="always" variant="body2">
                    833.550.5280
                  </Link>
                  <Box component="span" sx={spanSx}>
                    or
                  </Box>
                  <Link
                    href="mailto:support@serenityconnect.com"
                    sx={linkSx}
                    underline="always"
                    variant="body2"
                  >
                    support@serenityconnect.com
                  </Link>
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </CardContent>
        <CardActions sx={cardActionsSx}>
          <Button
            fullWidth
            color="neutral"
            size="large"
            startIcon={<ArrowBack />}
            sx={buttonSx}
            variant="outlined"
            onClick={navigateToSignIn}
          >
            Back to sign in
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default NeedHelpPage;
