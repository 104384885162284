export const containerSx = {
  mt: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  py: 8,
  px: 4,
};

export const buttonSx = {
  mt: 4,
};
