import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Cancel, Refresh, ArrowCircleDown } from '@mui/icons-material';
import { Box, ListItem, ListItemText, Button, Alert } from '@mui/material';
import { DateFn } from '@serenityapp/core';
import { CalendarSyncMode, AppStatus, OrganizationAppEdge } from '@serenityapp/domain';
import { icons } from '@serenityapp/components-react-web';
import { snackAdd, googleCalendarSync, appDisconnect } from '@serenityapp/redux-store';
import {
  listItemsWrapperSx,
  controlsWrapperSx,
  accountEmailSx,
  googleAccountWrapperSx,
  lastSyncedSx,
  pausedLastSyncSx,
} from './connectionStatusStyles';
import { redirectToGoogleCalendarAuthorization } from '../../../../utils';

const { GoogleAccount } = icons;

type ConnectionStatusProps = {
  app: OrganizationAppEdge;
  isDisconnecting: boolean;
  isSyncing: boolean;
  organizationId: string;
  checkedCalendarIds: Array<string>;
};

const ConnectionStatus = ({
  isDisconnecting,
  isSyncing,
  app,
  organizationId,
  checkedCalendarIds,
}: ConnectionStatusProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAppDisconnectSuccess = () => {
    navigate('..');
    dispatch(
      snackAdd({
        message: 'Google Calendar app successfully disconnected.',
        type: 'success',
      }),
    );
  };

  const onAppDisconnectFailed = () => {
    dispatch(
      snackAdd({
        message: 'Could not disconnect Google Calendar app. Please try again.',
        type: 'error',
      }),
    );
  };

  const handleSyncClick = () => {
    dispatch(
      googleCalendarSync({
        appId: app.node.id,
        variables: {
          input: {
            calendarIds: checkedCalendarIds,
            organizationId: organizationId,
            mode: CalendarSyncMode.INCREMENTAL,
          },
        },
      }),
    );
  };

  const handleDisconnectClick = () => {
    dispatch(
      appDisconnect({
        onSuccess: onAppDisconnectSuccess,
        onFailed: onAppDisconnectFailed,
        variables: {
          organizationId: organizationId,
          appId: app.node.id,
        },
      }),
    );
  };

  const handleConnectClick = () => {
    redirectToGoogleCalendarAuthorization();
  };

  return (
    <>
      {app.status === AppStatus.PAUSED && (
        <Alert severity="error">
          The calendar connection has been expired or revoked by your calendar provider. Reconnect
          below to reactivate your calendar features.
        </Alert>
      )}
      <Box sx={listItemsWrapperSx}>
        <ListItem disableGutters>
          <ListItemText
            primary={
              <Box sx={googleAccountWrapperSx}>
                <GoogleAccount />
                <Box sx={accountEmailSx}>{app.credentialEmail}</Box>
              </Box>
            }
            secondary="Account"
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary={
              <Box sx={[lastSyncedSx, app.status === AppStatus.PAUSED && pausedLastSyncSx]}>
                {isSyncing || !app.jobInfo.timestamp
                  ? 'Syncing...'
                  : app.status === AppStatus.PAUSED
                    ? 'Connection paused'
                    : DateFn.format(app.jobInfo.timestamp, 'LLL do, h:mmaaa', '')}
              </Box>
            }
            secondary="Last synced"
          />
        </ListItem>
      </Box>
      <Box sx={controlsWrapperSx}>
        {app.status === AppStatus.ENABLED && (
          <Button
            disabled={isSyncing}
            startIcon={<Refresh />}
            variant="contained"
            onClick={handleSyncClick}
          >
            Manual Sync
          </Button>
        )}
        {app.status !== AppStatus.ENABLED && (
          <Button
            startIcon={<ArrowCircleDown />}
            variant="contained"
            onClick={handleConnectClick}
          >
            Connect
          </Button>
        )}
        {app.status !== AppStatus.DISABLED && (
          <Button
            color="error"
            disabled={isDisconnecting}
            startIcon={<Cancel />}
            variant="outlined"
            onClick={handleDisconnectClick}
          >
            Disconnect
          </Button>
        )}
      </Box>
    </>
  );
};

export default ConnectionStatus;
