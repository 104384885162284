import { useEffect } from 'react';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  Form,
  FormikField,
  HorizontalSerenityColorLogo,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import {
  getViewerState,
  viewerClearSSOSignInError,
  viewerSSOSignIn,
} from '@serenityapp/redux-store';
import {
  SSOLoginFormValues,
  SerenityForm,
  SSOLoginFormConfig,
} from '@serenityapp/components-react-common';

import {
  alertSx,
  buttonSx,
  cardActionsSx,
  cardContentSx,
  cardHeaderSx,
  cardSx,
  containerSx,
  fieldSx,
  logoWrapperSx,
  submitButtonSx,
} from './ssoSignInPageStyles';

type LocationState = {
  from?: string;
};

const SSOSignInPage = () => {
  const dispatch = useDispatch();
  const makeTestId = useMakeTestId('SSOSignInPage');
  const location = useLocation();

  const navigate = useNavigate();
  const { ready, signedIn, ssoSignInError, ssoSigningIn } = useSelector(getViewerState);

  const formConfig = SSOLoginFormConfig;
  const { strings } = formConfig;

  useEffect(() => {
    const locationState = location.state as LocationState;
    if (signedIn && ready) {
      navigate(locationState.from || '/');
    }
  }, [location, navigate, ready, signedIn]);

  const navigateToSignIn = () => {
    if (ssoSignInError) {
      // We don't clear the error on mount, because the page reloads
      // after attempting signing in with SSO. We need to clear it
      // on form submit and on leaving the page.
      dispatch(viewerClearSSOSignInError());
    }
    navigate('/sign-in');
  };

  const handleFormSubmit = (values: SSOLoginFormValues) => {
    if (ssoSignInError) {
      dispatch(viewerClearSSOSignInError());
    }
    dispatch(
      viewerSSOSignIn({
        variables: {
          input: {
            email: values.email,
          },
        },
      }),
    );
  };

  return (
    <Container sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader subheader={strings.subheader} sx={cardHeaderSx} title={strings.title} />
        <SerenityForm config={SSOLoginFormConfig} onSubmit={handleFormSubmit}>
          <Form disabled={ssoSigningIn}>
            <CardContent sx={cardContentSx}>
              {ssoSignInError && (
                <Alert severity="error" sx={alertSx}>
                  {ssoSignInError.message}
                </Alert>
              )}
              <FormikField
                autoFocus
                autoComplete="email"
                dataTestId={makeTestId('email')}
                name="email"
                sx={fieldSx}
                type="email"
                variant="outlined"
              />
            </CardContent>
            <CardActions disableSpacing sx={cardActionsSx}>
              <LoadingButton
                fullWidth
                data-testid={makeTestId('submit')}
                loading={ssoSigningIn || !ready}
                size="large"
                sx={submitButtonSx}
                type="submit"
                variant="contained"
              >
                {strings.submit}
              </LoadingButton>
              <Button
                fullWidth
                color="neutral"
                size="large"
                startIcon={<ArrowBack />}
                sx={buttonSx}
                type="button"
                variant="outlined"
                onClick={navigateToSignIn}
              >
                {strings.back}
              </Button>
            </CardActions>
          </Form>
        </SerenityForm>
      </Card>
    </Container>
  );
};

export default SSOSignInPage;
