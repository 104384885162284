import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { App } from '@serenityapp/core';
import { AppData, appInstall, snackAdd } from '@serenityapp/redux-store';
import { AppCard, ConfirmDialog } from '@serenityapp/components-react-web';
import { AppStatus } from '@serenityapp/domain';

type Props = {
  app: AppData;
};

const AlexaForSeniorLivingAppCard = ({ app }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  // Add route to bring up a drawer with:
  //   - status of customer success provisioning
  //   - diagnostic information (e.g, their ASP id)
  //   - disable button

  const handleCardClick =
    app.status !== AppStatus.DISABLED ? () => navigate('../a4sl') : undefined;
  const handleAppEnableClick = () => setIsOpen(true);

  const onAppInstallFailed = () => {
    dispatch(
      snackAdd({ message: 'Alexa for Senior Living installation failed.', type: 'error' }),
    );
  };

  const onAppInstallSuccess = () => {
    dispatch(
      snackAdd({ message: 'Alexa for Senior Living installed successfully.', type: 'success' }),
    );
  };

  const handleAppEnableSubmit = () => {
    dispatch(
      appInstall({
        onFailed: onAppInstallFailed,
        onSuccess: onAppInstallSuccess,
        variables: {
          appName: App.Names.AlexaForSeniorLiving,
        },
      }),
    );
    setIsOpen(false);
  };

  return (
    <>
      <AppCard
        description={app.description}
        status={app.status}
        title={app.displayName}
        onCardClick={handleCardClick}
        onEnableClick={handleAppEnableClick}
      />
      <ConfirmDialog
        description="Send a request to Serenity Customer Success to enable your organization on Alexa for Senior Living?"
        handleClose={() => setIsOpen(false)}
        handleSubmit={handleAppEnableSubmit}
        isOpen={isOpen}
        title="Enable Alexa for Senior Living"
      />
    </>
  );
};

export default AlexaForSeniorLivingAppCard;
