import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  List,
  ListItem,
  DialogActions,
  Button,
} from '@mui/material';

type UnsupportedFolderDialogProps = {
  onClose: () => void;
  directoryNames: string[];
};

const UnsupportedFolderDialog = ({ onClose, directoryNames }: UnsupportedFolderDialogProps) => {
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Folders are not supported</DialogTitle>
      <DialogContent>
        {directoryNames.length === 1 ? (
          <Typography>
            Sorry,{' '}
            <Typography component="span" sx={boldTextSx}>
              {directoryNames[0]}
            </Typography>{' '}
            is not supported by Serenity. Try uploading a .zip version of the file instead.
          </Typography>
        ) : (
          <Box>
            <Typography>Sorry, following files are not supported by Serenity.</Typography>
            <List dense sx={listSx}>
              {directoryNames.map((directoryName) => (
                <Typography key={directoryName} component="span" sx={boldTextSx}>
                  <ListItem sx={listItemSx}>{directoryName}</ListItem>
                </Typography>
              ))}
            </List>
            <Typography>Try uploading a .zip version of the file instead.</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

const boldTextSx = {
  fontWeight: 'bold',
  display: 'inline',
};

export default UnsupportedFolderDialog;
