import { useNavigate, useParams, Outlet } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Button,
  Typography,
  Skeleton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { TabContext, TabPanel, TabListProps } from '@mui/lab';

import { ConversationTitle, useMakeTestId } from '@serenityapp/components-react-web';

import { useConversationData } from '../hooks';
import ConversationAboutTab from './ConversationAboutTab';
import ConversationPinnedMessagesTab from './ConversationPinnedMessagesTab';
import ConversationMembersTab from './ConversationMembersTab';
import ConversationSettingsTab from './ConversationSettingsTab';
import {
  dialogContentSx,
  tabPanelSx,
  tabsSx,
  dialogTitleSx,
} from './conversationDetailsDialogStyles';

type ConversationDetailsDialogParams = { tabId: string; conversationId: string };

const ConversationDetailsDialog = () => {
  const makeTestId = useMakeTestId('ConversationDetails');
  const mainTestId = makeTestId('');
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const { tabId, conversationId } =
    useParams<ConversationDetailsDialogParams>() as ConversationDetailsDialogParams;

  const { conversationData, conversationMembersData, isLoading } =
    useConversationData(conversationId);

  const { canCreateMessage, subtype, name, isGroupConversation, muted, about } =
    conversationData ?? {};
  const isDM = subtype === 'dm';
  const isAppChannel = subtype === 'app';

  const { membersCount = 0 } = conversationMembersData ?? {};

  const handleChange: TabListProps['onChange'] = (_, newValue) => {
    navigate(`../details/${newValue}`);
  };

  const handleCloseClick = () => navigate('..');

  const tabs = [
    {
      label: `Member${membersCount === 1 ? '' : 's'} ${membersCount}`,
      value: 'members',
      onClick: () => navigate('../details/members'),
      panel: <ConversationMembersTab conversationId={conversationId} />,
    },
    {
      label: 'Pinned Messages',
      value: 'pinned-messages',
      onClick: () => navigate('../details/pinned-messages'),
      panel: <ConversationPinnedMessagesTab conversationId={conversationId} />,
    },
    {
      label: 'Settings',
      value: 'settings',
      onClick: () => navigate('../details/settings'),
      panel: (
        <ConversationSettingsTab
          conversationId={conversationId}
          isConversationDataLoading={isLoading}
          isConversationMuted={muted}
        />
      ),
    },
  ];

  if (isAppChannel && about) {
    // About tab should be first
    tabs.unshift({
      label: 'About',
      value: 'about',
      onClick: () => navigate('../details/about'),
      panel: <ConversationAboutTab aboutText={about} />,
    });
  }

  if (conversationData && !canCreateMessage) {
    // data is loaded and user cannot view the conversation detail
    navigate('..');
  }

  return (
    <Dialog
      fullWidth
      open
      data-testid={mainTestId}
      fullScreen={fullScreen}
      scroll={fullScreen ? 'paper' : 'body'}
      onClose={handleCloseClick}
    >
      <DialogTitle sx={dialogTitleSx}>
        {isLoading && !name ? (
          <Typography variant="subtitle1">
            <Skeleton width="200px" />
          </Typography>
        ) : (
          <ConversationTitle isDM={isDM} isGroupDM={isGroupConversation} title={name ?? ''} />
        )}
        <Tabs
          selectionFollowsFocus
          indicatorColor="primary"
          sx={tabsSx}
          textColor="primary"
          value={tabId}
          onChange={handleChange}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              disableRipple
              data-testid={makeTestId(tab.value)}
              label={tab.label}
              sx={[fullScreen && { px: 0 }]}
              value={tab.value}
              onClick={tab.onClick}
            />
          ))}
        </Tabs>
      </DialogTitle>
      <DialogContent
        sx={[dialogContentSx, !fullScreen && { minHeight: '50vh', maxHeight: '50vh' }]}
      >
        <TabContext value={tabId}>
          {tabs.map((tab) => (
            <TabPanel
              key={tab.value}
              data-testid={makeTestId(tab.value)}
              sx={tabPanelSx}
              value={tab.value}
            >
              {tab.panel}
            </TabPanel>
          ))}
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCloseClick}>
          Close
        </Button>
      </DialogActions>
      <Outlet />
    </Dialog>
  );
};

export default ConversationDetailsDialog;
