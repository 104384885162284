export const cardSx = {
  pt: 2,
  width: 1,
  height: 236,
};

export const cardsWrapperSx = {
  p: 2,
  display: 'flex',
  width: 1,
  flexWrap: 'wrap',
};

export const cardMediaSx = {
  height: 108,
  objectFit: 'none',
};

export const cardContentSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  pt: 2,
  pb: 3,
};
