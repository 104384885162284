import { Schema } from '@serenityapp/domain';
import * as Sentry from '@sentry/react';
import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { ClientApi } from '@serenityapp/api-client-graph';

export default function conversationCreate(
  result: ClientApi.Conversation.Api.Create.MutationResult,
  _args: Schema.Conversation.Create.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  const createdConversationOutput = result?.result;

  if (info.error || !createdConversationOutput || !createdConversationOutput.success) {
    return;
  }

  const { id, edited, name, archived, type, subtype, subsubtype, description, orgId } =
    createdConversationOutput.conversation;

  const conversation: ClientApi.Conversation.Fragments.ListType = {
    id,
    orgId,
    edited,
    name: name || '',
    description: description || '',
    type,
    archived: archived || 0,
    subtype: subtype || '',
    subsubtype: subsubtype || '',
    __typename: 'Conversation',
  };

  const node = { node: conversation, __typename: 'ViewerConversationEdge' } as {
    node: ClientApi.Conversation.Fragments.ListType;
    __typename: 'ViewerConversationEdge';
  };

  const q = {
    query: ClientApi.Viewer.Api.Conversations.Query,
    variables: {},
    requestPolicy: 'cache-and-network',
  };

  cache.updateQuery<ClientApi.Viewer.Api.Conversations.Response>(q, (data) => {
    if (!data) {
      Sentry.addBreadcrumb({
        message:
          'Failed to get current viewer conversations query data while trying to add a conversation',
        data: { q },
      });
      return null;
    }
    const updatedEdges = [node, ...data.viewer.conversations.edges];

    const newConversations = {
      ...data.viewer.conversations,
      edges: updatedEdges,
    };

    data.viewer.conversations = newConversations;
    return data;
  });
}
