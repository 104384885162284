export const rootSx = {
  bgcolor: 'background.paper',
  display: 'flex',
  p: 2,
  pt: 0,
  width: 1,

  '& .tree-view-output': {
    width: 500,
    height: 500,
    overflow: 'scroll',
  },
};

export const innerSx = {
  borderWidth: 1,
  borderColor: 'outlineBorder',
  borderRadius: 1.5,
  borderStyle: 'solid',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  bgcolor: 'background.paper',
  position: 'relative',
};

export const toolbarSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& .MuiIconButton-root': {
    display: 'flex',
    justifyContent: 'center',
    color: 'action.active',

    '&:hover': {
      bgcolor: 'action.hover',
    },
  },
};

export const toolbarEmptySx = {
  display: 'flex',
  flexGrow: 1,
  height: 1,
  cursor: 'text',
};

export const toolbarRightSx = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  ml: 'auto',
  gap: 1,
  mr: 1.5,
  py: 0.75,
};

export const contentEditableSx = {
  maxHeight: 200,
  overflowY: 'auto',
  padding: 1.5,
};
