import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '@serenityapp/components-react-web';
import { getSnackbarFirstMainSnack, snackDismiss } from '@serenityapp/redux-store';

/**
 * Displays the first snack in the queue.
 */
const MainPageSnackbar = () => {
  const dispatch = useDispatch();
  const firstSnack = useSelector(getSnackbarFirstMainSnack);

  if (!firstSnack) return null;

  const onSnackbarClose = () => dispatch(snackDismiss({ id: firstSnack.id }));

  return (
    <Snackbar
      open
      autoHideDuration={firstSnack.timeout}
      message={firstSnack.message}
      type={firstSnack.type}
      onClose={onSnackbarClose}
    />
  );
};

MainPageSnackbar.displayName = 'MainPageSnackbar';

export default MainPageSnackbar;
