import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { batch, useDispatch } from 'react-redux';
import {
  viewerSetActiveConversationId,
  viewerSetActiveUnreadConversationId,
} from '@serenityapp/redux-store';
import useConversationData from './useConversationData';

export default function useConversationPage(id: string) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { conversationData, isLoading } = useConversationData(id);

  const { unreadCount = 0 } = conversationData ?? {};

  useEffect(() => {
    if (!isLoading && !conversationData) navigate('/client/home');
  }, [conversationData, isLoading, navigate]);

  useEffect(() => {
    if (unreadCount > 0) {
      dispatch(viewerSetActiveUnreadConversationId({ conversationId: id }));
    }

    return () => {
      dispatch(viewerSetActiveUnreadConversationId({ conversationId: null }));
    };
    // This effect needs to be fired **only** once for each conversation (when id changes).
    // Other dependencies are being ignored because we need only their initial values. We do
    // not care about those getting changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch]);

  useEffect(() => {
    batch(() => {
      dispatch(viewerSetActiveConversationId({ activeConversationId: id }));
    });
    return () => {
      dispatch(viewerSetActiveConversationId({ activeConversationId: null }));
    };
  }, [id, dispatch]);

  return {
    conversation: conversationData,
    isLoading,
  };
}
