import { useDispatch } from 'react-redux';
import { FormikValues } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import {
  snackAdd,
  useRetentionPolicies,
  useRetentionPolicyEditMutation,
} from '@serenityapp/redux-store';
import type { RouteParamId, RetentionPolicy } from '@serenityapp/domain';
import type { ViewerOrganizationRetentionPoliciesQueryResult } from '@serenityapp/api-graphql';

import RetentionPolicyCreateEditForm from './components/RetentionPolicyCreateEditForm';
import { useCurrentUser } from '../common/hooks';

/**
 * Extracts the currently opened retention policy from the list of retention policies.
 */
export const retentionPolicyTransformer = (
  retentionPoliciesData: ViewerOrganizationRetentionPoliciesQueryResult,
  isLoadingRetentionPolicies: boolean,
  policyId: string,
): RetentionPolicy | undefined => {
  if (isLoadingRetentionPolicies) return undefined;

  const edge = retentionPoliciesData.viewer.organization.retentionPolicies.edges.find(
    (edge) => edge.node.id === policyId,
  );

  return edge?.node;
};

const RetentionPolicyEditDrawer = () => {
  const { id } = useParams<RouteParamId>() as RouteParamId;

  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch the retention policies
  const retentionPolicies = useRetentionPolicies();
  // Extract the currently opened retention policy
  const retentionPolicy = retentionPolicyTransformer(
    retentionPolicies.data,
    retentionPolicies.isLoading,
    id,
  );

  // Mutation to edit the retention policy
  const { doMutation: editRetentionPolicy, isFetching } = useRetentionPolicyEditMutation();

  // Initial values for the form
  const initialValues = {
    daysToRetain: retentionPolicy?.daysToRetain ?? 0,
    retainForever: retentionPolicy?.retainForever,
    name: retentionPolicy?.name,
  };

  const onSuccess = () => {
    navigate('../');
    dispatch(snackAdd({ message: 'Retention policy successfully edited.', type: 'success' }));
  };

  const onFailed = () => {
    dispatch(snackAdd({ message: 'Error editing retention policy.', type: 'error' }));
  };

  // Handle form submission
  const handleSubmit = (values: FormikValues) => {
    const orgId = currentUser?.orgId;
    if (!orgId) return;

    editRetentionPolicy(
      {
        input: {
          organization: {
            id: orgId,
          },
          retentionPolicy: {
            id,
            name: values.name,
            skipArchive: false,
            retainForever: values.daysToRetain === '0',
            daysToRetain: values.daysToRetain !== '0' ? Number(values.daysToRetain) : undefined,
          },
        },
      },
      onSuccess,
      onFailed,
    );
  };

  return (
    <RetentionPolicyCreateEditForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isDefault={retentionPolicy?.isDefault}
      isLoading={retentionPolicies.isLoading || !retentionPolicy}
      isSaving={isFetching}
      title={retentionPolicy?.name ?? ''}
    />
  );
};

export default RetentionPolicyEditDrawer;
