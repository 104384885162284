import { useSelector, useDispatch } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  Form,
  FormikField,
  HorizontalSerenityColorLogo,
  PasswordField,
} from '@serenityapp/components-react-web';
import {
  SerenityForm,
  ForgotPasswordCodeFormValues,
  ForgotPasswordCodeFormConfig,
  useDidMount,
} from '@serenityapp/components-react-common';
import {
  getViewerState,
  viewerClearForgotPasswordError,
  viewerForgotPasswordSubmit,
} from '@serenityapp/redux-store';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import {
  alertSx,
  buttonSx,
  cardActionsSx,
  cardContentSx,
  cardHeaderSx,
  cardSx,
  containerSx,
  fieldSx,
  linkSx,
  logoWrapperSx,
} from './resetPasswordPageStyles';
import { useAnalytics } from '../../../providers';
import { useNavigate } from 'react-router-dom';

const ResetPasswordPage = () => {
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const formConfig = ForgotPasswordCodeFormConfig;
  const { strings } = formConfig;

  const { forgotPasswordEmail, forgotPasswordError, forgotPasswordLoading, forgotPasswordStep } =
    useSelector(getViewerState);

  useDidMount(() => {
    analytics.track({ name: 'auth/reset-password/enter' });
  });

  const navigateToSignIn = () => navigate('/sign-in');
  const navigateToForgotPassword = () => navigate('/forgot-password');

  const handleSubmit = ({ email, code, newPassword }: ForgotPasswordCodeFormValues) => {
    if (forgotPasswordError) {
      dispatch(viewerClearForgotPasswordError());
    }
    dispatch(
      viewerForgotPasswordSubmit({
        email: email.trim(),
        code: code.trim(),
        password: newPassword.trim(),
      }),
    );
  };

  const initialValues: ForgotPasswordCodeFormValues = {
    code: '',
    newPassword: '',
    email: forgotPasswordEmail ?? '',
  };

  if (forgotPasswordStep === 'success') return <ResetPasswordSuccess />;

  return (
    <Container sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader subheader={strings.instructions} sx={cardHeaderSx} title={strings.title} />
        <SerenityForm
          config={ForgotPasswordCodeFormConfig}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <Form disabled={forgotPasswordLoading}>
            <CardContent sx={cardContentSx}>
              {forgotPasswordError && (
                <Alert severity="error" sx={alertSx}>
                  {forgotPasswordError.message}
                </Alert>
              )}
              <FormikField
                autoFocus
                autoComplete="username"
                name="email"
                sx={fieldSx}
                type="email"
                variant="outlined"
              />
              <FormikField
                autoComplete="code"
                name="code"
                sx={fieldSx}
                type="text"
                variant="outlined"
              />
              <PasswordField autoComplete="new-password" name="newPassword" sx={fieldSx} />
              <Link
                component="button"
                sx={linkSx}
                type="button"
                underline="always"
                variant="body1"
                onClick={navigateToForgotPassword}
              >
                Request another code
              </Link>
            </CardContent>
            <CardActions disableSpacing sx={cardActionsSx}>
              <LoadingButton
                fullWidth
                loading={forgotPasswordLoading}
                size="large"
                type="submit"
                variant="contained"
              >
                {strings.submit}
              </LoadingButton>
              <Button
                fullWidth
                color="neutral"
                size="large"
                startIcon={<ArrowBack />}
                sx={buttonSx}
                type="button"
                variant="outlined"
                onClick={navigateToSignIn}
              >
                {strings.backToSignIn}
              </Button>
            </CardActions>
          </Form>
        </SerenityForm>
      </Card>
    </Container>
  );
};

export default ResetPasswordPage;
