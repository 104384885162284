export const containerSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  mt: 8,
  py: 6,
};

export const linkSx = {
  display: 'flex',
  textDecoration: 'none',
};

export const serenityLogoSx = {
  width: 'auto',
  height: 160,
  mb: 2.5,
};

export const storeButtonsContainerSx = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  my: 2,
  mx: 0,
};

export const subtitleSx = {
  mt: 2,
};

export const titleSx = {
  my: 4,
  mx: 2,
  textAlign: 'center',
};
