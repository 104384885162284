import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useMakeTestId } from '@serenityapp/components-react-web';

type ArchiveDialogProps = {
  dataTestId?: string;
  title: string;
  resourceName: string;
  isArchiving: boolean;
  onClose: () => void;
  handleConfirmClick: () => void;
};

const ArchiveDialog = ({
  dataTestId,
  title,
  resourceName,
  isArchiving,
  onClose,
  handleConfirmClick,
}: ArchiveDialogProps) => {
  const makeTestId = useMakeTestId('ArchiveDialog', dataTestId);
  const archiveDialog = makeTestId('');

  return (
    <Dialog
      fullWidth
      open
      data-testid={archiveDialog}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to Archive {resourceName}?</Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>Archiving will remove {resourceName} from the list.</ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} disabled={isArchiving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm')}
          loading={isArchiving}
          variant="contained"
          onClick={handleConfirmClick}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default ArchiveDialog;
