import Box from '@mui/material/Box';

type DotProps = {
  active: boolean;
};

const Dot = ({ active }: DotProps) => {
  return (
    <Box
      sx={{
        display: 'inline-block',
        margin: '0 3px',
        width: '8px',
        height: '8px',
        backgroundColor: active ? 'grey.500' : 'grey.400',
        borderRadius: '50%',
        opacity: active ? 1 : 0.4,
        transform: active ? 'scale(1.125)' : 'scale(1)', // scale to 9px width/height and back to 8px
        transition: `all 250ms ease-out`,
        transitionDelay: active ? '150ms' : '0ms',
      }}
    />
  );
};

export default Dot;
