import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import React, { useState } from 'react';
import { useMakeTestId } from '@serenityapp/components-react-web';

type SelectInputFilterProps = {
  dataTestId?: string;
  items: Array<{
    value: string;
    label: string;
  }>;
  fieldName: string;
  fieldLabel: string;
};

/**
 * This is a select component that uses DataGrid's context (api) to update the filter model.
 *
 * @param items A list of items to show in the select dropdown
 * @param fieldName The name of the field to filter on
 * @param fieldLabel The label to show in the select dropdown
 */
const SelectInputFilter = ({
  items,
  fieldName,
  fieldLabel,
  dataTestId,
}: SelectInputFilterProps) => {
  const makeTestId = useMakeTestId('SelectInputFilter', dataTestId);
  const apiRef = useGridApiContext();
  const [selectedValue, setSelectedValue] = useState<string>('All');

  const handleFilterChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    apiRef.current.upsertFilterItem({
      field: fieldName,
      operator: value === 'All' ? 'isAnyOf' : 'contains',
      value,
    });

    setSelectedValue(value);
  };

  const labelId = `${fieldName}-select-label`;

  return (
    <FormControl data-testid={makeTestId('')}>
      <InputLabel id={labelId} data-testid={makeTestId(labelId)}>
        {fieldLabel}
      </InputLabel>
      <Select
        labelId={labelId}
        data-testid={makeTestId(`${fieldName}-select`)}
        id={`${fieldName}-select`}
        label={fieldLabel}
        defaultValue="All"
        variant="outlined"
        value={selectedValue}
        onChange={handleFilterChange}
        sx={selectSx}
      >
        <MenuItem key="All" value="All" data-testid={makeTestId('select-item-all')}>
          All
        </MenuItem>
        {items.map((item) => (
          <MenuItem
            key={item.value}
            value={item.value}
            data-testid={makeTestId(`select-item-${item.value}`)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const selectSx = {
  width: 220,
};

export default SelectInputFilter;
