import { useEffect, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Backdrop } from '@mui/material';

import { INSERT_ATTACHMENT_COMMAND } from '../../customLexicalCommands';
import UnsupportedFolderDialog from './UnsupportedFolderDialog';
import BackdropContent from './BackdropContent';

type FileDragAndDropPluginProps = {
  conversationName: string;
};

export default function FileDragAndDropPlugin({
  conversationName,
}: FileDragAndDropPluginProps): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [isDragging, setIsDragging] = useState(false);
  const [showNotSupportedFormatDialog, setShowNotSupportedFormatDialog] = useState(false);
  const [directoryNames, setDirectoryNames] = useState<string[]>([]);
  const [nonDirectoryEntries, setNonDirectoryEntries] = useState<File[]>([]);

  useEffect(() => {
    // dispatch command from AttachmentsPlugin
    const handleInsertAttachmentCommand = (files: File[]) => {
      editor.dispatchCommand(INSERT_ATTACHMENT_COMMAND, files);
    };

    const handleDrop = (event: DragEvent) => {
      event.preventDefault();

      // we need to check if DragEvent contains selected text of links
      // in that case we don't want proceed with drop event
      if (!event?.dataTransfer?.types.includes('Files')) return;

      // very old versions of browsers, IE and Firefox on Android don't support the feature yet
      const supportsWebkitGetAsEntry = 'webkitGetAsEntry' in DataTransferItem.prototype;

      if (event.dataTransfer && supportsWebkitGetAsEntry) {
        const { items, files } = event.dataTransfer;

        const directoryEntries: DataTransferItem[] = Array.from(items).filter(
          (item) => item?.webkitGetAsEntry()?.isDirectory,
        );

        const directoryEntriesNames = directoryEntries.map(
          (directory) => directory.webkitGetAsEntry()?.name || '',
        );

        // used to display to user what files won't attach to message entry
        if (directoryEntriesNames) setDirectoryNames(directoryEntriesNames);

        if (directoryEntries.length > 0) {
          // if dropped files contain directory
          // we display warning dialog and do nothing until user closes dialog
          const nonDirectoryEntries = Array.from(files).filter(
            (file) => !directoryEntriesNames.includes(file.name),
          );

          setNonDirectoryEntries(nonDirectoryEntries);
          setShowNotSupportedFormatDialog(true);
        } else {
          handleInsertAttachmentCommand(Array.from(files));
        }
      }

      // if webKitGetAsEntry is not supported just drop files without the check
      // related to IE and other old browsers
      if (!supportsWebkitGetAsEntry) {
        const files: FileList | undefined = event.dataTransfer?.files;
        if (files) handleInsertAttachmentCommand(Array.from(files));
      }

      setIsDragging(false);
    };

    // By default, data cannot be dropped in other elements.
    // To allow a drop, we must prevent the default handling of the element.
    // Otherwise, file will be open in a separate tab in the browser
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    // fires when you stop dragging the element
    const handleDragEnd = (event: DragEvent) => {
      event.preventDefault();
    };

    // fires when you drag the element outside of the boundary of the drop target
    const handleDragLeave = (event: DragEvent) => {
      event.preventDefault();

      // Check if the mouse pointer has left the window
      if (
        event.clientX <= 0 ||
        event.clientY <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        setIsDragging(false);
      }
    };

    // fires repeatedly as long as you’re dragging the element within the boundary of the drop target
    const handleDragEnter = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();

      // we need to check if DragEvent contains selected text of links
      // in that case we don't want to display backdrop
      if (!event?.dataTransfer?.types.includes('Files')) return;

      setIsDragging(true);
    };

    // Add DragEvent listeners
    window.addEventListener('drop', handleDrop);
    window.addEventListener('dragend', handleDragEnd);
    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('dragenter', handleDragEnter);

    // Clean up DragEvent listeners
    return () => {
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragend', handleDragOver);
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('dragenter', handleDragEnter);
    };
  }, [editor, isDragging]);

  const handleNotSupportedFormatDialogClose = () => {
    // after the dialog is closed we can check and attach rest of the files
    setShowNotSupportedFormatDialog(false);
    editor.dispatchCommand(INSERT_ATTACHMENT_COMMAND, nonDirectoryEntries);
  };

  return (
    <>
      <Backdrop open={isDragging} sx={backdropSx}>
        <BackdropContent conversationName={conversationName} isOpen={isDragging} />
      </Backdrop>
      {showNotSupportedFormatDialog && (
        <UnsupportedFolderDialog
          directoryNames={directoryNames}
          onClose={handleNotSupportedFormatDialogClose}
        />
      )}
    </>
  );
}

const backdropSx = {
  zIndex: 9999,
  bgcolor: 'backdropOverlay',
};
