import { GridRow, GridRowProps } from '@mui/x-data-grid-pro';
import { useMakeTestId } from '@serenityapp/components-react-web';

type CustomGridRowProps = GridRowProps & {
  dataTestId?: string;
};

/**
 * This is a custom GridRow component that adds a data-testid attribute to the GridRow component from MUI DataGrid.
 */
const CustomGridRow = ({ dataTestId, ...rest }: CustomGridRowProps) => {
  const makeTestId = useMakeTestId('CustomGridRow');
  return <GridRow {...rest} data-testid={makeTestId(dataTestId ?? '')} />;
};

export default CustomGridRow;
