import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  FormikField,
  Form,
  HorizontalSerenityColorLogo,
} from '@serenityapp/components-react-web';
import {
  SerenityForm,
  ForgotPasswordEmailFormConfig,
  ForgotPasswordEmailFormValues,
  useDidMount,
} from '@serenityapp/components-react-common';
import {
  viewerForgotPassword,
  viewerClearForgotPasswordError,
  getViewerState,
} from '@serenityapp/redux-store';
import {
  alertSx,
  buttonSx,
  cardActionsSx,
  cardContentSx,
  cardHeaderSx,
  cardSx,
  containerSx,
  fieldSx,
  logoWrapperSx,
  submitButtonSx,
} from './forgotPasswordPageStyles';
import { useAnalytics } from '../../../providers';

const ForgotPasswordPage = () => {
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const navigateToSignIn = () => navigate('/sign-in');
  const navigateToResetPassword = () => navigate('/reset-password');

  const { forgotPasswordError, forgotPasswordLoading } = useSelector(getViewerState);

  const formConfig = ForgotPasswordEmailFormConfig;
  const { strings } = formConfig;

  const handleSubmit = ({ email }: ForgotPasswordEmailFormValues) => {
    if (forgotPasswordError) {
      dispatch(viewerClearForgotPasswordError());
    }

    dispatch(viewerForgotPassword({ email: email.trim(), onSuccess: navigateToResetPassword }));
  };

  useDidMount(() => {
    dispatch(viewerClearForgotPasswordError());
    analytics.track({ name: 'auth/forgot-password/enter' });
  });

  return (
    <Container sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader subheader={strings.instructions} sx={cardHeaderSx} title={strings.title} />
        <SerenityForm config={ForgotPasswordEmailFormConfig} onSubmit={handleSubmit}>
          <Form disabled={forgotPasswordLoading}>
            <CardContent sx={cardContentSx}>
              {forgotPasswordError && (
                <Alert severity="error" sx={alertSx}>
                  {forgotPasswordError.message}
                </Alert>
              )}
              <FormikField
                autoFocus
                autoComplete="email"
                name="email"
                sx={fieldSx}
                type="email"
                variant="outlined"
              />
            </CardContent>
            <CardActions disableSpacing sx={cardActionsSx}>
              <LoadingButton
                fullWidth
                loading={forgotPasswordLoading}
                size="large"
                sx={submitButtonSx}
                type="submit"
                variant="contained"
              >
                {strings.submit}
              </LoadingButton>
              <Button
                fullWidth
                color="neutral"
                size="large"
                startIcon={<ArrowBack />}
                sx={buttonSx}
                type="button"
                variant="outlined"
                onClick={navigateToSignIn}
              >
                {strings.backToSignIn}
              </Button>
            </CardActions>
          </Form>
        </SerenityForm>
      </Card>
    </Container>
  );
};

export default ForgotPasswordPage;
