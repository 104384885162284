import * as R from 'ramda';

type ImageDimensions = {
  height: number;
  width: number;
};

/** Removes header from a data URL returning only the Base64 data string */
const stripDataURLHeader = (dataURL: string): string => dataURL.replace(/^data:(.+),/i, '');

/** Calculates new width and height within given maximum size while keeping the original aspect ratio */
const getReducedSize = (height: number, width: number, maxSize: number): ImageDimensions => {
  if (width > height) {
    if (width > maxSize) {
      height *= maxSize / width;
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }
  }
  return {
    height: Math.floor(height),
    width: Math.floor(width),
  };
};

/**
 * Returns image's height and width given its URL.
 * @param url Image source URL
 * @returns Image dimensions
 */
export function getImageSize(url: string): Promise<ImageDimensions> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('error', () => reject(`Failed to load image at ${url}`));
    image.addEventListener('load', () => resolve({ height: image.height, width: image.width }));
    image.src = url;
  });
}

type ResizeImageConfig = {
  // New image height
  height?: number;
  // New image width
  width?: number;
  // Max size (height or width) the image should fit into
  maxSize?: number;
  // Resize the image by a percentage
  percentage?: number;
  // Output JPEG image quality (0-1)
  quality?: number;
};

/** Given resize config and an image element, calculates new dimensions the image would be resized to */
const calculateResizedDimensions = (
  config: ResizeImageConfig,
  image: HTMLImageElement,
): ImageDimensions => {
  let width = image.width;
  let height = image.height;

  // Configure dimensions explicitly
  if (config.width !== undefined && config.height !== undefined) {
    width = config.width;
    height = config.height;
  }
  // Calculate height if only width is specified and configure dimensions explicitly
  if (config.width !== undefined && config.height === undefined) {
    width = config.width;
    height = image.height / (image.width / config.width);
  }
  // Calculate width if only height is specified and configure dimensions explicitly
  if (config.height !== undefined && config.width === undefined) {
    height = config.height;
    width = image.width / (image.height / config.height);
  }
  // Configure dimensions based on a percentage
  if (config.percentage) {
    width = image.width * config.percentage;
    height = image.height * config.percentage;
  }
  // Clamp configured or original dimensions while keeping the aspect ratio
  if (config.maxSize) {
    const calculated = getReducedSize(height, width, config.maxSize);
    width = calculated.width;
    height = calculated.height;
  }

  return {
    height: Math.floor(height),
    width: Math.floor(width),
  };
};

/** Resizes image at the URL and draws the output to an in-memory canvas for further processing */
const resizeImageOnCanvas = (
  config: ResizeImageConfig,
  url: string,
): Promise<ImageDimensions & { canvas: HTMLCanvasElement }> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('error', ({ error }) => reject(error));
    image.addEventListener('load', () => {
      const { height, width } = calculateResizedDimensions(config, image);

      // Initiate a canvas where the image will be drawn with new dimensions
      const canvas = document.createElement('canvas');
      canvas.height = height;
      canvas.width = width;

      const context = canvas.getContext('2d');
      if (context === null) {
        return reject('Failed to get drawing context while resizing an image');
      }

      context.drawImage(image, 0, 0, width, height);

      return resolve({ canvas, height, width });
    });
    image.src = url;
  });
};

/**
 * Returns a resized JPEG copy of an image at the URL as a Base64 encoded string and its new dimensions.
 *
 * Configuration can take a specific height-width pair, both height or width separately,
 * percentage resize or a maximum size to clamp the image to.
 * Output quality can be configured as a float between 0 and 1.
 *
 * Unless height-width pair is given, the aspect ratio is preserved.
 *
 * If the max size constraint is set along with other size options, it's always applied last.
 *
 * @param config Resize configuration
 * @param url Image source URL
 * @returns Resized image dimensions and its Base64 encoded data
 */
export async function getResizedImageAsBase64(
  config: ResizeImageConfig,
  url: string,
): Promise<ImageDimensions & { base64: string }> {
  // Output JPEG quality is a float between 0 and 1
  const outputQuality = R.clamp(0, 1, config.quality ?? 0.8);

  // Resize the image and draw it to a in-memory canvas
  const resizedImage = await resizeImageOnCanvas(config, url);

  return new Promise((resolve, reject) => {
    try {
      const dataURL = resizedImage.canvas.toDataURL('image/jpeg', outputQuality);
      const base64EncodedImage = stripDataURLHeader(dataURL);
      return resolve({
        base64: base64EncodedImage,
        height: resizedImage.height,
        width: resizedImage.width,
      });
    } catch (error) {
      return reject(error);
    }
  });
}

/**
 * Returns a resized JPEG copy of an image at the URL as a Blob instance and its new dimensions.
 *
 * Configuration can take a specific height-width pair, both height or width separately,
 * percentage resize or a maximum size to clamp the image to.
 * Output quality can be configured as a float between 0 and 1.
 *
 * Unless height-width pair is given, the aspect ratio is preserved.
 *
 * If the max size constraint is set along with other size options, it's always applied last.
 *
 * @param config Resize configuration
 * @param url Image source URL
 * @returns Resized image dimensions and its Blob instance
 */
export async function getResizedImageAsBlob(
  config: ResizeImageConfig,
  url: string,
): Promise<ImageDimensions & { blob: Blob }> {
  // Output JPEG quality is a float between 0 and 1
  const outputQuality = R.clamp(0, 1, config.quality ?? 0.8);

  // Resize the image and draw it to a in-memory canvas
  const resizedImage = await resizeImageOnCanvas(config, url);

  return new Promise((resolve, reject) => {
    // Output the resized image as a Blob
    resizedImage.canvas.toBlob(
      (blob) => {
        if (blob === null) {
          return reject('Failed to output resized image as Blob');
        }
        try {
          return resolve({
            blob,
            height: resizedImage.height,
            width: resizedImage.width,
          });
        } catch (error) {
          return reject(error);
        }
      },
      'image/jpeg',
      outputQuality,
    );
  });
}
