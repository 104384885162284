import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  getIsPasswordResetLoading,
  RootState,
  snackAdd,
  userResetPassword,
} from '@serenityapp/redux-store';
import {
  ResetPasswordFormConfig,
  ResetPasswordFormValues,
  SerenityForm,
} from '@serenityapp/components-react-common';
import { FormikField, Form, useMakeTestId } from '@serenityapp/components-react-web';

type ResetPasswordDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  userName: string;
  email: string;
  userId: string;
  onActionSuccess?: () => void;
};

const ResetPasswordDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  email,
  userName,
  userId,
  onActionSuccess,
}: ResetPasswordDialogProps) => {
  const makeTestId = useMakeTestId('ResetPasswordDialog', dataTestId);
  const resetPasswordDialog = makeTestId('');
  const dispatch = useDispatch();

  const isResetPasswordLoading = useSelector((state: RootState) =>
    getIsPasswordResetLoading(state),
  );

  const handleSubmit = async ({ temporaryPassword }: ResetPasswordFormValues) => {
    const input = {
      id: userId,
      temporaryPassword: temporaryPassword ?? 'Serenity!',
    };

    dispatch(
      userResetPassword({
        input,
        onFailed: () => {
          dispatch(snackAdd({ message: 'Error resetting password', type: 'error' }));
          onClose();
        },
        onSuccess: () => {
          dispatch(snackAdd({ message: 'Password successfully reset', type: 'success' }));
          onClose();
          onActionSuccess?.();
        },
      }),
    );
  };

  return (
    <SerenityForm config={ResetPasswordFormConfig} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Dialog
          fullWidth
          data-testid={resetPasswordDialog}
          open={isOpen}
          onClick={(event) => event.stopPropagation()}
          onClose={onClose}
        >
          <DialogTitle>Reset password</DialogTitle>
          <DialogContent>
            <Typography>
              A password reset will be sent by email to {userName} at {email} with a temporary
              password.
            </Typography>
            <Alert severity="info" sx={alertSx}>
              <strong>Note: </strong>
              Upon next login the user will be prompted to change their password.
            </Alert>
            <Form disabled={isResetPasswordLoading}>
              <FormikField
                autoFocus
                dataTestId={makeTestId('temporaryPassword')}
                name="temporaryPassword"
                variant="filled"
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid={makeTestId('cancel')}
              disabled={isResetPasswordLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
            <LoadingButton
              data-testid={makeTestId('resetPassword')}
              loading={isResetPasswordLoading}
              variant="contained"
              onClick={submitForm}
            >
              Reset password
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </SerenityForm>
  );
};

const alertSx = {
  my: 2,
};

export default ResetPasswordDialog;
