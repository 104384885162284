import * as Sentry from '@sentry/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createTextNode, $getRoot, TextNode } from 'lexical';
import { useEffect, useState } from 'react';
import { escapeRegExp } from 'lodash';

import { SORT_DIRECTION, UserKind, UserProps } from '@serenityapp/domain';
import {
  useViewerOrganizationUsers,
  viewerOrganizationUsersTransformer,
} from '@serenityapp/redux-store';

import { useCurrentUser } from '../../../../common/hooks';
import { MentionNode } from '../nodes';
import { $createMentionNode, $isMentionNode } from '../nodes/MentionNode';
import useCheckInPolicy from './useCheckInPolicy';

type FullNameMatcherResult = {
  index: number;
  length: number;
  text: string;
};

/**
 * A function that takes in TextNode, finds all organization user full name matches within the text node,
 * splits up the text by matches, replaces the matches with MentionNode and saves all changes into
 * editor's state.
 * @param node pure TextNode
 * @param users array of users of viewer's organization
 */
const handleMentionCreation = (node: TextNode, users: Array<UserProps>) => {
  const nodeText = node.getTextContent();

  let text = nodeText;
  let remainingTextNode = node;
  let match;

  while ((match = fullNameMatcher(text, users)) && match !== null) {
    const matchStart = match?.index;
    const matchLength = match.length;
    const matchEnd = matchStart + matchLength;

    let mentionTextNode;

    if (matchStart === 0) {
      [mentionTextNode, remainingTextNode] = remainingTextNode.splitText(matchLength);
    } else {
      [, mentionTextNode, remainingTextNode] = remainingTextNode.splitText(matchStart, matchEnd);
    }

    const matchedFullName = mentionTextNode.getTextContent().slice(1);
    const matchedUser = users?.find(
      (user) => user.fullName?.toLocaleLowerCase() === matchedFullName.toLowerCase(),
    );

    if (!matchedUser) {
      Sentry.captureMessage(
        `User with full name ${matchedFullName} not found in organization users`,
      );
      return;
    }

    const mentionNode = $createMentionNode(`@${matchedFullName}`, matchedUser);
    mentionTextNode.replace(mentionNode);

    text = text.substring(matchEnd);
  }
};

/**
 * A matcher that matches user full name written in the message entry
 * based on the regex from users in viewer organization
 * @param text
 * @param users array of users of viewer's organization
 * @param isFullMatchRegex if true user full name is matched fully
 */
const fullNameMatcher = (
  text: string,
  users: Array<UserProps>,
  isFullMatchRegex = false,
): FullNameMatcherResult | null => {
  const regexForFullNames = new RegExp(
    users.map((user) => `@${escapeRegExp(user.fullName)}`).join('|'),
    'i',
  );

  const regexForFullNamesWithFullMatch = new RegExp(`^(${regexForFullNames.source})$`, 'i');

  const regex = isFullMatchRegex ? regexForFullNamesWithFullMatch : regexForFullNames;

  // If no regex is available
  if (regex === null) return null;

  // Execute regex on provided string
  const match = regex.exec(text);

  // If no match is found we return null
  if (match === null) return null;

  const fullMatch = match[0];

  return {
    index: match.index,
    length: fullMatch.length,
    text: fullMatch,
  };
};

/**
 * A plugin that is used to replace all user fullName typed into message entry with MentionNode.
 * Registers a listener that will run when a text node is marked dirty during an update.
 * @returns null
 */
export default function AutoMentionsPlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  const [isAutoMentionsPluginActive, setIsAutoMentionsPluginActive] = useState(false);

  const currentUser = useCurrentUser();

  const { data } = useViewerOrganizationUsers({
    variables: {
      organizationInput: {
        orgId: currentUser?.orgId,
      },
      usersInput: { kinds: [UserKind.RESIDENT] },
    },
  });

  const { serviceLevels } = useCheckInPolicy();

  const organizationUsers = viewerOrganizationUsersTransformer(
    data,
    serviceLevels,
    undefined,
    SORT_DIRECTION.DESC,
  );

  // For now, mentions or detection of `@{fullName}` should work only in pair with slash commands
  useEffect(() => {
    const updateListener = () => {
      editor.getEditorState().read(() => {
        const root = $getRoot();
        const rootTextContent = root.getTextContent();
        setIsAutoMentionsPluginActive(
          rootTextContent.startsWith('/') && rootTextContent.includes('@'),
        );
      });
    };
    const removeUpdateListener = editor.registerUpdateListener(updateListener);

    return () => {
      removeUpdateListener();
    };
  }, [editor]);

  // If created MentionNode's text content doesn't match to user fullNames regex anymore
  // we want to transform MentionNode back to TextNode
  useEffect(() => {
    if (!editor.hasNodes([MentionNode])) {
      throw new Error('AutoMentionPlugin: MentionNode not registered on editor');
    }

    if (isAutoMentionsPluginActive) {
      return editor.registerNodeTransform(MentionNode, (mentionNode: MentionNode) => {
        const mentionNodeText = mentionNode.getTextContent();
        const match = fullNameMatcher(mentionNodeText, organizationUsers, true);
        if (match === null) {
          mentionNode.replace($createTextNode(mentionNodeText));
        }
      });
    }
  }, [editor, organizationUsers, isAutoMentionsPluginActive]);

  useEffect(() => {
    if (!editor.hasNodes([MentionNode])) {
      throw new Error('AutoMentionPlugin: MentionNode not registered on editor');
    }

    if (isAutoMentionsPluginActive) {
      return editor.registerNodeTransform(TextNode, (textNode: TextNode) => {
        // It can happen that user is still typing other than matched full name
        // for example one user may have full name 'John Doe' and another user may have
        // full name 'John Doe Room3'. 'John Doe' will match and transform immediately
        // and 'Room3' will be just a TextNode, so we want to merge MentionNode and TextNode
        // after it to a single MentionNode if combination of their text contents matches the regex
        const previousMentionNode = textNode?.getPreviousSibling();
        const textNodeContent = textNode.getTextContent();
        if (previousMentionNode && textNodeContent.trim() !== '') {
          const previousMentionNodeContent = previousMentionNode?.getTextContent();

          const mentionNodeTextPlusTextNodeText = `${previousMentionNodeContent}${textNodeContent}`;
          const match = fullNameMatcher(mentionNodeTextPlusTextNodeText, organizationUsers, true);

          if (match !== null && previousMentionNode && $isMentionNode(previousMentionNode)) {
            const matchedTextWithoutAtSymbol = match.text.slice(1);
            const matchedUser = organizationUsers?.find(
              (user) =>
                user.fullName?.toLocaleLowerCase() === matchedTextWithoutAtSymbol.toLowerCase(),
            );

            if (!matchedUser) {
              Sentry.captureMessage(
                `User with full name ${matchedTextWithoutAtSymbol} not found in organization users`,
              );
              return;
            }

            const newMentionNode = $createMentionNode(match.text, matchedUser);
            previousMentionNode.replace(newMentionNode);
            textNode?.remove();
          }
        }

        // Function that automatically matches full names in message entry and
        // transforms TextNode to MentionNode
        handleMentionCreation(textNode, organizationUsers);
      });
    }
  }, [editor, organizationUsers, isAutoMentionsPluginActive]);

  return null;
}
