import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createTextNode, $insertNodes, COMMAND_PRIORITY_CRITICAL, PASTE_COMMAND } from 'lexical';

// The plugin contains an override for PASTE_COMMAND from lexical
// the 'override' works only if clipboard contains plain text
// as "native" lexical command contains bug for line breaks and tabs
export default function CustomOnPastePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (payload) => {
        const clipboardData =
          payload instanceof InputEvent || payload instanceof KeyboardEvent
            ? null
            : payload.clipboardData;
        const text =
          clipboardData?.getData('text/plain') || clipboardData?.getData('text/uri-list');
        if (text) {
          $insertNodes([$createTextNode(text)]);
          return true;
        }
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor]);

  return null;
}
