export const alertSx = {
  mt: 1.5,
};

export const accountEmailSx = {
  ml: 1,
  typography: 'body1',
};

export const lastSyncedSx = {
  typography: 'body1',
};

export const pausedLastSyncSx = {
  color: 'error.dark',
};

export const controlsWrapperSx = {
  display: 'flex',
  justifyContent: 'space-between',
  mb: 3,
};

export const googleAccountWrapperSx = {
  display: 'flex',
};

export const listItemsWrapperSx = {
  display: 'flex',
  mb: 1,
};
