import { Divider, Typography, Box, Menu, MenuItem, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { KeyboardArrowDown } from '@mui/icons-material';
import { useState } from 'react';

import {
  SerenityForm,
  FilterCreateEditFormConfig,
  FilterCreateEditFormValues,
  SelectorItemProps,
} from '@serenityapp/components-react-common';
import {
  Drawer,
  Form,
  FormikField,
  ChipSelectorField,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import { useChannels, channelsListAsSelectorItemsBySubtype } from '@serenityapp/client-data';
import { ConversationSubjectKind } from '@serenityapp/domain';

import ConfirmCloseDialog from '../../components/ConfirmCloseDialog';
import ArchiveFilterDialog from './ArchiveFilterDialog';

type FilterCreateEditFormProps = {
  filterId?: string;
  filterName?: string;
  handleFormClose: () => void;
  handleFormSubmit: (values: FilterCreateEditFormValues) => void;
  dataTestId?: string;
  initialValues?: Partial<FilterCreateEditFormValues>;
  title: string;
  isLoading?: boolean;
  isSaving: boolean;
  isEditMode?: boolean;
};

const FilterCreateEditForm = ({
  filterId,
  filterName,
  handleFormClose,
  handleFormSubmit,
  dataTestId,
  initialValues,
  title,
  isLoading = false,
  isSaving = false,
  isEditMode = false,
}: FilterCreateEditFormProps) => {
  const makeTestId = useMakeTestId('FilterCreateEditForm', dataTestId);
  const mainTestId = makeTestId('');

  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const isActionsMenuOpen = Boolean(actionsMenuAnchor);

  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const shouldRenderArchiveDialog = isEditMode && !!filterId && !!filterName;
  const shouldDisplayActionsMenu = isEditMode;

  const { channels, fetching: isFetchingChannels } = useChannels();

  const generalChannelsAsSelectorItems: SelectorItemProps[] =
    channelsListAsSelectorItemsBySubtype(channels, ConversationSubjectKind.GENERAL);

  const residentChannelsAsSelectorItems: SelectorItemProps[] =
    channelsListAsSelectorItemsBySubtype(channels, ConversationSubjectKind.RESIDENT);

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) =>
    setActionsMenuAnchor(event.currentTarget);
  const closeActionsMenu = () => setActionsMenuAnchor(null);
  const onArchiveActionClick = () => {
    setIsArchiveDialogOpen(true);
    closeActionsMenu();
  };
  const onCloseArchiveDialogClick = () => setIsArchiveDialogOpen(false);
  const onCancelConfirmDialogClick = () => setIsConfirmDialogOpen(false);

  return (
    <>
      {shouldRenderArchiveDialog && isArchiveDialogOpen && (
        <ArchiveFilterDialog
          filterId={filterId}
          isOpen={isArchiveDialogOpen}
          onActionSuccess={handleFormClose}
          onClose={onCloseArchiveDialogClick}
        />
      )}
      <ConfirmCloseDialog
        dataTestId={mainTestId}
        open={isConfirmDialogOpen}
        onCancelClick={onCancelConfirmDialogClick}
        onDiscardClick={handleFormClose}
      />
      <SerenityForm
        enableReinitialize
        validateOnBlur
        validateOnChange
        config={FilterCreateEditFormConfig}
        initialValuesOverride={initialValues}
        onSubmit={handleFormSubmit}
      >
        {({ submitForm, dirty }) => {
          const onDrawerCloseClick = () => {
            if (dirty || isSaving) {
              setIsConfirmDialogOpen(true);
              return;
            }

            handleFormClose();
          };

          return (
            <Drawer open onClose={onDrawerCloseClick}>
              <Drawer.Header
                dataTestId={mainTestId}
                loading={isLoading || isSaving || isFetchingChannels}
              >
                <Box sx={headerInfoSx}>
                  <Typography noWrap data-testid={makeTestId('title')} variant="h6">
                    {title}
                  </Typography>
                </Box>
                {shouldDisplayActionsMenu && (
                  <>
                    <Button
                      color="neutral"
                      data-testid={makeTestId('actions')}
                      disabled={isLoading}
                      endIcon={<KeyboardArrowDown sx={actionsButtonIconSx} />}
                      sx={actionsButtonSx}
                      onClick={openActionsMenu}
                    >
                      Actions
                    </Button>
                    <Menu
                      anchorEl={actionsMenuAnchor}
                      open={isActionsMenuOpen}
                      onClose={closeActionsMenu}
                    >
                      <MenuItem
                        key="archive-action"
                        data-testid={makeTestId('archive-action')}
                        onClick={onArchiveActionClick}
                      >
                        Archive
                      </MenuItem>
                    </Menu>
                    <Divider orientation="vertical" sx={headerDividerSx} />
                  </>
                )}
              </Drawer.Header>
              <Drawer.Content>
                <Form disabled={isLoading || isSaving || isFetchingChannels}>
                  <Typography display="block" variant="overline">
                    general
                  </Typography>
                  <Divider sx={dividerSx} />
                  <FormikField dataTestId={makeTestId('name')} name="name" type="text" />

                  <FormikField
                    dataTestId={makeTestId('filterPositionPriority')}
                    name="filterPositionPriority"
                    type="number"
                  />
                  <Typography display="block" variant="overline">
                    channels
                  </Typography>
                  <Divider sx={dividerSx} />
                  <ChipSelectorField
                    dataTestId={makeTestId('generalChannels')}
                    disabled={isLoading}
                    items={generalChannelsAsSelectorItems}
                    name="generalChannels"
                  />
                  <ChipSelectorField
                    dataTestId={makeTestId('residentChannels')}
                    disabled={isLoading}
                    items={residentChannelsAsSelectorItems}
                    name="residentChannels"
                  />
                  <Typography display="block" variant="overline">
                    other
                  </Typography>
                  <Divider sx={dividerSx} />
                  <FormikField dataTestId={makeTestId('description')} name="description" />
                </Form>
              </Drawer.Content>
              <Drawer.Footer>
                <Button
                  data-testid={makeTestId('close')}
                  disabled={isSaving}
                  onClick={onDrawerCloseClick}
                >
                  Close
                </Button>
                <LoadingButton
                  data-testid={makeTestId('save')}
                  disabled={isLoading || !dirty}
                  loading={isSaving}
                  variant="contained"
                  onClick={submitForm}
                >
                  Save
                </LoadingButton>
              </Drawer.Footer>
            </Drawer>
          );
        }}
      </SerenityForm>
    </>
  );
};

const actionsButtonSx = {
  color: 'text.primary',
  mr: 1,
};

const actionsButtonIconSx = {
  color: 'action.active',
};

const dividerSx = {
  mb: 2.25,
};

const headerInfoSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '11rem',
  flexGrow: 1,
};

const headerDividerSx = {
  height: '40px',
};

export default FilterCreateEditForm;
