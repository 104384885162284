import { ChangeEvent, useRef } from 'react';
import { Box, Button, SxProps } from '@mui/material';
import { AttachFile as AttachFileIcon, Close } from '@mui/icons-material';
import { MimeFn, MimeLookupIsomorphic } from '@serenityapp/core';
import { File as ReactFile, useMakeTestId } from '@serenityapp/components-react-web';

const MimeLookup = new MimeLookupIsomorphic();

type AttachFileProps = {
  accept?: string;
  dataTestId?: string;
  onFileAttached?: (file: File | undefined | null) => void;
  file?: File | null;
  sx?: SxProps;
};

/**
 * Controlled component for attaching a file. Toggles between a button and a file chip, with
 * hidden HTML file input to bring up system dialog.
 *
 * @param param0
 * @returns
 */
const AttachFile = ({
  accept = MimeFn.Accept.CsvAndExcel,
  dataTestId,
  file,
  sx,
  onFileAttached,
}: AttachFileProps) => {
  const makeTestId = useMakeTestId('AttachFile', dataTestId);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleSelectedFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    if (files && files.length > 0 && onFileAttached) {
      onFileAttached(files[0]);
    }
  };

  const handleAttachButton = () => {
    fileInputRef.current?.click();
  };

  const onDismissClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    onFileAttached?.(undefined);
  };

  const actionTools = [
    {
      key: 'close',
      Icon: Close,
      onClick: onDismissClick,
    },
  ];

  return (
    <Box sx={sx}>
      <Box
        ref={fileInputRef}
        accept={accept}
        component="input"
        sx={{ display: 'none' }}
        type="file"
        onChange={handleSelectedFileChange}
      />
      {file ? (
        <ReactFile
          key="attached-file"
          actionTools={actionTools}
          contentType={MimeLookup.lookup(file?.name, 'text/csv')}
          primaryText={file?.name}
        />
      ) : (
        <Button
          key="attach-file-button"
          data-testid={makeTestId('attach-file-button')}
          startIcon={<AttachFileIcon />}
          variant="outlined"
          onClick={handleAttachButton}
        >
          Attach
        </Button>
      )}
    </Box>
  );
};

export default AttachFile;
