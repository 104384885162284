export const alertSx = {
  mb: 2.5,
};

export const closeButtonSx = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'grey.500',
};

export const dividerSx = {
  mb: 2.25,
};

export const formWrapperSx = {
  pt: 2.5,
};
