import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppCard, useMakeTestId } from '@serenityapp/components-react-web';
import { AppStatus } from '@serenityapp/domain';
import { AppData, appInstall, snackAdd } from '@serenityapp/redux-store';
import { CHECK_IN_APP_NAME } from '@serenityapp/core';

type CheckInAppCardProps = {
  app: AppData;
};

const CheckInAppCard = ({ app }: CheckInAppCardProps) => {
  const makeTestId = useMakeTestId('CheckInAppCard');
  const checkInAppCardTestId = makeTestId('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCardClick =
    app.status !== AppStatus.DISABLED ? () => navigate('check-in') : undefined;

  const onAppInstallFailed = () =>
    dispatch(snackAdd({ message: 'Check-In Bot installation failed.', type: 'error' }));

  const onAppInstallSuccess = () => {
    dispatch(snackAdd({ message: 'Check-In Bot installed successfully.', type: 'success' }));
    navigate('check-in');
  };

  const handleAppEnableClick = () =>
    dispatch(
      appInstall({
        onFailed: onAppInstallFailed,
        onSuccess: onAppInstallSuccess,
        variables: {
          appName: CHECK_IN_APP_NAME,
        },
      }),
    );

  return (
    <AppCard
      dataTestId={checkInAppCardTestId}
      description={app.description}
      enabledLabel="Enabled"
      status={app.status}
      title={app.displayName}
      onCardClick={handleCardClick}
      onEnableClick={handleAppEnableClick}
    />
  );
};

export default CheckInAppCard;
