export const sectionDividerSx = {
  mb: 2,
};

export const appStatusChipSx = {
  ml: 1,
};

export const accountNameSx = {
  ml: 1,
  typography: 'body1',
};

export const lastSyncedSx = {
  typography: 'body1',
};

export const accountWrapperSx = {
  display: 'flex',
  alignItems: 'center',
};

export const listItemsWrapperSx = {
  display: 'flex',
  mb: 1,
};
