import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getViewerState, useViewerBaseInformation } from '@serenityapp/redux-store';

type LocationState = {
  from?: string;
};

const isValidSerenityAdminPath = (path: string) => path.startsWith('/serenity-admin');

const isValidAdminPath = (path: string) => path.startsWith('/admin');

const isValidClientPath = (path: string) => path.startsWith('/client');

const PublicRoute = () => {
  const location = useLocation();
  const { signedIn, ready, canAdminChannel, canAdminLocation, canAdminUser } =
    useSelector(getViewerState);

  const viewerBaseInformation = useViewerBaseInformation();
  const viewerPermissions = viewerBaseInformation.data.permissions;

  const isOrgAdmin = canAdminChannel && canAdminLocation && canAdminUser;

  if (signedIn && ready) {
    const locationState = location?.state as LocationState;
    const locationFrom = locationState?.from !== '/sign-out' ? locationState?.from : undefined;

    if (viewerPermissions.canDisplaySystemAdmin) {
      if (locationFrom && isValidSerenityAdminPath(locationFrom)) {
        return <Navigate to={locationFrom} />;
      } else {
        return <Navigate to="/serenity-admin" />;
      }
    } else if (isOrgAdmin && !viewerPermissions.canDisplayMessaging) {
      if (locationFrom && isValidAdminPath(locationFrom)) {
        return <Navigate to={locationFrom} />;
      } else {
        return <Navigate to="/admin" />;
      }
    } else if (isOrgAdmin && viewerPermissions.canDisplayMessaging) {
      if (locationFrom && (isValidAdminPath(locationFrom) || isValidClientPath(locationFrom))) {
        return <Navigate to={locationFrom} />;
      } else {
        return <Navigate to="/client" />;
      }
    } else {
      if (locationFrom && isValidClientPath(locationFrom)) {
        return <Navigate to={locationFrom} />;
      } else {
        return <Navigate to="/client" />;
      }
    }
  }

  return <Outlet />;
};

export default PublicRoute;
