import { Box, Grid, Skeleton } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { App } from '@serenityapp/core';
import { AdministrationHeader, useMakeTestId, View } from '@serenityapp/components-react-web';
import {
  AppData,
  appsListTransformer,
  useApps,
  useIsFeatureEnabled,
} from '@serenityapp/redux-store';

import { useAdminBreadcrumbs } from '../hooks';
import {
  AlexaForSeniorLivingAppCard,
  BoxAppCard,
  CalendarAppCard,
  CheckInAppCard,
  PccAppCard,
} from './components';

const AppsPage = () => {
  const title = 'Apps';
  const makeTestId = useMakeTestId('Apps');
  const mainTestId = makeTestId('');

  const crumbs = useAdminBreadcrumbs(title);

  const isA4SLFeatureEnabled = useIsFeatureEnabled('a4sl');
  const isCheckInFeatureEnabled = useIsFeatureEnabled('check-in');
  const isCalendarFeatureEnabled = useIsFeatureEnabled('calendar');
  const isCloudStorageAppsEnabled = useIsFeatureEnabled('cloudStorageApps');
  const isPCCFeatureEnabled = useIsFeatureEnabled('pcc');

  const { data } = useApps();
  const apps = appsListTransformer(data);

  const renderApp = (app: AppData) => {
    if (app.name === App.Names.AlexaForSeniorLiving && isA4SLFeatureEnabled) {
      return (
        <Grid key={app.id} item xs="auto">
          <AlexaForSeniorLivingAppCard app={app} />
        </Grid>
      );
    }
    if (app.name === App.Names.Box && isCloudStorageAppsEnabled)
      return (
        <Grid key={app.id} item xs="auto">
          <BoxAppCard app={app} />
        </Grid>
      );
    if (app.name === App.Names.Calendar && isCalendarFeatureEnabled)
      return (
        <Grid key={app.id} item xs="auto">
          <CalendarAppCard app={app} />
        </Grid>
      );
    if (app.name === App.Names.Checkin && isCheckInFeatureEnabled)
      return (
        <Grid key={app.id} item xs="auto">
          <CheckInAppCard app={app} />
        </Grid>
      );
    if (app.name === App.Names.PointClickCare && isPCCFeatureEnabled)
      return (
        <Grid key={app.id} item xs="auto">
          <PccAppCard app={app} />
        </Grid>
      );

    return null;
  };

  const appsListSkeleton = (
    <>
      {/* display 3 skeleton cards */}
      {Array.from({ length: 3 }, (_, index) => (
        <Grid key={index} item xs="auto">
          <Skeleton height={180} variant="rectangular" width={330} />
        </Grid>
      ))}
    </>
  );

  return (
    <View>
      <View.Header>
        <AdministrationHeader crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <Box data-testid={mainTestId} sx={cardsWrapperSx}>
          <Grid container spacing={3}>
            {apps.length === 0 ? appsListSkeleton : apps.map(renderApp)}
          </Grid>
        </Box>
        <Outlet />
      </View.Content>
    </View>
  );
};

export const cardsWrapperSx = {
  px: 3,
  display: 'flex',
  width: 1,
  flexWrap: 'wrap',
};

export default AppsPage;
