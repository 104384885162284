import { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TabContext, TabPanel, TabListProps } from '@mui/lab';

import {
  useViewerBaseInformation,
  useViewerMemberOfConversations,
} from '@serenityapp/redux-store';
import { Drawer, useMakeTestId } from '@serenityapp/components-react-web';

import { useCurrentUser } from '../hooks';
import { tabPanelSx, tabsSx, drawerContentSx } from './accountDrawerStyles';
import NotificationSettings from './NotificationSettings';
import AccountSettings from './AccountSettings';

export enum AccountTab {
  settings = 'settings',
  notifications = 'notifications',
}

type AccountDrawerProps = {
  currentTab: AccountTab;
  onClose: () => void;
};

const AccountDrawer = ({ currentTab, onClose }: AccountDrawerProps) => {
  const makeTestId = useMakeTestId('Account');
  const mainTestId = makeTestId('');

  const viewerBaseInformation = useViewerBaseInformation();
  const viewerPermissions = viewerBaseInformation.data.permissions;

  const { isLoading } = useViewerMemberOfConversations();
  const currentUser = useCurrentUser();

  const [selectedTab, setSelectedTab] = useState<string>(currentTab);

  const handleChange: TabListProps['onChange'] = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const shouldDisplayLoading =
    !currentUser || (viewerPermissions.canDisplayMessaging && isLoading);

  const goToAccountSettings = () => null;

  return (
    <Drawer open dataTestId={mainTestId} onClose={onClose}>
      <Drawer.Header dataTestId={mainTestId} loading={shouldDisplayLoading}>
        <Box>
          <Typography noWrap data-testid={makeTestId('title')} variant="h6">
            Account - {currentUser?.fullName}
          </Typography>
        </Box>
      </Drawer.Header>
      <Drawer.Content disableElevation sx={drawerContentSx}>
        {viewerPermissions.canDisplayMessaging ? (
          <>
            <Tabs
              selectionFollowsFocus
              aria-label="Account tabs"
              indicatorColor="primary"
              sx={tabsSx}
              textColor="primary"
              value={selectedTab}
              variant="fullWidth"
              onChange={handleChange}
            >
              <Tab
                key="settings"
                disableRipple
                data-testid={makeTestId('account-settings')}
                label="Settings"
                value="settings"
                onClick={() => goToAccountSettings()}
              />

              <Tab
                key="notifications"
                disableRipple
                data-testid={makeTestId('notifications')}
                label="Notifications"
                value="notifications"
                onClick={() => goToAccountSettings()}
              />
            </Tabs>
            <TabContext value={selectedTab}>
              <TabPanel
                key="Settings"
                data-testid={makeTestId('account-settings-panel')}
                sx={tabPanelSx}
                value="settings"
              >
                <AccountSettings currentUser={currentUser} />
              </TabPanel>
              <TabPanel
                key="Notifications"
                data-testid={makeTestId('account-notifications')}
                sx={tabPanelSx}
                value="notifications"
              >
                <NotificationSettings />
              </TabPanel>
            </TabContext>
          </>
        ) : (
          <AccountSettings currentUser={currentUser} />
        )}
      </Drawer.Content>
    </Drawer>
  );
};

export default AccountDrawer;
