import { DateFn, MimeFn } from '@serenityapp/core';
import {
  AbstractStorageProvider,
  IStorageProvider,
  IStorageTransform,
  IStorageTransformContext,
  StoragePrepareDescriptorInput,
  StoragePrepareDescriptorOutput,
} from '@serenityapp/core-storage';
import { getResizedImageAsBlob } from '../../utils/image';

export class TransformStorageProviderWeb
  extends AbstractStorageProvider
  implements IStorageProvider
{
  protected asContext(result: StoragePrepareDescriptorOutput): IStorageTransformContext {
    return {
      contentName: result.descriptor.contentName,
      contentType: result.descriptor.contentType,
      filesystem: this.filesystem,
      imageInfoLookup: this.imageInfoLookup,
      key: result.descriptor.key,
      level: result.descriptor.level,
      mimelookup: this.mimelookup,
      path: result.originalFile.uri,
      tmpDir: '',
    };
  }

  transform(result: StoragePrepareDescriptorOutput): Promise<IStorageTransformContext> {
    // Applies all given transforms in sequence
    return this.transforms.reduce(
      (context, transformer) => context.then(transformer.transform),
      Promise.resolve(this.asContext(result)),
    );
  }

  async prepareDescriptor(
    input: StoragePrepareDescriptorInput,
  ): Promise<StoragePrepareDescriptorOutput> {
    this.config.analytics.track({
      name: 'storage-provider-transform/prepare-descriptor-start',
      attributes: { transformDisabled: input.disable?.transform ?? false },
    });

    const superResult = await super.prepareDescriptor(input);

    if (input.disable?.transform) {
      return superResult;
    }

    const start = DateFn.now();

    const transformResult = await this.transform(superResult);

    const elapsed = DateFn.now() - start;
    this.config.analytics.track({
      name: 'storage-provider-transform/prepare-descriptor-end',
      attributes: { transformElapsed: elapsed },
    });

    return {
      ...superResult,
      descriptor: {
        ...superResult.descriptor,
        contentName: transformResult.contentName,
        contentType: transformResult.contentType,
        height: transformResult.height,
        width: transformResult.width,
      },
      elapsed: {
        ...superResult.elapsed,
        transform: elapsed,
      },
      originalFile: {
        ...superResult.originalFile,
        name: transformResult.contentName,
        type: transformResult.contentType,
        uri: transformResult.path,
      },
    };
  }
}

export async function wrapTransform(
  inner: IStorageProvider,
): Promise<TransformStorageProviderWeb> {
  const provider = new TransformStorageProviderWeb(inner);
  await provider.init();
  return provider;
}

// Used to optimize images before upload
export const optimizeImageTransform: IStorageTransform = {
  async transform(context) {
    const TRANSFORM_SIZE = 1024;
    const TRANSFORM_QUALITY = 0.8;
    const TRANSFORM_FILE_TYPES = ['heic', 'png', 'webp', 'jpeg', 'gif', 'bmp', 'avif']; // Resizing for other file types is skipped

    const isSupportedContentType = MimeFn.isImage(context.contentType, ...TRANSFORM_FILE_TYPES);
    if (isSupportedContentType === false) {
      return context;
    }

    const { height = Infinity, width = Infinity } = context;
    const isAlreadyReduced = height <= TRANSFORM_SIZE && width <= TRANSFORM_SIZE;
    if (isAlreadyReduced) {
      return context;
    }

    const resizedImage = await getResizedImageAsBlob(
      { maxSize: TRANSFORM_SIZE, quality: TRANSFORM_QUALITY },
      context.path,
    );

    // The original context path should be an object URL on web and since it's being replaced
    // by the URL of the resized image, it should be revoked to prevent memory leaks
    URL.revokeObjectURL(context.path);
    const resizedImageFileURL = URL.createObjectURL(resizedImage.blob);

    return {
      ...context,
      contentType: resizedImage.blob.type,
      height: resizedImage.height,
      path: resizedImageFileURL,
      width: resizedImage.width,
    };
  },
  transformTrial(context) {
    return optimizeImageTransform.transform(context);
  },
};
