import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDenormalizedIAMGroup,
  getIsIAMGroupArchiving,
  getIsIAMGroupDetailLoading,
  iamGroupDetail,
  RootState,
} from '@serenityapp/redux-store';

export default function useGroupDetail(groupId: string) {
  const dispatch = useDispatch();

  const group = useSelector((state: RootState) => getDenormalizedIAMGroup(state, groupId));
  const isArchiving = useSelector((state: RootState) => getIsIAMGroupArchiving(state, groupId));
  const isLoading = useSelector((state: RootState) => getIsIAMGroupDetailLoading(state, groupId));

  useEffect(() => {
    dispatch(iamGroupDetail({ groupId }));
  }, [dispatch, groupId]);

  return {
    group,
    isLoading,
    isArchiving,
  };
}
