import { useState, MouseEvent } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import {
  AccountCircle,
  Notifications,
  Logout,
  ArrowDropDown,
  Menu,
  KeyboardArrowDown,
  Forum,
  Settings,
  Person,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';

import {
  Avatar,
  BasicMenu,
  BasicMenuItemProps,
  HorizontalSerenityColorLogo,
  SerenityIconColorLogo,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import { convertBase64toImageSrc, StringFn } from '@serenityapp/core';
import { WithKey } from '@serenityapp/domain';
import {
  totalUnreadCountTransformer,
  useOrganizationDetail,
  useViewerBaseInformation,
  useViewerMemberOfConversations,
} from '@serenityapp/redux-store';

import {
  arrowDropDownIconSx,
  horizontalLogoSx,
  iconButtonSx,
  listItemAvatarSx,
  listItemButtonSx,
  listItemIconSx,
  listItemTextSx,
  logoBreakpointSx,
  logoSx,
  menuIconSx,
  roleButtonSx,
  toolbarLeftSx,
  toolbarRightSx,
  toolbarSx,
  userInfoMenuSx,
  verticalLogoSx,
} from './appBarContentStyles';
import { useCurrentUser } from '../hooks';
import AccountDrawer from '../AccountDrawer';
import { AccountTab } from '../AccountDrawer/AccountDrawer';
import AppBarContentSkeleton from './AppBarContentSkeleton';

type AppBarContentProps = {
  onBurgerMenuClick?: () => void;
  title?: string;
};

const AppBarContent = ({ onBurgerMenuClick, title }: AppBarContentProps) => {
  const makeTestId = useMakeTestId('AppBar');
  const mainTestId = makeTestId('');

  const navigate = useNavigate();
  const location = useLocation();

  const isOnClientRoute = useMatch({ path: '/client', end: false });
  const isOnAdminRoute = useMatch({ path: '/admin', end: false });
  const isOnSerenityAdminRoute = useMatch({ path: '/serenity-admin', end: false });

  const burgerMenuTestId = makeTestId('burger-menu');

  const { data, isLoading } = useViewerMemberOfConversations();
  const totalUnreadCount = totalUnreadCountTransformer(data);

  const [accountTabId, setAccountTabId] = useState<AccountTab>();

  const goToAccountSettings = () => setAccountTabId(AccountTab.settings);
  const goToNotificationSettings = () => setAccountTabId(AccountTab.notifications);

  const currentUser = useCurrentUser();
  let userName = currentUser?.fullName ?? currentUser?.name ?? '';
  let userAvatarSrc =
    currentUser?.avatar?.url || convertBase64toImageSrc(currentUser?.avatar?.preview);
  const userInitials = currentUser?.initials ?? StringFn.initials(userName);

  const viewerBaseInformation = useViewerBaseInformation();
  const viewerPermissions = viewerBaseInformation.data.permissions;

  const shouldDisplayRouteSwitch =
    viewerPermissions.canDisplayMessaging && viewerPermissions.canDisplayAdmin;

  const orgId = currentUser?.orgId ?? '';
  const { organization } = useOrganizationDetail(orgId);
  let organizationName = organization?.name ?? '';

  if (viewerPermissions.canDisplaySystemAdmin) {
    userAvatarSrc = undefined;
    userName = 'Super Admin';
    organizationName = 'Serenity';
  }

  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isAccountMenuOpen = Boolean(accountMenuAnchorEl);

  const handleAccountDrawerClose = () => setAccountTabId(undefined);

  const handleCloseAccountMenu = () => setAccountMenuAnchorEl(null);

  const handleAccountMenu = (event: MouseEvent<HTMLElement>) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const [roleAnchorEl, setRoleAnchorEl] = useState<null | HTMLElement>(null);
  const isRoleMenuOpen = Boolean(roleAnchorEl);

  const handleCloseRoleMenu = () => setRoleAnchorEl(null);

  const handleRoleMenu = (event: MouseEvent<HTMLElement>) => {
    setRoleAnchorEl(event.currentTarget);
  };

  const handleSignOutClick = () => {
    setAccountMenuAnchorEl(null);

    // This line of code is navigating user to the SignOutPage where s/he gets automatically
    // signed out and redirected to the SignInPage.
    // The navigation state is also being cleared out at this moment. This prevents state from
    // previously logged in user to be available to the newly logged in users which might cause
    // (in worse case) some security issues.
    navigate('/sign-out', { replace: true, state: {} });
  };

  const handleAccountSettingsClick = () => {
    setAccountMenuAnchorEl(null);

    goToAccountSettings();
  };

  const handleNotificationSettingsClick = () => {
    setAccountMenuAnchorEl(null);

    goToNotificationSettings();
  };

  const settingsSection = [];

  if (!viewerPermissions.canDisplaySystemAdmin) {
    settingsSection.push({
      key: 'my-profile',
      dataTestId: makeTestId('my-profile'),
      onClick: handleAccountSettingsClick,
      label: 'Account',
      Icon: AccountCircle,
      handleCloseMenu: handleCloseAccountMenu,
    });
  }

  if (viewerPermissions.canDisplayMessaging) {
    settingsSection.push({
      key: 'notifications-settings',
      dataTestId: makeTestId('notifications-settings'),
      onClick: handleNotificationSettingsClick,
      label: 'Notifications settings',
      Icon: Notifications,
      handleCloseMenu: handleCloseAccountMenu,
    });
  }

  const basicMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [
    settingsSection,
    [
      {
        key: 'log-out',
        dataTestId: makeTestId('log-out'),
        onClick: handleSignOutClick,
        label: 'Sign Out',
        Icon: Logout,
        handleCloseMenu: handleCloseAccountMenu,
      },
    ],
  ];

  const roleMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [
    [
      {
        key: 'messaging',
        dataTestId: makeTestId('messaging'),
        onClick: () => {
          if (location.pathname.includes('/client')) return;

          navigate('/client');
        },
        label: 'Messaging',
        Icon: Forum,
        handleCloseMenu: handleCloseRoleMenu,
      },
      {
        key: 'admin',
        dataTestId: makeTestId('admin'),
        onClick: () => {
          if (location.pathname.includes('/admin')) return;

          navigate('/admin');
        },
        label: 'Admin',
        Icon: Settings,
        handleCloseMenu: handleCloseRoleMenu,
      },
    ],
  ];

  const handleLogoClick = () => {
    if (isOnSerenityAdminRoute) {
      navigate('/serenity-admin');
      return;
    }

    if (isOnAdminRoute) {
      navigate('/admin');
      return;
    }
  };

  const renderSerenityLogo = () => {
    const logo = (
      <>
        <Box sx={verticalLogoSx}>
          <SerenityIconColorLogo height={34} />
        </Box>
        <Box sx={horizontalLogoSx}>
          <HorizontalSerenityColorLogo height={34} />
        </Box>
      </>
    );

    if (isOnClientRoute) return logo;

    return (
      <Link aria-label="Serenity" component="button" onClick={handleLogoClick}>
        {logo}
      </Link>
    );
  };

  const renderAccountButton = () => (
    <ListItemButton
      dense
      data-testid={makeTestId('my-account-button')}
      sx={listItemButtonSx}
      onClick={handleAccountMenu}
    >
      <ListItemIcon sx={listItemIconSx}>
        <ArrowDropDown sx={arrowDropDownIconSx} />
      </ListItemIcon>
      <ListItemText primary={userName ?? ''} secondary={organizationName} sx={listItemTextSx} />
      <ListItemAvatar sx={listItemAvatarSx}>
        <Avatar initials={userInitials} size="medium" src={userAvatarSrc}>
          {viewerPermissions.canDisplaySystemAdmin && <Person />}
        </Avatar>
      </ListItemAvatar>
    </ListItemButton>
  );

  if (isLoading) return <AppBarContentSkeleton />;
  return (
    <Toolbar sx={toolbarSx}>
      <Box sx={toolbarLeftSx}>
        {onBurgerMenuClick && (
          <IconButton
            aria-label="burger-menu"
            data-testid={burgerMenuTestId}
            size="small"
            sx={iconButtonSx}
            onClick={onBurgerMenuClick}
          >
            {totalUnreadCount > 0 ? (
              <Badge color="error" variant="dot">
                <Menu sx={menuIconSx} />
              </Badge>
            ) : (
              <Menu sx={menuIconSx} />
            )}
          </IconButton>
        )}
        <Box sx={[logoSx, !!onBurgerMenuClick && logoBreakpointSx]}>{renderSerenityLogo()}</Box>
        {shouldDisplayRouteSwitch && (
          <Button
            color="neutral"
            data-testid={makeTestId('switch-role-button')}
            endIcon={<KeyboardArrowDown />}
            sx={roleButtonSx}
            onClick={handleRoleMenu}
          >
            {title}
          </Button>
        )}
      </Box>
      <Box sx={toolbarRightSx}>{renderAccountButton()}</Box>
      <BasicMenu
        anchorEl={accountMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        dataTestId={mainTestId}
        open={isAccountMenuOpen}
        sections={basicMenuSections}
        onClose={handleCloseAccountMenu}
      >
        <ListItem divider sx={userInfoMenuSx}>
          <ListItemText primary={userName} secondary={organizationName} />
        </ListItem>
      </BasicMenu>
      <BasicMenu
        anchorEl={roleAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        dataTestId={mainTestId}
        open={isRoleMenuOpen}
        sections={roleMenuSections}
        onClose={handleCloseRoleMenu}
      />
      {accountTabId && (
        <AccountDrawer currentTab={accountTabId} onClose={handleAccountDrawerClose} />
      )}
    </Toolbar>
  );
};

export default AppBarContent;
