import { checkInPoliciesTransformer, useCheckInPolicies } from '@serenityapp/redux-store';
import { useMemo } from 'react';

const useCheckInPolicy = () => {
  const { data: checkInPoliciesData, isLoading: isLoadingCheckInPolicies } = useCheckInPolicies({
    variables: {},
  });

  const serviceLevels = useMemo(() => {
    if (isLoadingCheckInPolicies || !checkInPoliciesData) {
      return [];
    }

    const checkInPolicies = checkInPoliciesTransformer(
      checkInPoliciesData,
      isLoadingCheckInPolicies,
    );
    return checkInPolicies?.[0]?.serviceLevels || [];
  }, [checkInPoliciesData, isLoadingCheckInPolicies]);

  return { serviceLevels };
};

export default useCheckInPolicy;
