import type { Spread } from 'lexical';
import {
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  $applyNodeReplacement,
  TextNode,
} from 'lexical';
import { colors } from '@serenityapp/components-react-common';
import { UserProps } from '@serenityapp/domain';

export type SerializedMentionNode = Spread<
  {
    mention: string;
    user: UserProps;
  },
  SerializedTextNode
>;

export default class MentionNode extends TextNode {
  __mention: string;
  __user: UserProps;

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__mention, node.__user, node.__text, node.__key);
  }
  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(serializedNode.mention, serializedNode.user);
    return node;
  }

  constructor(mention: string, user: UserProps, text?: string, key?: NodeKey) {
    super(text ?? mention, key);
    this.__mention = mention;
    this.__user = user;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mention: this.__mention,
      user: this.__user,
      type: 'mention',
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);
    dom.style.color = `${colors.primary.main}`;
    dom.style.backgroundColor = `${colors.primary.outlinedHoverBackground}`;
    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-mention', 'true');
    element.textContent = this.__mention;
    return { element };
  }

  getUser(): UserProps {
    return this.__user;
  }

  getMention(): string {
    return this.__mention;
  }

  isTextEntity(): true {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createMentionNode(mention: string, user: UserProps): MentionNode {
  const mentionNode = new MentionNode(mention, user);
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode;
}
