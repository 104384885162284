/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import { AssertFn, createDebug } from '@serenityapp/core';
import { Schema } from '@serenityapp/domain';
import {
  SerenityForm,
  RoomCreateEditFormConfig,
  RoomCreateEditFormValues,
} from '@serenityapp/components-react-common';
import {
  AutocompleteChipSelectorField,
  Drawer,
  Form,
  FormikField,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import ActionsMenu from './components/ActionsMenu';
import { Footer, FormSectionHeader } from './components';
import {
  getAdministerableResidentUsersAsSelectorItems,
  snackAdd,
  useAlexaCommunicationProfileCreateMutation,
  useRoomAlexaUnitNewMutation,
} from '@serenityapp/redux-store';
import RoomDrawerAlexaUnitPrompt from './components/RoomDrawerAlexaUnitPrompt';
import RoomDrawerCommunicationProfilePrompt from './components/RoomDrawerCommunicationProfilePromt';

const HeaderTitle = ({ title }: { title: string }) => {
  return (
    <Box sx={headerInfoSx}>
      <Typography noWrap variant="h6">
        {title}
      </Typography>
    </Box>
  );
};

type RoomCreateEditFormProps = {
  name?: string;
  handleClose: () => void;
  handleFormSubmit: (values: RoomCreateEditFormValues) => void;
  onConfirmDialogOpen: (isOpen: boolean) => void;
  dataTestId?: string;
  initialValues?: Partial<RoomCreateEditFormValues>;
  room?: Schema.Room.Detail;
  title: string;
  isEditMode?: boolean;
  isLoading?: boolean;
  isSaving?: boolean;
};

const RoomCreateEditForm = ({
  handleClose,
  handleFormSubmit,
  dataTestId,
  initialValues,
  room,
  title,
  isLoading = false,
  isSaving = false,
  isEditMode = false,
  onConfirmDialogOpen,
}: RoomCreateEditFormProps) => {
  debug({
    room,
  });
  const residentAsSelectorItems = useSelector(getAdministerableResidentUsersAsSelectorItems);
  const { doMutation: roomAlexaUnitNew } = useRoomAlexaUnitNewMutation();
  const { doMutation: alexaCommunicationProfileCreate } =
    useAlexaCommunicationProfileCreateMutation();

  const dispatch = useDispatch();
  const makeTestId = useMakeTestId('LocationCreateEditForm', dataTestId);
  const mainTestId = makeTestId('');

  const [isAlexaUnitLinkDialogOpen, setIsAlexaUnitLinkDialogOpen] = useState<boolean>(false);
  const onAlexaUnitLinkActionClick = () => {
    setIsAlexaUnitLinkDialogOpen(true);
  };
  const [isAlexaUnitNewDialogOpen, setIsAlexaUnitNewDialogOpen] = useState<boolean>(false);

  const createNewAlexaUnitForRoom = (room: Schema.Room.Item | undefined) => {
    const roomId = room?.id;
    AssertFn.isDefined(roomId, filename, 'roomId');
    roomAlexaUnitNew(
      {
        input: {
          id: roomId,
        },
      },
      () => {
        dispatch(
          snackAdd({
            message: `Successfully created a new alexa unit`,
            type: 'success',
          }),
        );
      },
      (uoutput: unknown) => {
        // TODO: adjust hygen and/or framework to make type assertion unnecessary
        const output = uoutput as Schema.Room.AlexaUnitNew.MutationResult;
        dispatch(
          // TODO: Helpers that map code to snack message
          snackAdd({
            message: `Error creating a new alexa unit: ${output.result?.msg}`,
            type: 'error',
          }),
        );
      },
    );
  };

  const createAlexaCommunicationProfile = (
    room: Schema.Room.Item | undefined,
    communicationProfileDisplayName: string,
  ) => {
    const alexaUnitId = room?.alexaUnit?.id;

    AssertFn.isDefined(alexaUnitId, filename, 'alexaUnitId');
    alexaCommunicationProfileCreate(
      {
        input: {
          alexaUnitId,
          //name: communicationProfileDisplayName,
        },
      },
      () => {
        dispatch(
          snackAdd({
            message: `Successfully created a new communication profile`,
            type: 'success',
          }),
        );
      },
      (uoutput: unknown) => {
        const output = uoutput as Schema.Room.AlexaUnitNew.MutationResult;
        dispatch(
          snackAdd({
            message: `Error creating a communication profile: ${output.result?.msg}`,
            type: 'error',
          }),
        );
      },
    );
  };

  return (
    <>
      <SerenityForm
        enableReinitialize
        validateOnBlur
        validateOnChange
        config={RoomCreateEditFormConfig}
        initialValuesOverride={initialValues}
        onSubmit={handleFormSubmit}
      >
        {({ submitForm, dirty, values, setFieldValue }) => {
          const onDrawerCloseClick = () => {
            if (dirty || isSaving) {
              onConfirmDialogOpen(true);
              return;
            }

            handleClose();
          };

          return (
            <Drawer open onClose={onDrawerCloseClick}>
              <Drawer.Header dataTestId={mainTestId} loading={isLoading || isSaving}>
                <HeaderTitle title={title} />
              </Drawer.Header>
              <Drawer.Content>
                <Form disabled={isLoading || isSaving}>
                  <FormSectionHeader sx={dividerSx} title="General" />
                  <FormikField dataTestId={makeTestId('name')} name="name" type="text" />
                  <FormSectionHeader sx={dividerSx} title="Users" />
                  <AutocompleteChipSelectorField
                    items={residentAsSelectorItems}
                    name="users"
                    placeholder="Add"
                    sx={{ pb: 0 }}
                    // onChange={(e) => handleInputChange(e, 'users')}
                  />
                  <RoomDrawerAlexaUnitPrompt
                    createNewAlexaUnitForRoom={createNewAlexaUnitForRoom}
                    room={room}
                    onAlexaUnitLinkActionClick={onAlexaUnitLinkActionClick}
                  />
                  {room?.alexaUnit && (
                    <RoomDrawerCommunicationProfilePrompt
                      createAlexaCommunicationProfile={createAlexaCommunicationProfile}
                      room={room}
                      setFieldValue={setFieldValue}
                      values={values}
                    />
                  )}
                  {/* {room?.alexaUnit && <RoomDrawerSkillsPrompt room={room} values={values} />} */}
                </Form>
              </Drawer.Content>
              <Drawer.Footer>
                <Footer
                  dirty
                  isLoading={isLoading}
                  isSaving={isSaving}
                  makeTestId={makeTestId}
                  submitForm={submitForm}
                  onDrawerCloseClick={onDrawerCloseClick}
                />
              </Drawer.Footer>
            </Drawer>
          );
        }}
      </SerenityForm>
    </>
  );
};

export const dividerSx = {
  mb: 2.25,
};

const headerInfoSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '11rem',
  flexGrow: 1,
};

export default RoomCreateEditForm;

const _debug = false;
const filename = 'RoomCreateEditForm.tsx';
const debug = createDebug({
  meta: {
    filename,
    debug: _debug,
  },
});
