import { FormikValues } from 'formik';
import { IpAllowListPolicyCreateEditForm } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { getSortedIAMGroups, ipAllowListPolicyCreate, snackAdd } from '@serenityapp/redux-store';
import { useCurrentUser } from '../common/hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { IAMGroupProps, Range } from '@serenityapp/domain';

const IpAllowListPolicyCreateDrawer = () => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  const groups: Array<IAMGroupProps> = useSelector(getSortedIAMGroups);
  const groupsAsSelectorItems: Array<SelectorItemProps> = groups.map((group) => ({
    id: group.id,
    label: group.name ?? '',
  }));

  const onSuccess = () => {
    setIsSaving(false);
    navigate('../');
    dispatch(snackAdd({ message: 'IP Allow List policy successfully added.', type: 'success' }));
  };

  const onFailed = () => {
    setIsSaving(false);
    dispatch(snackAdd({ message: 'Error adding IP Allow List policy.', type: 'error' }));
  };

  const handleSubmit = (values: FormikValues) => {
    const orgId = currentUser?.orgId;
    if (!orgId) return;
    setIsSaving(true);
    dispatch(
      ipAllowListPolicyCreate({
        onSuccess,
        onFailed,
        variables: {
          input: {
            organization: {
              id: orgId,
            },
            ipAllowListPolicy: {
              name: values.name,
              ranges: values.ranges.map((range: Range) => ({
                ipStart: range.ipStart,
                ipEnd: range.ipEnd ? range.ipEnd : range.ipStart,
              })),
              roleIds: values.roles.map((role: SelectorItemProps) => role.id),
            },
          },
        },
      }),
    );
  };

  return (
    <IpAllowListPolicyCreateEditForm
      groupsAsSelectorItems={groupsAsSelectorItems}
      handleSubmit={handleSubmit}
      isSaving={isSaving}
      title="Add IP Allow List policy"
    />
  );
};

export default IpAllowListPolicyCreateDrawer;
