import { FormHelperText, Slider, SliderProps } from '@mui/material';
import { useSerenityField } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';

import { AssertFn } from '@serenityapp/core';

type SliderFieldProps = SliderProps & {
  dataTestId?: string;
  name: string;
};

const SliderField = ({
  dataTestId,
  name,
  max,
  min,
  step,
  marks,
  sx,
  valueLabelFormat,
  onChange,
}: SliderFieldProps) => {
  const [config, field, meta] = useSerenityField({ name });
  AssertFn.isDefined(config, filename, `config (name=${name})`);

  const makeTestId = useMakeTestId('SliderField', dataTestId);

  return (
    <>
      <FormHelperText error>{meta.error || ' '}</FormHelperText>
      <Slider
        disableSwap
        data-testid={makeTestId('')}
        marks={marks}
        {...field}
        max={max}
        min={min}
        size="small"
        step={step}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        track={false}
        valueLabelDisplay="on"
        valueLabelFormat={valueLabelFormat}
        onChange={onChange}
      />
    </>
  );
};

export default SliderField;

const filename = 'SliderField.tsx';
