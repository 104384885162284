import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Box, Card, CardActions, CardContent, CardHeader, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  Form,
  PasswordField,
  HorizontalSerenityColorLogo,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import {
  getViewerState,
  viewerClearNewPasswordError,
  viewerNewPassword,
} from '@serenityapp/redux-store';
import {
  NewPasswordFormValues,
  NewPasswordFormConfig,
  SerenityForm,
} from '@serenityapp/components-react-common';
import {
  containerSx,
  cardSx,
  cardHeaderSx,
  cardContentSx,
  cardActionsSx,
  alertSx,
  logoWrapperSx,
} from './newPasswordPageStyles';

const NewPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const makeTestId = useMakeTestId('NewPasswordPage');
  const mainTestId = makeTestId('');
  const createNewPasswordButtonTestId = makeTestId('createNewPassword');
  const newPasswordInputTestId = makeTestId('newPassword');

  const formConfig = NewPasswordFormConfig;
  const { strings } = formConfig;

  const { signingIn, newPasswordError, creatingNewPassword } = useSelector(getViewerState);

  if (signingIn === false) navigate('/sign-in');

  const handleClearError = () => dispatch(viewerClearNewPasswordError());

  const handleSubmit = ({ newPassword }: NewPasswordFormValues) => {
    dispatch(
      viewerNewPassword({
        newPassword,
      }),
    );
  };

  return (
    <Container data-testid={mainTestId} sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader subheader={strings.instructions} sx={cardHeaderSx} title="New password" />
        <CardContent sx={cardContentSx}>
          {newPasswordError && (
            <Alert severity="error" sx={alertSx} onClose={handleClearError}>
              {newPasswordError.message}
            </Alert>
          )}
          <SerenityForm config={NewPasswordFormConfig} onSubmit={handleSubmit}>
            <Form>
              <PasswordField
                autoComplete="new-password"
                dataTestId={newPasswordInputTestId}
                name="newPassword"
              />
              <CardActions sx={cardActionsSx}>
                <LoadingButton
                  fullWidth
                  data-testid={createNewPasswordButtonTestId}
                  loading={creatingNewPassword}
                  type="submit"
                  variant="contained"
                >
                  {strings.submit}
                </LoadingButton>
              </CardActions>
            </Form>
          </SerenityForm>
        </CardContent>
      </Card>
    </Container>
  );
};

export default NewPasswordPage;
