export const listItemSx = {
  px: 0,
};

export const featureNameSx = {
  '.MuiListItemText-primary': {
    fontWeight: 'medium',
  },
};

export const titleSx = {
  flexGrow: 1,
};
