import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useMakeTestId, Form, PasswordField } from '@serenityapp/components-react-web';
import {
  ChangePasswordFormConfig,
  ChangePasswordFormValues,
  SerenityForm,
} from '@serenityapp/components-react-common';
import { getViewerState, viewerChangePassword } from '@serenityapp/redux-store';

import { closeButtonSx, alertSx, formWrapperSx } from './changePasswordDialogStyles';

type ChangePasswordDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ChangePasswordDialog = ({ isOpen = false, onClose }: ChangePasswordDialogProps) => {
  const makeTestId = useMakeTestId('ChangePasswordDialog');
  const mainTestId = makeTestId('');
  const dispatch = useDispatch();

  const { changePasswordError, changingPassword } = useSelector(getViewerState);

  const isLoading = changingPassword;

  const handleSubmit = ({ currentPassword, newPassword }: ChangePasswordFormValues) => {
    dispatch(
      viewerChangePassword({
        currentPassword: currentPassword.trim(),
        newPassword: newPassword.trim(),
        onChangePassword: onClose,
      }),
    );
  };

  const onDialogCloseClick = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <SerenityForm config={ChangePasswordFormConfig} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Dialog data-testid={mainTestId} maxWidth="sm" open={isOpen} onClose={onDialogCloseClick}>
          <DialogTitle>
            Change Password
            <IconButton
              aria-label="close"
              data-testid={makeTestId('close-dialog')}
              disabled={isLoading}
              sx={closeButtonSx}
              onClick={onDialogCloseClick}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {changePasswordError && (
              <Alert severity="error" sx={alertSx}>
                {changePasswordError.message}
              </Alert>
            )}
            <Form disabled={isLoading}>
              <Grid container spacing={3} sx={formWrapperSx}>
                <Grid item sm={12}>
                  <PasswordField
                    autoComplete="current-password"
                    dataTestId={makeTestId('currentPassword')}
                    name="currentPassword"
                  />
                </Grid>
                <Grid item sm={12}>
                  <PasswordField
                    autoComplete="new-password"
                    dataTestId={makeTestId('newPassword')}
                    name="newPassword"
                  />
                </Grid>
              </Grid>
            </Form>
          </DialogContent>
          <DialogActions>
            <Button
              data-testid={makeTestId('close')}
              disabled={isLoading}
              onClick={onDialogCloseClick}
            >
              Close
            </Button>
            <LoadingButton
              data-testid={makeTestId('save')}
              loading={isLoading}
              variant="contained"
              onClick={submitForm}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </SerenityForm>
  );
};

export default ChangePasswordDialog;
