import { AppCard, icons } from '@serenityapp/components-react-web';
import { AppStatus } from '@serenityapp/domain';
import { AppData } from '@serenityapp/redux-store';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { redirectToGoogleCalendarAuthorization } from '../../../../../utils';
import CalendarProviderDialog from './CalendarProviderDialog';

const { GoogleCalendar, OutlookCalendar } = icons;

type CalendarAppCardProps = {
  app: AppData;
};

const CalendarAppCard = ({ app }: CalendarAppCardProps) => {
  const navigate = useNavigate();

  const [calendarProviderDialog, setCalendarProviderDialog] = useState(false);

  const handleCardClick =
    app.status !== AppStatus.DISABLED ? () => navigate('google-calendar') : undefined;

  const handleAppEnableClick = () => setCalendarProviderDialog(true);

  const handleAppConnect = () => {
    redirectToGoogleCalendarAuthorization();
  };

  const calendarAppIcons = [<GoogleCalendar />, <OutlookCalendar />];

  const icons =
    app.status === AppStatus.ENABLED || app.status === AppStatus.PAUSED
      ? [<GoogleCalendar />]
      : calendarAppIcons;

  return (
    <>
      <AppCard
        description={app.description}
        icons={icons}
        status={app.status}
        title={app.displayName}
        onCardClick={handleCardClick}
        onEnableClick={handleAppEnableClick}
      />
      <CalendarProviderDialog
        isOpen={calendarProviderDialog}
        onClose={() => setCalendarProviderDialog(false)}
        onConnect={handleAppConnect}
      />
    </>
  );
};

export default CalendarAppCard;
