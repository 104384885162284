import {
  IAnalytics,
  IImageInfoLookup,
  ISentry,
  LOG_LEVEL,
  MimeLookupIsomorphic,
  SerenityLoggerLevelsFn,
} from '@serenityapp/core';
import { IS3Config } from '@serenityapp/core-aws';
import { IApiClient } from '@serenityapp/core-graphql';
import { wrapRetry, wrapSentry } from '@serenityapp/core-storage';
import { Auth } from 'aws-amplify';
import { FileSystemWeb } from '../../utils/file-system-web';
import { getImageSize } from '../../utils/image';
import { wrapOutbox } from './outbox';
import { previewGenerator, wrapPreview } from './preview';
import { createStorageProvider, IStorageProviderConfigS3 } from './provider';
import { optimizeImageTransform, wrapTransform } from './transform';

// Used in the upload process to determine image dimensions
const imageInfoLookup: IImageInfoLookup = {
  async getInfo(path) {
    const { height, width } = await getImageSize(path);
    return {
      height,
      width,
    };
  },
};

/**
 * Creates an S3 storage client with an in-memory outbox handling upload
 */
export async function createStorageClientAndOutbox(
  s3Config: IS3Config,
  sentry: ISentry,
  analytics: IAnalytics,
  apiClient: IApiClient,
) {
  const providerConfig: IStorageProviderConfigS3 = {
    analytics,
    baseDirectory: '', // Not used on the web
    bucket: s3Config.bucket,
    defaultMimeType: 'application/octet-stream',
    diagnostics: {
      breadcrumbLevels: SerenityLoggerLevelsFn.build(LOG_LEVEL.DEBUG),
    },
    filesystem: FileSystemWeb,
    identifyIdFn: () => Auth.currentCredentials().then((credentials) => credentials.identityId),
    imageInfoLookup,
    mimelookup: new MimeLookupIsomorphic(),
    outboxDirectory: '', // Filesystem-based outbox is not used on the web
    previewGenerator,
    region: s3Config.region,
    sentry,
    tmpDirectory: '', // Not used on the web
    transforms: [optimizeImageTransform],
  };

  // We need the intermediate value for direct access to the outbox (otherwise we could keep wrapping with .then())
  const storageOutbox = await createStorageProvider(providerConfig, apiClient)
    .then(wrapRetry)
    .then(wrapOutbox);
  // prettier-ignore
  const storageClient = await wrapPreview(storageOutbox)
    .then(wrapTransform)
    .then(wrapSentry);

  return {
    client: storageClient,
    outbox: storageOutbox,
  };
}
