export const alertSx = {
  mb: 2.5,
};

export const buttonSx = {
  mb: 2,
};

export const linkButtonSx = {
  border: '1px solid',
  borderColor: 'neutral.outlinedRestingBorder',
  color: 'text.primary',
  mt: 2,
  mb: -1,

  '& .MuiSvgIcon-root': {
    color: 'action.active',
  },
};

export const linkSx = {
  color: 'primary.dark',
  cursor: 'pointer',
};

export const disabledLinkSx = {
  cursor: 'alias',
  pointerEvents: 'none',
  color: 'grey.400',
};

export const logoWrapperSx = {
  display: 'flex',
  justifyContent: 'center',
  pt: 8,
  pb: 3,
};

export const containerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
};

export const cardSx = {
  width: 448,
  pt: 0,
  px: 3,
};

export const cardContentSx = {
  p: 0,
  pt: 2,
};

export const cardHeaderSx = {
  pl: 0,
};

export const cardActionsSx = {
  display: 'flex',
  flexDirection: 'column',
  p: 0,
  pb: 6,
};

export const fieldSx = {
  mb: 0,
};
