export const iconSx = {
  width: 64,
  height: 64,
  fontSize: '3rem',
  mb: 1.5,
};

export const listSx = {
  flex: 1,
};

export const featuresSectionSx = {
  display: 'flex',
};

export const featuresTitleSx = {
  flexGrow: 0,
  mr: 2,
};

export const headWrapperSx = {
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

export const searchBarSx = {
  px: 2,
  pb: 1,
};
