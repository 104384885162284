import { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography,
} from '@mui/material';

import { convertBase64toImageSrc, StringFn } from '@serenityapp/core';
import { Avatar, FormikField, Form, useMakeTestId } from '@serenityapp/components-react-web';
import { AccountSettingsFormConfig, SerenityForm } from '@serenityapp/components-react-common';
import { AbstractUserProps, UserFn } from '@serenityapp/domain';

import ChangePasswordDialog from '../ChangePasswordDialog';
import {
  actionButtonSx,
  avatarSx,
  fieldsWrapperSx,
  headWrapperSx,
  listItemSx,
  sectionHeaderSx,
  userSubTypeSx,
} from './accountSettingsStyles';
import { useIsFeatureEnabled } from '@serenityapp/redux-store';
import DeleteAccountDialog from '../DeleteAccountDialog';

type AccountSettingsProps = {
  currentUser: AbstractUserProps | undefined;
};

const AccountSettings = ({ currentUser }: AccountSettingsProps) => {
  const makeTestId = useMakeTestId('AccountSettings');

  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] = useState(false);

  const isRemoveUsernamesFeatureEnabled = useIsFeatureEnabled('removeUsernames');
  const isDeleteAccountFeatureEnabled = useIsFeatureEnabled('deleteAccount');

  const userName = currentUser?.fullName ?? currentUser?.name ?? '';
  const userAvatarSrc =
    currentUser?.avatar?.url || convertBase64toImageSrc(currentUser?.avatar?.preview);
  const userInitials = currentUser?.initials ?? StringFn.initials(userName);

  const initialFormValues = {
    email: currentUser?.email ?? '',
    fullName: currentUser?.fullName ?? '',
    phoneNumber: currentUser?.phone ?? '',
    userName: currentUser?.name ?? '',
  };

  const isContact = currentUser ? UserFn.isContact(currentUser) : false;

  const handleFormSubmit = () => null;

  const handlePasswordDialog = () => setIsChangePasswordDialogOpen(true);

  const handleDeleteAccountDialog = () => setIsDeleteAccountDialogOpen(true);

  return (
    <Box>
      <Box sx={headWrapperSx}>
        <Avatar initials={userInitials} size={130} src={userAvatarSrc} sx={avatarSx} />
        <Typography variant="h6">{currentUser?.fullName}</Typography>
        <Typography sx={userSubTypeSx} variant="body1">
          {currentUser?.subtype}
        </Typography>
      </Box>
      <List>
        <ListSubheader disableGutters sx={sectionHeaderSx}>
          <Typography display="block" variant="overline">
            General
          </Typography>
        </ListSubheader>
        <Divider />
        <ListItem disableGutters disablePadding>
          <SerenityForm
            enableReinitialize
            config={AccountSettingsFormConfig}
            initialValuesOverride={initialFormValues}
            onSubmit={handleFormSubmit}
          >
            <Box sx={fieldsWrapperSx}>
              <Form>
                <FormikField
                  dataTestId={makeTestId('fullName')}
                  InputProps={{ readOnly: true }}
                  name="fullName"
                  type="text"
                />
                <FormikField
                  disabled
                  dataTestId={makeTestId('email')}
                  InputProps={{ readOnly: true }}
                  name="email"
                  type="email"
                />
                {!isRemoveUsernamesFeatureEnabled && (
                  <FormikField
                    dataTestId={makeTestId('userName')}
                    InputProps={{ readOnly: true }}
                    name="userName"
                    type="text"
                  />
                )}
                <FormikField
                  dataTestId={makeTestId('phoneNumber')}
                  InputProps={{ readOnly: true }}
                  name="phoneNumber"
                  type="text"
                />
              </Form>
            </Box>
          </SerenityForm>
        </ListItem>
        <ListSubheader disableGutters sx={sectionHeaderSx}>
          <Typography display="block" variant="overline">
            Account
          </Typography>
        </ListSubheader>
        <Divider />
        <ListItem disableGutters sx={listItemSx}>
          <ListItemText
            primary="Password"
            secondary="A strong password helps prevent unauthorized access to your account."
          />
          <Button
            data-testid="ChangePassword"
            sx={actionButtonSx}
            variant="outlined"
            onClick={handlePasswordDialog}
          >
            Change password
          </Button>
        </ListItem>
        {isContact && isDeleteAccountFeatureEnabled && (
          <ListItem disableGutters sx={listItemSx}>
            <ListItemText
              primary="Delete Serenity account"
              secondary="Keep in mind you will lose access to your personal data. This action is irreversible."
            />
            <Button
              color="error"
              data-testid="DeleteAccount"
              sx={actionButtonSx}
              variant="contained"
              onClick={handleDeleteAccountDialog}
            >
              Delete account
            </Button>
          </ListItem>
        )}
        <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack direction="row" spacing={4} sx={{ marginTop: '1.5rem' }}>
            <Link
              href={'https://serenityconnect.com/privacy-policy/'}
              rel="noopener"
              target="_blank"
              type="button"
              underline="always"
              variant="body1"
            >
              Privacy Policy
            </Link>
            <Link
              href={'mailto:support@serenityconnect.com'}
              rel="noopener"
              target="_blank"
              type="button"
              underline="always"
              variant="body1"
            >
              Support
            </Link>
          </Stack>
        </ListItem>
      </List>
      <ChangePasswordDialog
        isOpen={isChangePasswordDialogOpen}
        onClose={() => setIsChangePasswordDialogOpen(false)}
      />
      <DeleteAccountDialog
        isOpen={isDeleteAccountDialogOpen}
        onClose={() => setIsDeleteAccountDialogOpen(false)}
      />
    </Box>
  );
};

export default AccountSettings;
