import { ListSubheader, MenuItem, Divider, TextFieldProps, TextField } from '@mui/material';
import { SelectorItemProps, useSerenityField } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';

export type GroupedSelectItem = {
  name: string;
  items: Array<SelectorItemProps>;
};

export type GroupedSelectFieldProps = TextFieldProps & {
  dataTestId?: string;
  name: string;
  groups?: Array<GroupedSelectItem>;
};

// Function to find the label by id
const findLabelById = (id: string, groups: Array<GroupedSelectItem>) => {
  for (const group of groups) {
    const foundItem = group.items.find((item) => item.id === id);
    if (foundItem) {
      return foundItem.label;
    }
  }
  return null;
};

const GroupedSelectField = ({
  dataTestId,
  groups = [],
  name,
  helperText,
  label,
  sx,
  variant,
  disabled,
}: GroupedSelectFieldProps) => {
  const [config, field, meta] = useSerenityField({ name });

  const hasError = meta.touched && !!meta.error;
  const newHelperText = (hasError && meta.error) || helperText || config.helperText || ' ';

  const makeTestId = useMakeTestId('GroupedSelectField', dataTestId);
  const selectFieldTestId = makeTestId('', { error: hasError });

  return (
    <TextField
      {...field}
      fullWidth
      select
      data-testid={selectFieldTestId}
      disabled={disabled}
      error={hasError}
      helperText={newHelperText}
      label={label || config.label}
      SelectProps={{
        autoWidth: true,
        renderValue: (selectedValue: any) => findLabelById(selectedValue, groups),
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      }}
      sx={[helperTextMarginSx, ...(Array.isArray(sx) ? sx : [sx])]}
      value={field.value || ''}
      variant={variant}
    >
      <MenuItem key="none" value="">
        <em>None</em>
      </MenuItem>
      {/* the Select component in Material-UI does not support rendering Fragments directly as children. Select is used under the hook here */}
      {groups.map((group) => [
        <ListSubheader key={`header-${group.name}`}>{group.name}</ListSubheader>,
        <Divider key={`divider-${group.name}`} sx={{ mx: 1.75 }} />,
        group.items.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        )),
      ])}
    </TextField>
  );
};

const helperTextMarginSx = {
  mb: 2,
};

export default GroupedSelectField;
