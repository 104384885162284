import { z } from 'zod';

const digitalSignValidationSchema = z.object({
  kind: z.literal('DigitalSign').default('DigitalSign'),
});

const alexaDeviceValidationSchema = z.object({
  kind: z.literal('AlexaDevice').default('AlexaDevice'),
  dndModeEnabled: z.boolean().optional(),
  maxVolume: z.number().optional(),
  echoSpeakingRate: z.number().optional(),
  settingsPolicyId: z.string().optional(),
});

const commonValidationSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, { message: 'Required' }),
  kind: z.string().refine((value) => value === 'AlexaDevice' || value === 'DigitalSign', {
    message: 'Required',
  }),
});

const alexaDeviceValidationSchemaAllFields = commonValidationSchema.merge(
  alexaDeviceValidationSchema,
);

export type AlexaDeviceFormValues = z.infer<typeof alexaDeviceValidationSchemaAllFields>;

export type DigitalSignFormValues = z.infer<typeof digitalSignValidationSchema>;

export const validationSchema = commonValidationSchema.and(
  z.discriminatedUnion('kind', [alexaDeviceValidationSchema, digitalSignValidationSchema]),
);
