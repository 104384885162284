export const buttonSx = {
  border: '1px solid',
  borderColor: 'neutral.outlinedRestingBorder',
  color: 'text.primary',

  '& .MuiSvgIcon-root': {
    color: 'action.active',
  },
};

export const containerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
};

export const cardSx = {
  width: 448,
};

export const cardHeaderSx = {
  pl: 3,
};

export const cardContentSx = {
  p: 0,
};

export const logoWrapperSx = {
  display: 'flex',
  justifyContent: 'center',
  pt: 8,
  pb: 3,
};

export const listItemButtonSx = {
  px: 3,
};

export const listItemSx = {
  px: 3,
};

export const listItemIconSx = {
  minWidth: 24,
};

export const cardActionsSx = {
  pb: 8,
  px: 3,
  pt: 2,
};

export const spanSx = {
  mx: 0.5,
  fontSize: 14,
  color: 'action.active',
};

export const linkSx = {
  color: 'primary.dark',
};
