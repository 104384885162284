import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ipAllowListPolicyUpdateStatus, snackAdd } from '@serenityapp/redux-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

type EnableIpAllowListPolicyDialogProps = {
  id: string;
  orgId: string;
  onSuccess?: () => void;
  onFailed?: () => void;
  onClose: () => void;
  title: string;
  dataTestId?: string;
};

const EnableIpAllowListPolicyDialog = ({
  id,
  orgId,
  onSuccess,
  onFailed,
  onClose,
  title,
  dataTestId,
}: EnableIpAllowListPolicyDialogProps) => {
  const makeTestId = useMakeTestId('EnableIpAllowListPolicyDialog', dataTestId);
  const enableDialog = makeTestId('');

  const dispatch = useDispatch();

  const [isDisabling, setIsDisabling] = useState(false);

  const onEnableSuccess = () => {
    setIsDisabling(false);
    dispatch(
      snackAdd({ message: 'IP Allow List policy successfully enabled.', type: 'success' }),
    );
    onSuccess?.();
  };

  const onEnableFailed = () => {
    setIsDisabling(false);
    dispatch(snackAdd({ message: 'Error enabling IP Allow List policy.', type: 'error' }));
    onFailed?.();
  };

  const handleEnableConfirmClick = () => {
    setIsDisabling(true);
    dispatch(
      ipAllowListPolicyUpdateStatus({
        onFailed: onEnableFailed,
        onSuccess: onEnableSuccess,
        variables: {
          input: {
            ipAllowListPolicy: {
              id: id,
              disabled: false,
            },
            organization: {
              id: orgId,
            },
          },
        },
      }),
    );
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open
      data-testid={enableDialog}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Enable {title} policy</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to enable this IP allow list policy '{title}'?
        </Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>
            Once enabled, these IP ranges will have access permissions through this policy.
          </ListItem>
          <ListItem sx={listItemSx}>
            To disable this policy in the future, you can do so via "Actions" menu.
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} disabled={isDisabling} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('enable')}
          loading={isDisabling}
          variant="contained"
          onClick={handleEnableConfirmClick}
        >
          Enable
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default EnableIpAllowListPolicyDialog;
