export const snackbarSx = {
  '.MuiSnackbar-root': {
    position: 'absolute',
    top: 8,
  },
};

export const unreadMessageIndicatorWrapperSx = {
  position: 'absolute',
  right: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 10,
  width: '100%',
  bgcolor: 'transparent',
};

export const positionUpSx = {
  top: 10,
};

export const positionDownSx = {
  bottom: 10,
};
