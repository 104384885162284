import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Skeleton, Typography } from '@mui/material';
import { ConversationTitle } from '@serenityapp/components-react-web';

type ConversationPageHeaderTitleProps = {
  subtype?: string;
  isGroupConversation?: boolean;
  name?: string;
  isLoading: boolean;
};

const ConversationPageHeaderTitle = ({
  subtype,
  isGroupConversation,
  name,
  isLoading,
}: ConversationPageHeaderTitleProps) => {
  const navigate = useNavigate();

  const openAboutTab = useCallback(() => {
    navigate('details/about');
  }, [navigate]);

  if (isLoading)
    return (
      <Typography component="h1" sx={conversationTitleSx} variant="h6">
        <Skeleton width="200px" />
      </Typography>
    );

  if (!name) return null;

  const isDM = subtype === 'dm';
  const isAppChannel = subtype === 'app';

  return (
    <ConversationTitle
      isDM={isDM}
      isGroupDM={isGroupConversation}
      sx={conversationTitleSx}
      title={name}
      onClick={isAppChannel ? openAboutTab : undefined}
    />
  );
};

const conversationTitleSx = {
  display: 'flex',
  flexGrow: 1,
  width: 0,
};

export default ConversationPageHeaderTitle;
