import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CLEAR_EDITOR_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ENTER_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { $convertToMarkdownString } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import {
  RootState,
  getMessageEntryByConversationId,
  messagesCreate,
} from '@serenityapp/redux-store';

import {
  CLEAR_ATTACHMENT_COMMAND,
  SEND_BUTTON_CLICKED_COMMAND,
  SEND_MESSAGE_COMMAND,
} from '../customLexicalCommands';
import { getEmojiTransformer, getLineBreakTransformer, getLinkTransformer } from './utils';

const EMOJI_TRANSFORMER = getEmojiTransformer();
const LINK_TRANSFORMER = getLinkTransformer();
const LINE_BREAK_TRANSFORMER = getLineBreakTransformer();

type SendMessagePluginProps = {
  onSendMessage: () => void;
  conversationId: string;
};

const SendMessagePlugin = ({ onSendMessage, conversationId }: SendMessagePluginProps): null => {
  const dispatch = useDispatch();

  const [editor] = useLexicalComposerContext();

  const { attachments = [] } = useSelector((state: RootState) =>
    getMessageEntryByConversationId(state, conversationId),
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        SEND_MESSAGE_COMMAND,
        () => {
          const markdownMessage = $convertToMarkdownString([
            LINE_BREAK_TRANSFORMER,
            LINK_TRANSFORMER,
            EMOJI_TRANSFORMER,
          ]);

          if (markdownMessage.length === 0 && attachments.length === 0) {
            // If entry is empty we want to return from the command handler without doing anything.
            // Returning false since we do not want to stop propagation of the command.
            // There are other commands registers with lower priority that needs to be executed.
            return false;
          }

          dispatch(
            messagesCreate({
              conversationId,
              message: markdownMessage,
              attachments,
            }),
          );

          onSendMessage();
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
          editor.dispatchCommand(CLEAR_ATTACHMENT_COMMAND, undefined);
          editor.focus();

          // Returning false since we do not want to stop propagation of the command.
          // There are other commands registers with lower priority that needs to be executed.
          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null && !event.shiftKey) {
            event.preventDefault();
            editor.dispatchCommand(SEND_MESSAGE_COMMAND, undefined);
          }
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        SEND_BUTTON_CLICKED_COMMAND,
        () => {
          editor.dispatchCommand(SEND_MESSAGE_COMMAND, undefined);
          return true;
        },
        COMMAND_PRIORITY_HIGH,
      ),
    );
  }, [attachments, conversationId, dispatch, editor, onSendMessage]);

  return null;
};

export default SendMessagePlugin;
