import { Skeleton } from '@mui/material';
import { AvatarGroup } from '@serenityapp/components-react-web';
import {
  conversationMembersTransformer,
  getVariablesForConversationDetailMessagingQuery,
  useConversationDetailMessaging,
} from '@serenityapp/redux-store';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type AvatarGroupControlProps = {
  conversationId: string;
  canDisplayControl?: boolean;
};

const AvatarGroupControl = ({ conversationId, canDisplayControl }: AvatarGroupControlProps) => {
  const navigate = useNavigate();
  const { data, isLoading } = useConversationDetailMessaging({
    variables: getVariablesForConversationDetailMessagingQuery(conversationId),
  });

  const { allMembers } = conversationMembersTransformer(data);
  const hasMembers = allMembers.length > 0;

  const onClick = useCallback(() => {
    navigate('details/members');
  }, [navigate]);

  // We display Skeleton in two cases:
  // 1. when permission is loaded or passed in props and is set to true,
  // but the conversation details are still loading and no cached data exists
  // 2. when we are waiting for permission to load
  if ((canDisplayControl && isLoading && !hasMembers) || canDisplayControl === undefined)
    return <Skeleton width="100px" />;

  // We return null if permission to see the data is set to false OR when there is no data to display after loading
  if (canDisplayControl === false || !hasMembers) return null;

  return <AvatarGroup items={allMembers} max={4} sx={membersButtonSx} onClick={onClick} />;
};

const membersButtonSx = {
  display: { xs: 'none', sm: 'flex' },
};

export default AvatarGroupControl;
