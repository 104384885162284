export const dialogContentSx = {
  p: 0,
  display: 'flex',
  alignItems: 'stretch',
};

export const dialogTitleSx = {
  pb: 0,
};

export const tabsSx = {
  pt: 3,
  pb: 1,

  '& .MuiTab-root': {
    textTransform: 'none',
  },
};

export const tabPanelSx = {
  flexGrow: 1,
  overflowY: 'auto',

  '&.MuiTabPanel-root': {
    p: 0,
  },
};
