import * as Sentry from '@sentry/react';
import { Cache, ResolveInfo } from '@urql/exchange-graphcache';
import { ClientApi } from '@serenityapp/api-client-graph';

/**
 * @remark handles filter create mutation result in urql cache
 * (filter = legacy location)
 */
export default function locationCreate(
  result: ClientApi.Filter.Api.Create.MutationResult,
  _args: ClientApi.Filter.Api.Create.Variables,
  cache: Cache,
  info: ResolveInfo,
) {
  // TODO: rename this mutation when we refactor legacy locations api to filters
  // change types and typename accordingly
  const createFilterOutput = result?.result;

  if (info.error || !createFilterOutput || !createFilterOutput.success) {
    return;
  }

  const { id, name, description, type, archived } = createFilterOutput.filter;

  const filter: ClientApi.Filter.Fragments.ListType = {
    id,
    name: name || '',
    description: description || '',
    type: type || '',
    archived: archived ?? 0,
    __typename: 'Location',
  };

  const node = { node: filter, __typename: 'ViewerLocationEdge' };

  const q = {
    query: ClientApi.Viewer.Api.Filters.Query,
    variables: {},
    requestPolicy: 'cache-and-network',
  };

  cache.updateQuery<ClientApi.Viewer.Api.Filters.Response>(q, (data) => {
    if (!data) {
      Sentry.addBreadcrumb({
        message: 'Failed to get current viewer filters query data while trying to add a filter',
        data: { q },
      });
      return null;
    }
    const updatedEdges = [node, ...data.viewer.filters.edges];

    const newFilter = {
      ...data.viewer.filters,
      edges: updatedEdges,
    };

    data.viewer.filters = newFilter;
    return data;
  });
}
