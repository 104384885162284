import * as Sentry from '@sentry/react';
import { Cache } from '@urql/exchange-graphcache';
import { Schema } from '@serenityapp/domain';
import { OrganizationResult, organizationLocationsQuery } from '@serenityapp/api-graphql';
import { DEFAULT_LOCATIONS_PAGE_SIZE } from '../../pages/admin/LocationsPage/utils';

const getOrganizationLocationsQueryVariables = (organizationId: string) => ({
  getInput: {
    id: organizationId,
  },
  input: {
    kinds: ['LocationGroup', 'Building', 'Unit'],
  },
  pageInput: {
    after: '',
    first: DEFAULT_LOCATIONS_PAGE_SIZE,
  },
});

export const addLocation = (
  location: Schema.Location.ItemWithTypename,
  organizationId: string,
  cache: Cache,
) => {
  const q = {
    query: organizationLocationsQuery,
    variables: getOrganizationLocationsQueryVariables(organizationId),
  };

  const newEdge: Schema.Organization.Locations.Edge = {
    __typename: 'OrganizationLocationEdge',
    organization: { orgId: organizationId, id: organizationId },
    id: `${organizationId}-${location.id}`,
    location,
  };

  cache.updateQuery<OrganizationResult>(q, (data) => {
    if (!data) {
      Sentry.addBreadcrumb({
        message:
          'Failed to get current organization location query data while trying to add a location',
        data: { q },
      });
      return null;
    }
    const updatedEdges = [newEdge, ...data.organization.locations.edges];
    const locations = {
      ...data.organization.locations,
      edges: updatedEdges,
    };
    data.organization.locations = locations;
    return data;
  });
};

export const removeLocations = (
  locationsToRemove: string[],
  organizationId: string,
  cache: Cache,
) => {
  const entityKey = cache.keyOfEntity({ __typename: 'Organization', id: organizationId });
  const allFields = cache.inspectFields(entityKey);

  // Filter all fields on the Organization entity to find location fields
  const locationFields = allFields.filter((field) => field.fieldName === 'locations');

  locationFields.forEach((field) => {
    const arg = field?.arguments || getOrganizationLocationsQueryVariables(organizationId);

    const q = {
      query: organizationLocationsQuery,
      variables: {
        input: arg.input,
        pageInput: arg.pageInput,
        getInput: {
          id: organizationId,
        },
      },
    };

    cache.updateQuery<OrganizationResult>(q, (data) => {
      if (!data) {
        Sentry.addBreadcrumb({
          message:
            'Failed to get current organization location query data while trying to remove a location',
          data: { q },
        });
        return null;
      }

      const cachedEdges = data.organization.locations.edges;

      const updatedEdges = cachedEdges.filter(
        (edge) => !locationsToRemove.includes(edge.location.id),
      );

      // If no changes were made, return the original data
      if (updatedEdges.length === cachedEdges.length) {
        return data;
      }

      data.organization.locations.edges = updatedEdges;
      return data;
    });
  });
};
