import { FieldArray, Field, FieldArrayRenderProps } from 'formik';
import { Box, Button, IconButton, SxProps, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSerenityField } from '@serenityapp/components-react-common';
import { Range } from '@serenityapp/domain';
import { useMakeTestId } from '@serenityapp/components-react-web';

type IpRangeFieldProps = {
  name: string;
  sx?: SxProps;
};

type IpRangeFieldError = {
  ipStart?: string;
  ipEnd?: string;
};

const IpRangeField = ({ name, sx }: IpRangeFieldProps) => {
  const makeTestId = useMakeTestId('IpRangeField');
  const ipRangeFieldTestId = makeTestId('');

  const [, field, meta, helpers] = useSerenityField({ name });
  // can't cast string to array so I had to cast it to any first
  const errors: IpRangeFieldError[] = meta.error
    ? (meta.error as any as IpRangeFieldError[])
    : [];

  const onRemoveRange = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    if (field.value.length > 1) {
      arrayHelpers.remove(index);
    } else {
      helpers.validateField();
    }
  };

  return (
    <Box data-testid={ipRangeFieldTestId} sx={sx}>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <Box>
            {field.value.map((range: Range, index: number) => {
              const ipStartError = errors[index]?.ipStart;
              const ipEndError = errors[index]?.ipEnd;

              return (
                <Box key={`${name}.${index}`} sx={ipRangeWrapperSx}>
                  <Field
                    as={TextField}
                    data-testid={makeTestId(`${index}.ipStart`)}
                    error={!!ipStartError}
                    helperText={ipStartError ? ipStartError : 'Required'}
                    label="IP Start Address"
                    name={`${name}.${index}.ipStart`}
                    sx={fieldSx}
                    onBlur={() => helpers.validateField()}
                  />
                  <Field
                    as={TextField}
                    data-testid={makeTestId(`${index}.ipEnd`)}
                    error={!!ipEndError}
                    helperText={ipEndError ? ipEndError : ' '}
                    label="IP End Address"
                    name={`${name}.${index}.ipEnd`}
                    sx={fieldSx}
                    onBlur={() => helpers.validateField()}
                  />
                  {field.value.length > 1 && (
                    <IconButton
                      data-testid={makeTestId(`${index}.remove`)}
                      sx={removeButtonSx}
                      onClick={() => onRemoveRange(arrayHelpers, index)}
                    >
                      <CancelIcon />
                    </IconButton>
                  )}
                </Box>
              );
            })}
            <Box sx={addButtonWrapperSx}>
              <Button
                color="primary"
                data-testid={makeTestId('Add')}
                sx={addButtonSx}
                type="button"
                variant="outlined"
                onClick={() => {
                  arrayHelpers.push({ ipStart: '', ipEnd: '' });
                  helpers.validateField();
                }}
              >
                Add IP Range
              </Button>
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

const ipRangeWrapperSx = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  mb: 1,
  gap: 2,
};

const fieldSx = {
  flex: 1,
};

const removeButtonSx = {
  display: 'flex',
  alignSelf: 'flex-start',
  mt: 1,
};

const addButtonWrapperSx = {
  display: 'flex',
  alignSelf: 'flex-start',
  mt: 2,
};

const addButtonSx = {
  display: 'flex',
  flex: 1,
};

export default IpRangeField;
