import { ChangeEvent } from 'react';
import * as R from 'ramda';
import { FormikErrors } from 'formik';
import { useSelector } from 'react-redux';
import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Typography,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  FormHelperText,
  Tooltip,
} from '@mui/material';

import {
  CheckInPolicyCreateEditFormConfig,
  CheckInPolicyCreateEditFormValues,
  SerenityForm,
  SelectorItemProps,
} from '@serenityapp/components-react-common';
import {
  FormikField,
  Drawer,
  Form,
  AutocompleteChipSelectorField,
  SwitchField,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import {
  RootState,
  getSortedIAMGroups,
  useApps,
  cloudStorageAppOptionsTransformer,
  useIsFeatureEnabled,
} from '@serenityapp/redux-store';
import { IAMGroupProps } from '@serenityapp/domain';
import { App, ServiceLevel } from '@serenityapp/core';

import SliderField from './SliderField';
import { transformServiceLevelsToSelectorItemProps } from '../../../common/utils';

type CheckInCreateEditFormProps = {
  initialValues?: Partial<CheckInPolicyCreateEditFormValues>;
  handleSubmit: (values: CheckInPolicyCreateEditFormValues) => void;
  returnToCheckInAppDetail: () => void;
  onCheckInPolicyFormStateChanged: (isDirty: boolean) => void;
  onConfirmDialogOpen: (isOpen: boolean) => void;
  title: string;
  isLoading?: boolean;
  isSaving?: boolean;
  initialErrors?: FormikErrors<CheckInPolicyCreateEditFormValues>;
};

const MIN_DISTANCE = 4;

const CheckInPolicyCreateEditForm = ({
  initialValues,
  handleSubmit,
  onCheckInPolicyFormStateChanged,
  title,
  isSaving,
  isLoading,
  returnToCheckInAppDetail,
  onConfirmDialogOpen,
  initialErrors,
}: CheckInCreateEditFormProps) => {
  const makeTestId = useMakeTestId('CheckInPolicyCreateEditForm');

  const { data } = useApps();
  const cloudStorageAppOptions = cloudStorageAppOptionsTransformer(data);
  const isCloudStorageAppsFeatureEnabled = useIsFeatureEnabled('cloudStorageApps');

  const groups: Array<IAMGroupProps> = useSelector((state: RootState) =>
    getSortedIAMGroups(state),
  );

  const groupsAsSelectorItems: Array<SelectorItemProps> = groups.map((group) => ({
    id: group.id,
    label: group.name ?? '',
  }));

  const serviceLevels: SelectorItemProps[] = transformServiceLevelsToSelectorItemProps(
    Object.values(ServiceLevel),
  );

  const formatTimeLabel = (value: number) => {
    const hour = value % 12 === 0 ? 12 : value % 12;
    const amPm = value < 12 || value === 24 ? 'am' : 'pm';
    return `${hour}${amPm}`;
  };

  const timeMarks = Array.from({ length: 25 }, (_, hour) => ({
    value: hour,
    label: hour === 0 || hour === 24 ? formatTimeLabel(hour) : '',
  }));

  const isRestrictedTime = (time: number) =>
    (time >= 0 && time <= 1) || (time >= 23 && time <= 24);

  const handleClose = () => {
    returnToCheckInAppDetail();
  };

  // This one is to make sure we have all cloud storage apps in the system (installed as
  // catalogue apps)
  const hasCloudStorageAppsInSystem =
    cloudStorageAppOptions &&
    // We need to compare two arrays for its equality. We need to sort both arrays in order
    // to compare them with R.equals. Otherwise, R.equals will return false even if both
    // arrays have the same elements.
    R.equals(
      Object.keys(cloudStorageAppOptions).sort(),
      // The reason why App.CloudStorageProviderApps is copied here and not used directly is
      // because it contains correct order of the items in which we want to display items in the
      // UI. If we use App.CloudStorageProviderApps directly, it will be sorted alphabetically
      // and we will lose the order.
      [...App.CloudStorageProviderApps].sort(),
    );

  const renderCloudStorageProviderAppOption = (app: string) => {
    if (!cloudStorageAppOptions || !cloudStorageAppOptions[app]) return null;

    const appInfo = cloudStorageAppOptions[app];

    const formControl = (
      <FormControlLabel
        key={app}
        control={<Radio />}
        disabled={appInfo.disabled}
        label={appInfo.name}
        value={app}
      />
    );

    if (appInfo.disabled) {
      return (
        <Tooltip key={app} arrow title={appInfo.tooltip}>
          {formControl}
        </Tooltip>
      );
    }

    return formControl;
  };

  return (
    <>
      <SerenityForm
        enableReinitialize
        config={CheckInPolicyCreateEditFormConfig}
        initialErrors={initialErrors}
        initialValuesOverride={initialValues}
        onSubmit={handleSubmit}
      >
        {({ dirty, values, setFieldValue, submitForm, errors, handleChange, validateField }) => {
          const onDrawerCloseClick = () => {
            if (dirty) {
              onConfirmDialogOpen(true);
              return;
            }

            handleClose();
          };

          const hasErrors = Object.keys(errors).length > 0;

          const handleInputChange = async (e: any, fieldName: string) => {
            await handleChange(e);
            validateField(fieldName);
          };

          return (
            <>
              <Drawer.Header loading={isSaving || isLoading}>
                <IconButton sx={iconButtonSx} onClick={onDrawerCloseClick}>
                  <ArrowBack />
                </IconButton>
                <Typography noWrap variant="h6">
                  {title}
                </Typography>
              </Drawer.Header>
              <Drawer.Content>
                <Form
                  disabled={isLoading || isSaving}
                  onBlur={() => {
                    onCheckInPolicyFormStateChanged(dirty);
                  }}
                >
                  <Typography display="block" sx={{ pb: 2 }} variant="subtitle1m">
                    General
                  </Typography>
                  <FormikField
                    dataTestId={makeTestId('name')}
                    helperText="Required"
                    name="name"
                    sx={{ mb: 0 }}
                    type="text"
                    onChange={(e) => handleInputChange(e, 'name')}
                  />
                  <Typography display="block" sx={{ pb: 2 }} variant="subtitle1m">
                    Who can see reports?
                  </Typography>
                  <AutocompleteChipSelectorField
                    dataTestId={makeTestId('viewers')}
                    helperText="Required"
                    items={groupsAsSelectorItems}
                    label="Roles"
                    name="viewers"
                    placeholder="Add"
                    sx={{ pb: 0 }}
                    onChange={(e) => handleInputChange(e, 'viewers')}
                  />
                  <Typography display="block" variant="subtitle1m">
                    Who can manage reports?
                  </Typography>
                  <Typography display="block" sx={{ ...secondaryTextSx, pb: 2 }}>
                    Manually Check participants in and set their away status
                  </Typography>
                  <AutocompleteChipSelectorField
                    dataTestId={makeTestId('managers')}
                    helperText="Required"
                    items={groupsAsSelectorItems}
                    label="Roles"
                    name="managers"
                    placeholder="Add"
                    onChange={(e) => handleInputChange(e, 'managers')}
                  />
                  <Typography display="block" variant="subtitle1m">
                    Who can approve reports?
                  </Typography>
                  <Box sx={{ pb: 2 }}>
                    <FormControlLabel
                      control={
                        <SwitchField
                          dataTestId={makeTestId('isApprovalRequired')}
                          name="isApprovalRequired"
                          onChange={(event) => {
                            const isSwitchedOn = event.target.checked;
                            setFieldValue('isApprovalRequired', isSwitchedOn);
                            if (!isSwitchedOn) {
                              setFieldValue('approvers', []);
                            }
                          }}
                        />
                      }
                      label="Require report approval"
                    />
                  </Box>
                  {values.isApprovalRequired && (
                    <AutocompleteChipSelectorField
                      dataTestId={makeTestId('approvers')}
                      helperText="Required"
                      items={groupsAsSelectorItems}
                      label="Roles"
                      name="approvers"
                      placeholder="Add"
                      onChange={(e) => handleInputChange(e, 'approvers')}
                    />
                  )}
                  <Typography display="block" sx={{ pb: 2 }} variant="subtitle1m">
                    Participants
                  </Typography>
                  <AutocompleteChipSelectorField
                    dataTestId={makeTestId('serviceLevels')}
                    helperText="Required"
                    items={serviceLevels}
                    label="Service level"
                    name="serviceLevels"
                    placeholder="Add"
                    onChange={(e) => handleInputChange(e, 'serviceLevels')}
                  />
                  <Typography display="block" variant="subtitle1m">
                    Report
                  </Typography>
                  <Box sx={{ pb: 2 }}>
                    <FormControlLabel
                      control={
                        <SwitchField
                          dataTestId={makeTestId('includeOptedOut')}
                          name="includeOptedOut"
                        />
                      }
                      label="Include Opted-Out participants section in the report"
                    />
                  </Box>
                  {isCloudStorageAppsFeatureEnabled && hasCloudStorageAppsInSystem && (
                    <>
                      <Box sx={{ pb: 2 }}>
                        <FormControlLabel
                          control={
                            <SwitchField
                              dataTestId={makeTestId('useCloudStorage')}
                              name="useCloudStorage"
                              onChange={(event) => {
                                const isSwitchedOn = event.target.checked;
                                setFieldValue('useCloudStorage', isSwitchedOn);
                                if (!isSwitchedOn) {
                                  setFieldValue('cloudStorageName', undefined);
                                  setFieldValue('reportFolderId', undefined);
                                }
                              }}
                            />
                          }
                          label="Save report to cloud storage"
                        />
                        <Alert severity="info" sx={alertSx}>
                          Save all report files to your preferred cloud storage service (Needs to
                          be configured separately). It will be organized in the following folder
                          structure: Serenity/Check-In/Policy/Report Date/
                        </Alert>
                      </Box>
                      {values.useCloudStorage && (
                        <Box sx={{ pb: 2 }}>
                          <Typography sx={secondaryTextSx}>Cloud Storage providers</Typography>
                          <FormControl>
                            <RadioGroup
                              defaultValue="S3"
                              name="cloudStorageName"
                              value={values.cloudStorageName}
                              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('cloudStorageName', event.target.value);
                              }}
                            >
                              {App.CloudStorageProviderApps.map(
                                renderCloudStorageProviderAppOption,
                              )}
                            </RadioGroup>
                            {errors.cloudStorageName && (
                              <FormHelperText error sx={{ m: 0 }}>
                                {errors.cloudStorageName}
                              </FormHelperText>
                            )}
                          </FormControl>
                          {values.cloudStorageName === App.Names.Box && (
                            <>
                              <Alert severity="info" sx={alertSx}>
                                The ID for any folder can be determined by visiting this folder in
                                the web application and copying the ID from the URL
                              </Alert>
                              <FormikField
                                helperText="Required"
                                name="reportFolderId"
                                type="text"
                                onChange={(e) => handleInputChange(e, 'reportFolderId')}
                              />
                            </>
                          )}
                        </Box>
                      )}
                    </>
                  )}
                  <Typography variant="subtitle1m">
                    How frequent should Check-In run each day?
                  </Typography>
                  <Typography sx={secondaryTextSx}>
                    Run Check-In ones or twice a day, generating separate reports for each time
                    period. Each Check-In session will have its own reminder schedule.
                  </Typography>
                  <FormControl sx={{ py: 2 }}>
                    <RadioGroup
                      name="checkInFrequency"
                      value={values.checkInFrequency}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('checkInFrequency', event.target.value);

                        if (event.target.value === '1') {
                          // If the first check in time is greater than 18 at the moment we
                          // switch check-in frequency from running once to running twice,
                          // than we should move the first check in time at latest possible
                          // time (18:00) for first check in time and add the second point at
                          // the end of the slider.
                          if (values.timeSliderValue?.[0] >= 18) {
                            setFieldValue('timeSliderValue', [18, 22]);
                          } else {
                            setFieldValue('timeSliderValue', [
                              ...values.timeSliderValue,
                              values.timeSliderValue?.[0] + MIN_DISTANCE,
                            ]);
                          }
                        } else {
                          setFieldValue('timeSliderValue', [values.timeSliderValue?.[0]]);
                        }
                      }}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        data-testid={makeTestId('once')}
                        label="Once a day"
                        value="0"
                      />
                      <FormControlLabel
                        control={<Radio />}
                        data-testid={makeTestId('twice')}
                        label="Twice a day"
                        value="1"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Typography display="block" variant="subtitle1m">
                    Check-In time(s)
                  </Typography>
                  <Typography sx={secondaryTextSx}>
                    The time by which most participants should respond. Used for reminders and
                    updating reports.
                  </Typography>
                  <Box
                    data-testid={makeTestId('SliderFieldInfo')}
                    sx={{ mt: 2, display: 'flex' }}
                  >
                    <Typography sx={{ mr: 3 }}>
                      First Check-In: {formatTimeLabel(values.timeSliderValue?.[0])}{' '}
                      {values.checkInPolicyTimeZone}
                    </Typography>
                    {values.timeSliderValue?.[1] && (
                      <Typography>
                        Second Check-In: {formatTimeLabel(values.timeSliderValue?.[1])}{' '}
                        {values.checkInPolicyTimeZone}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ px: 2.2 }}>
                    <SliderField
                      disableSwap
                      marks={timeMarks}
                      max={24}
                      min={0}
                      name="timeSliderValue"
                      size="small"
                      step={1}
                      sx={sliderSx}
                      track={false}
                      valueLabelDisplay="on"
                      valueLabelFormat={formatTimeLabel}
                      onChange={(_: any, newValue: number | number[], activeThumb: number) => {
                        if (!Array.isArray(newValue)) {
                          return;
                        }

                        // We want to prevent user selecting 12am 1am at the start of day
                        // so residents have 2 hours before check-in time and 11pm 12 am at the end of the day
                        if (isRestrictedTime(newValue[0]) || isRestrictedTime(newValue[1])) {
                          return;
                        }
                        if (values.timeSliderValue.length === 1) {
                          setFieldValue('timeSliderValue', [newValue[0]]);
                        } else {
                          if (activeThumb === 0) {
                            setFieldValue('timeSliderValue', [
                              Math.min(newValue[0], values.timeSliderValue?.[1] - MIN_DISTANCE),
                              values.timeSliderValue[1],
                            ]);
                          } else {
                            setFieldValue('timeSliderValue', [
                              values.timeSliderValue[0],
                              Math.max(newValue[1], values.timeSliderValue?.[0] + MIN_DISTANCE),
                            ]);
                          }
                        }
                      }}
                    />
                  </Box>
                  {values.timeSliderValue.length === 2 && (
                    <Typography sx={{ mt: 1, ...secondaryTextSx }} variant="caption">
                      Note: Check-In sessions running twice a day must be at least 4 hours apart.
                    </Typography>
                  )}
                  <Alert severity="info" sx={alertSx}>
                    Participants are allowed to Check-In before each session's Check-In time and 2
                    hours after. Checking-In prior will cancel any scheduled reminders.
                  </Alert>
                  <Box sx={{ pb: 2 }}>
                    <FormControlLabel
                      control={
                        <SwitchField
                          dataTestId={makeTestId('allowAutoMove')}
                          name="allowAutoMove"
                        />
                      }
                      label="Allow the session to auto Close or move into Pending Approval state if all participants have been accounted for prior to selected Check-In time(s)"
                    />
                  </Box>
                  <Box sx={wrapperBoxSx}>
                    <Typography variant="subtitle1m">Reminders</Typography>
                    <Typography sx={secondaryTextSx}>
                      Reminders will be sent out 60, 30, 15 and 5 minutes before the chosen
                      Check-In time. Custom reminders are coming soon.
                    </Typography>
                  </Box>
                </Form>
              </Drawer.Content>
              <Drawer.Footer>
                <Button data-testid={makeTestId('close')} onClick={onDrawerCloseClick}>
                  Close
                </Button>
                <LoadingButton
                  data-testid={makeTestId('save')}
                  disabled={!dirty || isSaving || isLoading || hasErrors}
                  loading={isSaving}
                  variant="contained"
                  onClick={submitForm}
                >
                  Save
                </LoadingButton>
              </Drawer.Footer>
            </>
          );
        }}
      </SerenityForm>
    </>
  );
};

const iconButtonSx = {
  mr: 2,
};

const alertSx = {
  my: 2,
  display: 'flex',
  alignItems: 'center',
};

const wrapperBoxSx = {
  py: 2,
};

const sliderSx = {
  mt: 9,
};

const secondaryTextSx = {
  color: 'text.secondary',
};

export default CheckInPolicyCreateEditForm;
