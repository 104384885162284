import { FormikHelpers, FormikProps, FormikValues } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Drawer, AppStatusChip } from '@serenityapp/components-react-web';
import { App } from '@serenityapp/core';
import { AppStatus, JobStatus } from '@serenityapp/domain';
import {
  googleCalendarsListTransformer,
  googleCheckedCalendarIdsTransformer,
  appTransformer,
  useGoogleCalendars,
  useApps,
  useViewerBaseInformation,
  googleCalendarsSetSync,
  snackAdd,
} from '@serenityapp/redux-store';

import GoogleCalendarDrawerBodySkeleton from './GoogleCalendarDrawerBodySkeleton';
import CalendarsListField from './CalendarsListField';
import ConnectionStatus from './ConnectionStatus';
import FormikDrawer from '../components/FormikDrawer';

const GoogleCalendarDrawer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: viewerData, isBaseInformationLoading } = useViewerBaseInformation();
  const organizationId = viewerData.me.user?.orgId;

  const { data, isAppDisconnecting, isGoogleCalendarSyncing } = useApps();
  const app = appTransformer(data, App.Names.Calendar);

  const {
    data: googleCalendarData,
    isLoading: isLoadingCalendars,
    isUpdatingCalendarsForSync,
  } = useGoogleCalendars();
  const calendars = googleCalendarsListTransformer(googleCalendarData);
  const checkedCalendarIds = googleCheckedCalendarIdsTransformer(googleCalendarData);

  const isLoadingApp = isBaseInformationLoading || !organizationId || !app;

  const isAppDisabled = app?.status === AppStatus.DISABLED;
  const isAppPaused = app?.status === AppStatus.PAUSED;

  const isCurrentAppDisconnecting = app?.node.id ? isAppDisconnecting[app.node.id] : false;

  const isSyncPending = app?.jobInfo.status === JobStatus.PENDING;
  const isSyncing = isGoogleCalendarSyncing || isSyncPending;

  const onClose = useCallback(() => {
    navigate('..');
  }, [navigate]);

  useEffect(() => {
    if (!app && !isLoadingApp) {
      onClose();
    }
  }, [onClose, app, isLoadingApp]);

  const onCalendarsSubmitFailed = () => {
    dispatch(snackAdd({ message: 'Something went wrong. Please try later.', type: 'error' }));
  };

  const onCalendarsSubmitSuccess = () => {
    dispatch(snackAdd({ message: 'Successfully updated calendars for sync.', type: 'success' }));
  };

  const handleCalendarsSubmit = (values: FormikValues, helpers: FormikHelpers<FormikValues>) => {
    if (!organizationId) return;

    const checkedCalendarIds: string[] = values.checkedCalendarIds;

    const calendarsToUpdate = calendars.map((calendar) => ({
      calendarId: calendar.id,
      isSync: checkedCalendarIds.includes(calendar.id),
    }));

    dispatch(
      googleCalendarsSetSync({
        variables: { input: { calendarsToUpdate, organizationId } },
        onSuccess: () => {
          helpers.resetForm({ values });
          onCalendarsSubmitSuccess();
        },
        onFailed: onCalendarsSubmitFailed,
      }),
    );
  };

  const renderDrawerBody = ({ dirty, submitForm }: FormikProps<FormikValues>) => {
    if (isLoadingApp || isAppDisabled) return <GoogleCalendarDrawerBodySkeleton />;

    return (
      <>
        <Drawer.Header loading={isCurrentAppDisconnecting || isUpdatingCalendarsForSync}>
          <Typography noWrap variant="h6">
            {app.node.displayName}
            <AppStatusChip status={app.status} sx={appStatusChipSx} />
          </Typography>
        </Drawer.Header>
        <Drawer.Content>
          <Typography display="block" variant="overline">
            Connection status
          </Typography>
          <Divider sx={sectionDividerSx} />
          <ConnectionStatus
            app={app}
            checkedCalendarIds={checkedCalendarIds}
            isDisconnecting={isCurrentAppDisconnecting}
            isSyncing={isSyncing}
            organizationId={organizationId}
          />
          <Typography display="block" variant="overline">
            Calendars list
          </Typography>
          <Divider sx={sectionDividerSx} />
          <CalendarsListField
            calendars={calendars}
            checkedCalendarIds={checkedCalendarIds}
            fieldName="checkedCalendarIds"
            isPaused={isAppPaused}
            isSyncing={isSyncing}
            isUpdatingCalendarsForSync={isUpdatingCalendarsForSync}
          />
        </Drawer.Content>
        <Drawer.Footer>
          <LoadingButton
            disabled={
              !dirty ||
              isLoadingCalendars ||
              isSyncing ||
              isAppPaused ||
              isCurrentAppDisconnecting
            }
            loading={isUpdatingCalendarsForSync}
            variant="contained"
            onClick={submitForm}
          >
            Update
          </LoadingButton>
        </Drawer.Footer>
      </>
    );
  };

  return (
    <FormikDrawer
      shouldConfirmClose
      initialValues={{ checkedCalendarIds }}
      onClose={onClose}
      onSubmit={handleCalendarsSubmit}
    >
      {renderDrawerBody}
    </FormikDrawer>
  );
};

export const sectionDividerSx = {
  mb: 2,
};

const appStatusChipSx = {
  ml: 1,
};

export default GoogleCalendarDrawer;
