import { ChangeEvent, DragEvent, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';
import { File } from '@serenityapp/components-react-web';
import {
  INVALID_BOX_CREDENTIALS,
  MISSING_BOX_WRITE_ACCESS,
  UNKNOWN_BOX_ERROR,
} from '@serenityapp/domain';
import { App, FileObject, MimeLookupIsomorphic, getFileSizeFromBytes } from '@serenityapp/core';
import { appInstallWithClientCredentials, snackAdd } from '@serenityapp/redux-store';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from '../../../../common/hooks';
import AuthorizationErrorDialog from './AuthErrorDialog';

const MimeLookup = new MimeLookupIsomorphic();

type BoxCloudStorageDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const BoxCloudStorageDialog = ({ isOpen, onClose }: BoxCloudStorageDialogProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useCurrentUser();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<FileObject | undefined>(undefined);
  const [fileContent, setFileContent] = useState<string | null>(null);

  const [dragOver, setDragOver] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);
  const [errorDialog, setErrorDialog] = useState(false);

  const [isConnecting, setIsConnecting] = useState(false);

  const fileSize = file?.size ? getFileSizeFromBytes(file?.size) : undefined;

  const handleFileDismiss = () => {
    setError(false);
    setFile(undefined);
  };

  const dismissTool = {
    key: 'dismiss-tool',
    Icon: Close,
    onClick: handleFileDismiss,
  };

  const processFile = (file?: File) => {
    if (!file) return null;

    const url = URL.createObjectURL(file);
    const fileObject = {
      key: url,
      name: file.name,
      size: file.size,
      type: file.type ? file.type : MimeLookup.lookup(file.name),
      uri: url,
    };
    setFile(fileObject);

    if (file.type !== 'application/json') {
      setError(true);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target?.result) {
        setFileContent(event.target.result as string);
      }
    };

    reader.readAsText(file);
    URL.revokeObjectURL(url);
  };

  // drag and drops events handlers

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);

    const file = event.dataTransfer.files[0];
    processFile(file);
  };

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!dragOver) {
      setDragOver(true);
    }
  };

  // browse files in file system events

  const handleBrowseFileClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];
    processFile(file);
  };

  // handle connection to box

  const onAppInstallFailed = (errorCode: string) => {
    setIsConnecting(false);
    // TODO: Discuss with Ivan more descriptive copy for each of following error comming from the Box
    // and then change it in the error dialog
    if (
      errorCode === INVALID_BOX_CREDENTIALS ||
      errorCode === MISSING_BOX_WRITE_ACCESS ||
      errorCode === UNKNOWN_BOX_ERROR
    ) {
      setErrorDialog(true);
    } else {
      dispatch(snackAdd({ message: 'Box configuration failed.', type: 'error' }));
    }
  };

  const onAppInstallSuccess = () => {
    setIsConnecting(false);
    dispatch(snackAdd({ message: 'Box configured successfully.', type: 'success' }));
    onClose();
    navigate('box');
  };

  const handleConnectClick = () => {
    if (!fileContent) return null;

    setIsConnecting(true);

    dispatch(
      appInstallWithClientCredentials({
        onFailed: onAppInstallFailed,
        onSuccess: onAppInstallSuccess,
        variables: {
          input: {
            appName: App.Names.Box,
            credential: {
              kind: 'clientCredential',
              provider: App.Names.Box.toLowerCase(),
              configFile: fileContent,
            },
            organizationId: currentUser?.orgId,
          },
        },
      }),
    );
  };

  const handleErrorDialogClose = () => {
    setErrorDialog(false);
    onClose();
  };

  return (
    <>
      <Dialog fullWidth open={isOpen && !errorDialog}>
        <DialogTitle>Connect Box account</DialogTitle>
        <DialogContent sx={dialogContentSx}>
          <Typography variant="body1">Upload your JSON API key below</Typography>
          {file ? (
            <File
              key={file.name}
              actionTools={[dismissTool]}
              contentType={file.type}
              error={error}
              helperText={error ? 'Wrong file type' : undefined}
              primaryText={file.name}
              secondaryText={fileSize}
              thumbnail={file.uri}
            />
          ) : (
            <Box
              sx={[dropZoneSx, dragOver && activeBorderSx]}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              Drop file here or&nbsp;
              <Box
                ref={fileInputRef}
                component="input"
                sx={{ display: 'none' }}
                type="file"
                onChange={handleChange}
              />
              <Box component="span" sx={fileUploadButtonSx} onClick={handleBrowseFileClick}>
                browse files
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            disabled={!file || error}
            loading={isConnecting}
            variant="contained"
            onClick={handleConnectClick}
          >
            Connect
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {errorDialog && (
        <AuthorizationErrorDialog
          isOpen={errorDialog}
          onCancelClick={handleErrorDialogClose}
          onRetryClick={() => setErrorDialog(false)}
        />
      )}
    </>
  );
};

const dialogContentSx = {
  pt: 1,
  pb: 1,
};

const dropZoneSx = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 2.25,
  bgcolor: 'grey.200',
  borderRadius: 1,
  borderWidth: 2,
  borderColor: 'transparent',
  borderStyle: 'solid',
};

const fileUploadButtonSx = {
  color: 'primary.main',
  cursor: 'pointer',
};

const activeBorderSx = {
  borderWidth: 2,
  borderColor: 'primary.main',
  borderStyle: 'dashed',
};

export default BoxCloudStorageDialog;
