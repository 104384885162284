import { Email, Phone, ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { HorizontalSerenityColorLogo } from '@serenityapp/components-react-web';
import { useNavigate } from 'react-router-dom';

import {
  iconSx,
  buttonSx,
  cardSx,
  containerSx,
  cardHeaderSx,
  logoWrapperSx,
  cardActionsSx,
  cardContentSx,
  listItemSx,
} from './signUpPageStyles';

const SignUpPage = () => {
  const navigate = useNavigate();

  const navigateToSignIn = () => navigate('/sign-in');

  return (
    <Container sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader
          subheader="Serenity is available from leading senior care providers. Your facility will provide you with an invitation to join. Contact us to with any questions."
          sx={cardHeaderSx}
          title="Need an account?"
        />
        <CardContent sx={cardContentSx}>
          <ListItem dense component="a" href="mailto:support@serenityconnect.com" sx={listItemSx}>
            <ListItemIcon>
              <Email color="primary" sx={iconSx} />
            </ListItemIcon>
            <ListItemText
              primary="support@serenityconnect.com"
              primaryTypographyProps={{ variant: 'body1' }}
            />
          </ListItem>
          <ListItem dense component="a" href="tel:833.550.5280" sx={listItemSx}>
            <ListItemIcon>
              <Phone color="primary" sx={iconSx} />
            </ListItemIcon>
            <ListItemText primary="833.550.5280" primaryTypographyProps={{ variant: 'body1' }} />
          </ListItem>
        </CardContent>
        <CardActions sx={cardActionsSx}>
          <Button
            fullWidth
            color="neutral"
            size="large"
            startIcon={<ArrowBack />}
            sx={buttonSx}
            type="button"
            onClick={navigateToSignIn}
          >
            Back to sign in
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default SignUpPage;
