import { NetInfoApi, NetInfoStateType } from '@serenityapp/redux-store';

export const netInfoApi: NetInfoApi = {
  fetch: () =>
    Promise.resolve({
      isInternetReachable: null,
      type: NetInfoStateType.unknown,
      isConnected: null,
      details: null,
    }),
};
