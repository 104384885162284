export const avatarSx = {
  width: 96,
  height: 96,
  bgcolor: 'primary.main',
  fontSize: '3rem',
  my: 1.5,
};

export const headWrapperSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const sectionHeaderSx = {
  bgcolor: 'background.paper',
};

export const userSubTypeSx = {
  textTransform: 'capitalize',
  color: 'text.secondary',
};

export const activeIconSx = {
  color: 'action.active',
};

export const listItemSx = {
  flexDirection: { xs: 'column', sm: 'row' },
};

export const actionButtonSx = {
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 'auto',
  alignSelf: { xs: 'flex-start', sm: 'center' },
};

export const fieldsWrapperSx = {
  pt: 2,
};
