import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { Add, MoreVert, Upload } from '@mui/icons-material';
import { Box, Button, IconButton, TableCell } from '@mui/material';

import { StringFn } from '@serenityapp/core';
import { UserProps } from '@serenityapp/domain';
import {
  getUsersLoadingState,
  RootState,
  getFilteredSortedAdministerableContactUsers,
  getFilteredSortedAdministerableStaffUsers,
  getFilteredSortedAdministerableResidentUsers,
  useIsFeatureEnabled,
} from '@serenityapp/redux-store';
import {
  AdministrationHeader,
  AvatarTableCell,
  BasicTable,
  BasicTableHeadCellProps,
  BasicTableRowDataProps,
  useMakeTestId,
  SearchBar,
  View,
  UserStatusTableCell,
} from '@serenityapp/components-react-web';

import { useAdminBreadcrumbs } from '../hooks';
import { UserActionsMenu } from '../components';
import { ImportUsersDialog } from '../components';

const NOT_AVAILABLE_STATUS = 'NOT_AVAILABLE';

const UsersPage = () => {
  const title = 'User Management';
  const makeTestId = useMakeTestId('UsersPage');
  const mainTestId = makeTestId('');

  const navigate = useNavigate();

  const [importUsersDialog, setImportUsersDialog] = useState(false);
  const [actionsMenuAnchorEl, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState<null | BasicTableRowDataProps>(
    null,
  );
  const shouldDisplayActionsMenu = !!currentlySelectedRow;

  const [searchKey, setSearchKey] = useState('');

  const isRemoveUsernamesFeatureEnabled = useIsFeatureEnabled('removeUsernames');
  const isUsersImportFeatureEnabled = useIsFeatureEnabled('usersImport');

  const isLoading: boolean = useSelector(getUsersLoadingState);

  const contactUsers: Array<UserProps> = useSelector((state: RootState) =>
    getFilteredSortedAdministerableContactUsers(state, searchKey),
  );
  const staffUsers: Array<UserProps> = useSelector((state: RootState) =>
    getFilteredSortedAdministerableStaffUsers(state, searchKey),
  );
  const residentUsers: Array<UserProps> = useSelector((state: RootState) =>
    getFilteredSortedAdministerableResidentUsers(state, searchKey),
  );

  const users = useMemo(
    () => [...contactUsers, ...staffUsers, ...residentUsers],
    [contactUsers, residentUsers, staffUsers],
  );

  const shouldShowSkeleton = isLoading && users.length === 0;

  const avatarCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    const initials =
      isRemoveUsernamesFeatureEnabled && row?.initials
        ? row?.initials
        : StringFn.initials(row?.fullName || row?.name);

    const name = isRemoveUsernamesFeatureEnabled
      ? row?.fullName || row?.name
      : row?.name || row?.fullName || '';

    return (
      <AvatarTableCell
        key={`avatar-cell-${row?.id}`}
        dataTestId={dataTestId}
        initials={initials}
        name={name}
        showSkeleton={shouldShowSkeleton}
      />
    );
  };

  const handleActionsMenuClick =
    (row?: BasicTableRowDataProps) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setActionsMenuAnchor(event.currentTarget);
      setCurrentlySelectedRow(row);
    };

  const actionsCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    return (
      <TableCell
        key={`action-cell-${row?.id}`}
        align="right"
        data-testid={dataTestId}
        padding="none"
      >
        <IconButton
          aria-label="Open menu"
          data-testid="UsersActionsMenu"
          onClick={handleActionsMenuClick(row)}
        >
          <MoreVert />
        </IconButton>
      </TableCell>
    );
  };

  const statusCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    return (
      <UserStatusTableCell
        key={`status-cell-${row?.id}`}
        dataTestId={dataTestId}
        status={row?.status ?? NOT_AVAILABLE_STATUS}
      />
    );
  };

  const headCells: BasicTableHeadCellProps[] = [
    {
      id: isRemoveUsernamesFeatureEnabled ? 'fullName' : 'name',
      label: 'Name',
      cellRenderer: avatarCellRenderer,
    },
    { id: 'subtype', label: 'Type' },
    { id: 'status', label: 'Account status', cellRenderer: statusCellRenderer },
    { id: 'email', label: 'Email', sort: false, hide: 'smDown' },
    {
      id: 'actions',
      label: 'Actions',
      sort: false,
      cellRenderer: actionsCellRenderer,
      align: 'right',
    },
  ];

  const goToResourceDetails = (id: string) => navigate(id);
  const goToResourceCreate = () => navigate('create');

  const handleActionsMenuClose = () => setActionsMenuAnchor(null);

  const crumbs = useAdminBreadcrumbs(title);

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add')}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;user
      </Box>
    </Button>
  );

  const handleImportUsersButton = () => setImportUsersDialog(true);
  const importUsersButton = (
    <Button
      key="import-users-button"
      data-testid={makeTestId('import-users')}
      startIcon={<Upload />}
      variant="outlined"
      onClick={handleImportUsersButton}
    >
      Import
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;users
      </Box>
    </Button>
  );

  const adminHeaderActions: React.ReactNode[] = isUsersImportFeatureEnabled
    ? [importUsersButton, addButton]
    : [addButton];

  return (
    <>
      {shouldDisplayActionsMenu && (
        <UserActionsMenu
          anchorEl={actionsMenuAnchorEl}
          email={currentlySelectedRow?.email}
          id={currentlySelectedRow?.id}
          name={currentlySelectedRow?.name || currentlySelectedRow?.fullName || ''}
          status={currentlySelectedRow?.status || NOT_AVAILABLE_STATUS}
          subtype={currentlySelectedRow?.subtype}
          onClose={handleActionsMenuClose}
        />
      )}
      <View dataTestId={mainTestId}>
        <View.Header>
          <AdministrationHeader actions={adminHeaderActions} crumbs={crumbs} title={title} />
        </View.Header>
        <View.Content>
          <ImportUsersDialog
            isOpen={importUsersDialog}
            onClose={() => setImportUsersDialog(false)}
          />
          <Box>
            <SearchBar
              dataTestId={makeTestId('Filter')}
              sx={searchBarSx}
              value={searchKey}
              onSearchKeyChange={setSearchKey}
            />
          </Box>
          <BasicTable
            aria-label="Users table"
            dataTestId={mainTestId}
            handleRowClick={goToResourceDetails}
            headCells={headCells}
            orderByColumnId="subtype"
            rows={users}
            showSkeleton={shouldShowSkeleton}
          />
          <Outlet />
        </View.Content>
      </View>
    </>
  );
};

const searchBarSx = {
  px: 2,
  pb: 1,
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default UsersPage;
