import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as Sentry from '@sentry/react';

import { useMakeTestId } from '@serenityapp/components-react-web';
import {
  conversationsDMCreate,
  usersForDMListTransformer,
  useUsersForDM,
  UserForDM,
  getConversationsIsCreatingDM,
} from '@serenityapp/redux-store';

import UsersForDMListItemSkeleton from './UsersForDMListItemSkeleton';
import UsersForDMEmptyView from './EmptyListView';
import UsersForDMListItem from './UsersForDMListItem';
import UsersForDMSearch from './UsersForDMSearch';
import EmptySearchView from './EmptySearchView';
import UsersForDMList from './UsersForDMList';
import { LoadingButton } from '@mui/lab';

const CreateDMDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const makeTestId = useMakeTestId('CreateDMDialog');
  const createDMDialogTestId = makeTestId('');

  const [searchKey, setSearchKey] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<UserForDM[]>([]);

  const isCreatingDM = useSelector(getConversationsIsCreatingDM);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = useUsersForDM();
  const usersForDM = usersForDMListTransformer(data, searchKey);

  const hasItemsToDisplay = usersForDM.length > 0;
  const hasSelectedItems = selectedUsers.length > 0;
  const isSearching = searchKey.length > 0;

  const shouldDisplayEmptySearchView = !hasItemsToDisplay && isSearching && !isFetching;
  const shouldDisplayEmptyListView =
    !hasItemsToDisplay && !isLoading && !isFetching && !isSearching;
  const shouldDisplayInitialLoader = isLoading;
  const shouldDisplayAlert = selectedUsers.length > 5;

  const shouldDisableCreateDMButton = !hasSelectedItems;

  // There is a problem with fetching first few items by virtuoso's endReached event. It is not
  // getting called reliably. Sometimes it need more time to figure out that it needs to fire
  // the event. However, after it has enough items to create scroll area the endReached event
  // gets called correctly.
  //
  // For that reason we have this useEffect which is called continuously fetching new items until
  // the scroll area is created. After that the fetching is fully controlled by the endReached
  // Virtuoso's event.
  useEffect(() => {
    if (usersForDM.length < 10 && hasNextPage && !isLoading && !isFetching) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetching, isLoading, usersForDM.length]);

  const handleClose = () => navigate('..');

  const handleCreateDMClick = () => {
    const selectedUsersIds = selectedUsers.map((user) => user.id);
    dispatch(
      conversationsDMCreate({
        userIds: selectedUsersIds,
        navigateToMain: handleClose,
        navigateToMessages: (id: string) => navigate(`/client/${id}`),
      }),
    );
  };

  const handleChange = (selectedId: string) => {
    const selectedUser =
      usersForDM?.find?.((user) => user.id === selectedId) ||
      selectedUsers?.find?.((user) => user.id === selectedId);

    if (selectedUser) {
      const alreadySelectedUser = selectedUsers.find((user) => user.id === selectedId);
      if (!alreadySelectedUser) {
        if (selectedUsers.length === 7) return;
        setSelectedUsers([...selectedUsers, selectedUser]);
      } else {
        setSelectedUsers(selectedUsers.filter((user) => user.id !== selectedId));
      }
      setSearchKey('');
    }
  };

  const handleSearchClear = () => {
    setSelectedUsers([]);
    setSearchKey('');
  };

  const handleEndReached = () => {
    // Prevent event from calling fetch function
    //  - when there is no more data to fetch
    //  - when there is already request in flight
    //  - when there is less than 10 items in a list.
    //    - In this case fetching is handled within the useEffect (see explanation above)
    if (!hasNextPage || isFetching || usersForDM.length < 10) return;

    fetchNextPage();
  };

  const renderItemContent = (user: UserForDM) => {
    const isSelected = selectedUsers.some((selectedUser) => selectedUser.id === user.id);
    const handleClick = () => handleChange(user.id);

    return (
      <UsersForDMListItem
        key={user.id}
        dataTestId={makeTestId('UserForDM')}
        disabled={isCreatingDM}
        isSelected={isSelected}
        onClick={handleClick}
        {...user}
      />
    );
  };

  const InitialSkeleton = () => <UsersForDMListItemSkeleton dataTestId="initialSkeleton" />;
  const FetchingSkeleton = () => <UsersForDMListItemSkeleton dataTestId="fetchingSkeleton" />;

  const components = {
    List: UsersForDMList,
    Footer: hasNextPage ? FetchingSkeleton : undefined,
    EmptyPlaceholder: shouldDisplayInitialLoader
      ? InitialSkeleton
      : shouldDisplayEmptyListView
        ? UsersForDMEmptyView
        : shouldDisplayEmptySearchView
          ? EmptySearchView
          : undefined,
  };

  return (
    <Dialog
      fullWidth
      open
      data-testid={createDMDialogTestId}
      fullScreen={fullScreen}
      onClose={handleClose}
    >
      <DialogTitle>
        Direct message
        <IconButton
          aria-label="close"
          data-testid={makeTestId('close')}
          sx={closeButtonSx}
          onClick={handleClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <Box sx={searchWrapperSx}>
        <UsersForDMSearch
          autoFocus
          disabled={isCreatingDM}
          selectedUsers={selectedUsers}
          value={searchKey}
          onChipDelete={handleChange}
          onClear={handleSearchClear}
          onSearchKeyChange={setSearchKey}
        />
        {shouldDisplayAlert && (
          <Alert severity="warning" sx={alertSx}>
            You have {selectedUsers.length}/7 members selected, only 7 people can be in a group
            message.
          </Alert>
        )}
      </Box>
      <DialogContent sx={dialogContentSx}>
        <Virtuoso
          components={components}
          data={usersForDM}
          endReached={handleEndReached}
          fixedItemHeight={60}
          itemContent={(_index, user) => renderItemContent(user)}
        />
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} onClick={handleClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('create')}
          disabled={shouldDisableCreateDMButton}
          loading={isCreatingDM}
          variant="contained"
          onClick={handleCreateDMClick}
        >
          Go
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const alertSx = { mb: 1 };

const closeButtonSx = {
  position: 'absolute',
  right: 8,
  top: 8,
  color: 'grey.500',
};

const dialogContentSx = {
  px: 0,
  pt: 0,
  height: '40vh',
};

const searchWrapperSx = {
  pt: 1,
  px: 3,
  pb: 0,
};

CreateDMDialog.displayName = 'CreateDMDialog';

export default Sentry.withProfiler(CreateDMDialog);
