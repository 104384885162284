import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { containerSx, buttonSx } from './notFoundPageStyles';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const goBack = () => navigate('/');

  return (
    <Container sx={containerSx}>
      <Typography variant="h3">Page not found.</Typography>
      <Button sx={buttonSx} variant="contained" onClick={goBack}>
        Return to Serenity
      </Button>
    </Container>
  );
};

export default NotFoundPage;
