import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useMakeTestId } from '@serenityapp/components-react-web';
import {
  getIsEnableAccountLoading,
  RootState,
  snackAdd,
  userAccountEnable,
} from '@serenityapp/redux-store';

type ReactivateAccountDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  userName: string;
  userId: string;
  onActionSuccess?: () => void;
};

const ReactivateAccountDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  userName,
  userId,
  onActionSuccess,
}: ReactivateAccountDialogProps) => {
  const makeTestId = useMakeTestId('ReactivateAccountDialog', dataTestId);
  const reactivateUserDialog = makeTestId('');
  const dispatch = useDispatch();

  const isReactivateAccountLoading = useSelector((state: RootState) =>
    getIsEnableAccountLoading(state),
  );

  const handleSubmit = async () => {
    const variables = {
      input: {
        userId,
      },
    };

    dispatch(
      userAccountEnable({
        variables,
        onFailed: () => {
          dispatch(snackAdd({ message: 'Error activating user account', type: 'error' }));
          onClose();
        },
        onSuccess: () => {
          dispatch(snackAdd({ message: 'User account successfully activated', type: 'success' }));
          onClose();
          onActionSuccess?.();
        },
      }),
    );
  };

  return (
    <Dialog
      fullWidth
      data-testid={reactivateUserDialog}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Re-activate account</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to re-activate {userName}?</Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>Users ability to login will be resumed.</ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid={makeTestId('cancel')}
          disabled={isReactivateAccountLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm')}
          loading={isReactivateAccountLoading}
          variant="contained"
          onClick={handleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default ReactivateAccountDialog;
