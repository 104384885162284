import { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from '@mui/material';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createTextNode,
  $getRoot,
  $insertNodes,
  $isTextNode,
  BLUR_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_TAB_COMMAND,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';

import { UserKind, UserProps } from '@serenityapp/domain';
import { Avatar } from '@serenityapp/components-react-web';
import {
  useViewerOrganizationUsers,
  viewerOrganizationUsersTransformer,
} from '@serenityapp/redux-store';

import { useCurrentUser } from '../../../../common/hooks';
import { INSERT_MENTION_COMMAND } from '../customLexicalCommands';
import { $createMentionNode, $isMentionNode } from '../nodes/MentionNode';
import useCheckInPolicy from './useCheckInPolicy';

const SLASH = '/';
const AT_SYMBOL = '@';

type UserPropsWithIndex = UserProps & {
  userIndex: number;
};

const MentionsPlugin = (): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();

  const dispatch = useDispatch();

  // To handle scroll to "focused" item in the list
  const focusedUser = useRef<HTMLDivElement>(null);

  const [focusedUserIndex, setFocusedUserIndex] = useState<number>(0);
  const [typedFullName, setTypedFullName] = useState<string>('');
  const [isAtSymbolTriggered, setIsAtSymbolTriggered] = useState(false);

  const currentUser = useCurrentUser();

  const { serviceLevels } = useCheckInPolicy();

  const { data } = useViewerOrganizationUsers({
    variables: {
      organizationInput: {
        orgId: currentUser?.orgId,
      },
      usersInput: { kinds: [UserKind.RESIDENT] },
    },
  });

  const organizationUsers = useMemo(
    () => viewerOrganizationUsersTransformer(data, serviceLevels, typedFullName),
    [data, serviceLevels, typedFullName],
  );

  const areAvailableOrganizationUsers = organizationUsers.length > 0;

  // we need indexes to keep track of currently focused item in the list
  const organizationUsersWithIndex = useMemo(() => {
    return organizationUsers?.map((user, index) => ({ ...user, userIndex: index })) || [];
  }, [organizationUsers]);

  useEffect(() => {
    setFocusedUserIndex(0);
  }, [organizationUsers?.length]);

  useEffect(() => {
    if (focusedUser.current) {
      focusedUser.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [focusedUserIndex]);

  useEffect(() => {
    const updateListener = () => {
      editor.getEditorState().read(() => {
        const root = $getRoot();
        const rootTextContent = root.getTextContent();

        if (rootTextContent.startsWith(SLASH) && rootTextContent.includes(AT_SYMBOL)) {
          setIsAtSymbolTriggered(true);
          const atSymbolIndex = rootTextContent.indexOf(AT_SYMBOL);
          atSymbolIndex && setTypedFullName(rootTextContent.slice(atSymbolIndex + 1));
        } else {
          setIsAtSymbolTriggered(false);
        }
      });
    };
    const removeUpdateListener = editor.registerUpdateListener(updateListener);

    return () => {
      removeUpdateListener();
    };
  }, [editor]);

  useEffect(() => {
    const messageEntry = document.getElementById('messageEntry');

    return mergeRegister(
      editor.registerCommand(
        INSERT_MENTION_COMMAND,
        (payload) => {
          const fullName = payload.userFullName;
          const user = payload.user;

          const root = $getRoot();
          const rootTextContent = root.getTextContent();

          const allTextNodes = $getRoot().getAllTextNodes();
          const oldMentionNode = allTextNodes.find((node) => $isMentionNode(node));
          const oldTextNode = allTextNodes.find((node) => $isTextNode(node));

          const atSymbolIndex = rootTextContent.indexOf(AT_SYMBOL);
          const command = rootTextContent.substring(0, atSymbolIndex);

          const commandTextNode = $createTextNode(command);
          oldTextNode?.replace(commandTextNode);

          oldMentionNode?.remove();

          if (rootTextContent.includes(fullName)) {
            return false;
          }

          root.selectEnd();

          const mentionNode = $createMentionNode(`@${fullName} `, user);
          setIsAtSymbolTriggered(false);

          $insertNodes([mentionNode]);

          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null && !event.shiftKey) {
            event.preventDefault();
            if (isAtSymbolTriggered && areAvailableOrganizationUsers) {
              const focusedUser = organizationUsersWithIndex[focusedUserIndex];
              if (focusedUser) {
                editor.dispatchCommand(INSERT_MENTION_COMMAND, {
                  userFullName: focusedUser.fullName || '',
                  user: focusedUser,
                });
              }
              return true;
            }
          }
          return false;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        KEY_ARROW_UP_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null) {
            event.preventDefault();
            setFocusedUserIndex((focusedUserIndex) => {
              const newId = focusedUserIndex - 1;
              return newId < 0 ? organizationUsersWithIndex.length - 1 : newId;
            });
          }
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_ARROW_DOWN_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null) {
            setFocusedUserIndex((focusedUserIndex) => {
              const newId = focusedUserIndex + 1;
              return newId > organizationUsersWithIndex.length - 1 ? 0 : newId;
            });
          }
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        KEY_TAB_COMMAND,
        (payload) => {
          const event: KeyboardEvent | null = payload;
          if (event !== null) {
            if (isAtSymbolTriggered && areAvailableOrganizationUsers) {
              event.preventDefault();
              const focusedUser = organizationUsersWithIndex[focusedUserIndex];
              if (focusedUser) {
                editor.dispatchCommand(INSERT_MENTION_COMMAND, {
                  userFullName: focusedUser?.fullName || '',
                  user: focusedUser,
                });
              }
            }
          }
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        (payload) => {
          const currentTarget = payload.relatedTarget as Node;
          if (isAtSymbolTriggered) {
            if (currentTarget && !messageEntry?.contains(currentTarget)) {
              setIsAtSymbolTriggered(false);
            }
          }

          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [
    editor,
    dispatch,
    isAtSymbolTriggered,
    focusedUserIndex,
    areAvailableOrganizationUsers,
    organizationUsersWithIndex,
  ]);

  const handleClick = useCallback(
    (fullName: string, user: UserProps) => {
      editor.dispatchCommand(INSERT_MENTION_COMMAND, { userFullName: fullName, user });
    },
    [editor],
  );

  return (
    <>
      {isAtSymbolTriggered && areAvailableOrganizationUsers ? (
        <Paper component="div" elevation={8} sx={paperSx}>
          <List dense>
            {organizationUsersWithIndex.map((user: UserPropsWithIndex) => {
              return (
                <ListItem
                  key={user.id}
                  disableGutters
                  component="div"
                  sx={listItemSx}
                  onClick={() => handleClick(user.fullName || '', user)}
                >
                  <ListItemButton
                    ref={focusedUserIndex === user.userIndex ? focusedUser : undefined}
                    selected={focusedUserIndex === user.userIndex}
                  >
                    <ListItemAvatar sx={listItemIconSx}>
                      <Avatar initials={user.initials} size="small" src={user.avatarUri} />
                    </ListItemAvatar>
                    <ListItemText primary={user.fullName} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Paper>
      ) : null}
    </>
  );
};

const listItemSx = {
  py: 0,
};

const listItemIconSx = {
  minWidth: 20,
  mr: 1,
};

const paperSx = {
  maxHeight: 230,
  minWidth: 220,
  overflowY: 'auto',
  position: 'absolute',
  bottom: 98,
  left: 0,
  zIndex: 1000,
};

export default MentionsPlugin;
