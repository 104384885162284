import { MouseEvent } from 'react';
import { WithKey } from '@serenityapp/domain';
import { BasicMenu, BasicMenuItemProps, useMakeTestId } from '@serenityapp/components-react-web';

type ActionsMenuProps = {
  filterId: string;
  anchorEl: HTMLElement | null;
  onClose?: () => void;
  onArchiveClick: () => void;
};

const ActionsMenu = ({ anchorEl, onClose, filterId, onArchiveClick }: ActionsMenuProps) => {
  const makeTestId = useMakeTestId('ActionsMenu');

  const filterActionsMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [];
  filterActionsMenuSections.push([
    {
      key: 'archive-filter',
      dataTestId: makeTestId('archive-filter'),
      onClick: onArchiveClick,
      label: 'Archive',
    },
  ]);

  const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClose?.();
  };

  return (
    <BasicMenu
      key="basic-menu"
      keepMounted
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      dataTestId={makeTestId('')}
      open={Boolean(anchorEl && filterId)}
      sections={filterActionsMenuSections}
      onClose={handleCloseMenu}
    />
  );
};

export default ActionsMenu;
