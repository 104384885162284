import { MouseEvent } from 'react';
import { WithKey } from '@serenityapp/domain';
import { BasicMenu, BasicMenuItemProps, useMakeTestId } from '@serenityapp/components-react-web';

type ActionsMenuProps = {
  channelId: string;
  anchorEl: HTMLElement | null;
  onClose?: () => void;
  onArchiveClick: () => void;
};

const ActionsMenu = ({ anchorEl, onClose, channelId, onArchiveClick }: ActionsMenuProps) => {
  const makeTestId = useMakeTestId('ActionsMenu');

  const channelActionsMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [];
  channelActionsMenuSections.push([
    {
      key: 'archive-channel',
      dataTestId: makeTestId('archive-channel'),
      onClick: onArchiveClick,
      label: 'Archive',
    },
  ]);

  const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClose?.();
  };

  return (
    <BasicMenu
      key="basic-menu"
      keepMounted
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      dataTestId={makeTestId('')}
      open={Boolean(anchorEl && channelId)}
      sections={channelActionsMenuSections}
      onClose={handleCloseMenu}
    />
  );
};

export default ActionsMenu;
