import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Drawer } from '@serenityapp/components-react-web';
import { CHECK_IN_APP_NAME } from '@serenityapp/core';
import { appTransformer, useApps, useViewerBaseInformation } from '@serenityapp/redux-store';
import ConfirmCloseDialog from '../components/ConfirmCloseDialog';
import {
  CheckInAppDetailDrawer,
  CheckInPolicyCreateDrawerContent,
  CheckInPolicyEditDrawerContent,
  CheckInEditInfoDialog,
} from './components';

type CheckInAppDrawerContentType =
  | 'checkInAppDetail'
  | 'checkInPolicyEditForm'
  | 'checkInPolicyCreateForm';

const CheckInAppDrawer = () => {
  const navigate = useNavigate();

  const { isBaseInformationLoading } = useViewerBaseInformation();

  const { data: appsData } = useApps();
  const app = appTransformer(appsData, CHECK_IN_APP_NAME);

  const isLoadingApp = isBaseInformationLoading || !app;

  const [currentlyOpenDrawerContent, setCurrentlyOpenDrawerContent] =
    useState<CheckInAppDrawerContentType>('checkInAppDetail');
  const [editingPolicyId, setEditingPolicyId] = useState<string>('');

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);
  const [isEditInfoDialogOpen, setIsEditInfoDialogOpen] = useState<boolean>(false);

  const handleEditInfoDialogClose = () => setIsEditInfoDialogOpen(false);

  const handleEditInfoDialogOpen = () => setIsEditInfoDialogOpen(true);

  const handleCancelConfirmDialogClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleCheckInPolicyFormStateChange = useCallback((isDirty: boolean) => {
    setIsFormDirty(isDirty);
  }, []);

  const handleConfirmDialogOpen = useCallback((isOpen: boolean) => {
    setIsConfirmDialogOpen(isOpen);
  }, []);

  const handleAddClick = () => {
    setCurrentlyOpenDrawerContent('checkInPolicyCreateForm');
  };

  const handleEditClick = (policyId: string) => {
    setCurrentlyOpenDrawerContent('checkInPolicyEditForm');
    setEditingPolicyId(policyId);
  };

  const handleReturnTurnToCheckInAppDetail = () => {
    setCurrentlyOpenDrawerContent('checkInAppDetail');
  };

  const handleClose = useCallback(() => {
    if (isFormDirty) {
      setIsConfirmDialogOpen(true);
    }
    if (!isFormDirty) {
      navigate('..');
    }
  }, [navigate, isFormDirty]);

  const handleDiscardClick = useCallback(() => {
    setIsConfirmDialogOpen(false);
    setIsFormDirty(false);
    if (currentlyOpenDrawerContent === 'checkInAppDetail') {
      navigate('..');
    }
    handleReturnTurnToCheckInAppDetail();
  }, [setIsConfirmDialogOpen, navigate, currentlyOpenDrawerContent]);

  return (
    <Drawer open onClose={handleClose}>
      {!isLoadingApp && (
        <>
          <CheckInEditInfoDialog
            open={isEditInfoDialogOpen}
            onClose={handleEditInfoDialogClose}
          />
          <ConfirmCloseDialog
            open={isConfirmDialogOpen}
            onCancelClick={handleCancelConfirmDialogClick}
            onDiscardClick={handleDiscardClick}
          />
          {currentlyOpenDrawerContent === 'checkInAppDetail' && (
            <CheckInAppDetailDrawer
              onAddClick={handleAddClick}
              onEditActionClick={(id) => handleEditClick(id)}
            />
          )}

          {currentlyOpenDrawerContent === 'checkInPolicyCreateForm' && (
            <CheckInPolicyCreateDrawerContent
              returnToCheckInAppDetail={handleReturnTurnToCheckInAppDetail}
              onCheckInPolicyFormStateChanged={handleCheckInPolicyFormStateChange}
              onConfirmDialogOpen={handleConfirmDialogOpen}
            />
          )}

          {currentlyOpenDrawerContent === 'checkInPolicyEditForm' && (
            <CheckInPolicyEditDrawerContent
              openInfoDialog={handleEditInfoDialogOpen}
              policyId={editingPolicyId}
              returnToCheckInAppDetail={handleReturnTurnToCheckInAppDetail}
              onCheckInPolicyFormStateChanged={handleCheckInPolicyFormStateChange}
              onConfirmDialogOpen={handleConfirmDialogOpen}
            />
          )}
        </>
      )}
    </Drawer>
  );
};

export default CheckInAppDrawer;
