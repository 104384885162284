import { AssertFn, DateFn, MimeFn } from '@serenityapp/core';
import {
  AbstractStorageProvider,
  IStoragePreviewGenerator,
  IStoragePreviewGeneratorContext,
  IStorageProvider,
  StoragePrepareDescriptorInput,
  StoragePrepareDescriptorOutput,
} from '@serenityapp/core-storage';
import { getFileAsBase64 } from '../../utils/file';
import { getResizedImageAsBase64 } from '../../utils/image';

export class PreviewStorageProviderWeb
  extends AbstractStorageProvider
  implements IStorageProvider
{
  protected asContext(result: StoragePrepareDescriptorOutput): IStoragePreviewGeneratorContext {
    return {
      contentName: result.descriptor.contentName,
      contentType: result.descriptor.contentType,
      filesystem: this.filesystem,
      imageInfoLookup: this.imageInfoLookup,
      key: result.descriptor.key,
      level: result.descriptor.level,
      mimelookup: this.mimelookup,
      path: result.originalFile.uri,
      tmpDir: '',
    };
  }

  async prepareDescriptor(
    input: StoragePrepareDescriptorInput,
  ): Promise<StoragePrepareDescriptorOutput> {
    this.config.analytics.track({
      name: 'storage-provider-preview/prepare-descriptor-start',
      attributes: {
        previewDisabled: input.disable?.preview ?? false,
      },
    });

    if (input.disable?.preview) {
      return super.prepareDescriptor(input);
    }

    const start = DateFn.now();

    const superResult = await super.prepareDescriptor(input);
    const descriptor = superResult.descriptor;
    AssertFn.isDefined(descriptor, 'storage-preview', 'Resulting descriptor is undefined');

    const preview = await this.previewGenerator.generatePreview(this.asContext(superResult));

    const elapsed = DateFn.now() - start;
    this.config.analytics.track({
      name: 'storage-provider-preview/prepare-descriptor-end',
      attributes: {
        previewElapsed: elapsed,
      },
    });

    return {
      ...superResult,
      descriptor: {
        ...descriptor,
        preview,
      },
      elapsed: {
        ...superResult.elapsed,
        preview: elapsed,
      },
    };
  }
}

export async function wrapPreview(inner: IStorageProvider): Promise<PreviewStorageProviderWeb> {
  const provider = new PreviewStorageProviderWeb(inner);
  await provider.init();
  return provider;
}

// Used in the upload process to generate a small base64 preview of the original image
export const previewGenerator: IStoragePreviewGenerator = {
  async generatePreview(context) {
    const PREVIEW_SIZE = 50;
    const PREVIEW_IMAGE_QUALITY = 0.7;
    const PREVIEW_FILE_TYPES = ['heic', 'png', 'webp', 'jpeg', 'gif', 'bmp', 'avif']; // Generating preview for other file types is skipped

    if (!context.path) {
      return undefined;
    }

    const isSupportedContentType = MimeFn.isImage(context.contentType, ...PREVIEW_FILE_TYPES);
    if (isSupportedContentType === false) {
      return undefined;
    }

    const { height = Infinity, width = Infinity } = context;
    const isAlreadyReduced = height <= PREVIEW_SIZE && width <= PREVIEW_SIZE;
    if (isAlreadyReduced) {
      return getFileAsBase64(context.path);
    }

    const resizedImage = await getResizedImageAsBase64(
      { maxSize: PREVIEW_SIZE, quality: PREVIEW_IMAGE_QUALITY },
      context.path,
    );

    return resizedImage.base64;
  },
};
