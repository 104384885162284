import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useMakeTestId } from '@serenityapp/components-react-web';
import {
  getIsDisableAccountLoading,
  RootState,
  snackAdd,
  userAccountDisable,
} from '@serenityapp/redux-store';

type DisableUserDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
  userId: string;
  userName: string;
  onActionSuccess?: () => void;
};

const DisableUserDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
  userId,
  userName,
  onActionSuccess,
}: DisableUserDialogProps) => {
  const makeTestId = useMakeTestId('DisableUserDialog', dataTestId);
  const disableUserDialog = makeTestId('');
  const dispatch = useDispatch();

  const isDisableAccountLoading = useSelector((state: RootState) =>
    getIsDisableAccountLoading(state),
  );

  const handleSubmit = async () => {
    const variables = {
      input: {
        userId,
      },
    };

    dispatch(
      userAccountDisable({
        variables,
        onFailed: () => {
          dispatch(snackAdd({ message: 'Error disabling user account', type: 'error' }));
          onClose();
        },
        onSuccess: () => {
          dispatch(snackAdd({ message: 'User account successfully disabled', type: 'success' }));
          onClose();
          onActionSuccess?.();
        },
      }),
    );
  };

  return (
    <Dialog
      fullWidth
      data-testid={disableUserDialog}
      open={isOpen}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Disable user</DialogTitle>
      <DialogContent>
        <Typography>Are you sure you want to disable {userName}?</Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>Users ability to login will be paused.</ListItem>
          <ListItem sx={listItemSx}>All user data is preserved.</ListItem>
          <ListItem sx={listItemSx}>You can re-activate user to restore login access.</ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('confirm')}
          loading={isDisableAccountLoading}
          variant="contained"
          onClick={handleSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default DisableUserDialog;
