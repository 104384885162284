import { useRef, useState } from 'react';
import { CloudUpload } from '@mui/icons-material';
import { Box, Fade, Slide, Typography } from '@mui/material';

type BackdropContentProps = {
  isOpen: boolean;
  conversationName?: string;
};

const BackdropContent = ({ isOpen, conversationName }: BackdropContentProps) => {
  const [isTextVisible, setIsTextVisible] = useState(false);
  const backdropContentRef = useRef<HTMLDivElement>();
  const backdropTextRef = useRef<HTMLDivElement>();

  return (
    <Box ref={backdropContentRef} sx={backdropContentSx}>
      <Fade
        easing={{
          enter: 'ease-in',
        }}
        in={isTextVisible}
        style={{ transitionDelay: '15ms' }}
      >
        <Box>
          <Slide container={backdropTextRef.current} direction="up" in={isTextVisible}>
            <CloudUpload sx={iconSx} />
          </Slide>
        </Box>
      </Fade>
      <Slide
        container={backdropContentRef.current}
        direction="up"
        in={isOpen}
        onEntered={() => setIsTextVisible(true)}
        onExited={() => setIsTextVisible(false)}
      >
        <Box ref={backdropTextRef} sx={backdropTextContainerSx}>
          <Box sx={conversationNameWrapperSx}>
            <Typography sx={truncateSx} variant="h5">
              Upload to #{conversationName || 'Serenity'}
            </Typography>
          </Box>
          <Typography paragraph align="center" variant="body1">
            You can add comments before sending
          </Typography>
        </Box>
      </Slide>
    </Box>
  );
};

const backdropContentSx = {
  color: 'text.light',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const iconSx = {
  width: 60,
  height: 60,
};
const backdropTextContainerSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const truncateSx = {
  textAlign: 'center',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

const conversationNameWrapperSx = {
  width: 250,
};

export default BackdropContent;
