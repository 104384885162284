import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  BasicTable,
  BasicTableHeadCellProps,
  View,
  AdministrationHeader,
  SearchBar,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import { OrganizationProps } from '@serenityapp/domain';
// TODO (viktor): Create dialog
// import OrganizationCreateDialog from './OrganizationCreateDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFilteredSortedOrganizations,
  getOrganizationsLoadingState,
  organizationsList,
  RootState,
} from '@serenityapp/redux-store';
import { useDidMount } from '@serenityapp/components-react-common';

const OrganizationsPage = () => {
  const makeTestId = useMakeTestId('OrganizationsPage');
  const mainTestId = makeTestId('');
  const navigate = useNavigate();

  const isLoading: boolean = useSelector(getOrganizationsLoadingState);

  const dispatch = useDispatch();
  useDidMount(() => {
    dispatch(organizationsList());
  });

  const [searchKey, setSearchKey] = useState('');
  const organizations: Array<OrganizationProps> = useSelector((state: RootState) =>
    getFilteredSortedOrganizations(state, searchKey),
  );

  const goToOrganizationDetail = (id: string) => navigate(id);

  const headCells: BasicTableHeadCellProps[] = [
    { id: 'id', label: 'id' },
    { id: 'name', label: 'Name' },
  ];
  const shouldShowSkeleton = isLoading && organizations.length === 0;

  // const addButton = (
  //   <Button
  //     key="add-button"
  //     startIcon={<Add />}
  //     variant="contained"
  //     onClick={handleOpenCreateDialogClick}
  //   >
  //     {matchesMdBreakpoint ? 'Create new' : 'Create new organization'}
  //   </Button>
  // );

  return (
    <View dataTestId={mainTestId}>
      <View.Header>
        <AdministrationHeader
          actions={
            [
              /* addButton */
            ]
          }
          title="Organizations Management"
        />
      </View.Header>
      <View.Content>
        <Box>
          <SearchBar
            dataTestId={makeTestId('Filter')}
            sx={searchBarSx}
            value={searchKey}
            onSearchKeyChange={setSearchKey}
          />
        </Box>
        <BasicTable
          aria-label="Organizations table"
          handleRowClick={goToOrganizationDetail}
          headCells={headCells}
          rows={organizations}
          showSkeleton={shouldShowSkeleton}
        />
      </View.Content>
    </View>
  );
};

const searchBarSx = {
  px: 2,
  py: 1,
};

export default OrganizationsPage;
