import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { conversationDetail, getCanSavePhoto } from '@serenityapp/redux-store';
import { MessagePartProps, UserProps } from '@serenityapp/domain';
import { FileViewerFileProps } from '@serenityapp/components-react-web';
import { DateFn, StringFn } from '@serenityapp/core';
import { useEffect } from 'react';

export default function useFileViewer(
  conversationId: string,
  messagePartItems: any,
  sender: UserProps,
): {
  files: Array<FileViewerFileProps>;
  canSaveFile: boolean;
} {
  const dispatch = useDispatch();

  const canSaveFile = useSelector(getCanSavePhoto);

  const files: Array<FileViewerFileProps> = R.reduce(
    (acc: Array<FileViewerFileProps>, item: MessagePartProps) => {
      const file = item.file;

      if (!file || !file.url || !item.created || !sender?.fullName) {
        return acc;
      }

      const fileViewerFile: FileViewerFileProps = {
        id: file.key,
        contentType: file.contentType,
        createdDate: new Date(DateFn.uxToJs(item.created) || 0),
        name: file.contentName,
        size: 0,
        src: file.url,
        sender: {
          avatarSrc: sender?.avatar?.url,
          initials: sender?.initials ?? StringFn.initials(sender?.fullName),
          name: sender?.fullName,
        },
      };

      return [...acc, fileViewerFile];
    },
    [],
    messagePartItems ?? [],
  );

  useEffect(() => {
    dispatch(conversationDetail({ conversationId }));
  }, [conversationId, dispatch]);

  return {
    files,
    canSaveFile,
  };
}
