import { useDidMount } from '@serenityapp/components-react-common';
import { viewerSignOut } from '@serenityapp/redux-store';
import { useDispatch } from 'react-redux';

// This is a blank page that only serves for firing the sign out action for the current user.
// The user is redirected immediatly to SignInPage after s/he has been logged out.
// This is just a ghost page.
const SignOutPage = () => {
  const dispatch = useDispatch();

  useDidMount(() => {
    dispatch(viewerSignOut());
  });

  return <></>;
};

export default SignOutPage;
