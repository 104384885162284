import { serializeError } from '@serenityapp/core';
import { NullId } from '@serenityapp/core-id';
import {
  getCurrentUserId,
  SagaContext,
  sessionInitReady,
  viewerInitSuccess,
  viewerSignOut,
} from '@serenityapp/redux-store';
import { Channel, eventChannel } from 'redux-saga';
import { call, Effect, select, take, takeEvery } from 'redux-saga/effects';

/**
 * Creates a Saga event channel for Local Storage inserts to a "serenity-auth" key
 */
function createSerenityAuthChannel() {
  return eventChannel((emit) => {
    const callback = (event: any) => {
      if (event.key === 'serenity-auth') emit(event.newValue || NullId);
    };
    window.addEventListener('storage', callback, false);
    return () => window.removeEventListener('storage', callback, false);
  });
}

/**
 * Forces reload in all currently opened tabs/windows whenever user signs out
 */
function* serenityAuthListener(context: SagaContext) {
  const serenityAuthChannel: Channel<string> = yield call(createSerenityAuthChannel);

  yield takeEvery([viewerInitSuccess, viewerSignOut], function* () {
    const userId: string = yield select(getCurrentUserId);
    localStorage.setItem('serenity-auth', userId || NullId);
  });

  while (true) {
    try {
      const eventUserId: string = yield take(serenityAuthChannel);
      const currentUserId: string = yield select(getCurrentUserId);
      if (eventUserId !== currentUserId) window.location.reload();
    } catch (error) {
      context.sentry.captureException(new Error('Sign Out listener error'), {
        extra: { error },
      });
    }
  }
}

export default function* rootSaga(context: SagaContext): Generator<Effect, any, any> {
  try {
    yield take(sessionInitReady);
    yield call(serenityAuthListener, context);
  } catch (error) {
    context.sentry.captureException(error, {
      extra: { serializedError: serializeError(error) },
      tags: { reduxSagaName: 'rootSaga' },
    });
  }
}
