import { Divider, Typography } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

import { SelectField, useMakeTestId } from '@serenityapp/components-react-web';
import { useCurrentUser, useAlexaDeviceSettingsPolicies } from '@serenityapp/client-data';

const echoSpeakingRateSelectorItems = [
  { id: '1', label: '1' },
  { id: '2', label: '2' },
  { id: '1.75', label: '1.75' },
  { id: '1.5', label: '1.5' },
  { id: '1.25', label: '1.25' },
  { id: '0.85', label: '0.85' },
  { id: '0.75', label: '0.75' },
];

const dndModeSelectorItems = [
  { id: 'true', label: 'On' },
  { id: 'false', label: 'Off' },
];

const maxVolumeSelectorItems = [
  { id: '1', label: '1' },
  { id: '2', label: '2' },
  { id: '3', label: '3' },
  { id: '4', label: '4' },
  { id: '5', label: '5' },
  { id: '6', label: '6' },
  { id: '7', label: '7' },
  { id: '8', label: '8' },
  { id: '9', label: '9' },
  { id: '10', label: '10' },
];

const AlexaDeviceFields = () => {
  const makeTestId = useMakeTestId('AlexaDeviceFields');

  const { setValues, setFieldValue } = useFormikContext<FormikValues>();
  const { user } = useCurrentUser();
  const organizationId = user?.orgId || '';

  const {
    fetching,
    policiesAsSelectorItems: deviceSettingsPoliciesSelectorItems,
    indexedAlexaDeviceSettingsPolicies,
  } = useAlexaDeviceSettingsPolicies(organizationId);

  const handleMaxVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxVolume = parseInt(event.target.value, 10);
    setFieldValue('maxVolume', maxVolume);
  };

  const handleDndModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dndModeEnabled = event.target.value === 'true';
    setFieldValue('dndModeEnabled', dndModeEnabled);
  };

  const handleEchoSpeakingRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const echoSpeakingRate = parseFloat(event.target.value);
    setFieldValue('echoSpeakingRate', echoSpeakingRate);
  };

  const handlePolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const policyId = event.target.value;
    const policy = indexedAlexaDeviceSettingsPolicies[policyId];
    if (!policy) return;
    setValues((previousValues) => ({
      ...previousValues,
      settingsPolicyId: policyId,
      maxVolume: policy.maxVolume,
      dndModeEnabled: policy.dndModeEnabled,
      echoSpeakingRate: policy.echoSpeakingRate,
    }));
  };

  return (
    <>
      <Typography display="block" variant="overline">
        device settings
      </Typography>
      <Divider sx={dividerSx} />
      <SelectField
        hideNone
        name="settingsPolicyId"
        label="Device Settings Policy"
        loading={fetching}
        items={deviceSettingsPoliciesSelectorItems}
        onChange={handlePolicyChange}
        dataTestId={makeTestId('settingsPolicyId')}
      />
      <SelectField
        hideNone
        name="maxVolume"
        label="Max Volume"
        items={maxVolumeSelectorItems}
        onChange={handleMaxVolumeChange}
        dataTestId={makeTestId('maxVolume')}
      />
      <SelectField
        hideNone
        name="dndModeEnabled"
        label="Do not disturb mode"
        items={dndModeSelectorItems}
        onChange={handleDndModeChange}
        dataTestId={makeTestId('dndModeEnabled')}
      />
      <SelectField
        hideNone
        name="echoSpeakingRate"
        label="Alexa Speaking Rate"
        items={echoSpeakingRateSelectorItems}
        onChange={handleEchoSpeakingRateChange}
        dataTestId={makeTestId('echoSpeakingRate')}
      />
    </>
  );
};

const dividerSx = {
  mb: 2.25,
};

export default AlexaDeviceFields;
