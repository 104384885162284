import { Outlet, useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, IconButton } from '@mui/material';
import {
  GridColDef,
  gridDateComparator,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useState, useMemo, useCallback, useRef } from 'react';

import { AdministrationHeader, useMakeTestId, View } from '@serenityapp/components-react-web';
import { DateFn, ServiceLevelFn } from '@serenityapp/core';
import { Schema } from '@serenityapp/domain';
import { useLocations } from '@serenityapp/client-data';

import { useAdminBreadcrumbs } from '../hooks';
import { useCurrentUser } from '../../common/hooks';
import LocationActionsMenu from './LocationsActionMenu';
import { AdminDataGrid } from '../components';
import {
  DEFAULT_LOCATIONS_PAGE_SIZE,
  gridResidentsCountComparator,
  gridServiceLevelComparator,
} from './utils';
import QuickSearchToolbarLocations from './components/QuickSearchToolbarLocations';

const PAGE_SIZE_OPTIONS = [DEFAULT_LOCATIONS_PAGE_SIZE, 50, 100];

const LocationsPage = () => {
  const title = 'Location categories';
  const makeTestId = useMakeTestId('LocationsPage');

  const currentUser = useCurrentUser();
  const organizationId = currentUser?.orgId;

  const { locations, fetching } = useLocations(organizationId, DEFAULT_LOCATIONS_PAGE_SIZE);

  const [actionsMenuAnchorEl, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const currentlySelectedLocation = useRef<string | null>(null);

  const navigate = useNavigate();

  const crumbs = useAdminBreadcrumbs(title);

  const goToResourceCreate = () => navigate('create');

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>, location: string) => {
    event.stopPropagation();
    currentlySelectedLocation.current = location;
    setActionsMenuAnchor(event.currentTarget);
  };

  const onCloseMenu = () => {
    setActionsMenuAnchor(null);
  };

  const actionsCellRenderer = useCallback(
    (params: GridRenderCellParams) => (
      <Box sx={actionCellSx}>
        <IconButton
          tabIndex={params.hasFocus ? 0 : -1}
          onClick={(e) => openMenu(e, params.value)}
        >
          <MoreVertIcon />
        </IconButton>
      </Box>
    ),
    [],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Location name',
        flex: 1,
      },
      {
        field: 'kind',
        headerName: 'Location type (hidden)',
        flex: 1,
      },
      {
        field: 'displayName',
        headerName: 'Location type',
        flex: 1,
      },
      {
        field: 'users',
        headerName: 'Residents',
        flex: 1,
        valueFormatter: (params: Schema.Unit.Item['users']) => {
          if (!params || !params?.edges?.length) return '-';
          return params.edges.length;
        },
        getApplyQuickFilterFn: () => null,
        sortComparator: gridResidentsCountComparator,
      },
      {
        field: 'serviceLevels',
        headerName: 'Service Levels',
        flex: 1,
        valueFormatter: (params: Schema.Unit.Item['serviceLevels']) => {
          if (!params || !params.length) return '-';
          return params.map((sl: string) => ServiceLevelFn.abbreviation(sl)).join(', ');
        },
        getApplyQuickFilterFn: () => null,
        sortComparator: gridServiceLevelComparator,
      },
      {
        field: 'edited',
        headerName: 'Last updated',
        flex: 1,
        valueFormatter: (params) => {
          return DateFn.formatIso(params, 'MM/dd/yyyy');
        },
        getApplyQuickFilterFn: () => null,
        sortComparator: gridDateComparator,
      },
      {
        field: 'id',
        headerName: 'Actions',
        width: 80,
        disableColumnMenu: true,
        disableColumnSelector: true,
        sortable: false,
        renderCell: actionsCellRenderer,
        filterable: false,
        getApplyQuickFilterFn: () => null,
      },
    ],
    [actionsCellRenderer],
  );

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add')}
      startIcon={<AddIcon />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;location
      </Box>
    </Button>
  );

  const goToResourceDetails = (params: GridRowParams) => {
    const { id } = params;
    navigate(id.toString());
  };

  return (
    <View>
      <View.Header>
        <AdministrationHeader actions={[addButton]} crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        {currentlySelectedLocation.current && (
          <LocationActionsMenu
            anchorEl={actionsMenuAnchorEl}
            locationId={currentlySelectedLocation.current}
            onClose={onCloseMenu}
          />
        )}
        <AdminDataGrid
          disableColumnSelector
          disableRowSelectionOnClick
          ignoreDiacritics
          disableColumnMenu
          initialState={{
            pagination: { paginationModel: { pageSize: DEFAULT_LOCATIONS_PAGE_SIZE } },
            filter: {
              filterModel: {
                items: [
                  {
                    field: 'kind',
                    operator: 'isAnyOf',
                  },
                ],
                quickFilterExcludeHiddenColumns: false,
              },
            },
          }}
          loading={fetching} // We want to have the loading indicator visible until all locations are fetched
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          onRowClick={goToResourceDetails}
          pagination
          columnVisibilityModel={{
            kind: false,
          }}
          columns={columns}
          rows={locations}
          slots={{
            toolbar: QuickSearchToolbarLocations,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton',
            },
          }}
        />
        <Outlet />
      </View.Content>
    </View>
  );
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

const actionCellSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  justifyContent: 'center',
  height: 'inherit',
};

export default LocationsPage;
