import { Box, Button, Typography } from '@mui/material';
import FormSectionHeader from './FormSectionHeader';
import { ConfirmButton, FormikField } from '@serenityapp/components-react-web';
import { Add } from '@mui/icons-material';
import { Schema } from '@serenityapp/domain';

const RoomDrawerAlexaUnitPrompt = ({
  room,
  onAlexaUnitLinkActionClick,
  createNewAlexaUnitForRoom,
}: {
  room?: Schema.Room.Item;
  onAlexaUnitLinkActionClick: () => void;
  createNewAlexaUnitForRoom: (room: Schema.Room.Item | undefined) => void;
}) => {
  const roomName = room?.displayName;
  const alexaUnit = room?.alexaUnit;

  if (!alexaUnit || alexaUnit.isMock) {
    return (
      <>
        <FormSectionHeader sx={dividerSx} title="Alexa Unit" />
        <Typography sx={horizontalPaddingSx}>
          No Alexa Unit is linked to this room. Please link an existing Alexa Unit or create a new
          one.
        </Typography>
        <Box sx={addButtonWrapperSx}>
          <Button variant="contained" onClick={onAlexaUnitLinkActionClick}>
            Link Existing Alexa Unit
          </Button>
          <ConfirmButton
            description={`This will create New Alexa Unit - ${roomName}`}
            dialogTitle="Are you sure you want to create a new Alexa Unit?"
            handleSubmit={() => createNewAlexaUnitForRoom(room)}
            startIcon={<Add />}
            variant="contained"
          >
            Add New Alexa Unit
          </ConfirmButton>
        </Box>
      </>
    );
  }
  return (
    <>
      <FormSectionHeader sx={dividerSx} title="Alexa Unit" />
      <FormikField name="alexaUnitKey" type="text" />
    </>
  );
};

const addButtonWrapperSx = {
  display: 'flex',
  justifyContent: 'space-evenly',
  p: {
    sm: 3,
    xs: 2,
  },
};

const horizontalPaddingSx = {
  pl: {
    sm: 3,
    xs: 2,
  },
  pr: {
    sm: 3,
    xs: 2,
  },
};

export const dividerSx = {
  mb: 2.25,
};

export default RoomDrawerAlexaUnitPrompt;
