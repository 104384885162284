import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { DateFn, ZonedDateFn } from '@serenityapp/core';
import { AwaySettings } from '@serenityapp/domain';

type ConfirmCheckInDialogProps = {
  handleCancelClick: () => void;
  handleConfirmClick: () => void;
  userFullName: string;
  awaySettings: AwaySettings;
};

const ConfirmCheckInDialog = ({
  handleCancelClick,
  handleConfirmClick,
  userFullName,
  awaySettings,
}: ConfirmCheckInDialogProps) => {
  return (
    <Dialog
      fullWidth
      open
      onClick={(event) => event.stopPropagation()}
      onClose={handleCancelClick}
    >
      <DialogTitle>Confirm Check-In</DialogTitle>
      <DialogContent>
        <Typography>
          {userFullName} is currently marked as 🚪 Away by {awaySettings.byFullName} through{' '}
          {DateFn.jan_1st(awaySettings.to.dateTime, awaySettings.to.timeZone)} (
          {DateFn.daysBetween(ZonedDateFn.now(), awaySettings.to.dateTime)} left). <br />
          <br /> Checking {userFullName} in now will override this status and mark participant as
          ✅ Checked-In starting today. Confirm Check-In?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelClick}>Cancel</Button>
        <Button autoFocus variant="contained" onClick={handleConfirmClick}>
          Confirm Check-In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCheckInDialog;
