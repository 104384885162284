import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { ConversationParamId } from '@serenityapp/domain';

import ConversationPageHeaderTitle from './ConversationPageHeaderTitle';
import AvatarGroupControl from './AvatarGroupControl';
import PinnedMessagesControl from './PinnedMessagesControl';
import SettingsControl from './SettingsControl';

type ConversationPageHeaderProps = {
  canCreateMessage?: boolean;
  subtype?: string;
  isGroupConversation?: boolean;
  name?: string;
  isLoading: boolean;
};

const ConversationPageHeader = ({
  canCreateMessage,
  subtype,
  isGroupConversation,
  name,
  isLoading,
}: ConversationPageHeaderProps) => {
  const { conversationId } = useParams<ConversationParamId>() as ConversationParamId;

  return (
    <Box data-testid="ConversationPageHeader" sx={rootSx}>
      <ConversationPageHeaderTitle
        isGroupConversation={isGroupConversation}
        isLoading={isLoading}
        name={name}
        subtype={subtype}
      />
      <Box sx={rightWrapperSx}>
        <PinnedMessagesControl
          canDisplayControl={canCreateMessage}
          conversationId={conversationId}
        />
        <AvatarGroupControl
          canDisplayControl={canCreateMessage}
          conversationId={conversationId}
        />
        <SettingsControl
          canDisplayControl={canCreateMessage}
          conversationId={conversationId}
          isLoading={isLoading}
          subtype={subtype}
        />
      </Box>
    </Box>
  );
};

const rootSx = {
  pl: 2,
  pr: 0.5,
  minHeight: 62,
  height: 62,
  width: 1,
  bgcolor: 'background.paper',
  display: 'flex',
  alignItems: 'center',
  borderBottomWidth: 1,
  borderBottomColor: 'divider',
  borderBottomStyle: 'solid',
};

const rightWrapperSx = {
  display: 'flex',
  alignItems: 'center',
};

export default ConversationPageHeader;
