import { useDispatch, useSelector } from 'react-redux';
import { ConfirmDialog, ConfirmDialogProps } from '@serenityapp/components-react-web';
import {
  getViewerSessionExpirationEstimate,
  getViewerSignInMethod,
  getViewerShowSessionExpirationWarning,
  viewerSignOut,
  viewerShowSessionExpirationWarning,
} from '@serenityapp/redux-store';

/**
 * Displays a warning to the user when their session is about to expire.
 */
const MainPageSessionExpirationWarning = () => {
  const dispatch = useDispatch();
  const sessionExpirationEstimate = useSelector(getViewerSessionExpirationEstimate);
  const signInMethod = useSelector(getViewerSignInMethod);
  const showSessionExpirationWarning = useSelector(getViewerShowSessionExpirationWarning);

  // When the session expiration's unknown, skip rendering the warning altogether
  if (!sessionExpirationEstimate) return null;

  let description = 'Your session is about to expire. Do you want to stay signed-in?';

  if (signInMethod === 'sso') {
    description =
      'Your single sign-on session is about to expire. Do you want to stay signed-in?';
  }

  const onDialogClose: ConfirmDialogProps['handleClose'] = (reason) => {
    if (reason === 'cancel') {
      dispatch(viewerSignOut());
    } else {
      dispatch(viewerShowSessionExpirationWarning({ showWarning: false, dismissWarning: true }));
    }
  };
  const onDialogSubmit: ConfirmDialogProps['handleSubmit'] = () => {
    dispatch(viewerShowSessionExpirationWarning({ showWarning: false, dismissWarning: true }));
  };

  return (
    <ConfirmDialog
      cancelButtonTitle="Sign Out"
      description={description}
      handleClose={onDialogClose}
      handleSubmit={onDialogSubmit}
      isOpen={showSessionExpirationWarning}
      submitButtonTitle="Stay Signed-In"
      title="Session expiration warning"
    />
  );
};

MainPageSessionExpirationWarning.displayName = 'MainPageSessionExpirationWarning';

export default MainPageSessionExpirationWarning;
