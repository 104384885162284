import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppStatus } from '@serenityapp/domain';
import { AppCard, icons } from '@serenityapp/components-react-web';
import { AppData } from '@serenityapp/redux-store';
import BoxCloudStorageDialog from './BoxCloudStorageDialog';

const { BoxCloudStorage } = icons;

type BoxAppCardProps = {
  app: AppData;
};

const BoxAppCard = ({ app }: BoxAppCardProps) => {
  const navigate = useNavigate();

  const [configurationDialog, setConfigurationDialog] = useState(false);

  const handleCardClick = app.status !== AppStatus.DISABLED ? () => navigate('box') : undefined;

  const handleAppEnableClick = () => setConfigurationDialog(true);

  const appIcons = [<BoxCloudStorage />];

  return (
    <>
      <AppCard
        description={app.description}
        icons={appIcons}
        status={app.status}
        title={app.displayName}
        onCardClick={handleCardClick}
        onEnableClick={handleAppEnableClick}
      />
      <BoxCloudStorageDialog
        isOpen={configurationDialog}
        onClose={() => setConfigurationDialog(false)}
      />
    </>
  );
};

export default BoxAppCard;
