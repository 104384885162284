import { SyntheticEvent, useEffect, useState } from 'react';
import { Divider, Typography } from '@mui/material';
import { FormikValues, useFormikContext } from 'formik';

import { FormikField, useMakeTestId } from '@serenityapp/components-react-web';
import { SelectorItemProps } from '@serenityapp/components-react-common';
import { useLocationGroups } from '@serenityapp/client-data';

import { dividerSx } from './styles';

import AddressFields from './AddressFields';
import FloorsField from './FloorsFields';
import AutocompleteField from './AutocompleteField';

const BuildingFields = () => {
  const { setFieldValue, initialValues } = useFormikContext();
  const { locationGroupId } = initialValues as FormikValues;

  const makeTestId = useMakeTestId('BuildingFields');

  const [selectedLocationGroupId, setSelectedLocationGroupId] = useState(locationGroupId);

  const { locationGroupOptions, fetching } = useLocationGroups();

  useEffect(() => {
    setSelectedLocationGroupId(locationGroupId);
  }, [initialValues, locationGroupId]);

  const selectedLocationGroupItem: SelectorItemProps | undefined = locationGroupOptions.find(
    (item: SelectorItemProps) => item.id === selectedLocationGroupId,
  );

  const handleLocationGroupChange = (event: SyntheticEvent, value: SelectorItemProps) => {
    setFieldValue('locationGroup', value);
    setFieldValue('locationGroupId', value?.id);
    setSelectedLocationGroupId(value?.id || null);
  };

  return (
    <>
      <FormikField
        dataTestId={makeTestId('DisplayName')}
        helperText="Overwrite default location type name"
        label="Display name"
        name="displayName"
        type="text"
      />
      <AutocompleteField
        dataTestId={makeTestId('LocationGroup')}
        items={locationGroupOptions}
        label="Location Group"
        name="locationGroup"
        value={selectedLocationGroupItem || null}
        loading={fetching}
        onChange={handleLocationGroupChange}
      />
      <Typography display="block" variant="overline">
        floors
      </Typography>
      <Divider sx={dividerSx} />
      <FloorsField name="floors" />
      <AddressFields />
    </>
  );
};

export default BuildingFields;
