import { FormGroup, Typography, Box, Alert, FormControlLabel, Checkbox } from '@mui/material';
import { CalendarItemData } from '@serenityapp/redux-store';
import { Field } from 'formik';

type CalendarsListProps = {
  isSyncing: boolean;
  isPaused: boolean;
  isUpdatingCalendarsForSync: boolean;
  calendars: Array<CalendarItemData>;
  checkedCalendarIds: Array<string>;
  fieldName: string;
};

const CalendarsListField = ({
  isSyncing,
  isPaused,
  isUpdatingCalendarsForSync,
  calendars,
  fieldName,
}: CalendarsListProps) => {
  if (isSyncing && !calendars.length) return <Typography>Syncing...</Typography>;

  if (!calendars.length)
    return <Typography>There are no calendars assigned to your account</Typography>;

  const renderCalendarItem = (calendar: CalendarItemData) => (
    <FormControlLabel
      key={`checkbox-list-${calendar.id}`}
      control={<Field as={Checkbox} name={fieldName} type="checkbox" value={calendar.id} />}
      disabled={isSyncing || isPaused || isUpdatingCalendarsForSync}
      label={calendar.name}
    />
  );

  return (
    <Box>
      <Typography>Select calendars to sync with Serenity</Typography>
      <Alert severity="info">
        To create / delete calendars and events, visit your Google calendar directly.
      </Alert>
      <FormGroup>{calendars.map(renderCalendarItem)}</FormGroup>
    </Box>
  );
};

export default CalendarsListField;
