import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Add, MoreVert, Upload } from '@mui/icons-material';
import { Box, Button, IconButton, TableCell } from '@mui/material';

import {
  AdministrationHeader,
  BasicTable,
  BasicTableHeadCellProps,
  useMakeTestId,
  View,
  SearchBar,
  BasicTableRowDataProps,
} from '@serenityapp/components-react-web';
import { useRooms } from '@serenityapp/redux-store';
import { roomsTransformer } from '@serenityapp/redux-store';
import { useAdminBreadcrumbs } from '../hooks';

import { ImportRoomsDialog, RoomActionsMenu } from './components';

const RoomsPage = () => {
  const title = 'Rooms';

  const makeTestId = useMakeTestId('RoomsPage');
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState('');
  const [actionsMenuAnchorEl, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState<null | BasicTableRowDataProps>(
    null,
  );
  const shouldDisplayActionsMenu = !!currentlySelectedRow;
  const mainTestId = makeTestId('');

  const { data, isLoading } = useRooms();

  const rooms = roomsTransformer(data, isLoading, { searchKey });
  const shouldShowSkeleton = isLoading && rooms.length === 0;

  const handleActionsMenuClick =
    (row?: BasicTableRowDataProps) => (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setActionsMenuAnchor(event.currentTarget);
      setCurrentlySelectedRow(row);
    };

  const handleActionsMenuClose = () => setActionsMenuAnchor(null);

  const actionsCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    return (
      <TableCell
        key={`action-cell-${row?.id}`}
        align="right"
        data-testid={dataTestId}
        padding="none"
      >
        <IconButton
          aria-label="Open menu"
          data-testid="RoomsActionsMenu"
          onClick={handleActionsMenuClick(row)}
        >
          <MoreVert />
        </IconButton>
      </TableCell>
    );
  };

  const headCells: BasicTableHeadCellProps[] = [
    { id: 'displayName', label: 'Name' },
    { id: 'isOpen', label: 'Status' },
    { id: 'serviceLevel', label: 'Service Level' },
    {
      id: 'actions',
      label: 'Actions',
      sort: false,
      cellRenderer: actionsCellRenderer,
      align: 'right',
    },
  ];

  const goToResourceDetails = (id: string) => navigate(id);
  const goToResourceCreate = () => navigate('create');

  const crumbs = useAdminBreadcrumbs(title);

  const [importRoomsDialog, setImportRoomsDialog] = useState(false);

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add')}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;room
      </Box>
    </Button>
  );

  const handleImportRoomsButton = () => setImportRoomsDialog(true);
  const importRoomsButton = (
    <Button
      key="import-rooms-button"
      data-testid={makeTestId('import-rooms')}
      startIcon={<Upload />}
      variant="outlined"
      onClick={handleImportRoomsButton}
    >
      Import
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;rooms
      </Box>
    </Button>
  );

  return (
    <>
      {shouldDisplayActionsMenu && (
        <RoomActionsMenu
          anchorEl={actionsMenuAnchorEl}
          id={currentlySelectedRow?.id}
          name={currentlySelectedRow?.displayName || currentlySelectedRow?.fullName || ''}
          onClose={handleActionsMenuClose}
        />
      )}
      <View>
        <View.Header>
          <AdministrationHeader
            actions={[importRoomsButton, addButton]}
            crumbs={crumbs}
            title={title}
          />
        </View.Header>
        <View.Content>
          <ImportRoomsDialog
            isOpen={importRoomsDialog}
            onClose={() => setImportRoomsDialog(false)}
          />
          <Box>
            <SearchBar
              dataTestId={makeTestId('Rooms')}
              sx={searchBarSx}
              value={searchKey}
              onSearchKeyChange={setSearchKey}
            />
          </Box>
          <BasicTable
            aria-label="Rooms table"
            dataTestId={mainTestId}
            handleRowClick={goToResourceDetails}
            headCells={headCells}
            rows={rooms}
            showSkeleton={shouldShowSkeleton}
          />
          <Outlet />
        </View.Content>
      </View>
    </>
  );
};

const searchBarSx = {
  px: 2,
  pb: 1,
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default RoomsPage;
