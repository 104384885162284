import { Box, CircularProgress } from '@mui/material';

const MessagesLoading = () => (
  <Box sx={rootSx}>
    <CircularProgress />
  </Box>
);

const rootSx = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  // 48px and 32px are sums of the heights of the virtuoso header and the virtuoso footer at different breakpoints
  height: {
    xs: `calc(100% - 48px)`,
    sm: `calc(100% - 32px)`,
  },
};

export default MessagesLoading;
