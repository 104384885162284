import * as R from 'ramda';
import { ViewerOrganizationRetentionPoliciesQueryResult } from '@serenityapp/api-graphql';
import { OrganizationRetentionPolicyEdge, RetentionPolicy } from '@serenityapp/domain';

export type RetentionPolicyRow = RetentionPolicy & {
  active?: boolean;
};

export const retentionPoliciesTransformer = (
  retentionPoliciesData: ViewerOrganizationRetentionPoliciesQueryResult,
  isLoadingRetentionPolicies: boolean,
): Array<RetentionPolicyRow> => {
  if (isLoadingRetentionPolicies) return [];

  const retentionPoliciesEdges =
    retentionPoliciesData.viewer.organization.retentionPolicies.edges;

  return R.reduce(
    (acc: Array<RetentionPolicyRow>, elem: OrganizationRetentionPolicyEdge) => {
      if (elem.node.archived) return acc;

      return [...acc, { ...elem.node, active: elem.active || false }];
    },
    [],
    retentionPoliciesEdges,
  );
};
