import { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Skeleton,
} from '@mui/material';

import {
  Avatar,
  Select,
  useMakeTestId,
  SelectChangeEvent,
} from '@serenityapp/components-react-web';

import {
  conversationMembersTransformer,
  getVariablesForConversationDetailMessagingQuery,
  useConversationDetailMessaging,
} from '@serenityapp/redux-store';

type ConversationMembersTabProps = {
  conversationId: string;
  dataTestId?: string;
};

type MembersType = 'all' | 'staff' | 'contact' | 'resident';

const ConversationMemberPanelSkeleton = () => (
  <Box sx={membersTabSx}>
    <Skeleton height="56px" sx={membersFilterSx} width="220px" />
    <List disablePadding>
      {[...new Array(3)].map((_, index) => (
        <ListItem key={index} disableGutters sx={listItemSx}>
          <ListItemIcon>
            <Skeleton height="40px" variant="circular" width="40px" />
          </ListItemIcon>
          <ListItemText
            primary={<Skeleton width="200px" />}
            secondary={<Skeleton width="300px" />}
          />
        </ListItem>
      ))}
    </List>
  </Box>
);

const ConversationMembersTab = ({ conversationId, dataTestId }: ConversationMembersTabProps) => {
  const makeTestId = useMakeTestId('ConversationMembersTab', dataTestId);

  const { data, isLoading } = useConversationDetailMessaging({
    variables: getVariablesForConversationDetailMessagingQuery(conversationId),
  });

  const { allMembers, staff, contacts, residents, apps, getMemberType } =
    conversationMembersTransformer(data);

  const [selectedMembersType, setSelectedMembersType] = useState<MembersType>('all');

  const getMembersByType = (type: string) => {
    switch (type) {
      case 'all':
        return allMembers;
      case 'staff':
        return staff || [];
      case 'contact':
        return contacts || [];
      case 'resident':
        return residents || [];
      case 'app':
        return apps || [];
      default:
        return [];
    }
  };

  const conversationMembersToShow = getMembersByType(selectedMembersType);

  const handleFilterSelect = (event: SelectChangeEvent) => {
    const selectedType = event.target.value as MembersType;

    setSelectedMembersType(selectedType);
  };

  if (isLoading) return <ConversationMemberPanelSkeleton />;

  return (
    <Box data-testid="members-panel" sx={membersTabSx}>
      <Box sx={membersFilterSx}>
        <FormControl sx={formControlSx}>
          <InputLabel id="filterSelect" variant="outlined">
            Filter
          </InputLabel>
          <Select
            data-testid={makeTestId('filter')}
            label="Filter"
            labelId="filterSelect"
            value={selectedMembersType}
            variant="outlined"
            onChange={handleFilterSelect}
          >
            <MenuItem data-testid={makeTestId('all')} value="all">
              All members
            </MenuItem>
            {apps && (
              <MenuItem data-testid={makeTestId('app')} value="app">
                Apps
              </MenuItem>
            )}
            {contacts && (
              <MenuItem data-testid={makeTestId('contacts')} value="contact">
                Contacts
              </MenuItem>
            )}
            {staff && (
              <MenuItem data-testid={makeTestId('staff')} value="staff">
                Staff
              </MenuItem>
            )}
            {residents && (
              <MenuItem data-testid={makeTestId('residents')} value="resident">
                Resident
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Box>
      <List disablePadding>
        {conversationMembersToShow.map((user) => (
          <ListItem
            key={user.id}
            disableGutters
            data-testid="conversation-member-user-info"
            sx={listItemSx}
          >
            <ListItemIcon>
              <Avatar initials={user.initials} size="medium" src={user?.src} sx={avatarSx} />
            </ListItemIcon>
            <ListItemText
              data-testid={makeTestId('')}
              primary={user?.fullName}
              secondary={user.subtype ? getMemberType(user.subtype) : ''}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const avatarSx = {
  bgcolor: 'grey.400',
};

const listItemSx = {
  pl: 3,
  '&:hover': {
    bgcolor: 'background.default',
    borderColor: 'grey.50',
  },
};

const membersTabSx = {
  pt: 1,
};

const membersFilterSx = {
  ml: 3,
};

const formControlSx = {
  minWidth: 220,
};

export default ConversationMembersTab;
