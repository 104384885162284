import { AutoLinkPlugin as LexicalAutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { EMAIL_REGEX } from '@serenityapp/components-react-common';

// URL RegEx from lexical docs https://lexical.dev/docs/react/plugins#lexicalautolinkplugin
const URL_MATCHER =
  /((https?:\/\/((www|app)\.)?)|((www|app)\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);

    if (match === null) {
      return null;
    }

    const fullMatch = match[0];
    let url = fullMatch;
    if (EMAIL_REGEX.test(fullMatch)) {
      url = `mailto:${fullMatch}`;
    } else if (!fullMatch.startsWith('http')) {
      url = `https://${fullMatch}`;
    }

    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url,
      attributes: { rel: 'noopener', target: '_blank' },
    };
  },
];

export default function AutoLinkPlugin(): JSX.Element {
  return <LexicalAutoLinkPlugin matchers={MATCHERS} />;
}
