import { Divider, SxProps, Typography } from '@mui/material';

const FormSectionHeader = ({ title, sx }: { title: string; sx: SxProps }) => {
  return (
    <>
      <Typography display="block" variant="overline">
        {title}
      </Typography>
      <Divider sx={sx} />
    </>
  );
};

export default FormSectionHeader;
