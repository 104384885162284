import { useDispatch } from 'react-redux';
import {
  PushPin,
  PushPinOutlined,
  ArchiveOutlined,
  ContentCopy,
  Delete,
  MarkUnreadChatAlt,
} from '@mui/icons-material';
import {
  LocalFileState,
  LocalFileStatus,
  MessageCreateInput_MessagePart,
  S3FileInput,
  WithKey,
} from '@serenityapp/domain';
import { FileProps } from '@serenityapp/components-react-web';
import { MimeFn, convertBase64toImageSrc, getFileSizeFromBytes } from '@serenityapp/core';
import {
  messagesArchive,
  messagesDismiss,
  messagesPin,
  snackAdd,
} from '@serenityapp/redux-store';

// capitalize and remove spaces
const makeTestIdFromLabel = (label: string) => {
  return label?.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()).replace(/\s/g, '');
};

// TODO (Liza): remove after LegacyMessageItem is deleted
export const getFiles = (
  files: readonly LocalFileState[],
  onFileClick: (file: LocalFileState) => void,
): Array<WithKey<FileProps>> => {
  return (
    files?.map((file) => {
      const onClick = () => onFileClick(file);
      // use file url as thumbnail until the preview is created
      // this should be the case for the optimistic files only
      const thumbnail = file.s3File.preview
        ? convertBase64toImageSrc(file.s3File.preview)
        : file.s3File.url;

      const fileSize = file.originalFile?.size
        ? getFileSizeFromBytes(file.originalFile?.size)
        : undefined;

      return {
        key: file.key,
        primaryText: file?.s3File?.contentName,
        loading: file.status === LocalFileStatus.TO_UPLOAD,
        secondaryText: fileSize,
        onClick,
        thumbnail,
        contentType: file.s3File.contentType,
      };
    }) ?? []
  );
};

export const getFilesFromMessagePartItems = (
  messagePartItems: ReadonlyArray<MessageCreateInput_MessagePart<S3FileInput>>,
  onFileClick: (file: S3FileInput) => void,
  isOptimistic: boolean,
): Array<WithKey<FileProps>> => {
  return (
    messagePartItems?.map(({ file }) => {
      const onClick = file ? () => onFileClick(file) : undefined;

      const isImage = MimeFn.isImage(file?.contentType);

      // use file url as thumbnail until the preview is created
      // this should be the case for the optimistic files only
      // and only for images
      let thumbnail: string | undefined;

      if (file?.preview) {
        thumbnail = convertBase64toImageSrc(file.preview);
      } else if (isImage) {
        thumbnail = file?.url;
      } else {
        thumbnail = undefined;
      }

      return {
        key: file?.key || '',
        primaryText: file?.contentName || '',
        onClick,
        thumbnail,
        contentType: file?.contentType || '',
        loading: isOptimistic,
      };
    }) ?? []
  );
};

export const usePinTool = (
  conversationId: string,
  messageId: string,
  isPinned: boolean,
  canPin: boolean,
  sendFailed?: boolean,
) => {
  const dispatch = useDispatch();

  if (!canPin || sendFailed) return undefined;

  const onClick = () => {
    dispatch(
      messagesPin({
        conversationId,
        messageId,
        isUnpin: isPinned,
      }),
    );
  };

  const label = isPinned ? 'Unpin' : 'Pin';

  return {
    dataTestId: makeTestIdFromLabel(label),
    key: 'inline-toolbar-pin-message',
    showInMenu: true,
    Icon: isPinned ? PushPinOutlined : PushPin,
    onClick: onClick,
    label,
  };
};

export const useArchiveTool = (
  conversationId: string,
  messageId: string,
  canArchive: boolean,
  sendFailed?: boolean,
) => {
  const dispatch = useDispatch();

  if (!canArchive || sendFailed) return undefined;

  const onClick = () => {
    dispatch(messagesArchive({ conversationId, messageId }));
  };

  const label = 'Archive';

  return {
    dataTestId: makeTestIdFromLabel(label),
    key: 'inline-toolbar-archive-message',
    showInMenu: true,
    Icon: ArchiveOutlined,
    hasConfirmDialog: true,
    onClick: onClick,
    label,
    confirmDialogTitle: 'Are you sure you want to archive this message?',
  };
};

export const useMarkUnreadTool = (
  messageId: string,
  onToolClick?: (messageId: string) => void,
  sendFailed?: boolean,
  loading?: boolean,
) => {
  // if the message is response for a slash command request
  // loading prop will be set to either false or true
  // for any other message it will be undefined
  // we don't want to show mark unread action to slash command request response
  if (sendFailed || loading !== undefined) return undefined;

  const onClick = () => onToolClick?.(messageId);
  const label = 'Mark as unread';

  return {
    dataTestId: makeTestIdFromLabel(label),
    key: 'menu-mark-unread',
    showInMenu: true,
    Icon: MarkUnreadChatAlt,
    onClick,
    label,
  };
};

export const useCopyTool = (text?: string, sendFailed?: boolean) => {
  const dispatch = useDispatch();

  if (!text?.trim() || sendFailed) return undefined;

  const onClick = () =>
    text &&
    navigator.clipboard.writeText(text).then(() =>
      dispatch(
        snackAdd({
          message: 'Text copied',
          type: 'success',
          timeout: 1000,
          position: 'conversationPage',
        }),
      ),
    );

  const label = 'Copy text';

  return {
    dataTestId: makeTestIdFromLabel(label),
    key: 'inline-toolbar-copy-text',
    showInMenu: true,
    Icon: ContentCopy,
    label,
    onClick,
  };
};

export const useDeleteTool = (
  conversationId: string,
  messageId: string,
  sendFailed?: boolean,
) => {
  const dispatch = useDispatch();

  if (!sendFailed) return undefined;

  const onClick = () => {
    dispatch(messagesDismiss({ conversationId, messageId }));
  };

  const label = 'Delete';

  return {
    dataTestId: makeTestIdFromLabel(label),
    key: 'delete-failed-message',
    Icon: Delete,
    showInMenu: true,
    label,
    onClick,
  };
};
