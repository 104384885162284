import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Add, Lock } from '@mui/icons-material';
import { Tooltip, TableCell, Skeleton, Button, Box } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  AdministrationHeader,
  BasicTable,
  BasicTableHeadCellProps,
  View,
  BasicTableRowDataProps,
  useMakeTestId,
  SearchBar,
} from '@serenityapp/components-react-web';
import { IAMGroupProps, StandardIAMGroups } from '@serenityapp/domain';
import {
  getFilteredSortedIAMGroups,
  getIAMGroupsLoadingState,
  RootState,
} from '@serenityapp/redux-store';

import { useAdminBreadcrumbs } from '../hooks';
import { lockIconSx, nameTableCellSx } from './groupsPageStyle';

const GroupsPage = () => {
  const title = 'Groups Management';
  const makeTestId = useMakeTestId('GroupsPage');
  const mainTestId = makeTestId('');

  const [searchKey, setSearchKey] = useState('');

  const groups: Array<IAMGroupProps> = useSelector((state: RootState) =>
    getFilteredSortedIAMGroups(state, searchKey),
  );

  const navigate = useNavigate();

  const goToResourceDetails = (id: string) => navigate(id);
  const goToResourceCreate = () => navigate('create');

  const isLoading = useSelector(getIAMGroupsLoadingState);

  const shouldShowSkeleton = isLoading && groups.length === 0;

  const groupNameCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    const groupName: string | undefined = row?.name;
    const isStandardGroup = !!groupName && groupName in StandardIAMGroups;

    if (!row)
      return (
        <>
          {shouldShowSkeleton ? (
            <Skeleton variant="text" width="70%" />
          ) : (
            <TableCell key={`name-cell-skeleton-${row?.id}`} />
          )}
        </>
      );

    return (
      <TableCell key={`name-cell-${row?.id}`} data-testid={dataTestId} sx={nameTableCellSx}>
        {groupName}
        {isStandardGroup && (
          <Tooltip arrow title="Standard group">
            <Lock sx={lockIconSx} />
          </Tooltip>
        )}
      </TableCell>
    );
  };

  const headCells: BasicTableHeadCellProps[] = [
    { id: 'name', label: 'Name', cellRenderer: groupNameCellRenderer },
    { id: 'type', label: 'Type' },
    { id: 'description', label: 'Description', sort: false, hide: 'smDown' },
  ];

  const crumbs = useAdminBreadcrumbs(title);

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add')}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;group
      </Box>
    </Button>
  );

  return (
    <View>
      <View.Header>
        <AdministrationHeader actions={[addButton]} crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <Box>
          <SearchBar
            dataTestId={makeTestId('Filter')}
            sx={searchBarSx}
            value={searchKey}
            onSearchKeyChange={setSearchKey}
          />
        </Box>
        <BasicTable
          aria-label="Groups table"
          dataTestId={mainTestId}
          handleRowClick={goToResourceDetails}
          headCells={headCells}
          rows={groups}
          showSkeleton={shouldShowSkeleton}
        />
        <Outlet />
      </View.Content>
    </View>
  );
};

const searchBarSx = {
  px: 2,
  pb: 1,
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default GroupsPage;
