import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  getIsPasswordResetLoading,
  RootState,
  snackAdd,
  userSystemResetPassword,
} from '@serenityapp/redux-store';
import {
  SystemResetPasswordFormConfig,
  SystemResetPasswordFormValues,
  SerenityForm,
} from '@serenityapp/components-react-common';
import { FormikField, Form, useMakeTestId } from '@serenityapp/components-react-web';

type SystemResetPasswordDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
};

const SystemResetPasswordDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
}: SystemResetPasswordDialogProps) => {
  const makeTestId = useMakeTestId('SystemResetPasswordDialog', dataTestId);
  const resetPasswordDialog = makeTestId('');
  const dispatch = useDispatch();

  const isResetPasswordLoading = useSelector((state: RootState) =>
    getIsPasswordResetLoading(state),
  );

  const handleSubmit = (input: SystemResetPasswordFormValues) => {
    dispatch(
      userSystemResetPassword({
        variables: { input },
        onFailed: (error) => {
          const errorMessage = `Error resetting password.${
            error?.props?.data?.data?.result.code === 'UserNotFoundException'
              ? ' This user has not been invited yet.'
              : ''
          }`;
          dispatch(snackAdd({ message: errorMessage, type: 'error' }));
          onClose();
        },
        onSuccess: () => {
          dispatch(
            snackAdd({
              message: 'Password successfully reset',
              type: 'success',
            }),
          );
          onClose();
        },
      }),
    );
  };

  return (
    <SerenityForm config={SystemResetPasswordFormConfig} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Dialog
          fullWidth
          data-testid={resetPasswordDialog}
          open={isOpen}
          onClick={(event) => event.stopPropagation()}
          onClose={onClose}
        >
          <DialogTitle>Reset password</DialogTitle>
          <DialogContent>
            <Alert severity="warning" sx={alertSx}>
              <strong>Note: </strong>
              System password reset does NOT send any email to user.
            </Alert>
            <Alert severity="info" sx={alertSx}>
              <strong>Note: </strong>
              Upon next login the user will be prompted to change their password.
            </Alert>
            <Form>
              <FormikField
                autoFocus
                dataTestId={makeTestId('email')}
                disabled={isResetPasswordLoading}
                name="email"
                variant="filled"
              />
              <FormikField
                dataTestId={makeTestId('password')}
                disabled={isResetPasswordLoading}
                name="password"
                variant="filled"
              />
            </Form>
          </DialogContent>
          <DialogActions>
            <Button data-testid={makeTestId('cancel')} onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              data-testid={makeTestId('resetPassword')}
              loading={isResetPasswordLoading}
              variant="contained"
              onClick={submitForm}
            >
              Reset password
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
    </SerenityForm>
  );
};

export const alertSx = {
  my: 2,
};

export default SystemResetPasswordDialog;
