import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';

import { Client, CombinedError, fetchExchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import { retryExchange } from '@urql/exchange-retry';
import { cacheExchange } from '@urql/exchange-graphcache';
import { devtoolsExchange } from '@urql/devtools';

import urqlSchema from '@serenityapp/api-client-graph/generated/urqlSchema.json';
import { ClientApi } from '@serenityapp/api-client-graph';
import { Schema } from '@serenityapp/domain';
import {
  buildingCreate,
  conversationCreate,
  locationGroupCreate,
  unitCreate,
  unitRemove,
  locationGroupRemove,
  buildingRemove,
  buildingLocationGroupConvert,
  locationGroupBuildingConvert,
  mapExchange,
  locationCreate,
} from './urql';
import onePageCacheResolver from './urql/resolvers/onePageCacheResolver';

// Helper function to ensure a cache key is a string
const ensureKey = (x: any): string => (typeof x === 'string' ? x : '');

const cache = cacheExchange({
  schema: urqlSchema,

  // URQ Docs: Custom cache keys & non-keyable entities https://commerce.nearform.com/open-source/urql/docs/graphcache/normalized-caching/#custom-keys-and-non-keyable-entities
  // We set keys to null for entities that do not have a unique id (they are not keyable). This prevents urql from throwing warnings about missing keys.
  keys: {
    UserAccount: () => null,
    Correlation: () => null,
    Address: () => null,
    UserAwaySettings: () => null,
    S3File: () => null,
  },
  resolvers: {
    Unit: {
      deviceIds: (parent, args, cache, info) => {
        // If the deviceIds field is not present, return an empty array
        // to prevent errors in the UI while data is being fetched
        return parent.deviceIds || [];
      },
    },
    Query: {
      device: (parent, args: Schema.Device.Get.Variables, cache, info) => {
        return { __typename: 'AlexaDevice', id: args?.input?.id };
      },
      location: (parent, args: Schema.Location.Get.Variables, cache, info) => {
        const typenames = ['Unit', 'Building', 'LocationGroup'];
        for (const typename of typenames) {
          const key = cache.keyOfEntity({ __typename: typename, id: args.input.id });

          if (cache.resolve(key, 'id')) {
            // If the entity with this typename and ID exists in the cache, resolve it
            return {
              __typename: typename,
              id: args.input.id,
            };
          }
        }

        // Fallback if nothing found
        return null;
      },
      conversation: (parent, args: Schema.Conversation.Get.Variables, cache, info) => {
        return { __typename: 'Conversation', id: args?.input?.id };
      },
    },
    Viewer: {
      location: (parent, args: ClientApi.Filter.Api.Get.Variables, cache, info) => {
        return { __typename: 'Location', id: args?.input?.id };
      },
      conversation: (parent, args: ClientApi.Conversation.Api.Get.Variables, cache, info) => {
        return { __typename: 'Conversation', id: args?.input?.conversationId };
      },
    },
    Organization: {
      locations: onePageCacheResolver,
      devices: (parent, args, cache, info) => {
        const { pageInput } = args;
        const typename = ensureKey(parent.__typename);
        const id = ensureKey(parent.id);
        const organizationKey = cache.keyOfEntity({ __typename: typename, id });

        // Attempt to read the 'devices' data from the cache using the organizationKey.
        // If the data is already cached, cachedResult will contain the cached data.
        const cachedResult = cache.resolve(organizationKey, 'devices');

        // Check if the query is paginated
        if (pageInput) {
          // If the query is paginated
          return cachedResult || onePageCacheResolver(parent, args, cache, info); // Return cached data or use resolver to save data to cache
        } else {
          // If the query is not paginated
          return cachedResult || null; // Return cached data or null if not found
        }
      },
    },
  },
  updates: {
    Mutation: {
      buildingCreate,
      buildingRemove,
      conversationCreate,
      buildingLocationGroupConvert,
      locationGroupRemove,
      locationGroupCreate,
      locationGroupBuildingConvert,
      unitCreate,
      unitRemove,
      // Filters
      locationCreate,
    },
  },
});

export function createClient(graphqlEndpoint: string) {
  const client = new Client({
    url: graphqlEndpoint,
    exchanges: [
      // Hooks up the URQL devtools (browser extension)
      devtoolsExchange,
      // Handles client-side cache
      // @urql/exchange-graphcache is a better alternative as it's normalized, but
      // it cannot work with aliased fields out-of-the-box and I didn't have time to
      // fix it yet, so I'm using the default cacheExchange (denormalized) for now.
      cache,
      // Retries the request if it fails
      retryExchange({
        initialDelayMs: 1000,
        maxDelayMs: 6000,
        randomDelay: true,
        maxNumberAttempts: 3,
        retryIf: (err) => err.networkError !== undefined && !isAuthError(err),
      }),
      // this needs to be placed ABOVE the authExchange in the exchanges array, otherwise the auth error
      // will show up hear before the auth exchange has had the chance to handle it
      mapExchange,
      // Handles authentication
      authExchange(async (utils) => {
        let session = await Auth.currentSession().catch((error: any) => {
          if (error.message !== 'No current user') {
            Sentry.addBreadcrumb({
              message: 'Failed to get current session when creating URQL client',
              data: { error },
            });
          }
        });

        return {
          // Adds the token to the headers of every request
          addAuthToOperation(operation) {
            try {
              const token = session?.getIdToken().getJwtToken();

              if (!token) {
                return operation;
              }

              return utils.appendHeaders(operation, { Authorization: token });
            } catch (error) {
              return operation;
            }
          },
          // willAuthError is called before sending a request.
          // Checks if the token has expired.
          // If it has, it will return true and refreshAuth is triggered.
          willAuthError() {
            try {
              return !session || !session.isValid();
            } catch (error: any) {
              return true;
            }
          },
          // Checks if the error is an authentication error and if it is, refreshes the token
          didAuthError(error, _operation) {
            return isAuthError(error);
          },
          // Refreshes the token
          async refreshAuth() {
            try {
              session = await Auth.currentSession();
              if (!session.isValid()) {
                await Auth.signOut();
              }
            } catch (error) {
              await Auth.signOut();
            }
          },
        };
      }),
      // Default "exchange" that just handles sending the request and fetching the response
      fetchExchange,
    ],
  });

  return client;
}

function isAuthError(error: CombinedError): boolean {
  const is401 = error.response?.status === 401;
  const tokenExpired = error.graphQLErrors.some((e) => e.message.includes('Token has expired'));
  return is401 || tokenExpired;
}
