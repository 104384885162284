import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPinnedMessagesListItemProps,
  messagesGetPinnedPage,
  RootState,
} from '@serenityapp/redux-store';

export default function usePinnedMessages(conversationId: string) {
  const dispatch = useDispatch();

  const { items: pinnedMessages, loading: isLoading } = useSelector((state: RootState) =>
    getPinnedMessagesListItemProps(state, conversationId),
  );

  useEffect(() => {
    dispatch(messagesGetPinnedPage(conversationId));
  }, [dispatch, conversationId]);

  return {
    pinnedMessages,
    isLoading,
  };
}
