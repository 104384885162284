import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as R from 'ramda';
import { useMutation } from 'urql';

import { IdFn } from '@serenityapp/core-id';
import { FilterCreateEditFormValues } from '@serenityapp/components-react-common';
import { snackAdd } from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ClientApi } from '@serenityapp/api-client-graph';

import FilterCreateEditForm from './components/FilterCreateEditForm';

const FilterCreateDrawer = () => {
  const makeTestId = useMakeTestId('FilterCreateDrawer');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const goBack = () => navigate('..');

  const [, createFilter] = useMutation<
    ClientApi.Filter.Api.Create.MutationResult,
    ClientApi.Filter.Api.Create.Variables
  >(ClientApi.Filter.Api.Create.Mutation);

  const handleFormSubmit = (values: FilterCreateEditFormValues) => {
    setIsSubmitting(true);

    const selectedChannels = [
      ...(values.residentChannels || []),
      ...(values.generalChannels || []),
    ];

    const input = {
      id: IdFn.new(),
      name: values.name?.trim(),
      description: values.description?.trim(),
      filterPositionPriority: values.filterPositionPriority || undefined,
      conversations: R.pluck('id', selectedChannels),
    };

    createFilter({
      input,
    }).then((result) => {
      setIsSubmitting(false);
      if (result.error || result.data?.result?.success === false) {
        dispatch(snackAdd({ message: 'Error creating filter', type: 'error' }));
      } else {
        dispatch(snackAdd({ message: 'Filter successfully created', type: 'success' }));
        goBack();
      }
    });
  };

  return (
    <FilterCreateEditForm
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      isSaving={isSubmitting}
      title="Create new general filter"
    />
  );
};

export default FilterCreateDrawer;
