import { useDispatch } from 'react-redux';
import { Box, ListItem, ListItemText, Switch } from '@mui/material';
import { ConversationMuteInput, ConversationNotificationKind } from '@serenityapp/domain';

import { conversationMute } from '@serenityapp/redux-store';

type ConversationSettingsTabProps = {
  conversationId: string;
  isConversationMuted?: boolean;
  isConversationDataLoading: boolean;
};

const ConversationSettingsTab = ({
  conversationId,
  isConversationMuted = false,
  isConversationDataLoading,
}: ConversationSettingsTabProps) => {
  const dispatch = useDispatch();

  const toggleMuteConversation = () => {
    const conversationMuteInput: ConversationMuteInput = {
      conversationIds: [conversationId],
      mute: isConversationMuted ? [] : [ConversationNotificationKind.PUSH],
    };

    dispatch(conversationMute({ input: conversationMuteInput }));
  };

  return (
    <Box data-testid="settings-panel" sx={rootSx}>
      <ListItem
        secondaryAction={
          <Switch
            checked={isConversationMuted}
            disabled={isConversationDataLoading}
            onChange={toggleMuteConversation}
          />
        }
        sx={listItemSx}
      >
        <ListItemText
          primary="Mute conversation"
          secondary="You won’t be receiving push notifications for this conversation"
          sx={{ mr: 1.25 }}
        />
      </ListItem>
    </Box>
  );
};

const rootSx = {
  display: 'flex',
  flexDirection: 'column',
};

const listItemSx = {
  pl: 3,
};

export default ConversationSettingsTab;
