import { useState, useCallback, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { MoreVert } from '@mui/icons-material';
import { Skeleton, IconButton, Menu, MenuItem } from '@mui/material';
import { useMakeTestId } from '@serenityapp/components-react-web';

type SettingsControlProps = {
  conversationId: string;
  canDisplayControl?: boolean;
  isLoading: boolean;
  subtype?: string;
};

const SettingsControl = ({
  conversationId,
  canDisplayControl,
  isLoading,
  subtype,
}: SettingsControlProps) => {
  const makeTestId = useMakeTestId('SettingsControl');
  const mainTestId = makeTestId('');
  const navigate = useNavigate();

  const [conversationDetailsMenuAnchor, setConversationDetailsMenuAnchor] =
    useState<null | HTMLElement>(null);

  const openConversationDetailsMenu = Boolean(conversationDetailsMenuAnchor);

  const isAppChannel = subtype === 'app';

  const handleMenuClose = () => setConversationDetailsMenuAnchor(null);
  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setConversationDetailsMenuAnchor(event.currentTarget);
  };

  const handleSettingsClick = useCallback(() => {
    handleMenuClose();
    navigate('details/settings');
  }, [navigate]);

  const handleMembersClick = useCallback(() => {
    handleMenuClose();
    navigate('details/members');
  }, [navigate]);

  const handlePinnedMessagesClick = useCallback(() => {
    handleMenuClose();
    navigate('details/pinned-messages');
  }, [navigate]);

  const handleAboutClick = useCallback(() => {
    handleMenuClose();
    navigate('details/about');
  }, [navigate]);

  if (isLoading && canDisplayControl === undefined)
    return <Skeleton height="24px" sx={buttonSkeletonSx} variant="rectangular" width="24px" />;

  if (!canDisplayControl) return null;

  return (
    <>
      <IconButton
        aria-label="more"
        data-testid="conversation-detail"
        size="large"
        sx={moreIconSx}
        onClick={handleMenuOpen}
      >
        <MoreVert />
      </IconButton>
      <Menu
        anchorEl={conversationDetailsMenuAnchor}
        data-testid={mainTestId}
        open={openConversationDetailsMenu}
        onClose={handleMenuClose}
      >
        {isAppChannel && (
          <MenuItem key="settings" data-testid={makeTestId('About')} onClick={handleAboutClick}>
            About
          </MenuItem>
        )}
        <MenuItem
          key="all-members"
          data-testid={makeTestId('AllMembers')}
          onClick={handleMembersClick}
        >
          All members
        </MenuItem>
        <MenuItem
          key="pinned-messages"
          data-testid={makeTestId('PinnedMessages')}
          onClick={handlePinnedMessagesClick}
        >
          Pinned messages
        </MenuItem>
        <MenuItem
          key="settings"
          data-testid={makeTestId('Settings')}
          onClick={handleSettingsClick}
        >
          Settings
        </MenuItem>
      </Menu>
    </>
  );
};

const moreIconSx = {
  p: 1.5,
  ml: 1,
};

const buttonSkeletonSx = {
  m: 1.5,
};

export default SettingsControl;
