import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { SxProps, Theme, styled } from '@mui/material';

const StyledContentEditable = ({ sx }: { sx?: SxProps<Theme> }) => {
  return <StyledCE sx={[contentEditableSx, ...(Array.isArray(sx) ? sx : [sx])]} />;
};

const StyledCE = styled(ContentEditable)({});

const contentEditableSx = {
  resize: 'none',
  outline: 'none',
  border: 0,
  '& .LexicalEditorTheme__link': {
    color: 'primary.main',
    textDecoration: 'none',
  },
  '& .LexicalEditorTheme__link:hover': {
    textDecoration: 'underline',
  },
  '& .LexicalEditorTheme__paragraph': {
    margin: 0,
    lineHeight: '150%',
  },
  '& .LexicalEditorTheme__emoji': {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'default',
    backgroundSize: 'cover',
    lineHeight: 0.9,
    mx: '1px',
    display: 'inline-block',

    '& span': {
      color: 'transparent',
      caretColor: 'black',
      display: 'inline-block',
      lineHeight: 0,

      '&::selection': {
        color: 'transparent',
        bgcolor: 'rgba(150,150,150,.4)',
      },
    },
  },
};

export default StyledContentEditable;
