import * as PluginIntegrations from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { isDevelopment, print } from '@serenityapp/core';

/**
 * Configures and initializes Sentry error tracking
 */
export default function configureSentryWeb() {
  Sentry.init({
    // Log events to terminal instead of sending them to Sentry when running locally
    beforeSend: (event, hint) => {
      if (isDevelopment(process.env.REACT_APP_PROFILE_KEY || 'dev')) {
        const error = hint?.originalException ?? hint?.syntheticException ?? event;
        print(`${new Date().toLocaleString()} [SENTRY-WEB]: `, error, hint?.captureContext);
        return null;
      }
      return event;
    },
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // See https://create-react-app.dev/docs/adding-custom-environment-variables/)
    environment: process.env.REACT_APP_PROFILE_KEY,
    // String patterns are used for partial match; use RegExp for exact matches
    ignoreErrors: [
      // Benign ResizeObserver errors getting logged from Virtuoso https://github.com/petyosi/react-virtuoso/issues/254
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // Connection related errors
      /^The request timed out\.$/,
      /^Network error\.?$/,
      /^NetworkError when attempting to fetch resource\.$/,
      /^Failed to fetch$/,
      /^The network connection was lost\.$/,
      /^Network request failed$/,
      /^Load failed$/,
      // Recoverable API (AppSync) error responses and token-refresh related errors
      'Token has expired.',
      /^Connection failed:.*UnauthorizedException$/,
      'Valid authorization header not provided.',
      'Response not successful: Received status code 401',
      'Invalid session for the user, session is expired.',
      // Subscriptions-related errors
      /^Connection closed$/,
      /^Connection failed: Connection handshake error$/,
      /^Timeout disconnect$/,
      /^Subscription timeout/,
      // Auth-related user errors
      /^Incorrect username or password\.$/,
      // Forbidden error returned by the GraphQL API (e.g. when trying to access from a non-allowlisted IP address)
      /403_FORBIDDEN$/,
      /403_FORBIDDEN_NA$/,
      // Errors thrown by web crawlers
      /^TypeError: Cannot read properties of undefined \(reading '(config|loaded|mvpConfig|get|ns|variables|outputCurrentConfiguration)'\)$/,
      /^ReferenceError: (apstagLOADED|ezDenty|omid|__IASOmidVerificationClient|OMID|moat_px|IMUID) is not defined$/,
    ],
    integrations: [
      // Performance and transactions tracking
      new Sentry.BrowserTracing({
        // Configures router instrumentation that creates transactions on pageload and navigation
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
        /**
         * Automatically create spans for outgoing HTTP requests to matching URLs,
         * amazonaws.com matches both Kinesis and Cognito
         */
        tracePropagationTargets: ['localhost', /^\//, /(.*)amazonaws\.com/],
        /**
         * Avoid auto-creating spans for requests to AppSync; we're creating them manually to capture
         * more information (query name, input parameters, ...) since Sentry only captures the
         * method and URL in auto-created spans for HTTP requests
         */
        shouldCreateSpanForRequest: (url) => {
          const isGraphQLRequest = /appsync-api\.us-east-2\.amazonaws\.com/.test(url);
          return isGraphQLRequest === false;
        },
      }),
      // Attaches non-native error properties if present
      new PluginIntegrations.ExtraErrorData(),
      // De-dupes duplicate events fired in a quick succession
      new PluginIntegrations.Dedupe(),
      // Logs entire Sentry event and transaction objects to console
      // new PluginIntegrations.Debug(),
      // Enables Session Replay
      new Sentry.Replay({
        // SVGs are blocked by default, but we only use it for icons and we want to see those in the replay
        blockAllMedia: false,
        // [data-sentry-block] is the default selector, but we also want to block images, videos and (potentially) audio
        blockSelector: '[data-sentry-block], img, video, picture, audio',
        // Capture request to the GraphQL API in the replay
        networkDetailAllowUrls: [/appsync-api\.us-east-2\.amazonaws.com\/graphql/],
        // Capture these headers in the request replay
        networkRequestHeaders: ['x-timezone'],
      }),
    ],
    // normalization depth for nested objects in additional data
    normalizeDepth: 5,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    /**
     * Set tracesSampleRate to 1.0 to capture 100%
     * of transactions for performance monitoring.
     * Higher sample rate comes with a performance overhead.
     * Sample rate of 0 disables the sampling altogether.
     */
    tracesSampleRate: isDevelopment(process.env.REACT_APP_PROFILE_KEY || 'dev') ? undefined : 0.2,
    /**
     * Sample rate for session-long replays
     * The session starts when the app is loaded and ends either when it's closed,
     * inactive for 5 minutes or open for 60 minutes.
     */
    replaysSessionSampleRate: 0.08,
    /**
     * Sample rate for error replays
     * Sentry will keep a replay of the last 60 seconds buffered in memory
     * and will attach it to the report if an error happens.
     * This is independent of the session-long replay configured above.
     */
    replaysOnErrorSampleRate: 1.0,
  });
}

/**
 * Works identically to React Router's createBrowserRouter,
 * but is wrapped by Sentry to automatically track app's performance on route change
 */
export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
