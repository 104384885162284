import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ipAllowListPolicyRemove, snackAdd } from '@serenityapp/redux-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

type DeleteIpAllowListPolicyDialogProps = {
  id: string;
  orgId: string;
  onSuccess?: () => void;
  onFailed?: () => void;
  onClose: () => void;
  title: string;
  dataTestId?: string;
};

const DeleteIpAllowListPolicyDialog = ({
  id,
  orgId,
  onSuccess,
  onFailed,
  onClose,
  title,
  dataTestId,
}: DeleteIpAllowListPolicyDialogProps) => {
  const makeTestId = useMakeTestId('DeleteIpAllowListPolicyDialog', dataTestId);
  const DeleteDialog = makeTestId('');

  const dispatch = useDispatch();

  const [isDisabling, setIsDisabling] = useState(false);

  const onDeleteSuccess = () => {
    setIsDisabling(false);
    dispatch(
      snackAdd({ message: 'IP allow list policy successfully deleted.', type: 'success' }),
    );
    onSuccess?.();
  };

  const onDeleteFailed = () => {
    setIsDisabling(false);
    dispatch(snackAdd({ message: 'Error deleting ip allow list policy policy.', type: 'error' }));
    onFailed?.();
  };

  const handleDeleteConfirmClick = () => {
    setIsDisabling(true);
    dispatch(
      ipAllowListPolicyRemove({
        onFailed: onDeleteFailed,
        onSuccess: onDeleteSuccess,
        variables: {
          input: {
            ipAllowListPolicy: {
              id: id,
            },
            organization: {
              id: orgId,
            },
          },
        },
      }),
    );
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open
      data-testid={DeleteDialog}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Delete {title} policy</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to permanently delete this IP Allow List policy '{title}'?
        </Typography>
        <Box sx={{ height: 16 }} />
        <Typography>
          This action cannot be undone. Once deleted, these IP ranges will no longer have access
          permissions through this policy.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} disabled={isDisabling} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('delete')}
          loading={isDisabling}
          variant="contained"
          onClick={handleDeleteConfirmClick}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteIpAllowListPolicyDialog;
