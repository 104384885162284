import {
  getViewerHasAgreedToTerms,
  viewerSetCurrentMode,
  getViewerState,
  useViewerBaseInformation,
} from '@serenityapp/redux-store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation, useMatch } from 'react-router-dom';

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const hasAgreedToTerms = useSelector(getViewerHasAgreedToTerms);
  const { signedIn } = useSelector(getViewerState);
  const location = useLocation();
  const isOnAdminRoute = useMatch('/admin/*');
  const isOnMessagingRoute = useMatch('/client/*');

  const viewerBaseInformation = useViewerBaseInformation();
  const viewerPermissions = viewerBaseInformation.data.permissions;

  useEffect(() => {
    if (isOnMessagingRoute) dispatch(viewerSetCurrentMode({ mode: 'messaging' }));
  }, [dispatch, isOnMessagingRoute]);

  useEffect(() => {
    if (isOnAdminRoute) dispatch(viewerSetCurrentMode({ mode: 'admin' }));
  }, [dispatch, isOnAdminRoute]);

  if (!signedIn) return <Navigate state={{ from: location?.pathname }} to="/sign-in" />;

  if (!hasAgreedToTerms)
    return <Navigate state={{ from: location?.pathname }} to="/agree-to-terms" />;

  // If user came into route s/he does not have permission for redirect them back to root.
  if (
    (location.pathname.startsWith('/serenity-admin') &&
      !viewerPermissions.canDisplaySystemAdmin) ||
    (location.pathname.startsWith('/client') && !viewerPermissions.canDisplayMessaging) ||
    (location.pathname.startsWith('/admin') && !viewerPermissions.canDisplayAdmin)
  )
    return <Navigate to="/" />;

  return <Outlet />;
};

export default PrivateRoute;
