export const rootSx = {
  height: 1,
  bgcolor: 'background.paper',
};

export const readReceiptsContainer = {
  pl: 8,
  pb: 1,
};

export const headerPlaceholderSx = {
  height: 24,
};

export const footerPlaceholderSx = {
  height: {
    xs: 24,
    sm: 8,
  },
};

export const footerLoaderSx = {
  height: {
    xs: 24,
    sm: 8,
  },
  width: 1,
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
};

export const footerLoaderAbsoluteBoxSx = {
  position: 'absolute',
  bottom: 0,
};
