import { useEffect, useState } from 'react';
import Dot from './Dot';
import Box from '@mui/material/Box';

const LoadingAnimation = () => {
  const [activeDot, setActiveDot] = useState(0);

  useEffect(() => {
    // Set an interval to change the active dot
    const interval = setInterval(() => {
      // Update the active dot, cycling through 0, 1, 2 (3 dots)
      setActiveDot((prevActiveDot) => (prevActiveDot + 1) % 3);
    }, 400);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {/* Map through an array of 3 elements to create 3 Dot components */}
      {[...Array(3)].map((_, index) => (
        <Dot key={index} active={index === activeDot} />
      ))}
    </Box>
  );
};

export default LoadingAnimation;
