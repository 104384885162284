import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { icons } from '@serenityapp/components-react-web';

const { OutlookCalendar, GoogleCalendar } = icons;

type CalendarProviderDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onConnect?: () => void;
};

const CalendarProviderDialog = ({ isOpen, onClose, onConnect }: CalendarProviderDialogProps) => {
  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle>Calendar Provider</DialogTitle>
      <DialogContent sx={dialogContentSx}>
        <Typography variant="body1">Select your preferred calendar provider</Typography>
        <RadioGroup sx={radioGroupSx}>
          <FormControlLabel
            checked
            control={<Radio />}
            label={
              <ListItem dense disableGutters disablePadding>
                <ListItemIcon sx={listItemIconSx}>
                  <GoogleCalendar />
                </ListItemIcon>
                <ListItemText>Google Calendar</ListItemText>
              </ListItem>
            }
            value="google"
          />
          <FormControlLabel
            disabled
            control={<Radio />}
            label={
              <ListItem dense disableGutters disablePadding>
                <ListItemIcon sx={listItemIconSx}>
                  <OutlookCalendar />
                </ListItemIcon>
                <ListItemText>Microsoft Outlook Calendar (coming soon)</ListItemText>
              </ListItem>
            }
            value="outlook"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" onClick={onConnect}>
          Connect
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const dialogContentSx = {
  pt: 1,
  pb: 1,
};

const listItemIconSx = {
  minWidth: 40,
};

const radioGroupSx = {
  pl: 1,
};

export default CalendarProviderDialog;
