import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { AppLayout } from '@serenityapp/components-react-web';

import { MainPage as CommonMainPage } from '../../../common';
import AppBarContent from '../../../common/AppBarContent';

const MainPage = () => (
  <CommonMainPage>
    <AppLayout>
      <AppLayout.AppBar>
        <AppBarContent title="Alexa for Senior Living" />
      </AppLayout.AppBar>
      <AppLayout.Main>
        <Outlet />
      </AppLayout.Main>
    </AppLayout>
  </CommonMainPage>
);

export default memo(MainPage);
