import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  Chip,
  Skeleton,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import {
  Add,
  MoreVert,
  Edit as EditIcon,
  NotInterested as NotInterestedIcon,
  Delete as DeleteIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

import {
  View,
  BasicTable,
  BasicTableHeadCellProps,
  BasicTableRowDataProps,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import { useIpAllowListPolicies, ipAllowListPoliciesTransformer } from '@serenityapp/redux-store';

import { SectionHeader } from '../../components';
import { activeChipSx, statusCellSx, tableSx } from './ipAllowListPoliciesTableStyles';
import DisableIpAllowListPolicyDialog from '../../components/DisableIpAllowListPolicyDialog';
import { useCurrentUser } from '../../../common/hooks';
import DeleteIpAllowListPolicyDialog from '../../components/DeleteIpAllowListPolicyDialog';
import EnableIpAllowListPolicyDialog from '../../components/EnableIpAllowListPolicyDialog';

const IpAllowListPoliciesTable = () => {
  const makeTestId = useMakeTestId('IpAllowListPoliciesTable');
  const mainTestId = makeTestId('');

  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const { data: ipAllowListPoliciesData, isLoading: isLoadingIpAllowListPolicies } =
    useIpAllowListPolicies({ variables: {} });
  const ipAllowListPoliciesRows = ipAllowListPoliciesTransformer(
    ipAllowListPoliciesData,
    isLoadingIpAllowListPolicies,
  );

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  const [isEnableDialogOpen, setIsEnableDialogOpen] = useState(false);
  const [ipAllowListPoliciesActionsMenuAnchorEl, setIpAllowListPoliciesActionsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [currentlySelectedRowIpAllowListPolicies, setCurrentlySelectedRowIpAllowListPolicies] =
    useState<null | BasicTableRowDataProps>(null);

  const actionsCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setIpAllowListPoliciesActionsMenuAnchorEl(event.currentTarget);
      setCurrentlySelectedRowIpAllowListPolicies(row);
    };

    return (
      <TableCell
        key={`user-actions-ip-allow-list-policy-${row?.id}`}
        align="right"
        data-testid={dataTestId}
        padding="none"
      >
        <IconButton
          aria-label="Open menu"
          disabled={isLoadingIpAllowListPolicies}
          onClick={onClick}
        >
          <MoreVert />
        </IconButton>
      </TableCell>
    );
  };

  const statusCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    if (!row)
      return (
        <TableCell key="status-ip-allow-list-policy-skeleton">
          <Skeleton />
        </TableCell>
      );

    return (
      <TableCell
        key={`status-ip-allow-list-policy-${row.id}`}
        data-testid={dataTestId}
        padding="none"
        sx={statusCellSx}
      >
        {row?.disabled ? <Chip label="Disabled" /> : <Chip label="Active" sx={activeChipSx} />}
      </TableCell>
    );
  };

  const ipAllowListPoliciesHeadCells: BasicTableHeadCellProps[] = [
    { id: 'name', label: 'Policy name' },
    { id: 'disabled', label: 'Status', cellRenderer: statusCellRenderer },
    {
      id: 'actions',
      label: 'Actions',
      sort: false,
      align: 'right',
      cellRenderer: actionsCellRenderer,
    },
  ];

  const handleIpAllowListPoliciesRowClick = (id: string) =>
    navigate(`ip-allow-list-policy/${id}`);

  const handleIpAllowListPoliciesActionsMenuClose = () => {
    setIpAllowListPoliciesActionsMenuAnchorEl(null);
  };

  const handleIpAllowListPolicyCreateButtonClick = () => {
    navigate('ip-allow-list-policy/create');
  };

  const handleIpAllowListPolicyEditActionClick = () => {
    navigate(`ip-allow-list-policy/${currentlySelectedRowIpAllowListPolicies.id}`);
    handleIpAllowListPoliciesActionsMenuClose();
  };

  const handleIpAllowListPolicyDeleteActionClick = () => {
    setIsDeleteDialogOpen(true);
    handleIpAllowListPoliciesActionsMenuClose();
  };

  const handleIpAllowListPolicyDisableActionClick = () => {
    setIsDisableDialogOpen(true);
    handleIpAllowListPoliciesActionsMenuClose();
  };

  const handleIpAllowListPolicyEnableActionClick = () => {
    setIsEnableDialogOpen(true);
    handleIpAllowListPoliciesActionsMenuClose();
  };

  const onDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const onDisableDialogClose = () => setIsDisableDialogOpen(false);

  const onEnableDialogClose = () => setIsEnableDialogOpen(false);

  const ipAllowListPoliciesAddButton = (
    <Button
      disabled={isLoadingIpAllowListPolicies}
      size="medium"
      startIcon={<Add />}
      variant="contained"
      onClick={handleIpAllowListPolicyCreateButtonClick}
    >
      Add
    </Button>
  );

  const ipAllowListPoliciesActionsMenu = (
    <Menu
      open
      anchorEl={ipAllowListPoliciesActionsMenuAnchorEl}
      onClose={handleIpAllowListPoliciesActionsMenuClose}
    >
      <MenuItem
        data-testid={makeTestId('edit-action')}
        onClick={handleIpAllowListPolicyEditActionClick}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>Edit</ListItemText>
      </MenuItem>
      {!currentlySelectedRowIpAllowListPolicies?.disabled && (
        <MenuItem
          data-testid={makeTestId('disable-action')}
          onClick={handleIpAllowListPolicyDisableActionClick}
        >
          <ListItemIcon>
            <NotInterestedIcon />
          </ListItemIcon>
          <ListItemText>Disable</ListItemText>
        </MenuItem>
      )}
      {currentlySelectedRowIpAllowListPolicies?.disabled && (
        <MenuItem
          data-testid={makeTestId('enable-action')}
          onClick={handleIpAllowListPolicyEnableActionClick}
        >
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText>Enable</ListItemText>
        </MenuItem>
      )}
      <MenuItem
        data-testid={makeTestId('delete-action')}
        onClick={handleIpAllowListPolicyDeleteActionClick}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <ListItemText>Delete</ListItemText>
      </MenuItem>
    </Menu>
  );

  const deleteDialog = currentlySelectedRowIpAllowListPolicies && currentUser && (
    <DeleteIpAllowListPolicyDialog
      dataTestId={mainTestId}
      id={currentlySelectedRowIpAllowListPolicies.id}
      orgId={currentUser.orgId}
      title={currentlySelectedRowIpAllowListPolicies.name}
      onClose={onDeleteDialogClose}
      onSuccess={onDeleteDialogClose}
    />
  );

  const disableDialog = currentlySelectedRowIpAllowListPolicies && currentUser && (
    <DisableIpAllowListPolicyDialog
      dataTestId={mainTestId}
      id={currentlySelectedRowIpAllowListPolicies.id}
      orgId={currentUser.orgId}
      title={currentlySelectedRowIpAllowListPolicies.name}
      onClose={onDisableDialogClose}
    />
  );

  const enableDialog = currentlySelectedRowIpAllowListPolicies && currentUser && (
    <EnableIpAllowListPolicyDialog
      dataTestId={mainTestId}
      id={currentlySelectedRowIpAllowListPolicies.id}
      orgId={currentUser.orgId}
      title={currentlySelectedRowIpAllowListPolicies.name}
      onClose={onEnableDialogClose}
    />
  );

  return (
    <View data-testid={mainTestId}>
      {isDeleteDialogOpen && deleteDialog}
      {isDisableDialogOpen && disableDialog}
      {isEnableDialogOpen && enableDialog}
      {ipAllowListPoliciesActionsMenuAnchorEl && ipAllowListPoliciesActionsMenu}
      <SectionHeader
        action={ipAllowListPoliciesAddButton}
        description="IP allow listing gives you extra control to define specific IP address ranges users must have to access Serenity. If no allow listing policies are specified, users can access Serenity from any IP address."
        title="IP Allow list"
      />
      <BasicTable
        handleRowClick={handleIpAllowListPoliciesRowClick}
        headCells={ipAllowListPoliciesHeadCells}
        rows={ipAllowListPoliciesRows}
        showSkeleton={isLoadingIpAllowListPolicies}
        skeletonRowsCount={2}
        sx={tableSx}
      />
      {!ipAllowListPoliciesRows.length && !isLoadingIpAllowListPolicies && (
        <Typography data-testid={makeTestId('empty-state')} sx={{ m: 2 }} variant="body1">
          No IP Allow policies found.
        </Typography>
      )}
    </View>
  );
};

export default IpAllowListPoliciesTable;
