import { AttachFile } from '@mui/icons-material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { IconButton, Tooltip } from '@mui/material';
import { OPEN_FILES_COMMAND } from '../../lexical';

const AttachmentButton = () => {
  const [editor] = useLexicalComposerContext();

  const handleAttachFilesPress = () => {
    editor.dispatchCommand(OPEN_FILES_COMMAND, undefined);
  };

  return (
    <Tooltip arrow title="Click to choose a file">
      <IconButton
        aria-label="attach file"
        data-testid="Attachment"
        size="small"
        onClick={handleAttachFilesPress}
      >
        <AttachFile fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

export default AttachmentButton;
