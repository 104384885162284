import { createContext, useContext } from 'react';
import { IAnalytics, NullAnalytics } from '@serenityapp/core';

export type IGlobalContext = {
  analytics: IAnalytics;
};

export const GlobalContext = createContext<IGlobalContext | undefined>(undefined);

export const useAnalytics = () => {
  const context = useContext(GlobalContext);
  if (!context) return new NullAnalytics();
  return context.analytics;
};
