import {
  conversationDataTransformer,
  conversationDetailTransformer,
  conversationMembersTransformer,
  getVariablesForConversationDetailMessagingQuery,
  useConversationDetailMessaging,
  useViewerMemberOfConversations,
} from '@serenityapp/redux-store';

/**
 * Retrieves conversation data and member data for a given conversation ID.
 * It combines the values returned by useConversationDetailMessaging and useViewerMemberOfConversations
 * hooks. If both the conversationDetail and memberOfConversationsData are falsy,
 * the function will return undefined conversation data with the loading state.
 * Otherwise, it merges the two data sets together and returns the conversation data,
 * conversation member data, and loading state.
 **/
export default function useConversationData(id: string) {
  // call useConversationDetailMessaging hook and transform it's data
  const { data, isLoading } = useConversationDetailMessaging({
    variables: getVariablesForConversationDetailMessagingQuery(id),
  });
  const conversationDetail = conversationDetailTransformer(data);
  const conversationMembersData = conversationMembersTransformer(data);

  // call useViewerMemberOfConversations as a fallback
  const { data: viewerMemberOfConversationsData } = useViewerMemberOfConversations();
  const memberOfConversationsData = conversationDataTransformer(
    viewerMemberOfConversationsData,
    id,
  );

  if (!conversationDetail && !memberOfConversationsData)
    return {
      conversationData: undefined,
      isLoading,
    };

  // merge the two objects together
  const conversationData = {
    ...(memberOfConversationsData ?? {}),
    ...(conversationDetail ?? {}),
  };

  return {
    conversationData,
    conversationMembersData,
    isLoading,
  };
}
