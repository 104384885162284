import * as R from 'ramda';
import { PushPin } from '@mui/icons-material';
import { Box } from '@mui/material';

import { LoadingMessages, EmptyStateView } from '@serenityapp/components-react-web';
import { MessagesListItemProps } from '@serenityapp/components-react-common';

import usePinnedMessages from '../hooks/usePinnedMessages';
import { MessageItem } from '../ConversationPage/MessageItem';
import { useIsFeatureEnabled } from '@serenityapp/redux-store';

type ConversationPinnedMessagesTabProps = {
  conversationId: string;
};

const ConversationPinnedMessagesTab = ({
  conversationId,
}: ConversationPinnedMessagesTabProps) => {
  const { pinnedMessages, isLoading } = usePinnedMessages(conversationId);
  const shouldDeprecateUsernames = useIsFeatureEnabled('removeUsernames');
  const isEmpty = R.isEmpty(pinnedMessages);

  if (isLoading) return <LoadingMessages />;

  if (isEmpty)
    return (
      <Box sx={emptyTabSx}>
        <EmptyStateView
          description="No messages have been pinned yet"
          Icon={PushPin}
          title="Pin a message"
        />
      </Box>
    );

  return (
    <Box data-testid="pinned-messages-panel">
      {pinnedMessages.map((message: MessagesListItemProps) => (
        <MessageItem
          key={message.id}
          dense
          pinned
          showPinnedTag={false}
          showToolsMenu={false}
          {...message}
          shouldDeprecateUsernames={shouldDeprecateUsernames}
        />
      ))}
    </Box>
  );
};

const emptyTabSx = {
  display: 'flex',
  height: 1,
};

export default ConversationPinnedMessagesTab;
