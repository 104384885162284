import { SelectorItemProps } from '@serenityapp/components-react-common';
import { ServiceLevel } from '@serenityapp/core';

const formatServiceLevel = (serviceLevel: string) =>
  serviceLevel.charAt(0) + serviceLevel.slice(1).toLocaleLowerCase().replace(/_/g, ' ');

export const transformServiceLevelsToSelectorItemProps = (serviceLevels?: string[]) => {
  if (!serviceLevels) return [];

  const serviceLevelsAsSelectorItemProps = serviceLevels.reduce(
    (acc: SelectorItemProps[], serviceLevel: string) => {
      // We want to hide unknown option since it is deprecated service level
      if (serviceLevel !== ServiceLevel.UNKNOWN && serviceLevel !== ServiceLevel.NONE) {
        const selectorItem = {
          id: serviceLevel,
          label: formatServiceLevel(serviceLevel),
        };

        return [...acc, selectorItem];
      }

      return acc;
    },
    [],
  );

  return serviceLevelsAsSelectorItemProps;
};
