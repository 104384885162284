import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { Schema } from '@serenityapp/domain';
import { useAlexaUnitDig } from '@serenityapp/redux-store';
import JsonView from '@uiw/react-json-view';
import { useState } from 'react';

type RoomDigDialogProps = {
  alexaUnitId: string;
  roomName: string;
  onClose: () => void;
};

export default function RoomDigDialog({
  alexaUnitId,
  roomName,
  onClose,
}: RoomDigDialogProps): JSX.Element {
  const [digResult, setDigResult] = useState<Schema.AlexaUnit.Dig.MutationResult | null>(null);
  const { doMutation: digAlexaUnit, isFetching } = useAlexaUnitDig();

  const onConfirmDig = (): void => {
    const onSuccess = (result: Schema.AlexaUnit.Dig.MutationResult) => {
      setDigResult(result);
    };
    const onFailure = (error: SerializedError) => {
      setDigResult(null);
    };
    digAlexaUnit({ input: { id: alexaUnitId } }, onSuccess, onFailure);
  };

  return (
    <Dialog fullWidth open onClose={onClose}>
      <DialogTitle>Dig Diagnostic</DialogTitle>
      <DialogContent>
        <Typography>Dig Room {roomName}</Typography>
        {digResult && <JsonView displayDataTypes={false} value={digResult} />}
      </DialogContent>
      <DialogActions>
        <Button disabled={isFetching} variant="contained" onClick={onConfirmDig}>
          Run Dig
        </Button>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
