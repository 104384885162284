import { getRequiredEnvironmentVar, GOOGLE_CALENDAR_ACCESS_SCOPES } from '@serenityapp/core';

export const BASE_OAUTH2_URL = 'https://accounts.google.com/o/oauth2/v2/auth';
export const GOOGLE_CALENDAR_AUTH_ROUTE = '/admin/apps/google-calendar-auth';

/**
 * A fuction that builds up Google OAuth2 URL and navigates to it
 *
 * @returns {void}
 **/
export const redirectToGoogleCalendarAuthorization = () => {
  // Base auth URL
  const authorizationURL = new URL(BASE_OAUTH2_URL);

  // Env variables
  const clientID = getRequiredEnvironmentVar('REACT_APP_GOOGLE_OAUTH2_CONFIG_CLIENT_ID');

  // Redirect URL
  const redirectURI = window.location.origin + GOOGLE_CALENDAR_AUTH_ROUTE;

  // Query parameters
  authorizationURL.searchParams.append('redirect_uri', redirectURI);
  authorizationURL.searchParams.append('scope', GOOGLE_CALENDAR_ACCESS_SCOPES.join(' '));
  authorizationURL.searchParams.append('response_type', 'code');
  authorizationURL.searchParams.append('client_id', clientID);
  authorizationURL.searchParams.append('access_type', 'offline');

  // Redirect
  window.location.replace(authorizationURL);
};
