import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

type SectionHeaderProps = {
  title: string;
  description?: string;
  action?: ReactNode;
};

const SectionHeader = ({ title, description, action }: SectionHeaderProps) => (
  <Box sx={rootSx}>
    <Box sx={mainSx}>
      <Typography variant="subtitle1m">{title}</Typography>
      <Typography color="textSecondary" variant="body2">
        {description}
      </Typography>
    </Box>
    <Box sx={actionSx}>{action}</Box>
  </Box>
);

const actionSx = {
  display: 'flex',
  alignItems: 'center',
};

const rootSx = {
  display: 'flex',
  justifyContent: 'space-between',
};

const mainSx = {
  mb: 2,
};

export default SectionHeader;
