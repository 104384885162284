import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

// This plugin is used to focus LexicalEditor when click event is fired inside MessageEntry
export default function FocusPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const handleClick = () => {
      editor.focus();
    };

    const messageEntryInput = document.getElementById('messageEntryInput');
    const messageEntryToolbarEmpty = document.getElementById('messageEntryToolbarEmpty');
    messageEntryInput?.addEventListener('click', handleClick);
    messageEntryToolbarEmpty?.addEventListener('click', handleClick);

    return () => {
      messageEntryInput?.removeEventListener('click', handleClick);
      messageEntryToolbarEmpty?.removeEventListener('click', handleClick);
    };
  }, [editor]);

  return null;
}
