import { useEffect } from 'react';

import { $getRoot, LexicalNode, $isTextNode, $isElementNode, $isLineBreakNode } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import { default as EmojiNode, $isEmojiNode } from '../nodes/EmojiNode';
import { LARGE_EMOJI_SIZE, REGULAR_EMOJI_SIZE } from './utils';

/**
 * A function that takes root node of the editor and recursively goes thru its state and checks if
 * all nodes in state are emoji nodes.
 * @param rootNode LexicalNode a root node in editor state
 * @returns boolean
 */
export const areAllNodesEmojis = (rootNode: LexicalNode): boolean => {
  let child: LexicalNode | null = rootNode.getFirstChild();
  while (child !== null) {
    if ($isTextNode(child) && !$isEmojiNode(child)) {
      // If there is a possibility of user attempting to write emoji we need to treat that text
      // node as emoji node. We use regex to match beginning of the message to check if user is
      // starting a string with column which might result in user writing shortcode or emoticon.
      const regexForEmojiStartString = /^[\s]?(:[^ \s:]*)$/g;
      const regexMatch = child.getTextContent().match(regexForEmojiStartString);
      const isPossibleEmojiAtBeginning = regexMatch !== null && regexMatch.length > 0;

      if (child.getTextContent().trim() !== '' && !isPossibleEmojiAtBeginning) {
        return false;
      }
    } else if (!($isEmojiNode(child) || $isLineBreakNode(child))) {
      if ($isElementNode(child)) {
        const areSubChildrenEmojis = areAllNodesEmojis(child);
        if (!areSubChildrenEmojis) return false;
      } else {
        return false;
      }
    }
    child = child.getNextSibling();
  }
  return true;
};

/**
 * A function that takes root node of the editor and recursively goes thru its state and finds all
 * emoji nodes and returns them.
 * @param rootNode LexicalNode a root node in editor state
 * @returns Array of emoji nodes found within the root node
 */
export const getAllEmojiNodes = (rootNode: LexicalNode): Array<EmojiNode> => {
  const emojiNodes: Array<EmojiNode> = [];
  let child: LexicalNode | null = rootNode.getFirstChild();
  while (child !== null) {
    if ($isEmojiNode(child)) {
      emojiNodes.push(child);
    }
    if ($isElementNode(child)) {
      const subChildrenNodes = getAllEmojiNodes(child);
      emojiNodes.push(...subChildrenNodes);
    }
    child = child.getNextSibling();
  }
  return emojiNodes;
};

/**
 * A plugin that is used to change size of emoji if needed while the editor's state is
 * being updated.
 * @returns null
 */
export default function EmojiSizePlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext();

  /**
   * A useEffect hook that defines and returns a listener for editor's state updates.
   * Every time something changes in the state we check if there is a need to update emoji sizes.
   * Emoji size rules:
   *   - The emojis should be assigned large size when editor state contains only emoji nodes* in
   *     its state.
   *   - The emojis should be assigned regular size when editor state contains emojis along with
   *     other visible nodes. (non-visible nodes are for example text nodes with empty space)
   *   - * If editor contains emojis along with empty text nodes we keep emojis large.
   */
  useEffect(() => {
    if (!editor.hasNodes([EmojiNode])) {
      throw new Error('EmojiSizePlugin: EmojiNode not registered on editor');
    }
    if (!editor.isEditable()) {
      throw new Error(
        'EmojiSizePlugin: This plugin should be used only in editable editors since it is listening for updates in editor',
      );
    }

    return editor.registerUpdateListener(
      ({ editorState, dirtyElements, dirtyLeaves, prevEditorState, tags }) => {
        if (
          (dirtyElements.size === 0 && dirtyLeaves.size === 0) ||
          prevEditorState.isEmpty() ||
          tags.has('history-merge')
        ) {
          return;
        }

        editorState.read(() => {
          const rootNode = $getRoot();
          const allEmojiNodes = getAllEmojiNodes(rootNode);
          const areAllNodesEmojiNodes = areAllNodesEmojis(rootNode);

          // Check if editor contains only emoji nodes.
          // If it does, check if all emojis are large.
          // If not all emojis are large, update their size to large.
          if (areAllNodesEmojiNodes) {
            const regularSizeEmojiNodes = allEmojiNodes.filter(
              (emojiNode) => emojiNode.getSize() === REGULAR_EMOJI_SIZE,
            );
            const areAllEmojisLargeSizeEmojis = regularSizeEmojiNodes.length === 0;

            if (!areAllEmojisLargeSizeEmojis) {
              editor.update(
                () => {
                  regularSizeEmojiNodes.forEach((emojiNode) =>
                    emojiNode.setSize(LARGE_EMOJI_SIZE),
                  );
                },
                { tag: 'history-merge' },
              );
            }
            // If editor does not contain emoji only, than we should check if there is any large emoji
            // left that needs its size to be updated to regular.
          } else {
            const largeSizeEmojiNodes = allEmojiNodes.filter(
              (emojiNode) => emojiNode.getSize() === LARGE_EMOJI_SIZE,
            );
            const areSomeEmojisLargeSizeEmojis = largeSizeEmojiNodes.length > 0;

            if (areSomeEmojisLargeSizeEmojis) {
              editor.update(
                () => {
                  largeSizeEmojiNodes.forEach((emojiNode) =>
                    emojiNode.setSize(REGULAR_EMOJI_SIZE),
                  );
                },
                { tag: 'history-merge' },
              );
            }
          }
        });
      },
    );
  }, [editor]);

  return null;
}
