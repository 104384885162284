import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import { PermissionValue } from '@serenityapp/core';
import { IdFn } from '@serenityapp/core-id';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { getCurrentUser, getIsIAMGroupAdding, iamGroupAdd } from '@serenityapp/redux-store';
import { GroupCreateEditFormValues } from '@serenityapp/components-react-common';
import { PermissionFieldInput, AbstractUserProps, permissionNames } from '@serenityapp/domain';

import { GroupCreateEditForm } from './components';
import { useNavigate } from 'react-router-dom';

const GroupCreateDrawer = () => {
  const makeTestId = useMakeTestId('GroupCreateDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const currentUser: AbstractUserProps | undefined = useSelector(getCurrentUser, R.equals);
  const isGroupAdding: boolean = useSelector(getIsIAMGroupAdding);

  const goBack = () => navigate('..');

  const handleFormSubmit = ({
    name,
    description,
    staff,
    contacts,
    groupType,
    ...permissionValues
  }: GroupCreateEditFormValues) => {
    const permissions: ReadonlyArray<PermissionFieldInput> = permissionNames.map(
      (permissionName: string) => ({
        name: permissionName,
        value:
          (permissionValues as Record<string, PermissionValue | undefined>)[permissionName] ??
          PermissionValue.INDETERMINATE,
      }),
    );
    const mergedUsers = [...staff, ...contacts];
    const users = R.pluck('id', mergedUsers);

    const newGroupID = IdFn.new();

    const groupToAdd = {
      name,
      users,
      description,
      id: newGroupID,
      orgId: currentUser?.orgId,
      permissionSet: {
        name: 'iamgroup',
        containerId: newGroupID,
        containerType: 'iamgroup',
        orgId: currentUser?.orgId,
        permissions,
      },
    };

    dispatch(
      iamGroupAdd({
        group: groupToAdd,
        onFailed: goBack,
        onSuccess: goBack,
      }),
    );
  };

  return (
    <GroupCreateEditForm
      dataTestId={makeTestId('')}
      handleFormClose={goBack}
      handleFormSubmit={handleFormSubmit}
      isSaving={isGroupAdding}
      title="Create new group"
    />
  );
};

export default GroupCreateDrawer;
