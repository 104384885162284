import { Forum } from '@mui/icons-material';
import { Box } from '@mui/material';
import { EmptyStateView } from '@serenityapp/components-react-web';

const MessagesEmptyView = () => (
  <Box sx={rootSx}>
    <EmptyStateView
      description="There are no messages yet"
      Icon={Forum}
      title="Start the conversation"
    />
  </Box>
);

const rootSx = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center',
  // 48px and 32px are sums of the heights of the virtuoso header and the virtuoso footer at different breakpoints
  height: {
    xs: `calc(100% - 48px)`,
    sm: `calc(100% - 32px)`,
  },
};

export default MessagesEmptyView;
