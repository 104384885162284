export const rootSx = {
  position: 'absolute',
  right: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  zIndex: 10,
  width: '100%',
  bgcolor: 'transparent',
};

export const positionUpSx = {
  top: 10,
};

export const positionDownSx = {
  bottom: 10,
};

export const arrowSx = {
  color: 'text.light',
  mr: 0.5,
};

export const iconButtonSx = {
  color: 'background.paper',
  p: 0,
};

export const stickerSx = {
  display: 'flex',
  bgcolor: 'error.dark',
  borderRadius: 4,
  py: 0.25,
  px: 1,
  alignItems: 'center',
  cursor: 'pointer',
};

export const messageSx = {
  color: 'text.light',
  mr: 0.5,
};
