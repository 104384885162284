import { Box, Container, Link, Paper, Typography } from '@mui/material';

import {
  useDeepLinkFromQueryParams,
  VerticalSerenityColorLogo,
} from '@serenityapp/components-react-web';

import appStoreBadge from '../../../assets/app-store-badge.svg';
import googlePlayBadge from '../../../assets/google-play-badge.png';
import {
  containerSx,
  linkSx,
  storeButtonsContainerSx,
  subtitleSx,
  titleSx,
} from './downloadLandingPageStyles';

const deepLinkBaseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://serenityapp.com'
    : 'https://dev.link.serenityapp.com';

export default function DownloadLandingPage() {
  const deepLink = useDeepLinkFromQueryParams(deepLinkBaseURL);

  return (
    <Container maxWidth="xs">
      <Paper>
        <Container sx={containerSx}>
          <VerticalSerenityColorLogo height={160} />
          <Typography sx={titleSx} variant="h5">
            Start by downloading the Serenity mobile app
          </Typography>
          <Container sx={storeButtonsContainerSx}>
            <Box
              component="a"
              href="https://apps.apple.com/us/app/serenity-engage/id1464476379"
              sx={linkSx}
              title="App Store"
            >
              <img alt="App Store logo" src={appStoreBadge} width={110} />
            </Box>
            <Box
              component="a"
              href="https://play.google.com/store/apps/details?id=com.serenityapp.engage&hl=en&gl=US"
              sx={linkSx}
              title="Google Play"
            >
              <img alt="Google Play logo" src={googlePlayBadge} width={135} />
            </Box>
          </Container>
          {deepLink && (
            <Typography sx={subtitleSx} variant="subtitle1">
              Have the app already?{' '}
              <Link href={deepLink.toString()} title="Launch Serenity">
                Open Serenity
              </Link>
            </Typography>
          )}
        </Container>
      </Paper>
    </Container>
  );
}
