export const dividerSx = {
  mb: 2.25,
};

export const headerInfoSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '11rem',
  flexGrow: 1,
};

export const listSx = {
  listStyle: 'inside',
};

export const listItemSx = {
  display: 'list-item',
};

export const boldTextSx = {
  fontWeight: 'bold',
};
