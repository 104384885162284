import {
  Box,
  Divider,
  FormControlLabel,
  FormGroup,
  ListItem,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { Drawer } from '@serenityapp/components-react-web';
import {
  listItemsWrapperSx,
  googleAccountWrapperSx,
  accountEmailSx,
  lastSyncedSx,
  controlsWrapperSx,
} from './ConnectionStatus/connectionStatusStyles';
import { sectionDividerSx } from './GoogleCalendarDrawer';

const GoogleCalendarDrawerBodySkeleton = () => (
  <>
    <Drawer.Header loading>
      <Skeleton width="60%" />
    </Drawer.Header>
    <Drawer.Content>
      <Typography display="block" variant="overline">
        <Skeleton width="40%" />
      </Typography>
      <Divider sx={sectionDividerSx} />
      <Box sx={listItemsWrapperSx}>
        <ListItem disableGutters>
          <ListItemText
            primary={
              <Box sx={googleAccountWrapperSx}>
                <Skeleton height="24px" variant="rounded" width="24px" />
                <Box sx={accountEmailSx}>
                  <Skeleton width="120px" />
                </Box>
              </Box>
            }
            secondary={<Skeleton width="30%" />}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary={
              <Box sx={lastSyncedSx}>
                <Skeleton width="50%" />
              </Box>
            }
            secondary={<Skeleton width="30%" />}
          />
        </ListItem>
      </Box>
      <Box sx={controlsWrapperSx}>
        <Skeleton height="36.5px" variant="rounded" width="136.5px" />
      </Box>
      <Typography display="block" variant="overline">
        <Skeleton width="40%" />
      </Typography>
      <Divider sx={sectionDividerSx} />
      <Box>
        <Typography>
          <Skeleton width="80%" />
        </Typography>
        <FormGroup>
          {[...new Array(5)].map((value, index) => (
            <FormControlLabel
              key={`calendar-skeleton-${index}`}
              control={<Skeleton height="24px" variant="rounded" width="24px" />}
              label={<Skeleton sx={{ m: 2, my: 1 }} width="150px" />}
              sx={{ display: 'flex', flexGrow: 1, m: 0 }}
            />
          ))}
        </FormGroup>
      </Box>
    </Drawer.Content>
  </>
);

export default GoogleCalendarDrawerBodySkeleton;
