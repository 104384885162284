import type { ReactNode } from 'react';
import MainPageSessionExpirationWarning from './MainPageSessionExpirationWarning';
import MainPageSnackbar from './MainPageSnackbar';

type MainPageProps = {
  children: ReactNode;
};

/**
 * The main page of the application.
 */
const MainPage = ({ children }: MainPageProps) => {
  return (
    <>
      {children}
      <MainPageSnackbar />
      <MainPageSessionExpirationWarning />
    </>
  );
};

MainPage.displayName = 'MainPage';

export default MainPage;
