import { lazy, Suspense } from 'react';
import { Route, Navigate, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { init } from 'emoji-mart';
import data from '@emoji-mart/data/sets/15/google.json';

import PrivateRoute from './PrivateRoute';
import RootRoute from './RootRoute';
import PublicRoute from './PublicRoute';

import { sentryCreateBrowserRouter } from '../configureSentry';

import {
  DownloadLandingPage,
  ForgotPasswordPage,
  AgreeToTermsPage,
  NeedHelpPage,
  NewPasswordPage,
  NotFoundPage,
  ResetPasswordPage,
  SignInPage,
  SignUpPage,
  AppSkeleton,
  SignOutPage,
  SSOSignInPage,
} from '../pages/common';

import AdminPage from '../pages/admin/AdminPage';
import AppsPage from '../pages/admin/AppsPage';
import BoxCloudStorageAppDrawer from '../pages/admin/BoxCloudStorageAppDrawer';
import BoxAppOAuth2 from '../pages/admin/BoxAppOAuth2';
import GeneralSettingsPage from '../pages/admin/GeneralSettingsPage';
import ChannelCreateDrawer from '../pages/admin/ChannelsPage/ChannelCreateDrawer';
import ChannelEditDrawer from '../pages/admin/ChannelsPage/ChannelEditDrawer';
import ChannelsPage from '../pages/admin/ChannelsPage';
import GroupCreateDrawer from '../pages/admin/GroupCreateDrawer';
import GroupEditDrawer from '../pages/admin/GroupEditDrawer';
import GroupsPage from '../pages/admin/GroupsPage';
import FiltersPage, { FilterCreateDrawer, FilterEditDrawer } from '../pages/admin/FiltersPage';
import LocationsPage, {
  LocationCreateDrawer,
  LocationEditDrawer,
} from '../pages/admin/LocationsPage';
import UserCreateDrawer from '../pages/admin/UserCreateDrawer';
import UsersPage from '../pages/admin/UsersPage';
import GoogleCalendarOAuth2 from '../pages/admin/GoogleCalendarOAuth2';
import GoogleCalendarDrawer from '../pages/admin/GoogleCalendarDrawer';
import CheckInAppDrawer from '../pages/admin/CheckInAppDrawer';
import RetentionPolicyEditDrawer from '../pages/admin/RetentionPolicyEditDrawer';
import RetentionPolicyCreateDrawer from '../pages/admin/RetentionPolicyCreateDrawer';
import RoomEditDrawer from '../pages/admin/RoomDrawer/RoomEditDrawer';
import RoomsPage from '../pages/admin/RoomsPage';
import ConversationDetailsDialog from '../pages/client/ConversationDetailsDialog';
import ConversationPage from '../pages/client/ConversationPage';
import FileViewerDialog from '../pages/client/FileViewerDialog';
import HomePage from '../pages/client/HomePage';
import EmptyChannelsPage from '../pages/client/EmptyChannelsPage';
import EmptyDMsPage from '../pages/client/EmptyDMsPage';
import CreateDMDialog from '../pages/client/CreateDMDialog';
import PCCAppDrawer from '../pages/admin/PCCAppDrawer';

import { OrganizationsPage } from '../pages/serenity-admin';
import OrganizationDetailPage from '../pages/serenity-admin/OrganizationDetailPage';
import { OrganizationFeaturesDrawer } from '../pages/serenity-admin/OrganizationFeaturesDrawer';
import SystemAdminPage from '../pages/serenity-admin/SystemAdminPage';
import Sandbox from '../pages/client/Sandbox/Sandbox';
import { SwitchOnFeatureFlag } from '@serenityapp/components-react-web';
import UserCreateDrawerLegacy from '../pages/admin/UserCreateDrawerLegacy';
import IpAllowListPolicyCreateDrawer from '../pages/admin/IpAllowListPolicyCreateDrawer';
import IpAllowListPolicyEditDrawer from '../pages/admin/IpAllowListPolicyEditDrawer';
import PermissionGuard from './PermissionGuard';
import { PermissionNameRecord } from '@serenityapp/domain';
import { A4SL } from '../pages/admin';
import UserEditDrawerLegacy from '../pages/admin/UserEditDrawerLegacy';
import UserEditDrawer from '../pages/admin/UserEditDrawer';
import RootErrorBoundary from './RootErrorBoundary';
import DevicesPage from '../pages/admin/DevicesPage';
import DeviceEditDrawer from '../pages/admin/DevicesPage/DeviceEditDrawer';

const ClientMainPage = lazy(() => import('../pages/client/ClientMainPage'));

const AdminMainPage = lazy(() => import('../pages/admin/AdminMainPage'));
const SerenityAdminMainPage = lazy(() => import('../pages/serenity-admin/SerenityAdminMainPage'));

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<RootErrorBoundary />}>
      <Route element={<PublicRoute />}>
        <Route element={<RootRoute />} path="/" />
        <Route element={<DownloadLandingPage />} path="download" />
        {/*
        Redirect signIn to sign-in route. This is just temporary
        change for backwards compatibility.
      */}
        <Route element={<Navigate to="/sign-in" />} path="signIn" />
        <Route element={<SignInPage />} path="sign-in" />
        <Route element={<SSOSignInPage />} path="sso-sign-in" />
        <Route element={<SignUpPage />} path="sign-up" />
        <Route element={<ForgotPasswordPage />} path="forgot-password" />
        <Route element={<ResetPasswordPage />} path="reset-password" />
        <Route element={<NeedHelpPage />} path="need-help" />
        <Route element={<NewPasswordPage />} path="new-password" />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route element={<SignOutPage />} path="sign-out" />
        <Route element={<SerenityAdminMainPage />} path="serenity-admin">
          <Route element={<OrganizationsPage />} path="organizations" />
          <Route element={<OrganizationDetailPage />} path="organizations/:id">
            <Route element={<OrganizationFeaturesDrawer />} path="features" />
          </Route>
          <Route element={<SystemAdminPage />} path="system" />
        </Route>
        <Route element={<A4SL.MainPage />} path="admin/a4sl">
          <Route index element={<A4SL.IndexPage />} />
          <Route element={<A4SL.AddressBooksPage />} path="address-books" />
        </Route>
        <Route element={<AdminMainPage />} path="admin">
          <Route index element={<AdminPage />} />
          <Route element={<AppsPage />} path="apps">
            <Route element={<BoxCloudStorageAppDrawer />} path="box" />
            <Route element={<BoxAppOAuth2 />} path="box-auth" />
            <Route element={<GoogleCalendarOAuth2 />} path="google-calendar-auth" />
            <Route element={<GoogleCalendarDrawer />} path="google-calendar" />
            <Route element={<CheckInAppDrawer />} path="check-in" />
            <Route element={<PCCAppDrawer />} path="pcc" />
          </Route>
          <Route element={<GeneralSettingsPage />} path="settings">
            <Route element={<RetentionPolicyCreateDrawer />} path="retention-policy/create" />
            <Route element={<RetentionPolicyEditDrawer />} path="retention-policy/:id" />
            <Route
              element={
                <PermissionGuard
                  requiredPermissions={[PermissionNameRecord.CAN_MANAGE_IP_ALLOW_LIST_POLICY]}
                />
              }
            >
              <Route
                element={<IpAllowListPolicyCreateDrawer />}
                path="ip-allow-list-policy/create"
              />
              <Route element={<IpAllowListPolicyEditDrawer />} path="ip-allow-list-policy/:id" />
            </Route>
          </Route>
          <Route element={<UsersPage />} path="users">
            <Route
              element={
                <SwitchOnFeatureFlag
                  featureFlag="removeUsernames"
                  ifDisabled={<UserCreateDrawerLegacy />}
                  ifEnabled={<UserCreateDrawer />}
                />
              }
              path="create"
            />
            <Route
              element={
                <SwitchOnFeatureFlag
                  featureFlag="removeUsernames"
                  ifDisabled={<UserEditDrawerLegacy />}
                  ifEnabled={<UserEditDrawer />}
                />
              }
              path=":id"
            />
          </Route>
          <Route element={<ChannelsPage />} path="conversations">
            <Route element={<ChannelCreateDrawer />} path="create" />
            <Route element={<ChannelEditDrawer />} path=":id" />
          </Route>
          <Route element={<FiltersPage />} path="filters">
            <Route element={<FilterCreateDrawer />} path="create" />
            <Route element={<FilterEditDrawer />} path=":id" />
          </Route>
          <Route element={<DevicesPage />} path="devices">
            <Route element={<DeviceEditDrawer />} path=":id" />
          </Route>
          <Route element={<LocationsPage />} path="locations">
            <Route element={<LocationCreateDrawer />} path="create" />
            <Route element={<LocationEditDrawer />} path=":id" />
          </Route>
          <Route element={<GroupsPage />} path="groups">
            <Route element={<GroupCreateDrawer />} path="create" />
            <Route element={<GroupEditDrawer />} path=":id" />
          </Route>
          <Route element={<RoomsPage />} path="rooms">
            <Route element={<RoomEditDrawer />} path=":id" />
          </Route>
        </Route>
        <Route element={<ClientMainPage />} path="client">
          <Route element={<HomePage />} path="home">
            <Route element={<CreateDMDialog />} path="create-dm" />
          </Route>
          <Route element={<EmptyChannelsPage />} path="empty-channels">
            <Route element={<CreateDMDialog />} path="create-dm" />
          </Route>
          <Route element={<EmptyDMsPage />} path="empty-dms">
            <Route element={<CreateDMDialog />} path="create-dm" />
          </Route>
          {/* Prevent users trying to navigate to /client/admin */}
          <Route element={<Navigate to="/client" />} path="admin" />
          <Route element={<ConversationPage />} path=":conversationId">
            <Route element={<CreateDMDialog />} path="create-dm" />
            <Route element={<ConversationDetailsDialog />} path="details/:tabId">
              <Route element={<FileViewerDialog />} path="file-viewer/:messageId/:fileId" />
            </Route>
            <Route element={<FileViewerDialog />} path="file-viewer/:messageId/:fileId" />
          </Route>
        </Route>
        {process.env.NODE_ENV !== 'production' && <Route element={<Sandbox />} path="sandbox" />}
      </Route>
      <Route element={<AgreeToTermsPage />} path="agree-to-terms" />
      <Route element={<NotFoundPage />} path="*" />
    </Route>,
  ),
);

init({ data });

const AppRoutes = () => (
  <Suspense fallback={<AppSkeleton />}>
    <RouterProvider router={router} />
  </Suspense>
);

export default AppRoutes;
