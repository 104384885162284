import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { ipAllowListPolicyUpdateStatus, snackAdd } from '@serenityapp/redux-store';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

type DisableIpAllowListPolicyDialogProps = {
  id: string;
  orgId: string;
  onSuccess?: () => void;
  onFailed?: () => void;
  onClose: () => void;
  title: string;
  dataTestId?: string;
};

const DisableIpAllowListPolicyDialog = ({
  id,
  orgId,
  onSuccess,
  onFailed,
  onClose,
  title,
  dataTestId,
}: DisableIpAllowListPolicyDialogProps) => {
  const makeTestId = useMakeTestId('DisableIpAllowListPolicyDialog', dataTestId);
  const disableDialog = makeTestId('');

  const dispatch = useDispatch();

  const [isDisabling, setIsDisabling] = useState(false);

  const onDisableSuccess = () => {
    setIsDisabling(false);
    dispatch(
      snackAdd({ message: 'IP allow list policy successfully disabled.', type: 'success' }),
    );
    onSuccess?.();
  };

  const onDisableFailed = () => {
    setIsDisabling(false);
    dispatch(
      snackAdd({ message: 'Error disabling ip allow list policy policy.', type: 'error' }),
    );
    onFailed?.();
  };

  const handleDisableConfirmClick = () => {
    setIsDisabling(true);
    dispatch(
      ipAllowListPolicyUpdateStatus({
        onFailed: onDisableFailed,
        onSuccess: onDisableSuccess,
        variables: {
          input: {
            ipAllowListPolicy: {
              id: id,
              disabled: true,
            },
            organization: {
              id: orgId,
            },
          },
        },
      }),
    );
    onClose();
  };

  return (
    <Dialog
      fullWidth
      open
      data-testid={disableDialog}
      onClick={(event) => event.stopPropagation()}
      onClose={onClose}
    >
      <DialogTitle>Disable {title} policy</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to disable this IP allow list policy '{title}'?
        </Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>
            Once disabled, these IP ranges will no longer have access permissions through this
            policy.
          </ListItem>
          <ListItem sx={listItemSx}>
            To re-enable this policy in the future, you can do so via "Actions" menu.
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} disabled={isDisabling} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          data-testid={makeTestId('disable')}
          loading={isDisabling}
          variant="contained"
          onClick={handleDisableConfirmClick}
        >
          Disable
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default DisableIpAllowListPolicyDialog;
