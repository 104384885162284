import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { AppLayout } from '@serenityapp/components-react-web';

import { MainPage } from '../common';
import AppBarContent from '../common/AppBarContent';

const AdminMainPage = () => (
  <MainPage>
    <AppLayout>
      <AppLayout.AppBar>
        <AppBarContent title="Admin" />
      </AppLayout.AppBar>
      <AppLayout.Main>
        <Outlet />
      </AppLayout.Main>
    </AppLayout>
  </MainPage>
);

export default memo(AdminMainPage);
