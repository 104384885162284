import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardActions, CardHeader, Container } from '@mui/material';

import { useDidMount } from '@serenityapp/components-react-common';
import { HorizontalSerenityColorLogo } from '@serenityapp/components-react-web';

import { useAnalytics } from '../../../providers';
import {
  cardActionsSx,
  cardHeaderSx,
  cardSx,
  containerSx,
  logoWrapperSx,
} from './resetPasswordPageStyles';

const ResetPasswordSuccess = () => {
  const analytics = useAnalytics();

  const navigate = useNavigate();

  const navigateToSignIn = () => navigate('/sign-in');

  useDidMount(() => {
    analytics.track({ name: 'auth/reset-password/success' });
  });

  return (
    <Container sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader
          subheader="You can now sign in with your new credentials."
          sx={cardHeaderSx}
          title="Password reset successful!"
        />
        <CardActions sx={cardActionsSx}>
          <Button
            autoFocus
            fullWidth
            size="large"
            startIcon={<ArrowBack />}
            type="submit"
            onClick={navigateToSignIn}
          >
            Back to sign in
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default ResetPasswordSuccess;
