import { getViewerState, useViewerBaseInformation } from '@serenityapp/redux-store';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const RootRoute = () => {
  const { signedIn } = useSelector(getViewerState);

  const viewerBaseInformation = useViewerBaseInformation();
  const viewerPermissions = viewerBaseInformation.data.permissions;

  if (signedIn) {
    if (viewerPermissions.canDisplaySystemAdmin) return <Navigate to="/serenity-admin" />;
    if (viewerPermissions.canDisplayAdmin && !viewerPermissions.canDisplayMessaging)
      return <Navigate to="/admin" />;
    return <Navigate to="/client" />;
  } else {
    return <Navigate to="/sign-in" />;
  }
};

export default RootRoute;
