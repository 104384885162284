import { GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';

const QuickSearchToolbarChannels = () => (
  <GridToolbarContainer>
    <GridToolbarQuickFilter variant="outlined" sx={quickSearchFieldSx} debounceMs={200} />
  </GridToolbarContainer>
);

const quickSearchFieldSx = {
  width: 220,
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
};

export default QuickSearchToolbarChannels;
