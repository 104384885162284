import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useMakeTestId } from '@serenityapp/components-react-web';

type SendInvitationDialogErrorProps = {
  onClose: () => void;
  userName: string;
};

const SendInvitationDialogError = ({ onClose, userName }: SendInvitationDialogErrorProps) => {
  const makeTestId = useMakeTestId('SendInvitationErrorDialog');
  const sendInvitationDialogErrorTestId = makeTestId('');

  return (
    <Dialog fullWidth open data-testid={sendInvitationDialogErrorTestId} onClose={onClose}>
      <DialogTitle>Missing email</DialogTitle>
      <DialogContent>
        <Typography>
          We're sorry but we can't send an invite to {userName}, there is no email associated with
          this account.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('got-it')} variant="contained" onClick={onClose}>
          Got It
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendInvitationDialogError;
