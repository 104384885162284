import { Box, Divider, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';
import { Drawer } from '@serenityapp/components-react-web';

const CheckInAppDrawerSkeleton = () => (
  <>
    <Drawer.Header loading>
      <Skeleton width="30%" />
    </Drawer.Header>
    <Drawer.Content>
      <Typography display="block" variant="body1">
        <Skeleton width="60%" />
      </Typography>
      <Typography display="block" variant="body1">
        <Skeleton width="40%" />
      </Typography>
      <Typography display="block" sx={{ pt: 4 }} variant="caption">
        <Skeleton width="20%" />
      </Typography>
      <Divider sx={{ pb: 3 }} />
      <Box>
        <ListItem disableGutters>
          <ListItemText
            primary={<Skeleton width="120px" />}
            secondary={<Skeleton width="30%" />}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={<Skeleton width="50%" />} secondary={<Skeleton width="30%" />} />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary={<Skeleton width="40%" />} secondary={<Skeleton width="60%" />} />
        </ListItem>
      </Box>
    </Drawer.Content>
  </>
);

export default CheckInAppDrawerSkeleton;
