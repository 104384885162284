export const lockIconSx = {
  fontSize: 'small',
  color: 'grey.600',
  ml: 1,
};

export const nameTableCellSx = {
  display: 'flex',
  alignItems: 'center',
};
