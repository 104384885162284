import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { RouteParamId, Schema } from '@serenityapp/domain';
import { RoomCreateEditFormValues } from '@serenityapp/components-react-common';
import { useMakeTestId } from '@serenityapp/components-react-web';

import {
  roomGetTransformer,
  snackAdd,
  useRoomGet,
  useRoomUpdateMutation,
} from '@serenityapp/redux-store';
import RoomCreateEditForm from './RoomCreateEditForm';
import { useCallback, useState } from 'react';
import ConfirmCloseDialog from '../components/ConfirmCloseDialog';

function toInitialValues(room?: Schema.Room.Detail) {
  return {
    name: room?.displayName ?? room?.name ?? '',
    users:
      room?.users?.map((user) => ({
        id: user.id,
        label: user.name,
      })) ?? [],
    alexaUnitKey: room?.alexaUnit?.name ?? '',
    communicationProfileDisplayName: room?.alexaUnit?.alexaCommunicationProfile?.name ?? '',
    isCommunicationProfileEnabled: room?.alexaUnit?.alexaCommunicationProfile?.id ? true : false,
    // skills:
    //   room?.alexaUnit?.alexaSkills?.map((skill) => ({
    //     id: skill.id,
    //     label: skill.name,
    //   })) ?? [],
  };
}

function toMutationVariables(
  id: string,
  values: RoomCreateEditFormValues,
): Schema.Room.Update.Variables {
  return {
    input: {
      id: id,
      name: values.name,
      userIds: values.users?.map((user) => user.id),
      alexaUnit: {
        id: id,
        // addressBookAssociations
        // announcementChannel
        communicationProfile: {
          isDisabled: !values.isCommunicationProfileEnabled,
        },
        displayName: values.name,
        name: values.alexaUnitKey,
        // skillEnablements
      },
    },
  };
}

const RoomEditDrawer = () => {
  const makeTestId = useMakeTestId('RoomEditDrawer');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  // const handleFormStateChange = useCallback((isDirty: boolean) => {
  //   setIsFormDirty(isDirty);
  // }, []);

  const handleConfirmDialogOpen = useCallback((isOpen: boolean) => {
    setIsConfirmDialogOpen(isOpen);
  }, []);

  const handleCancelConfirmDialogClick = () => {
    setIsConfirmDialogOpen(false);
  };

  const handleClose = useCallback(() => {
    if (isFormDirty) {
      setIsConfirmDialogOpen(true);
      return;
    }
    if (!isFormDirty) {
      navigate('..');
    }
  }, [navigate, isFormDirty]);

  const handleDiscardClick = useCallback(() => {
    setIsConfirmDialogOpen(false);
    setIsFormDirty(false);
    navigate('..');
  }, [setIsConfirmDialogOpen, navigate]);

  const { id } = useParams<RouteParamId>() as RouteParamId;
  const { data, isLoading } = useRoomGet({ variables: { input: { roomId: id } } });
  const { doMutation: updateRoom, isFetching } = useRoomUpdateMutation();

  const room = roomGetTransformer(data, isLoading);
  const name = room?.displayName ?? room?.name ?? '';
  const initialValues = toInitialValues(room);

  const handleFormSubmit = (values: RoomCreateEditFormValues) => {
    const onSuccess = () => {
      dispatch(
        snackAdd({ message: `Room ${values.name} successfully edited.`, type: 'success' }),
      );
      navigate('..');
    };

    const onFailure = () => {
      dispatch(snackAdd({ message: `Error editing room ${values.name}.`, type: 'error' }));
      navigate('..');
    };

    updateRoom(toMutationVariables(id, values), onSuccess, onFailure);
  };

  return (
    <>
      <ConfirmCloseDialog
        open={isConfirmDialogOpen}
        onCancelClick={handleCancelConfirmDialogClick}
        onDiscardClick={handleDiscardClick}
      />
      <RoomCreateEditForm
        isEditMode
        dataTestId={makeTestId('')}
        handleClose={handleClose}
        handleFormSubmit={handleFormSubmit}
        initialValues={initialValues}
        isLoading={isLoading}
        isSaving={isFetching}
        name={name}
        room={room}
        title={`Edit Room - ${name}`}
        onConfirmDialogOpen={handleConfirmDialogOpen}
      />
    </>
  );
};

export default RoomEditDrawer;
