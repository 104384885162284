import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { print } from '@serenityapp/core';
import { snackAdd } from '@serenityapp/redux-store';

// We are using StrictMode in react. StrictMode renders components twice (on dev but not
// production) in order to detect any problems with your code and warn you about them.
//
// This component calls mutation when it gets rendered. In strict mode it means that the
// component will be rendered twice and therefore the mutation will be called twice.
//
// We do not want it to be called twice so we define this global variable that holds and
// information about the mutation calls. If the mutation was already called in first render
// than we prevent the second call using this variable.
let hasCalledInstallMutation = false;

const BoxAppOAuth2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const goBack = useCallback(() => navigate('..'), [navigate]);
  const onAuthorizationSuccess = useCallback(() => {
    dispatch(snackAdd({ message: 'Authorization successful.', type: 'success' }));
    navigate('../google-calendar');
  }, [navigate, dispatch]);
  const onAuthorizationFailed = useCallback(() => {
    dispatch(snackAdd({ message: 'Authorization rejected.', type: 'error' }));
    goBack();
  }, [dispatch, goBack]);

  useEffect(() => {
    if (hasCalledInstallMutation) return;

    const authorizationCode = searchParams.get('code');

    if (authorizationCode) {
      // dispatch(
      //   appInstall({
      //     onFailed: onAuthorizationFailed,
      //     onSuccess: onAuthorizationSuccess,
      //     variables: {
      //       appName: App.Names.Calendar,
      //       credential: {
      //         kind: 'oauth2',
      //         provider: 'google',
      //         code: authorizationCode,
      //         redirect_uri: window.location.origin + GOOGLE_CALENDAR_AUTH_ROUTE,
      //         clientId: getRequiredEnvironmentVar('REACT_APP_GOOGLE_OAUTH2_CONFIG_CLIENT_ID'),
      //         scopes: GOOGLE_CALENDAR_ACCESS_SCOPES,
      //       },
      //     },
      //   }),
      // );
      print(`Search params: ${JSON.stringify(searchParams, null, 2)}`);
      print(`Authorization code: ${authorizationCode}`);

      hasCalledInstallMutation = true;
    } else {
      const authorizationError = searchParams.get('error');

      if (authorizationError) {
        Sentry.addBreadcrumb({
          message: 'Google Calendar OAuth2 failed with authorization error',
          data: { error: authorizationError },
        });
      }

      onAuthorizationFailed();
    }
  }, [dispatch, onAuthorizationFailed, onAuthorizationSuccess, searchParams]);

  return null;
};

export default BoxAppOAuth2;
