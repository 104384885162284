import { useDispatch } from 'react-redux';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { conversationSystemConnect, snackAdd } from '@serenityapp/redux-store';
import {
  SystemChannelConnectFormConfig,
  SystemChannelConnectFormValues,
  SerenityForm,
} from '@serenityapp/components-react-common';
import { Form, FormikField, useMakeTestId } from '@serenityapp/components-react-web';
import { SerenityError } from '@serenityapp/core';

type SystemChannelConnectDialogProps = {
  dataTestId?: string;
  isOpen?: boolean;
  onClose: () => void;
};

const SystemChannelConnectDialog = ({
  dataTestId,
  isOpen = true,
  onClose,
}: SystemChannelConnectDialogProps) => {
  const makeTestId = useMakeTestId('SystemChannelConnectDialog', dataTestId);
  const channelConnectDialog = makeTestId('');
  const dispatch = useDispatch();

  const handleSubmit = async ({ channel, owner, partner }: SystemChannelConnectFormValues) => {
    const input = {
      channel,
      owner,
      partner,
    };

    dispatch(
      conversationSystemConnect({
        variables: { input },
        onFailed: (error?: SerenityError) => {
          const message = error?.props?.data?.data?.result?.msg || 'Error connecting channel';
          dispatch(snackAdd({ message, type: 'error' }));
          onClose();
        },
        onSuccess: () => {
          dispatch(snackAdd({ message: 'Channel successfully connected', type: 'success' }));
          onClose();
        },
      }),
    );
  };

  return (
    <SerenityForm config={SystemChannelConnectFormConfig} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Dialog
          fullWidth
          data-testid={channelConnectDialog}
          open={isOpen}
          onClick={(event) => event.stopPropagation()}
          onClose={onClose}
        >
          <DialogTitle>Channel Connect Across Orgs</DialogTitle>
          <DialogContent>
            <Alert severity="warning" sx={alertSx}>
              <strong>Note: </strong>
              Names must be an exact match and are case sensitive
            </Alert>
            <Form>
              <FormikField
                autoFocus
                dataTestId={makeTestId('owner')}
                name="owner"
                variant="filled"
              />
              <FormikField dataTestId={makeTestId('partner')} name="partner" variant="filled" />
              <FormikField dataTestId={makeTestId('channel')} name="channel" variant="filled" />
            </Form>
          </DialogContent>
          <DialogActions>
            <Button data-testid={makeTestId('cancel')} onClick={onClose}>
              Cancel
            </Button>
            <Button
              data-testid={makeTestId('connectChannel')}
              variant="contained"
              onClick={submitForm}
            >
              Connect channel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </SerenityForm>
  );
};

export const alertSx = {
  my: 2,
};
export default SystemChannelConnectDialog;
