import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  RootState,
  getMessageEntryByConversationId,
  updateMessageEntryMessage,
} from '@serenityapp/redux-store';
import { COMMAND_PRIORITY_NORMAL } from 'lexical';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SEND_MESSAGE_COMMAND } from '../customLexicalCommands';

const SAVE_CONTENT_DELAY = 1000;

type ReduxPluginProps = {
  conversationId: string;
};

const ReduxPlugin = ({ conversationId }: ReduxPluginProps) => {
  const isFirstRender = useRef<boolean>(true);
  const [editor] = useLexicalComposerContext();
  const dispatch = useDispatch();

  const [debouncedDispatch] = useState(() => debounce(dispatch, SAVE_CONTENT_DELAY));

  const { messageJSON } = useSelector((state: RootState) =>
    getMessageEntryByConversationId(state, conversationId),
  );

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      if (messageJSON) {
        const initialEditorState = editor.parseEditorState(messageJSON);
        editor.setEditorState(initialEditorState);
      }
    }

    // Excluding messageJSON from the dependencies array since we do not want this useEffect to
    // be called when messageJSON changes. We use only initial value of the messageJSON to set
    // initial value of the editor. We do not want to do anything when its value changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, isFirstRender]);

  useEffect(() => {
    const removeUpdateListener = editor.registerUpdateListener(
      ({ editorState, dirtyElements, dirtyLeaves }) => {
        // Don't update if nothing changed
        if (dirtyElements.size === 0 && dirtyLeaves.size === 0) return;
        const serializedState = JSON.stringify(editorState);
        debouncedDispatch(
          updateMessageEntryMessage({ conversationId, messageJSON: serializedState }),
        );
      },
    );

    return removeUpdateListener;
  }, [conversationId, debouncedDispatch, editor]);

  useEffect(() => {
    const removeCommandListener = editor.registerCommand(
      SEND_MESSAGE_COMMAND,
      () => {
        debouncedDispatch.cancel();
        // Returning true to stop propagation of the command since this one has lowest priority
        return true;
      },
      COMMAND_PRIORITY_NORMAL,
    );

    return removeCommandListener;
  }, [debouncedDispatch, editor]);

  return null;
};

export default ReduxPlugin;
