import { Outlet } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

import { StringFn } from '@serenityapp/core';
import { View, AdministrationHeader, useMakeTestId } from '@serenityapp/components-react-web';

import { useAdminBreadcrumbs } from '../../hooks';
import DashboardCard, { DashboardCardProps } from './DashboardCard';

import { cardsWrapperSx } from './styles';
import cardImage from '../../../../assets/AdminDashboard/Apps.svg';

const IndexPage = () => {
  const title = 'Alexa for Senior Living';
  const crumbs = useAdminBreadcrumbs(title);

  const makeTestId = useMakeTestId('A4SL.IndexPage');

  const dashboardCardItems: DashboardCardProps[] = [
    {
      cardMedia: cardImage,
      title: 'Address Books',
      description: 'Add or manage address books',
      to: 'address-books',
    },
  ];

  return (
    <View>
      <View.Header>
        <AdministrationHeader crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <Box sx={cardsWrapperSx}>
          <Grid container spacing={3}>
            {dashboardCardItems.map((item) => {
              const key = StringFn.generateKey(item.title);
              return (
                <Grid key={key} container item md={4} sm={6} xl={3} xs={12}>
                  <DashboardCard
                    cardMedia={item.cardMedia}
                    dataTestId={makeTestId(key)}
                    description={item.description}
                    title={item.title}
                    to={item.to}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </View.Content>
      <Outlet />
    </View>
  );
};

export default IndexPage;
