import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from 'urql';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  PccInstallFormConfig,
  PccInstallFormValues,
  SelectorItemProps,
  SerenityForm,
} from '@serenityapp/components-react-common';
import { KeyValuePair } from '@serenityapp/domain';
import { AutocompleteChipSelectorField, FormikField } from '@serenityapp/components-react-web';
import {
  pccFacilitiesQuery,
  GetPCCFacilitiesResult,
  GetPCCFacilitiesVariables,
} from '@serenityapp/api-graphql';
import { appInstall, snackAdd } from '@serenityapp/redux-store';
import { App } from '@serenityapp/core';

type AppConfigureDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const PccAppConfigureDialog = ({ isOpen, onClose }: AppConfigureDialogProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [organizationId, setOrganizationId] = useState<null | string>(null);
  const [step, setStep] = useState<1 | 2>(1);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isAppInstalling, setIsAppInstalling] = useState(false);

  // attemptNext is set to true after organizationId is set and facilities query is executed.
  // It is used to trigger the effect that checks the result of the facilities query. If facilities
  // are found, the configuration will be switched to the second step, otherwise an error message
  // is displayed in the first step.
  const [attemptNext, setAttemptNext] = useState(false);

  const [result, executeQuery] = useQuery<GetPCCFacilitiesResult, GetPCCFacilitiesVariables>({
    query: pccFacilitiesQuery,
    variables: {
      // organizationId is set before the query is executed, so it should be safe to cast it to string
      input: { organizationId: organizationId as string },
    },
    pause: true,
  });

  // Effect to handle the completion of the query
  useEffect(() => {
    const { fetching, data, error } = result;
    if (attemptNext && !fetching && data && !error) {
      if (data.getPCCFacilities.code === '404') {
        setErrorMessage('Invalid organization UUID');
        return;
      }
      if (!data.getPCCFacilities.success) {
        dispatch(snackAdd({ message: 'An error occurred. Please try again.', type: 'error' }));
        return;
      }
      setStep(2);
      setAttemptNext(false);
    }
  }, [result, attemptNext, dispatch]);

  const handleFirstStep = () => {
    setAttemptNext(true);
    executeQuery();
  };

  const handleClose = () => {
    setStep(1);
    setOrganizationId(null);
    setErrorMessage('');
    onClose();
  };

  const onAppInstallFailed = () => {
    dispatch(snackAdd({ message: 'PointClickCare installation failed.', type: 'error' }));
    setIsAppInstalling(false);
    handleClose();
  };

  const onAppInstallSuccess = () => {
    setIsAppInstalling(false);
    handleClose();
    dispatch(snackAdd({ message: 'PointClickCare installed successfully.', type: 'success' }));
    navigate('pcc');
  };

  const handleSecondStep = (values: PccInstallFormValues) => {
    setIsAppInstalling(true);
    const data: KeyValuePair[] = [
      { key: 'pccOrgId', type: 'string', stringValue: values.organizationId },
    ];

    if (values.facilities.length > 0) {
      data.push({
        key: 'facilitiesIds',
        type: 'number[]',
        numberArrayValue: values.facilities.map((facility) => Number(facility.id)),
      });
    }
    dispatch(
      appInstall({
        onFailed: onAppInstallFailed,
        onSuccess: onAppInstallSuccess,
        variables: {
          appName: App.Names.PointClickCare,
          data,
        },
      }),
    );
  };

  const initialValues = {
    organizationId: '',
    facilitiesIds: [],
  };

  const groupsAsSelectorItems: Array<SelectorItemProps> =
    result?.data?.getPCCFacilities?.facilities.map((facility: any) => ({
      id: facility.facId.toString(),
      label: facility.facilityName ?? '',
    })) || [];

  return (
    <SerenityForm
      key={isOpen ? 'open' : 'closed'}
      enableReinitialize
      validateOnChange
      config={PccInstallFormConfig}
      initialValuesOverride={initialValues}
      onSubmit={handleSecondStep}
    >
      {({ submitForm, handleChange }) => {
        return (
          <>
            <Dialog fullWidth open={isOpen} onClose={handleClose}>
              <DialogTitle>Connect to PointClickCare</DialogTitle>
              {step === 1 && (
                <>
                  <DialogContent sx={dialogContentSx}>
                    <Typography variant="body1">
                      Activate Serenity integration via PointClickCare and enter your OrgUUID
                      below, contact the Serenity Customer Team for assistance.
                    </Typography>
                    <FormikField
                      error={Boolean(errorMessage)}
                      helperText={errorMessage || 'Required'}
                      name="organizationId"
                      sx={textFieldSx}
                      type="text"
                      onChange={(event) => {
                        handleChange(event);
                        setOrganizationId(event.target.value);
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton
                      loading={result.fetching}
                      variant="contained"
                      onClick={handleFirstStep}
                    >
                      Next
                    </LoadingButton>
                  </DialogActions>
                </>
              )}
              {step === 2 && (
                <>
                  <DialogContent sx={dialogContentSx}>
                    <Typography variant="body1">
                      Choose facilities to sync with Serenity.
                    </Typography>
                    <AutocompleteChipSelectorField
                      helperText="Required"
                      items={groupsAsSelectorItems}
                      label="Facilities"
                      name="facilities"
                      placeholder="Select facilities"
                      sx={textFieldSx}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton
                      loading={isAppInstalling}
                      variant="contained"
                      onClick={submitForm}
                    >
                      Save
                    </LoadingButton>
                  </DialogActions>
                </>
              )}
            </Dialog>
          </>
        );
      }}
    </SerenityForm>
  );
};

const textFieldSx = {
  mt: 3,
  mb: 0,
};

const dialogContentSx = {
  pt: 1,
  pb: 1,
};

export default PccAppConfigureDialog;
