import { useCallback, memo, useRef, useMemo, useEffect } from 'react';
import { Virtuoso, VirtuosoProps, VirtuosoHandle } from 'react-virtuoso';
import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import * as R from 'ramda';
import throttle from 'lodash.throttle';

import { LoadingMessages } from '@serenityapp/components-react-web';

import {
  PAGE_SIZE,
  useMessages,
  useMessagesScroll,
  VisibleRange,
  useMessagesUpdates,
  useUnreadMessages,
  useIsFeatureEnabled,
} from '@serenityapp/redux-store';
import { useStableCallback } from '@serenityapp/components-react-common';

import { MessageItem } from '../MessageItem';
import {
  rootSx,
  footerPlaceholderSx,
  headerPlaceholderSx,
  footerLoaderSx,
  footerLoaderAbsoluteBoxSx,
} from './messagesListStyles';
import { MessagesLoading, MessagesEmptyView, UnreadMessagesSticker } from './components';
import ItemVisibilityObserver from './ItemVisibilityObserver';

type MessagesListProps = {
  conversationId: string;
  lastRead?: number;
  unreadCount?: number;
};

const initialTopMostItemIndex: VirtuosoProps<any, any>['initialTopMostItemIndex'] = {
  index: 'LAST',
  behavior: 'auto',
};

const HeaderMessagesLoader = () => <LoadingMessages />;
const FooterMessagesLoader = () => (
  <Box sx={footerLoaderSx}>
    <Box sx={footerLoaderAbsoluteBoxSx}>
      <LoadingMessages />
    </Box>
  </Box>
);
const FooterPlaceholder = () => <Box sx={footerPlaceholderSx} />;
const HeaderPlaceholder = () => <Box sx={headerPlaceholderSx} />;

const MessagesList = ({ conversationId, lastRead = 0, unreadCount = 0 }: MessagesListProps) => {
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const shouldDeprecateUsernames = useIsFeatureEnabled('removeUsernames');

  const {
    messages,
    isLoading,
    isEmpty,
    hasPrevPage,
    totalMessagesCount,
    goToPageForIndex,
    isNextDataLoading,
    isPrevDataLoading,
    lastMessages,
    lastDataIsFetching,
    firstItemIndex,
  } = useMessages(conversationId);

  useMessagesUpdates(conversationId);

  const { handlePageShiftCallback } = useMessagesScroll({
    goToPageForIndex,
  });

  const scrollToIndex = useCallback((index: number) => {
    virtuosoRef.current?.scrollToIndex({
      index,
      behavior: 'smooth',
    });
  }, []);

  const {
    shouldDisplayUnreadMessagesSticker,
    stickerDirection,
    firstUnreadMessageIndexInList,
    newMessageIndicatorIndexInList,
    shouldDisplayNewMessageIndicator,
    shouldWrapMessageInIntersectionObserver,
    stickerCount,
    handleUnreadMessagesStickerDirectionChange,
    handleFirstUnreadMessageVisibilityChange,
    handleUnreadMessagesStickerClick,
    handleMarkMessageUnread,
    handleMarkConversationAsRead,
    handleBottomStateChange,
    handlePageRendered,
    handleAppFocusChange,
  } = useUnreadMessages({
    scrollToIndex,
    conversationId,
    actualLastRead: lastRead,
    unreadCount,
    totalMessagesCount,
    messages,
    lastMessages,
    firstItemIndex,
    hasPrevPage,
    isLoading,
    isPrevDataLoading,
  });

  useEffect(() => {
    const handleWindowFocus = () => {
      handleAppFocusChange(true);
    };
    const handleWindowBlur = () => {
      handleAppFocusChange(false);
    };
    window.addEventListener('focus', handleWindowFocus);
    window.addEventListener('blur', handleWindowBlur);
    return () => {
      window.removeEventListener('focus', handleWindowFocus);
      window.removeEventListener('blur', handleWindowBlur);
    };
  }, [handleAppFocusChange]);

  // Report page rendered after a second
  // to prevent unread messages sticker from displaying too soon
  useEffect(() => {
    let timeoutId: number;
    if (!isLoading) {
      timeoutId = window.setTimeout(() => {
        handlePageRendered();
      }, 1000);
    }
    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [handlePageRendered, isLoading]);

  const updatingRangeChangeCallback = useCallback(
    (renderedRange: VisibleRange) => {
      handlePageShiftCallback(renderedRange);
      handleUnreadMessagesStickerDirectionChange({
        startIndex: renderedRange.startIndex,
        endIndex: renderedRange.endIndex,
      });
    },
    [handlePageShiftCallback, handleUnreadMessagesStickerDirectionChange],
  );
  const stableRangeChangeCallback = useStableCallback(updatingRangeChangeCallback);
  const rangeChangeCallback = useMemo(
    () => throttle(stableRangeChangeCallback, 500),
    [stableRangeChangeCallback],
  );

  const renderItemContent = useCallback(
    (index: number) => {
      const item = messages[index - firstItemIndex];

      const hasNewMessageIndicator =
        shouldDisplayNewMessageIndicator && index === newMessageIndicatorIndexInList;

      const isFirstUnreadMessage =
        shouldWrapMessageInIntersectionObserver && index === firstUnreadMessageIndexInList;

      if (item) {
        if (isFirstUnreadMessage) {
          return (
            <ItemVisibilityObserver
              id={item.id}
              // It's safer if the callback fires at different visibility thresholds so it doesn't miss its window when scrolling
              threshold={[0.5, 0.75, 1]}
              onChange={(_id, isVisible) => handleFirstUnreadMessageVisibilityChange(isVisible)}
            >
              <MessageItem
                {...item}
                hasNewMessageIndicator={hasNewMessageIndicator}
                shouldDeprecateUsernames={shouldDeprecateUsernames}
                onMarkMessageUnread={handleMarkMessageUnread}
              />
            </ItemVisibilityObserver>
          );
        }
        return (
          <MessageItem
            {...item}
            hasNewMessageIndicator={hasNewMessageIndicator}
            shouldDeprecateUsernames={shouldDeprecateUsernames}
            onMarkMessageUnread={handleMarkMessageUnread}
          />
        );
      }
      // TODO: make this a message skeleton
      return <div style={{ height: 118 }} />;
    },
    [
      messages,
      firstItemIndex,
      shouldDisplayNewMessageIndicator,
      newMessageIndicatorIndexInList,
      shouldWrapMessageInIntersectionObserver,
      firstUnreadMessageIndexInList,
      handleMarkMessageUnread,
      shouldDeprecateUsernames,
      handleFirstUnreadMessageVisibilityChange,
    ],
  );

  const virtuosoComponents = useMemo(() => {
    const EmptyPlaceholder = isLoading
      ? MessagesLoading
      : isEmpty
        ? MessagesEmptyView
        : undefined;

    const Footer = isLoading
      ? FooterPlaceholder
      : isNextDataLoading || lastDataIsFetching
        ? FooterMessagesLoader
        : FooterPlaceholder;
    const Header = isLoading
      ? HeaderPlaceholder
      : isPrevDataLoading
        ? HeaderMessagesLoader
        : HeaderPlaceholder;

    return { EmptyPlaceholder, Footer, Header };
  }, [isLoading, isEmpty, isNextDataLoading, lastDataIsFetching, isPrevDataLoading]);

  const computeItemKey = useCallback(
    (item: number) => {
      const messageItem = messages[item - firstItemIndex];
      if (messageItem) {
        return `${messageItem.id}`;
      }
      return `placeholder-${item}`;
    },
    [messages, firstItemIndex],
  );

  return (
    <Box sx={rootSx}>
      <Virtuoso
        // This is a workaround for the issue where the list flashes/jumps on initial load.
        // The problem here is that we are setting `initialTopMostItemIndex` before the data is
        // loaded. `initialTopMostItemIndex` should not be executed until data is available.
        // The maintainer said this is potential improvement for the library.
        // Until that is improved we need to handle this manually.
        // Notice that we are using information about if we already have some messages loaded
        // to deconstruct the Virtuoso key prop value. This lets the initialTopMostItemIndex
        // to kick in correctly when there is at least one page of messages loaded.
        key={`virtuoso-${conversationId}-${messages.length > 0 ? 'loaded' : 'empty'}`}
        ref={virtuosoRef}
        alignToBottom
        atBottomStateChange={handleBottomStateChange}
        components={virtuosoComponents}
        computeItemKey={computeItemKey}
        data-testid="Virtuoso"
        // It is recommended to keep the value of the defaultItemHeight prop the same as the height of the one liner MessageItem component
        defaultItemHeight={118}
        firstItemIndex={firstItemIndex}
        followOutput="auto"
        increaseViewportBy={100}
        initialTopMostItemIndex={initialTopMostItemIndex}
        itemContent={renderItemContent}
        rangeChanged={rangeChangeCallback}
        totalCount={totalMessagesCount}
      />
      {shouldDisplayUnreadMessagesSticker && (
        <UnreadMessagesSticker
          direction={stickerDirection}
          maxDirectionDownCount={PAGE_SIZE}
          newMessagesCount={stickerCount}
          onClick={handleUnreadMessagesStickerClick}
          onCloseClick={handleMarkConversationAsRead}
        />
      )}
    </Box>
  );
};

MessagesList.displayName = 'MessagesList';

export default Sentry.withProfiler(memo(MessagesList, R.equals));
