import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@mui/material';

type AuthorizationErrorDialogProps = {
  isOpen: boolean;
  onRetryClick: () => void;
  onCancelClick: () => void;
};

const AuthorizationErrorDialog = ({
  isOpen,
  onRetryClick,
  onCancelClick,
}: AuthorizationErrorDialogProps) => {
  return (
    <Dialog fullWidth open={isOpen}>
      <DialogTitle>Authorization failed</DialogTitle>
      <DialogContent>
        <Typography>
          Oops! It looks like you haven't granted the necessary permissions. Please grant access
          and try again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button variant="contained" onClick={onRetryClick}>
          Retry
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthorizationErrorDialog;
