import { IAnalytics } from '@serenityapp/core';
import LocalForage from 'localforage';
import type { Storage as IReduxPersistStorage } from 'redux-persist';

// Configure browser storage
LocalForage.config({
  name: 'serenity/store',
  // Storage backend used in order of priority (LocalStorage has strict size limits - prefer IndexedDB/WebSQL)
  driver: [LocalForage.INDEXEDDB, LocalForage.WEBSQL, LocalForage.LOCALSTORAGE],
});

// Maximum allowed size of the persisted Redux state when reading from the storage
// The number is a guesstimate based on analytics
const MAX_READ_SIZE_IN_BYTES = 21_000_000;

type IncomingPersistedState = Record<string | number, any> | Array<any> | string;
type OutgoingPersistedState = Record<string | number, any> | Array<any>;

/**
 * Handles local storage of a Redux state and keeps the stored state size within bounds
 */
export class ReduxPersistStorage implements IReduxPersistStorage {
  constructor(readonly analytics?: IAnalytics) {}

  async getItem(key: string): Promise<OutgoingPersistedState | null> {
    const persistedState = await LocalForage.getItem<IncomingPersistedState>(key);

    if (persistedState === null) return null;

    // Serialize (if not already serialized) to measure the persisted state size
    let serializedState = '';
    if (typeof persistedState === 'string') serializedState = persistedState;
    else serializedState = JSON.stringify(persistedState);

    const persistedStateSizeInBytes = serializedState.length;
    const isSizeLimitExceeded = persistedStateSizeInBytes > MAX_READ_SIZE_IN_BYTES;

    this.analytics?.track({
      name: 'expo-persistence-store/get-item',
      metrics: {
        jsonStringLength: persistedStateSizeInBytes,
        sizeLimitInBytes: MAX_READ_SIZE_IN_BYTES,
      },
      attributes: {
        sizeLimitExceeded: isSizeLimitExceeded,
      },
    });

    // When the stored state exceeds the maximum allowed size, it's dropped
    if (isSizeLimitExceeded) {
      await this.removeItem(key);
      return null;
    }

    // When the persisted state's serialized, it needs to be parsed before returning
    if (typeof persistedState === 'string') {
      return JSON.parse(persistedState);
    }

    return persistedState;
  }

  async setItem(key: string, item: IncomingPersistedState): Promise<void> {
    await LocalForage.setItem(key, item);
  }

  removeItem(key: string): Promise<void> {
    return LocalForage.removeItem(key);
  }
}
