import * as Sentry from '@sentry/react';
import { default as emojiRegularExpression } from 'emoji-regex';

import { LineBreakNode } from 'lexical';
import { LinkNode } from '@lexical/link';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { $convertFromMarkdownString } from '@lexical/markdown';

import { EmojiNode } from '../lexical/nodes';

import LexicalEditorTheme from '../lexical/theme/LexicalEditorTheme';
import StyledContentEditable from '../lexical/components/StyledContentEditable';
import {
  getEmojiTransformer,
  getLineBreakTransformer,
  getLinkTransformer,
} from '../lexical/plugins/utils';
// import { TreeViewPlugin } from '../lexical/plugins';

const emojiRegex = emojiRegularExpression();

/**
 * A function to create initial state for the editor using text coming from the BE
 * @param text a pure text coming from BE
 * @returns A callback to create initial state
 */
const prepopulatedRichText = (text: string) => () => {
  // We know that message contains emojis only by removing all of them from message and checking
  // if there is anything left in the message string.
  const containsEmojisOnly =
    text.trim().length > 0 && text.replaceAll(emojiRegex, '').trim() === '';
  const EMOJI_TRANSFORMER = getEmojiTransformer(containsEmojisOnly);
  const LINK_TRANSFORMER = getLinkTransformer();
  const LINE_BREAK_TRANSFORMER = getLineBreakTransformer();

  // TODO: fix the hack below when markdown is implemented in client-native
  // markdown does not really allow empty lines
  // lexical convertFromMarkdownString removes them by default before applying transformers
  // this hack helps to preserve line breaks and creates LineBreakNode where expected
  const patchedText = text.replace(/\n/g, '↵');
  $convertFromMarkdownString(patchedText, [
    LINE_BREAK_TRANSFORMER,
    LINK_TRANSFORMER,
    EMOJI_TRANSFORMER,
  ]);
};

const LexicalText = ({ text }: { text: string }) => {
  const initialEditorConfig = {
    editable: false,
    namespace: 'LexicalText',
    nodes: [LinkNode, EmojiNode, LineBreakNode],
    onError: (error: Error) => {
      Sentry.addBreadcrumb({
        message: 'Error in lexical editor within LexicalText',
        data: { error },
      });
    },
    theme: LexicalEditorTheme,
    editorState: prepopulatedRichText(text),
    dirty: true,
  };

  return (
    <LexicalComposer initialConfig={initialEditorConfig}>
      {/* <TreeViewPlugin /> */}
      <RichTextPlugin
        contentEditable={<StyledContentEditable />}
        ErrorBoundary={LexicalErrorBoundary}
        placeholder={null}
      />
    </LexicalComposer>
  );
};

export default LexicalText;
