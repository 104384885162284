import { Outlet } from 'react-router-dom';
import { Box, TextField } from '@mui/material';
import { useSelector } from 'react-redux';

import { View, AdministrationHeader } from '@serenityapp/components-react-web';
import {
  useIsFeatureEnabled,
  useOrganizationDetail,
  getViewerState,
} from '@serenityapp/redux-store';

import { useAdminBreadcrumbs } from '../hooks';
import { SectionHeader } from '../components';
import { organizationDetailSectionSx, sectionSx } from './generalSettingsPageStyles';
import { RetentionPoliciesTable } from './RetentionPoliciesTable';
import { IpAllowListPoliciesTable } from './IpAllowListPoliciesTable';
import { useCurrentUser } from '../../common/hooks';

const GeneralSettingsPage = () => {
  const title = 'General settings';
  const crumbs = useAdminBreadcrumbs(title);

  const currentUser = useCurrentUser();
  const { organization } = useOrganizationDetail(currentUser?.orgId || '');
  const organizationName = organization?.name ?? '';

  const { canAdminIpAllowListPolicy } = useSelector(getViewerState);

  const isDataRetentionFeatureEnabled = useIsFeatureEnabled('dataRetention');
  const isIpAllowListPoliciesFeatureEnabled = useIsFeatureEnabled('ipAllowListPolicies');
  const shouldDisplayIpAllowListPolicies =
    isIpAllowListPoliciesFeatureEnabled && canAdminIpAllowListPolicy;

  return (
    <View>
      <View.Header>
        <AdministrationHeader crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <Box sx={organizationDetailSectionSx}>
          <SectionHeader title="Organization name" />
          <TextField
            disabled
            InputLabelProps={{ shrink: true }}
            label="Organization name"
            value={organizationName}
            variant="outlined"
          />
        </Box>
        <Box sx={sectionSx}>{isDataRetentionFeatureEnabled && <RetentionPoliciesTable />}</Box>
        <Box sx={sectionSx}>
          {shouldDisplayIpAllowListPolicies && <IpAllowListPoliciesTable />}
        </Box>
      </View.Content>
      <Outlet />
    </View>
  );
};

export default GeneralSettingsPage;
