import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { useMakeTestId } from '@serenityapp/components-react-web';

type ConfirmCloseDialogProps = {
  dataTestId?: string;
  open: boolean;
  onCancelClick: () => void;
  onDiscardClick: () => void;
};

const ConfirmCloseDialog = ({
  dataTestId,
  open,
  onCancelClick,
  onDiscardClick,
}: ConfirmCloseDialogProps) => {
  const makeTestId = useMakeTestId('ConfirmCloseDialog', dataTestId);
  const mainTestId = makeTestId('');

  return (
    <Dialog data-testid={mainTestId} open={open}>
      <DialogTitle>Discard unsaved changes</DialogTitle>
      <DialogContent>
        Are you sure that you want to leave? The changes that you made won't be saved.
      </DialogContent>
      <DialogActions>
        <Button data-testid={makeTestId('cancel')} onClick={onCancelClick}>
          Cancel
        </Button>
        <Button data-testid={makeTestId('discard')} variant="contained" onClick={onDiscardClick}>
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCloseDialog;
