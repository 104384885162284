import { Button } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { View, EmptyStateView } from '@serenityapp/components-react-web';
import { icons } from '@serenityapp/components-react-web';
import { useViewerBaseInformation } from '@serenityapp/redux-store';

const { Hashtag } = icons;

const EmptyChannelsPage = () => {
  const navigate = useNavigate();

  const viewerBaseInformation = useViewerBaseInformation();
  const viewerPermissions = viewerBaseInformation.data.permissions;

  const onManageChannelClick = () => navigate('/admin/conversations');

  const actionButton = viewerPermissions.canDisplayAdmin ? (
    <Button sx={buttonSx} variant="outlined" onClick={onManageChannelClick}>
      Manage Channels
    </Button>
  ) : undefined;

  return (
    <View>
      <View.Content>
        <EmptyStateView
          actionButton={actionButton}
          description="When you are added to a channel, you'll see them in here."
          Icon={Hashtag}
          title="No channels yet"
        />
      </View.Content>
      <Outlet />
    </View>
  );
};

const buttonSx = {
  mt: 2.25,
};

export default EmptyChannelsPage;
