import { useState } from 'react';
import { WithKey } from '@serenityapp/domain';
import { BasicMenu, BasicMenuItemProps, useMakeTestId } from '@serenityapp/components-react-web';
import { useLocationDetails } from '@serenityapp/client-data';

import { DeleteLocationDialog } from './components';

type LocationActionsMenuProps = {
  locationId: string;
  anchorEl: HTMLElement | null;
  onClose?: () => void;
  onActionSuccess?: () => void;
};

const LocationActionsMenu = ({
  anchorEl,
  onClose,
  locationId,
  onActionSuccess,
}: LocationActionsMenuProps) => {
  const makeTestId = useMakeTestId('LocationsActionsMenu');

  const [isDeleteLocationDialogOpen, setIsDeleteLocationDialogOpen] = useState<boolean>(false);

  const { location, fetching } = useLocationDetails(locationId);

  const openLocationActionsMenuAnchor = Boolean(anchorEl && locationId);

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    onClose?.();
  };

  const locationActionsMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [];
  locationActionsMenuSections.push([
    {
      key: 'delete-location',
      dataTestId: makeTestId('delete-location'),
      onClick: () => setIsDeleteLocationDialogOpen(true),
      label: 'Delete',
      handleCloseMenu,
    },
  ]);

  const shouldDisplayDeleteLocationDialog = isDeleteLocationDialogOpen && !fetching;

  return (
    <>
      <BasicMenu
        key="basic-menu"
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        dataTestId={makeTestId('')}
        open={openLocationActionsMenuAnchor}
        sections={locationActionsMenuSections}
        onClose={handleCloseMenu}
      />
      {shouldDisplayDeleteLocationDialog && (
        <DeleteLocationDialog
          location={location}
          onActionSuccess={onActionSuccess}
          onClose={() => setIsDeleteLocationDialogOpen(false)}
        />
      )}
    </>
  );
};

export default LocationActionsMenu;
