import { ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';
import { useMakeTestId } from '@serenityapp/components-react-web';

type UsersForDMListItemSkeletonProps = {
  dataTestId?: string;
};

const UsersForDMListItemSkeleton = ({ dataTestId }: UsersForDMListItemSkeletonProps) => {
  const makeTestId = useMakeTestId('UsersForDMListItemSkeleton', dataTestId);
  const usersForDMListSkeleton = makeTestId('');

  return (
    <ListItem dense data-testid={usersForDMListSkeleton} sx={listItemSx}>
      <ListItemAvatar>
        <Skeleton height={40} variant="circular" width={40} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={<Skeleton height={20} sx={primaryTextSkeletonSx} width="60%" />}
        secondary={<Skeleton height={16} width="30%" />}
      />
    </ListItem>
  );
};

const listItemSx = {
  px: 3,
  height: 60,
};

const primaryTextSkeletonSx = {};

export default UsersForDMListItemSkeleton;
