import { ListItem, ListItemText } from '@mui/material';

const EmptySearchView = () => (
  <ListItem sx={emptySearchViewSx}>
    <ListItemText>Nothing found</ListItemText>
  </ListItem>
);

const emptySearchViewSx = {
  pl: 3,
  pb: 1,
};

export default EmptySearchView;
