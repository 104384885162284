import { useEffect, useRef, ReactNode } from 'react';

type Props = {
  // Identification handle that's given back in the onChange callback
  id: string;
  children: ReactNode;
  onChange: (id: string, isVisible: boolean) => void;
  // Numbers between 0 and 1 indicating at what percentage of the item's visibility should the onChange callback be fired
  // See https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#intersection_observer_options
  threshold?: number | Array<number>;
};

/**
 * Triggers a callback when children's visibility changes beyond the given threshold.
 * Meant to be used in the messages' virtual list.
 */
const ItemVisibilityObserver = ({ id, children, onChange, threshold = 0 }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => entries.forEach((entry) => onChange(id, entry.isIntersecting)),
      { threshold },
    );

    const wrapperElement = ref.current as Element;
    observer.observe(wrapperElement);

    return () => {
      observer.disconnect();
      // We can assume that once an item's unmounted in the messages' virtual list, it's not visible anymore
      onChange(id, false);
    };
  }, [id, onChange, threshold]);

  return <div ref={ref}>{children}</div>;
};

export default ItemVisibilityObserver;
