import { Box, IconButton, Typography } from '@mui/material';
import { ArrowDownward, ArrowUpward, Close } from '@mui/icons-material';
import { UnreadMessagesDirection } from '@serenityapp/core';
import { MouseEvent } from 'react';
import {
  arrowSx,
  iconButtonSx,
  messageSx,
  positionDownSx,
  positionUpSx,
  rootSx,
  stickerSx,
} from './unreadMessagesStickerStyles';
import { useMakeTestId } from '@serenityapp/components-react-web';

type UnreadMessagesStickerProps = {
  // the newMessagesCount is different from unreadCount, because it doesn't include messages
  // that have been marked unread
  newMessagesCount: number;
  // if sticker points down and the newMessagesCount is greater than maxDirectionDownCount, the label
  // will say "Last maxDirectionDownCount+ messages"
  maxDirectionDownCount: number;
  dataTestId?: string;
  direction?: UnreadMessagesDirection;
  onCloseClick?: () => void;
  onClick?: () => void;
};

const UnreadMessagesSticker = ({
  newMessagesCount,
  maxDirectionDownCount,
  dataTestId,
  direction,
  onCloseClick,
  onClick,
}: UnreadMessagesStickerProps) => {
  const makeTestId = useMakeTestId('UnreadMessagesSticker', dataTestId);
  const unreadMessagesStickerTestId = makeTestId('');

  const handleCloseClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (onCloseClick) onCloseClick();
  };

  const ArrowIcon = direction === 'UP' ? ArrowUpward : ArrowDownward;

  // If sticker is pointing down and it's count is greater than or equal page size,
  // let it say <PAGE_SIZE>+. It's because we cannot reliably count the number
  // of new messages if it's greater than PAGE_SIZE in the useUnreadMessages hook
  // that is providing the newMessagesCount value, because the new messages
  // do not fit in the page that lastMessages hook provides.
  let displayCountBottom: string = newMessagesCount + '';

  if (direction === 'DOWN' && newMessagesCount >= maxDirectionDownCount) {
    displayCountBottom = maxDirectionDownCount + '+';
  }

  const isPlural = newMessagesCount > 1;
  const directionUpLabel = `${newMessagesCount} unread ${isPlural ? 'messages' : 'message'}`;
  const directionDownLabel = `Last ${displayCountBottom} new ${
    isPlural ? 'messages' : 'message'
  }`;

  return (
    <Box
      data-testid={unreadMessagesStickerTestId}
      sx={[rootSx, direction === 'UP' && positionUpSx, direction === 'DOWN' && positionDownSx]}
    >
      <Box sx={stickerSx} onClick={onClick}>
        <ArrowIcon fontSize="small" sx={arrowSx} />
        <Typography sx={messageSx} variant="body2">
          {direction === 'UP' ? directionUpLabel : directionDownLabel}
        </Typography>
        {onCloseClick && (
          <IconButton
            aria-label="Mark as read"
            size="small"
            sx={iconButtonSx}
            onClick={handleCloseClick}
          >
            <Close fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default UnreadMessagesSticker;
