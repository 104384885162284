import { useNavigate } from 'react-router-dom';
import { Alert, Button, Skeleton, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';

import {
  RetentionPolicyCreateEditFormConfig,
  RetentionPolicyCreateEditFormValues,
  SelectorItemProps,
  SerenityForm,
} from '@serenityapp/components-react-common';
import { Drawer, FormikField, SelectField, Form } from '@serenityapp/components-react-web';
import { useState } from 'react';
import ConfirmCloseDialog from './ConfirmCloseDialog';

type RetentionPolicyCreateEditFormProps = {
  title: string;
  isDefault?: boolean;
  initialValues?: Partial<RetentionPolicyCreateEditFormValues>;
  handleSubmit: (values: RetentionPolicyCreateEditFormValues) => void;
  isLoading?: boolean;
  isSaving?: boolean;
};

const RetentionPolicyCreateEditForm = ({
  title,
  isDefault = false,
  initialValues,
  handleSubmit,
  isLoading,
  isSaving,
}: RetentionPolicyCreateEditFormProps) => {
  const navigate = useNavigate();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const daysToRetainOptions: SelectorItemProps[] = [
    { id: '0', label: 'Never expire' },
    { id: '3653', label: '10 years (3653 days)' },
    { id: '3288', label: '9 years (3288 days)' },
    { id: '2923', label: '8 years (2923 days)' },
    { id: '2557', label: '7 years (2557 days)' },
    { id: '2192', label: '6 years (2192 days)' },
    { id: '1827', label: '5 years (1827 days)' },
    { id: '1461', label: '4 years (1461 days)' },
    { id: '1096', label: '3 years (1096 days)' },
    { id: '730', label: '2 years (730 days)' },
    { id: '545', label: '18 months (545 days)' },
    { id: '400', label: '13 months (400 days)' },
    { id: '365', label: '12 months (365 days)' },
    { id: '180', label: '6 months (180 days)' },
    { id: '150', label: '5 months (150 days)' },
    { id: '120', label: '4 months (120 days)' },
    { id: '90', label: '3 months (90 days)' },
    { id: '60', label: '2 months (60 days)' },
    { id: '30', label: '1 month (30 days)' },
    { id: '14', label: '2 weeks (14 days)' },
    { id: '7', label: '1 week (7 days)' },
    { id: '5', label: '5 days' },
    { id: '3', label: '3 days' },
    { id: '1', label: '1 day' },
  ];

  const onCancelConfirmDialogClick = () => setIsConfirmDialogOpen(false);

  const handleClose = () => navigate('..');

  if (isLoading) {
    return (
      <Drawer open onClose={handleClose}>
        <Drawer.Header loading>
          <Typography noWrap variant="h6">
            <Skeleton width="150px" />
          </Typography>
        </Drawer.Header>
        <Drawer.Content>
          <Typography sx={policyNameSx} variant="subtitle1m">
            <Skeleton width="150px" />
          </Typography>
          <Skeleton height="50px" />
          <Typography variant="subtitle1m">
            <Skeleton width="150px" />
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <Skeleton width="300px" />
          </Typography>
          <Skeleton height="50px" />
        </Drawer.Content>
      </Drawer>
    );
  }

  const renderDrawerBody = ({
    dirty,
    submitForm,
  }: FormikProps<RetentionPolicyCreateEditFormValues>) => {
    const onDrawerCloseClick = () => {
      if (dirty) {
        setIsConfirmDialogOpen(true);
        return;
      }

      handleClose();
    };

    return (
      <Drawer open onClose={onDrawerCloseClick}>
        <ConfirmCloseDialog
          open={isConfirmDialogOpen}
          onCancelClick={onCancelConfirmDialogClick}
          onDiscardClick={handleClose}
        />
        <Drawer.Header loading={isSaving}>
          <Typography noWrap variant="h6">
            {title}
          </Typography>
        </Drawer.Header>
        <Drawer.Content>
          <Form disabled={isLoading || isSaving}>
            <Alert severity="info" sx={alertSx}>
              By default Serenity will retain all messages and files for the lifetime of your
              organization’s account. You can adjust your default retention settings to
              automatically delete data after a certain time period.
            </Alert>
            <Typography sx={policyNameSx} variant="subtitle1m">
              Policy name
            </Typography>
            <FormikField
              disabled={isDefault}
              helperText={isDefault ? 'Default policy name can’t be changed' : undefined}
              name="name"
              type="text"
            />
            <Typography variant="subtitle1m">Message data deletion</Typography>
            <Typography color="textSecondary" variant="body2">
              Data is retained in backups for up to 35 days after deletion.
            </Typography>
            <SelectField items={daysToRetainOptions} name="daysToRetain" sx={selectFieldSx} />
          </Form>
        </Drawer.Content>
        <Drawer.Footer>
          <Button onClick={onDrawerCloseClick}>Close</Button>
          <LoadingButton
            disabled={!dirty || isSaving}
            loading={isSaving}
            variant="contained"
            onClick={submitForm}
          >
            Save
          </LoadingButton>
        </Drawer.Footer>
      </Drawer>
    );
  };

  return (
    <SerenityForm
      enableReinitialize
      validateOnBlur
      validateOnChange
      config={RetentionPolicyCreateEditFormConfig}
      initialValuesOverride={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => renderDrawerBody(formikProps)}
    </SerenityForm>
  );
};

const alertSx = {
  mb: 3,
};

const selectFieldSx = {
  mt: 3,
};

const policyNameSx = {
  display: 'flex',
  pb: 2,
};

export default RetentionPolicyCreateEditForm;
