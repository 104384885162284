import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

type CommunicationProfileRemoveDialogProps = {
  dataTestId?: string;
  roomName: string;
  open: boolean;
  onCancelClick: () => void;
  onConfirmClick: () => void;
};

const CommunicationProfileRemoveDialog = ({
  open,
  roomName,
  onCancelClick,
  onConfirmClick,
}: CommunicationProfileRemoveDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Disable Communication Profile - {roomName}</DialogTitle>
      <DialogContent>
        <Typography>Are you sure that you want remove this communication profile?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button variant="contained" onClick={onConfirmClick}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommunicationProfileRemoveDialog;
