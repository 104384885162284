import { Box, Typography } from '@mui/material';

type ConversationAboutTabProps = {
  aboutText: string;
};

const ConversationAboutTab = ({ aboutText }: ConversationAboutTabProps) => {
  return (
    <Box data-testid="about-panel" sx={rootSx}>
      <Typography sx={typographySx} variant="body1">
        {aboutText}
      </Typography>
    </Box>
  );
};

const rootSx = {
  display: 'flex',
  flexDirection: 'column',
};

const typographySx = {
  px: 3,
  pt: 3,
  whiteSpace: 'pre-line',
};

export default ConversationAboutTab;
