import { LoadingButton } from '@mui/lab';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import { useMakeTestId } from '@serenityapp/components-react-web';

type CheckInEditInfoDialogProps = {
  open: boolean;
  onClose: () => void;
  dataTestId?: string;
};

const CheckInEditInfoDialog = ({ onClose, dataTestId, open }: CheckInEditInfoDialogProps) => {
  const makeTestId = useMakeTestId('CheckInInfoDialog', dataTestId);
  const infoDialog = makeTestId('');

  return (
    <Dialog fullWidth data-testid={infoDialog} open={open} onClose={onClose}>
      <DialogTitle>Changes Applied</DialogTitle>
      <DialogContent>
        <Typography>
          Updates to the following fields have been applied and are now live:
        </Typography>
        <List dense sx={listSx}>
          <ListItem sx={listItemSx}>Who can manage reports</ListItem>
          <ListItem sx={listItemSx}>Who can approve reports</ListItem>
          <ListItem sx={listItemSx}>Participants</ListItem>
        </List>
        <Typography>
          Check-In time changes will take effect at the start of the next day.
        </Typography>
        <Typography>
          All other changes will take effect at the start of your next Check-In session.
        </Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton data-testid={makeTestId('got-it')} variant="contained" onClick={onClose}>
          Got it
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const listSx = {
  listStyle: 'inside',
};

const listItemSx = {
  display: 'list-item',
};

export default CheckInEditInfoDialog;
