import { PermissionNameRecord } from '@serenityapp/domain';
import { getViewerState } from '@serenityapp/redux-store';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

type PermissionGuardProps = {
  requiredPermissions: string[];
};

// This component is used to guard routes that require certain permissions.

// For now it only checks if the user has the permission to manage IP allow list policies.
// But ideally we would have a hook that returns all permissions user has
// and based on that we could change the implementation to support it.
// This component should be able to accept any permission we have in the system and check if
// the user has it.
const PermissionGuard = ({ requiredPermissions }: PermissionGuardProps) => {
  const { canAdminIpAllowListPolicy, ready } = useSelector(getViewerState);

  if (!ready) {
    return null;
  }

  if (
    requiredPermissions.includes(PermissionNameRecord.CAN_MANAGE_IP_ALLOW_LIST_POLICY) &&
    canAdminIpAllowListPolicy
  ) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export default PermissionGuard;
