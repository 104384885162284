import { StringFn } from '@serenityapp/core';

type Crumb = {
  title: string;
  to?: string;
  id: string;
};

export default function useBreadcrumbs(title: string) {
  const adminCrumb: Crumb = {
    title: 'Admin',
    to: '../..',
    id: 'admin',
  };

  const a4slCrumb: Crumb = {
    title: 'Alexa for Senior Living',
    to: '..',
    id: 'a4sl',
  };

  const crumbs = [adminCrumb, a4slCrumb];

  crumbs.push({
    title,
    id: StringFn.generateKey(title),
  });

  return crumbs;
}
