export const sectionHeaderSx = {
  bgcolor: 'background.paper',
};

export const sectionListSx = {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  pt: 0,
  pb: 3,
};

export const dialogContentSx = {
  pt: 0,
};

export const switchSx = {
  ml: 2,
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: 'auto',
};

export const listItemTextSx = {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  width: 0,
};

export const listItemSx = {
  pb: 3,
};
