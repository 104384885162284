export const alertSx = {
  mb: 2,
};

export const fieldSx = {
  mb: 0,
};

export const containerSx = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
};

export const cardContentSx = {
  py: 0,
  px: 3,
};

export const cardHeaderSx = {
  px: 3,
};

export const cardActionsSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  pt: 0,
  pb: 8,
  px: 3,
};

export const cardSx = {
  width: 448,
};

export const logoWrapperSx = {
  display: 'flex',
  justifyContent: 'center',
  pt: 8,
  pb: 3,
};

export const buttonSx = {
  color: 'text.primary',
  mx: 0,

  '& .MuiSvgIcon-root': {
    color: 'action.active',
  },
};

export const submitButtonSx = {
  mb: 2,
};
