export const sectionSx = {
  m: 3,
};

export const organizationDetailSectionSx = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  mx: 3,
  my: 1.5,
};
