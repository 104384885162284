import { List, ListItem, ListItemText, ListSubheader, Skeleton, Switch } from '@mui/material';

import { sectionListSx, sectionHeaderSx } from './notificationSettingsStyles';

const NotificationSettingsItemSkeleton = ({ width }: { width: string }) => (
  <ListItem disableGutters>
    <ListItemText primary={<Skeleton component="span" width={width} />} />
    <Switch disabled />
  </ListItem>
);

const NotificationSettingsSkeleton = () => {
  return (
    <List>
      <ListItem disableGutters>
        <ListItemText
          primary="Mute conversations"
          secondary="Turn the switch off to mute conversation."
        />
      </ListItem>
      <ListItem disableGutters>
        <List sx={sectionListSx}>
          <ListSubheader disableGutters sx={sectionHeaderSx}>
            <Skeleton component="span" width={'40%'} />
          </ListSubheader>
          <NotificationSettingsItemSkeleton width={'35%'} />
          <NotificationSettingsItemSkeleton width={'21%'} />
          <NotificationSettingsItemSkeleton width={'43%'} />
        </List>
      </ListItem>
      <ListItem disableGutters>
        <List sx={sectionListSx}>
          <ListSubheader disableGutters sx={sectionHeaderSx}>
            <Skeleton component="span" width={'35%'} />
          </ListSubheader>
          <NotificationSettingsItemSkeleton width={'45%'} />
          <NotificationSettingsItemSkeleton width={'21%'} />
        </List>
      </ListItem>
    </List>
  );
};

export default NotificationSettingsSkeleton;
