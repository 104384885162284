export const tabPanelSx = {
  p: 0,
};

export const tabsSx = {
  position: 'sticky',
  top: 0,
  zIndex: 1000,
  bgcolor: 'background.paper',
  '& .MuiTab-root': {
    textTransform: 'none',
  },
};

export const drawerContentSx = {
  pt: 0,
};
