import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Outlet } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { getSnackbarFirstConversationSnack, snackDismiss } from '@serenityapp/redux-store';
import { ConversationParamId } from '@serenityapp/domain';
import { Snackbar, useMakeTestId, View } from '@serenityapp/components-react-web';
import { useConversationPage } from '../hooks';
import MessagesList from './MessagesList';
import { snackbarSx } from './conversationPageStyles';
import { ConversationPageHeader } from './ConversationPageHeader';
import { noop } from '@serenityapp/core';
import MessageEntry from './MessageEntry';

const ConversationPage = () => {
  const makeTestId = useMakeTestId('ConversationPage');
  const mainTestId = makeTestId('');

  const dispatch = useDispatch();

  const { conversationId } = useParams<ConversationParamId>() as ConversationParamId;

  const { conversation, isLoading } = useConversationPage(conversationId);

  const {
    canCreateMessage,
    lastRead,
    name,
    subtype,
    subsubtype,
    isGroupConversation,
    unreadCount,
  } = conversation ?? {};

  const firstSnack = useSelector(getSnackbarFirstConversationSnack);

  const renderSnackbar = () => {
    if (!firstSnack) return;

    const onSnackbarClose = () => dispatch(snackDismiss({ id: firstSnack.id }));
    return (
      <Snackbar
        open
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={firstSnack.timeout}
        message={firstSnack.message}
        sx={snackbarSx}
        type={firstSnack.type}
        onClose={onSnackbarClose}
      />
    );
  };

  return (
    <View dataTestId={mainTestId}>
      <View.Header>
        <ConversationPageHeader
          canCreateMessage={canCreateMessage}
          isGroupConversation={isGroupConversation}
          isLoading={isLoading && !conversation}
          name={name}
          subtype={subtype}
        />
      </View.Header>
      <View.Content>
        {renderSnackbar()}
        <MessagesList
          key={conversationId}
          conversationId={conversationId}
          lastRead={lastRead}
          unreadCount={unreadCount}
        />
      </View.Content>
      {canCreateMessage && (
        <View.Footer>
          <MessageEntry
            key={conversationId}
            conversationId={conversationId}
            conversationName={conversation?.name ?? ''}
            conversationSubsubtype={subsubtype}
            onSendMessage={noop}
          />
        </View.Footer>
      )}
      <Outlet />
    </View>
  );
};

ConversationPage.displayName = 'ConversationPage';

export default memo(Sentry.withProfiler(ConversationPage));
