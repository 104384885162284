import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetApp } from '@mui/icons-material';
import { FileViewer, FileViewerActionButtonProps } from '@serenityapp/components-react-web';
import { useFileViewer } from '../hooks';
import { downloadFile } from '../../../utils';

type FileViewerDialogParams = {
  conversationId: string;
  messageId: string;
  fileId?: string;
};

const FileViewerDialog = () => {
  const navigate = useNavigate();
  const { conversationId, messageId, fileId } =
    useParams<FileViewerDialogParams>() as FileViewerDialogParams;
  const location = useLocation();

  const { state } = location;

  const { files, canSaveFile } = useFileViewer(
    conversationId,
    state?.messagePartItems ?? [],
    state?.sender ?? {},
  );

  useEffect(() => {
    if (!location.state) {
      navigate('..');
    }
  }, [location, conversationId, navigate]);

  const fileIdIndex = files.findIndex((file) => file.id === fileId);
  const initialFileIndex = fileIdIndex < 0 ? 0 : fileIdIndex;

  const actionButtons: Array<FileViewerActionButtonProps> = [];
  if (canSaveFile) {
    actionButtons.push({
      Icon: GetApp,
      id: 'download',
      onClick: downloadFile,
      tooltip: 'Download',
    });
  }

  const handleCloseClick = () => navigate('..');

  const onFileChange = (id: string) => {
    navigate(`../file-viewer/${messageId}/${id}`, { replace: true, state });
  };

  return (
    <FileViewer
      open
      actionButtons={actionButtons}
      files={files}
      initialFileIndex={initialFileIndex}
      onCloseClick={handleCloseClick}
      onFileChange={onFileChange}
    />
  );
};

export default FileViewerDialog;
