import {
  AbstractStorageProvider,
  IStorageOutboxProvider,
  IStorageProvider,
  OutboxResult,
  StoragePutFileInput,
  StoragePutFileOutput,
} from '@serenityapp/core-storage';

export class StorageOutboxWeb extends AbstractStorageProvider implements IStorageOutboxProvider {
  private outbox: Array<StoragePutFileInput> = [];

  async countDeadLetters(): Promise<number> {
    // We're not using dead letter queue since there's no filesystem involved
    return 0;
  }

  async countPending(): Promise<number> {
    return this.outbox.length;
  }

  async process(): Promise<ReadonlyArray<OutboxResult>> {
    const resultPromises = this.outbox.map(async (input, index) => {
      this.config.analytics.track({
        name: 'outbox/process-one-put-input',
        attributes: {
          inputKeys: Object.keys(input ?? {}),
        },
      });

      const putFileResult = await super.putFile(input);

      if (putFileResult.success) {
        this.config.analytics.track({ name: 'outbox/process-one-put-success' });
      } else {
        this.config.analytics.track({ name: 'outbox/process-one-put-error' });
      }

      return {
        output: putFileResult?.descriptor
          ? {
              descriptor: putFileResult.descriptor,
              success: putFileResult.success,
              localPath: input.originalFile.uri,
            }
          : undefined,
        success: putFileResult.success,
        hasMore: index !== this.outbox.length - 1,
      };
    });

    const result = await Promise.all(resultPromises);

    this.outbox = [];

    return result;
  }

  async putFile(input: StoragePutFileInput): Promise<StoragePutFileOutput> {
    this.sentry.addBreadcrumb({
      message: 'StorageOutboxWeb putFile called',
    });
    this.outbox.push(input);

    return {
      descriptor: input.descriptor,
      localPath: input.originalFile.uri,
      success: true,
    };
  }

  // There's nothing to resurrect, since nothing's dead
  async resurrect(): Promise<void> {
    return undefined;
  }
}

export async function wrapOutbox(inner: IStorageProvider): Promise<IStorageOutboxProvider> {
  const provider = new StorageOutboxWeb(inner);
  await provider.init();

  return provider;
}
