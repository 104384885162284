import { useState } from 'react';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

import { WithKey } from '@serenityapp/domain';
import { BasicMenu, BasicMenuItemProps, useMakeTestId } from '@serenityapp/components-react-web';

import { SystemResetPasswordDialog } from './SystemUserActionsDialogs';

const SystemUserActionsMenu = () => {
  const makeTestId = useMakeTestId('UsersActionsMenu');
  const mainTestId = makeTestId('');
  const [userActionsMenuAnchor, setUserActionsMenuAnchor] = useState<null | HTMLElement>(null);

  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState<boolean>(false);

  const openUserActionsMenuAnchor = Boolean(userActionsMenuAnchor);

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setUserActionsMenuAnchor(null);
  };

  const handleActionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setUserActionsMenuAnchor(event.currentTarget);
  };

  const userActionsMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [];

  userActionsMenuSections.push([
    {
      key: 'system-reset-password',
      dataTestId: makeTestId('system-reset-password'),
      onClick: () => setIsResetPasswordDialogOpen(true),
      label: 'Reset Password',
      handleCloseMenu,
    },
  ]);

  return (
    <>
      <IconButton
        area-label="Open menu"
        data-testid={mainTestId}
        onClick={handleActionsMenuClick}
      >
        <MoreVert />
      </IconButton>
      <BasicMenu
        key="basic-menu"
        keepMounted
        anchorEl={userActionsMenuAnchor}
        open={openUserActionsMenuAnchor}
        sections={userActionsMenuSections}
        onClose={handleCloseMenu}
      />
      {isResetPasswordDialogOpen && (
        <SystemResetPasswordDialog onClose={() => setIsResetPasswordDialogOpen(false)} />
      )}
    </>
  );
};

export default SystemUserActionsMenu;
