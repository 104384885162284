import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, IconButton } from '@mui/material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { Send } from '@mui/icons-material';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { RootState, getMessageEntryByConversationId } from '@serenityapp/redux-store';
import { SEND_BUTTON_CLICKED_COMMAND } from '../../lexical';

type SendButtonProps = {
  conversationId: string;
};

const SendButton = ({ conversationId }: SendButtonProps) => {
  const [editor] = useLexicalComposerContext();

  const isEditorBlank = useLexicalIsTextContentEmpty(editor);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const { attachments = [] } = useSelector((state: RootState) =>
    getMessageEntryByConversationId(state, conversationId),
  );

  useEffect(() => {
    setIsButtonDisabled(isEditorBlank && attachments.length === 0);
  }, [isEditorBlank, editor, attachments.length]);

  const tooltipTitle = isButtonDisabled ? "Can't send empty message" : 'Click to send a message';

  const handleClick = () => {
    if (isButtonDisabled) return;
    editor.dispatchCommand(SEND_BUTTON_CLICKED_COMMAND, undefined);

    setIsButtonDisabled(true);
  };

  return (
    <Tooltip arrow title={tooltipTitle}>
      <span>
        <IconButton
          aria-label="send"
          data-testid="SendMessage"
          disabled={isButtonDisabled}
          size="small"
          sx={[sendSx, isButtonDisabled && sendDisabledSx]}
          onClick={handleClick}
        >
          <Send fontSize="medium" />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export const sendSx = {
  '&.MuiIconButton-root': {
    color: 'primary.main',
  },
};

export const sendDisabledSx = {
  '&.MuiIconButton-root': {
    color: 'action.disabled',
  },
};

export default SendButton;
