import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Drawer, AppStatusChip, icons } from '@serenityapp/components-react-web';
import { Typography, Divider, Box, ListItem, ListItemText } from '@mui/material';
import { App, DateFn } from '@serenityapp/core';
import {
  appTransformer,
  useApps,
  useOrganizationDetail,
  useViewerBaseInformation,
} from '@serenityapp/redux-store';
import {
  accountNameSx,
  accountWrapperSx,
  appStatusChipSx,
  lastSyncedSx,
  listItemsWrapperSx,
  sectionDividerSx,
} from './pccAppDrawerStyles';
import { AppStatus } from '@serenityapp/domain';

const { PCC } = icons;

const PCCAppDrawer = () => {
  const navigate = useNavigate();

  const { data: viewerData, isBaseInformationLoading } = useViewerBaseInformation();
  const organizationId = viewerData.me.user?.orgId ?? '';

  const { organization } = useOrganizationDetail(organizationId);
  const organizationName = organization?.name ?? '';

  const { data } = useApps();
  const app = appTransformer(data, App.Names.PointClickCare);

  const isLoadingApp = isBaseInformationLoading || !organizationId || !app;

  const onClose = useCallback(() => {
    navigate('..');
  }, [navigate]);

  return (
    <Drawer open onClose={onClose}>
      {isLoadingApp ? undefined : (
        <>
          <Drawer.Header>
            <Typography noWrap variant="h6">
              {app.node.displayName}
              <AppStatusChip status={app.status} sx={appStatusChipSx} />
            </Typography>
          </Drawer.Header>
          <Drawer.Content>
            <Typography display="block" variant="overline">
              Connection status
            </Typography>
            <Divider sx={sectionDividerSx} />
            <Box sx={listItemsWrapperSx}>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Box sx={accountWrapperSx}>
                      <PCC />
                      <Box sx={accountNameSx}>{`${organizationName}'s PointClickCare`}</Box>
                    </Box>
                  }
                  secondary="Account"
                />
              </ListItem>
              <ListItem disableGutters>
                <ListItemText
                  primary={
                    <Box sx={lastSyncedSx}>
                      {app.status === AppStatus.DISABLED
                        ? 'Syncing...'
                        : DateFn.format(app.jobInfo.timestamp, 'LLL do, h:mmaaa', '')}
                    </Box>
                  }
                  secondary="Last synced"
                />
              </ListItem>
            </Box>
          </Drawer.Content>
        </>
      )}
    </Drawer>
  );
};

export default PCCAppDrawer;
