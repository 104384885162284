import { useCallback, useMemo } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowProps,
} from '@mui/x-data-grid-pro';

import { AdministrationHeader, View, useMakeTestId } from '@serenityapp/components-react-web';
import { useChannels } from '@serenityapp/client-data';

import { ADMIN_DATA_GRID_PAGE_SIZE_OPTIONS, DEFAULT_ADMIN_DATA_GRID_PAGE_SIZE } from '../utils';
import CustomGridRow from '../components/CustomGridRow';
import AdminGridData from '../components/AdminDataGrid';
import { useAdminBreadcrumbs } from '../hooks';
import { ActionsCell, QuickSearchToolbarChannels } from './components';

const ChannelsPage = () => {
  const title = 'Channels Management';
  const makeTestId = useMakeTestId('ChannelsPage');
  const mainTestId = makeTestId('');

  const navigate = useNavigate();

  const { channels, fetching } = useChannels();

  const goToResourceDetails = (params: GridRowParams) => {
    const { id } = params;
    navigate(id.toString());
  };
  const goToResourceCreate = () => navigate('create');

  const crumbs = useAdminBreadcrumbs(title);

  const addButton = (
    <Button
      key="add-button"
      data-testid={makeTestId('add-button')}
      startIcon={<Add />}
      variant="contained"
      onClick={goToResourceCreate}
    >
      New
      <Box component="span" sx={longButtonTitleSx}>
        &nbsp;channel
      </Box>
    </Button>
  );

  const actionsCellRenderer = useCallback(
    (params: GridRenderCellParams) => <ActionsCell params={params} makeTestId={makeTestId} />,
    [makeTestId],
  );

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        filterable: false,
        sortable: true,
      },
      {
        field: 'subsubtype',
        headerName: 'Type',
        flex: 1,
        sortable: true,
        filterable: false,
        getApplyQuickFilterFn: () => null,
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 1,
        filterable: false,
        sortable: false,
        getApplyQuickFilterFn: () => null,
      },
      {
        field: 'id',
        headerName: 'Actions',
        width: 80,
        disableColumnMenu: true,
        disableColumnSelector: true,
        sortable: false,
        renderCell: actionsCellRenderer,
        filterable: false,
        getApplyQuickFilterFn: () => null,
      },
    ],
    [actionsCellRenderer],
  );

  return (
    <View>
      <View.Header>
        <AdministrationHeader actions={[addButton]} crumbs={crumbs} title={title} />
      </View.Header>
      <View.Content>
        <AdminGridData
          disableColumnSelector
          disableRowSelectionOnClick
          ignoreDiacritics
          disableColumnMenu
          initialState={{
            sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
            pagination: { paginationModel: { pageSize: DEFAULT_ADMIN_DATA_GRID_PAGE_SIZE } },
          }}
          loading={fetching && !channels.length}
          pageSizeOptions={ADMIN_DATA_GRID_PAGE_SIZE_OPTIONS}
          onRowClick={goToResourceDetails}
          pagination
          columns={columns}
          rows={channels}
          slots={{
            toolbar: QuickSearchToolbarChannels,
            row: (props: GridRowProps) => <CustomGridRow {...props} dataTestId={mainTestId} />,
          }}
          slotProps={{
            loadingOverlay: {
              variant: 'linear-progress',
              noRowsVariant: 'skeleton',
            },
          }}
        />
      </View.Content>
      <Outlet />
    </View>
  );
};

const longButtonTitleSx = {
  display: {
    xs: 'none',
    sm: 'block',
  },
};

export default ChannelsPage;
