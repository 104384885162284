import { Forum } from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { View, EmptyStateView } from '@serenityapp/components-react-web';
import { useViewerBaseInformation } from '@serenityapp/redux-store';

const EmptyDMsPage = () => {
  const navigate = useNavigate();

  const viewerBaseInformation = useViewerBaseInformation();
  const viewerPermissions = viewerBaseInformation.data.permissions;

  const onDirectMessageClick = () => navigate('create-dm');

  const actionButton = viewerPermissions.canCreateDM ? (
    <Button sx={buttonSx} variant="outlined" onClick={onDirectMessageClick}>
      Direct message
    </Button>
  ) : undefined;

  return (
    <View>
      <View.Content>
        <EmptyStateView
          actionButton={actionButton}
          description={`You have no direct messages yet.${
            viewerPermissions.canCreateDM ? ' Start by creating one.' : ''
          }`}
          Icon={Forum}
          title="No Direct Messages"
        />
      </View.Content>
      <Outlet />
    </View>
  );
};

const buttonSx = {
  mt: 2.25,
};

export default EmptyDMsPage;
