import { useRouteError } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import * as Sentry from '@sentry/react';

// A component that renders when a route error occurs.
// Example: When we try to access a property of undefined variable, this page will be rendered.
// It will send logs to sentry and also make sure to give some instructions to the user on what
// to do next.
const RootErrorBoundary = () => {
  const error = useRouteError();

  Sentry.captureException(error);

  return (
    <Box sx={boxSx}>
      <Box sx={titleSx}>Oops!</Box>
      <Box sx={errorSx}>Looks like something went wrong</Box>
      <Box sx={descriptionSx}>
        We track these errors automatically, but if the problem persists feel free to contact us.
        In the meantime, try reloading the page.
      </Box>
      <Button onClick={() => window.location.replace('/')} variant="contained">
        Reload
      </Button>
      <Button sx={contactUsSx} variant="text" href="mailto:support@serenityconnect.com">
        Contact us
      </Button>
    </Box>
  );
};

const titleSx = {
  typography: 'h1',
  mb: 1,
};

const descriptionSx = {
  fontSize: 18,
  maxWidth: 500,
  textAlign: 'center',
  mb: 2,
};

const errorSx = {
  fontSize: 18,
  mb: 2,
};

const boxSx = {
  width: '100%',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const contactUsSx = {
  mt: 2,
};

export default RootErrorBoundary;
