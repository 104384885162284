import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  Chip,
  Skeleton,
  Typography,
} from '@mui/material';
import { Add, MoreVert } from '@mui/icons-material';

import {
  View,
  BasicTable,
  BasicTableHeadCellProps,
  BasicTableRowDataProps,
} from '@serenityapp/components-react-web';
import { useRetentionPolicies, retentionPolicyArchive, snackAdd } from '@serenityapp/redux-store';

import { SectionHeader, ArchiveDialog } from '../../components';
import { retentionPoliciesTransformer } from './transformers';
import { activeChipSx, statusCellSx, tableSx } from './retentionPoliciesTableStyles';

const RetentionPoliciesTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: retentionPoliciesData, isLoading: isLoadingRetentionPolicies } =
    useRetentionPolicies({ variables: {} });
  const retentionPoliciesRows = retentionPoliciesTransformer(
    retentionPoliciesData,
    isLoadingRetentionPolicies,
  );

  const [isArchiving, setIsArchiving] = useState(false);
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState(false);
  const [retentionPoliciesActionsMenuAnchorEl, setRetentionPoliciesActionsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [currentlySelectedRowRetentionPolicies, setCurrentlySelectedRowRetentionPolicies] =
    useState<null | BasicTableRowDataProps>(null);

  const actionsCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setRetentionPoliciesActionsMenuAnchorEl(event.currentTarget);
      setCurrentlySelectedRowRetentionPolicies(row);
    };

    return (
      <TableCell
        key={`user-actions-data-retention-${row?.id}`}
        align="right"
        data-testid={dataTestId}
        padding="none"
      >
        <IconButton
          aria-label="Open menu"
          disabled={isLoadingRetentionPolicies}
          onClick={onClick}
        >
          <MoreVert />
        </IconButton>
      </TableCell>
    );
  };

  const statusCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    if (!row)
      return (
        <TableCell key="status-data-retention-skeleton">
          <Skeleton />
        </TableCell>
      );

    return (
      <TableCell
        key={`status-data-retention-${row.id}`}
        data-testid={dataTestId}
        padding="none"
        sx={statusCellSx}
      >
        {row.active ? <Chip label="Active" sx={activeChipSx} /> : <Chip label="Inactive" />}
      </TableCell>
    );
  };

  const retentionPoliciesHeadCells: BasicTableHeadCellProps[] = [
    { id: 'name', label: 'Policy name' },
    { id: 'active', label: 'Status', cellRenderer: statusCellRenderer },
    {
      id: 'actions',
      label: 'Actions',
      sort: false,
      align: 'right',
      cellRenderer: actionsCellRenderer,
    },
  ];

  const handleRetentionPoliciesRowClick = (id: string) => navigate(`retention-policy/${id}`);

  const handleRetentionPoliciesActionsMenuClose = () => {
    setRetentionPoliciesActionsMenuAnchorEl(null);
  };

  const handleRetentionPolicyCreateButtonClick = () => {
    navigate('retention-policy/create');
  };

  const handleRetentionPolicyEditActionClick = () => {
    navigate(`retention-policy/${currentlySelectedRowRetentionPolicies.id}`);
    handleRetentionPoliciesActionsMenuClose();
  };

  const handleRetentionPolicyArchiveActionClick = () => {
    setIsArchiveDialogOpen(true);
    handleRetentionPoliciesActionsMenuClose();
  };

  const onArchiveDialogClose = () => setIsArchiveDialogOpen(false);

  const onSuccess = () => {
    setIsArchiving(false);
    setIsArchiveDialogOpen(false);
    dispatch(snackAdd({ message: 'Retention policy successfully archived.', type: 'success' }));
  };

  const onFailed = () => {
    setIsArchiving(false);
    dispatch(snackAdd({ message: 'Error archiving retention policy.', type: 'error' }));
  };

  const handleArchiveRetentionPolicy = () => {
    dispatch(
      retentionPolicyArchive({
        onFailed,
        onSuccess,
        variables: {
          input: {
            id: currentlySelectedRowRetentionPolicies.id,
            organization: {
              id: retentionPoliciesData?.viewer?.organization.id,
            },
          },
        },
      }),
    );
  };

  const retentionPoliciesAddButton = (
    <Button
      disabled={isLoadingRetentionPolicies}
      size="medium"
      startIcon={<Add />}
      variant="contained"
      onClick={handleRetentionPolicyCreateButtonClick}
    >
      Add
    </Button>
  );

  const retentionPoliciesActionsMenu = (
    <Menu
      open
      anchorEl={retentionPoliciesActionsMenuAnchorEl}
      onClose={handleRetentionPoliciesActionsMenuClose}
    >
      <MenuItem onClick={handleRetentionPolicyEditActionClick}>Edit</MenuItem>
      <MenuItem
        disabled={currentlySelectedRowRetentionPolicies?.isDefault}
        onClick={handleRetentionPolicyArchiveActionClick}
      >
        Archive
      </MenuItem>
    </Menu>
  );

  const archiveDialog = currentlySelectedRowRetentionPolicies ? (
    <ArchiveDialog
      dataTestId="RetentionPolicies"
      handleConfirmClick={handleArchiveRetentionPolicy}
      isArchiving={isArchiving}
      resourceName={currentlySelectedRowRetentionPolicies?.name}
      title="Archive Retention Policy"
      onClose={onArchiveDialogClose}
    />
  ) : null;

  return (
    <View>
      {isArchiveDialogOpen ? archiveDialog : null}
      {retentionPoliciesActionsMenuAnchorEl ? retentionPoliciesActionsMenu : null}
      <SectionHeader
        action={retentionPoliciesAddButton}
        description="Define when to remove data in Serenity."
        title="Data retention"
      />
      <BasicTable
        handleRowClick={handleRetentionPoliciesRowClick}
        headCells={retentionPoliciesHeadCells}
        rows={retentionPoliciesRows}
        showSkeleton={isLoadingRetentionPolicies}
        skeletonRowsCount={2}
        sx={tableSx}
      />
      {!retentionPoliciesRows.length && !isLoadingRetentionPolicies && (
        <Typography sx={{ m: 2 }} variant="body1">
          No retention policies found.
        </Typography>
      )}
    </View>
  );
};

export default RetentionPoliciesTable;
