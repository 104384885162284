import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikValues } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Delete as DeleteIcon,
  NotInterested as NotInterestedIcon,
  Check as CheckIcon,
} from '@mui/icons-material';

import {
  ipAllowListPolicyEdit,
  snackAdd,
  useIpAllowListPolicies,
  ipAllowListPolicyTransformer,
  getSortedIAMGroups,
  getIAMGroupsLoadingState,
} from '@serenityapp/redux-store';
import { IAMGroupProps, Range, Role, RouteParamId, WithKey } from '@serenityapp/domain';

import IpAllowListPolicyCreateEditForm from './components/IpAllowListPolicyCreateEditForm/IpAllowListPolicyCreateEditForm';
import { useCurrentUser } from '../common/hooks';
import {
  IpAllowListPolicyCreateEditFormValues,
  SelectorItemProps,
} from '@serenityapp/components-react-common';
import { BasicMenu, BasicMenuItemProps, useMakeTestId } from '@serenityapp/components-react-web';
import DeleteIpAllowListPolicyDialog from './components/DeleteIpAllowListPolicyDialog';
import DisableIpAllowListPolicyDialog from './components/DisableIpAllowListPolicyDialog';

type GroupDataType = {
  byId: Record<string, SelectorItemProps>;
  list: Array<SelectorItemProps>;
};

const IpAllowListPolicyEditDrawer = () => {
  const { id } = useParams<RouteParamId>() as RouteParamId;

  const makeTestId = useMakeTestId('IpAllowListPolicyEditDrawer');
  const mainTestId = makeTestId('');

  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | HTMLElement>(null);

  const openActionsMenu = (event: React.MouseEvent<HTMLElement>) =>
    setActionsMenuAnchor(event.currentTarget);

  const handleActionsMenuClose = () => setActionsMenuAnchor(null);

  const [isSaving, setIsSaving] = useState(false);

  const { data: ipAllowListPoliciesData, isLoading: isLoadingIpAllowListPolicies } =
    useIpAllowListPolicies({ variables: {} });
  const ipAllowListPolicyEdge = ipAllowListPolicyTransformer(
    id,
    ipAllowListPoliciesData,
    isLoadingIpAllowListPolicies,
  );

  const isLoadingGroup = useSelector(getIAMGroupsLoadingState);
  const groups: Array<IAMGroupProps> = useSelector(getSortedIAMGroups);
  const isLoadingIAMGroups = !groups && isLoadingGroup;

  const groupsData: GroupDataType = groups.reduce(
    (acc: GroupDataType, group: IAMGroupProps) => {
      const g: SelectorItemProps = {
        id: group.id,
        label: group.name ?? '',
      };
      acc.list.push(g);
      acc.byId = {
        ...acc.byId,
        [group.id]: g,
      };
      return acc;
    },
    { list: [], byId: {} } as GroupDataType,
  );

  const initialValues: IpAllowListPolicyCreateEditFormValues = {
    name: ipAllowListPolicyEdge?.name || '',
    roles:
      ipAllowListPolicyEdge && !isLoadingIAMGroups
        ? ipAllowListPolicyEdge.roles.map((role: Role) => ({
            id: groupsData.byId[role.iamGroupId].id,
            label: groupsData.byId[role.iamGroupId].label || '',
          }))
        : [],
    ranges: ipAllowListPolicyEdge
      ? ipAllowListPolicyEdge.ranges.map((range) => ({
          ipStart: range.ipStart,
          ipEnd: range.ipEnd,
        }))
      : [],
  };

  const navigateOut = () => navigate('../');

  const onSuccess = () => {
    setIsSaving(false);
    navigateOut();
    dispatch(snackAdd({ message: 'IP allow list policy successfully edited.', type: 'success' }));
  };

  const onFailed = () => {
    setIsSaving(false);
    dispatch(snackAdd({ message: 'Error editing ip allow list policy.', type: 'error' }));
  };

  const handleSubmit = (values: FormikValues) => {
    const orgId = currentUser?.orgId;
    if (!orgId) return;

    setIsSaving(true);

    dispatch(
      ipAllowListPolicyEdit({
        onSuccess,
        onFailed,
        variables: {
          input: {
            organization: {
              id: orgId,
            },
            ipAllowListPolicy: {
              id,
              name: values.name,
              ranges: values.ranges.map((range: Range) => ({
                ipStart: range.ipStart,
                ipEnd: range.ipEnd ? range.ipEnd : range.ipStart,
              })),
              roleIds: values.roles.map((role: SelectorItemProps) => role.id),
            },
          },
        },
      }),
    );
  };

  const handleIpAllowListPolicyDeleteActionClick = () => {
    setIsDeleteDialogOpen(true);
    handleActionsMenuClose();
  };

  const handleIpAllowListPolicyDisableActionClick = () => {
    setIsDisableDialogOpen(true);
    handleActionsMenuClose();
  };

  const onDisableDialogClose = () => setIsDisableDialogOpen(false);
  const onDeleteDialogClose = () => setIsDeleteDialogOpen(false);
  const onDeleteActionConfirm = () => {
    navigateOut();
    setIsDeleteDialogOpen(false);
  };

  const ipAllowListPoliciesMenuSections: Array<Array<WithKey<BasicMenuItemProps>>> = [
    [
      {
        key: 'delete-ip-allow-list-policy',
        dataTestId: makeTestId('delete-ip-allow-list-in-policy'),
        onClick: handleIpAllowListPolicyDeleteActionClick,
        label: 'Delete',
        Icon: DeleteIcon,
        handleCloseMenu: handleActionsMenuClose,
        tooltip: 'Delete IP Allow List policy',
      },
      {
        key: 'toggle-status-ip-allow-list-policy',
        dataTestId: makeTestId('toggle-status-ip-allow-list-in-policy'),
        onClick: handleIpAllowListPolicyDisableActionClick,
        label: ipAllowListPolicyEdge?.disabled ? 'Enable' : 'Disable',
        Icon: ipAllowListPolicyEdge?.disabled ? CheckIcon : NotInterestedIcon,
        handleCloseMenu: handleActionsMenuClose,
        tooltip: `${ipAllowListPolicyEdge?.disabled ? 'Enable' : 'Disable'} IP Allow List policy`,
      },
    ],
  ];

  return (
    <>
      {actionsMenuAnchor && (
        <BasicMenu
          anchorEl={actionsMenuAnchor}
          open={!!actionsMenuAnchor}
          sections={ipAllowListPoliciesMenuSections}
          onClose={() => setActionsMenuAnchor(null)}
        />
      )}
      {isDeleteDialogOpen && ipAllowListPolicyEdge && currentUser && (
        <DeleteIpAllowListPolicyDialog
          dataTestId={mainTestId}
          id={ipAllowListPolicyEdge?.id}
          orgId={currentUser.orgId}
          title={ipAllowListPolicyEdge?.name}
          onClose={onDeleteDialogClose}
          onSuccess={onDeleteActionConfirm}
        />
      )}
      {isDisableDialogOpen && ipAllowListPolicyEdge && currentUser && (
        <DisableIpAllowListPolicyDialog
          dataTestId={mainTestId}
          id={ipAllowListPolicyEdge?.id}
          orgId={currentUser.orgId}
          title={ipAllowListPolicyEdge?.name}
          onClose={onDisableDialogClose}
        />
      )}
      <IpAllowListPolicyCreateEditForm
        isEditMode
        dataTestId={mainTestId}
        groupsAsSelectorItems={groupsData.list}
        handleSubmit={handleSubmit}
        initialValues={initialValues}
        isDisabled={ipAllowListPolicyEdge?.disabled}
        isLoading={isLoadingIpAllowListPolicies || !ipAllowListPolicyEdge || isLoadingIAMGroups}
        isSaving={isSaving}
        openActionsMenu={openActionsMenu}
        title={ipAllowListPolicyEdge?.name || ''}
      />
    </>
  );
};

export default IpAllowListPolicyEditDrawer;
