import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  Form,
  FormikField,
  HorizontalSerenityColorLogo,
  PasswordField,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import { getViewerState, viewerClearSignInError, viewerSignIn } from '@serenityapp/redux-store';
import {
  LoginFormValues,
  LoginFormConfig,
  SerenityForm,
} from '@serenityapp/components-react-common';

import {
  alertSx,
  buttonSx,
  cardActionsSx,
  cardContentSx,
  cardHeaderSx,
  cardSx,
  containerSx,
  fieldSx,
  linkSx,
  disabledLinkSx,
  logoWrapperSx,
  linkButtonSx,
} from './signInPageStyles';

type LocationState = {
  from?: string;
};

const SignInPage = () => {
  const dispatch = useDispatch();
  const makeTestId = useMakeTestId('SignInPage');
  const location = useLocation();

  const navigate = useNavigate();
  const { ready, signingIn, signedIn, signInError, signOutReason } = useSelector(getViewerState);

  const formConfig = LoginFormConfig;
  const { strings } = formConfig;
  const shouldShowAlert = signInError || signOutReason;

  const clearSignInError = useCallback(() => {
    dispatch(viewerClearSignInError());
  }, [dispatch]);

  useEffect(() => {
    clearSignInError();
  }, [location.key, clearSignInError]);

  useEffect(() => {
    const locationState = location.state as LocationState;
    if (signedIn && ready) navigate(locationState.from || '/');
  }, [location, navigate, ready, signedIn]);

  const navigateToSignUp = () => navigate('/sign-up');
  const navigateToNeedHelp = () => navigate('/need-help');
  const navigateToSsoSignIn = () => navigate('/sso-sign-in');

  const handleFormSubmit = (values: LoginFormValues) => {
    dispatch(
      viewerSignIn({
        email: values.email,
        password: values.password.trim(),
        onNewPasswordRequired: () => navigate('/new-password'),
      }),
    );
  };

  return (
    <Container sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader sx={cardHeaderSx} title="Sign In" />
        <CardContent sx={cardContentSx}>
          {shouldShowAlert && (
            <Alert severity="error" sx={alertSx}>
              {signInError?.message ?? signOutReason?.message}
            </Alert>
          )}
          <SerenityForm config={LoginFormConfig} onSubmit={handleFormSubmit}>
            <Form disabled={signingIn}>
              <FormikField
                autoFocus
                autoComplete="email"
                dataTestId={makeTestId('email')}
                name="email"
                sx={fieldSx}
                type="email"
              />
              <PasswordField
                autoComplete="password"
                dataTestId={makeTestId('password')}
                name="password"
                sx={fieldSx}
              />
              <CardActions disableSpacing sx={cardActionsSx}>
                <LoadingButton
                  fullWidth
                  data-testid={makeTestId('submit')}
                  loading={signingIn || !ready}
                  size="large"
                  sx={buttonSx}
                  type="submit"
                  variant="contained"
                >
                  {strings.submit}
                </LoadingButton>

                <Grid container>
                  <Grid item xs>
                    <Link
                      component="button"
                      data-testid={makeTestId('forgotPassword')}
                      disabled={signingIn || !ready}
                      sx={signingIn || !ready ? disabledLinkSx : linkSx}
                      type="button"
                      underline="always"
                      variant="body1"
                      onClick={navigateToNeedHelp}
                    >
                      {strings.forgot}
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="button"
                      data-testid={makeTestId('sign-up')}
                      sx={signingIn || !ready ? disabledLinkSx : linkSx}
                      type="button"
                      underline="always"
                      variant="body1"
                      onClick={navigateToSignUp}
                    >
                      {strings.signup}
                    </Link>
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  color="neutral"
                  data-testid={makeTestId('signInWithSso')}
                  disabled={signingIn || !ready}
                  size="large"
                  sx={linkButtonSx}
                  type="button"
                  variant="outlined"
                  onClick={navigateToSsoSignIn}
                >
                  {strings.ssoSignIn}
                </Button>
              </CardActions>
            </Form>
          </SerenityForm>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SignInPage;
