import { ViewerOrganizationCheckInPoliciesQueryResult } from '@serenityapp/api-graphql';
import { DateFn, ZonedDateFn } from '@serenityapp/core';
import { Schedule, Schema, mapKeyValuePairsToRecord } from '@serenityapp/domain';
import { transformServiceLevelsToSelectorItemProps } from '../../../common/utils';

export const checkInPolicyTransformer = (
  checkInPoliciesData: ViewerOrganizationCheckInPoliciesQueryResult,
  isLoadingCheckInPolicies: boolean,
  policyId: string,
) => {
  if (isLoadingCheckInPolicies) return undefined;
  const znow = ZonedDateFn.now();
  const getScheduleTime = (schedule?: Schedule) =>
    ZonedDateFn.getZoneHours(schedule?.late || znow);

  const formatIamGroup = (
    edge:
      | Schema.CheckInPolicy.Approvers.Edge
      | Schema.CheckInPolicy.Viewers.Edge
      | Schema.CheckInPolicy.Managers.Edge,
  ) => ({
    id: edge.iamGroup.id,
    label: edge.iamGroup.name ?? '',
  });

  const checkInPoliciesEdges = checkInPoliciesData.viewer.organization.checkInPolicies.edges;
  const checkInPolicyEdge = checkInPoliciesEdges.find(
    (edge) => edge.checkInPolicy.id === policyId,
  );
  const checkInPolicy = checkInPolicyEdge?.checkInPolicy;
  const schedules = checkInPolicy?.schedules;
  const schedulesTime: number[] = [getScheduleTime(schedules?.[0])];

  if (schedules?.[1]) {
    schedulesTime.push(getScheduleTime(schedules?.[1]));
  }

  const serviceLevels = checkInPolicyEdge?.checkInPolicy.serviceLevels;
  const serviceLevelsToSelectorItemProps =
    transformServiceLevelsToSelectorItemProps(serviceLevels);

  const approvers = (checkInPolicy?.approvers?.edges || []).map(formatIamGroup);
  const managers = (checkInPolicy?.managers?.edges || []).map(formatIamGroup);
  const viewers = (checkInPolicy?.viewers?.edges || []).map(formatIamGroup);

  const data = checkInPolicy?.data ? mapKeyValuePairsToRecord(checkInPolicy?.data) : undefined;

  const checkInPolicyTimeZone = ZonedDateFn.getTimeZoneAbbreviation(
    schedules?.[0]?.start?.timeZone ?? '',
  );

  return {
    id: checkInPolicy?.id,
    name: checkInPolicy?.name,
    schedules,
    schedulesTime,
    serviceLevels: serviceLevelsToSelectorItemProps,
    approvers,
    managers,
    viewers,
    includeOptedOut: checkInPolicy?.includeOptedOut,
    cloudStorageName: data?.cloudStorageName,
    reportFolderId: data?.reportFolderId,
    allowAutoMove: checkInPolicy?.allowAutoMove,
    checkInPolicyTimeZone,
  };
};

export const getCheckInSchedules = (
  policyName: string,
  timeSliderValues: number[],
  timezone?: string,
): Schedule[] => {
  const schedules: Schedule[] = [];
  const znow = ZonedDateFn.now(timezone);

  timeSliderValues.forEach((value, index) => {
    const isFirstSchedule = index === 0;
    const isSecondSchedule = index === 1;

    const startValue = value - 2;
    const lateValue = value;
    const endValue = value + 2;

    let startDate = DateFn.setFromTimeString(znow, `${startValue}:00`);
    const lateDate = DateFn.setFromTimeString(znow, `${lateValue}:00`);
    let endDate = null;

    // If the schedule end is set to 24, we need to set it to 23:59 instead
    if (endValue === 24) {
      endDate = DateFn.setFromTimeString(znow, `23:59`);
    } else {
      endDate = DateFn.setFromTimeString(znow, `${endValue}:00`);
    }

    // First schedule should always start after midnight (0:10)
    // We need to have 10 min reserve for the previous schedule to finish
    if (isFirstSchedule) {
      startDate = DateFn.setFromTimeString(znow, `0:10`);
    }

    // If there is a second schedule, it needs to start right after the first schedule ends (with
    // a delay of 10 min). We need to have 10 min reserve for the first schedule to finish.
    if (isSecondSchedule) {
      const firstScheduleEnd = timeSliderValues[0] + 2;
      startDate = DateFn.setFromTimeString(znow, `${firstScheduleEnd}:10`);
    }

    schedules.push({
      name: `${policyName} session${index + 1}`,
      start: startDate,
      late: lateDate,
      end: endDate,
    });
  });

  return schedules;
};
