import {
  $createTextNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  Spread,
  TextNode,
} from 'lexical';
import { EmojiObject } from '../types';
import { REGULAR_EMOJI_SIZE } from '../plugins/utils';
import { Data } from 'emoji-mart';

export type SerializedEmojiNode = Spread<
  {
    emojiObject: EmojiObject;
    emojiSize?: number;
  },
  SerializedTextNode
>;

function convertEmojiElement(domNode: HTMLElement): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createTextNode(textContent);
    return {
      node,
    };
  }

  return null;
}
export default class EmojiNode extends TextNode {
  __emojiObject: EmojiObject;
  __emojiSize: number;

  static getType() {
    return 'emoji';
  }

  static clone(node: EmojiNode): EmojiNode {
    return new EmojiNode(node.__emojiObject, node.__text, node.__emojiSize, node.__key);
  }

  static importJSON(serializedNode: SerializedEmojiNode): EmojiNode {
    const node = $createEmojiNode(serializedNode.emojiObject, serializedNode.text);
    return node;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-emoji')) {
          return null;
        }
        return {
          conversion: convertEmojiElement,
          priority: 1,
        };
      },
    };
  }

  constructor(emojiObject: EmojiObject, text: string, emojiSize?: number, key?: NodeKey) {
    super(text, key);
    this.__emojiObject = emojiObject;
    this.__emojiSize = emojiSize || REGULAR_EMOJI_SIZE;
  }

  exportJSON(): SerializedEmojiNode {
    return {
      ...super.exportJSON(),
      emojiObject: this.__emojiObject,
      emojiSize: this.__emojiSize,
      type: 'emoji',
      version: 1,
    };
  }

  createDOM(config: EditorConfig) {
    const rootSpan = document.createElement('span');

    const theme = config.theme;
    const className = theme.emoji;

    if (className !== undefined) {
      rootSpan.className = className;
    }

    rootSpan.style.backgroundImage = `url("https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-google/15.0.0/img/google/sheets-clean/64.png")`;
    rootSpan.style.fontSize = `${this.__emojiSize}px`;
    rootSpan.style.width = `${this.__emojiSize}px`;
    rootSpan.style.height = `${this.__emojiSize}px`;
    rootSpan.title = this.__emojiObject.name;
    rootSpan.style.backgroundSize = `${100 * Data.sheet.cols}% ${100 * Data.sheet.rows}%`;
    rootSpan.style.backgroundPosition = `${
      (100 / (Data.sheet.cols - 1)) * this.__emojiObject.x
    }% ${(100 / (Data.sheet.rows - 1)) * this.__emojiObject.y}%`;

    const span = super.createDOM(config);
    rootSpan.append(span);

    return rootSpan;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-emoji', 'true');
    element.textContent = this.__text;
    return { element };
  }

  updateDOM(): boolean {
    return true;
  }

  setSize(size: number) {
    const writable = this.getWritable();
    writable.__emojiSize = size;
  }

  getSize(): number {
    return this.__emojiSize;
  }
}

export function $isEmojiNode(node: LexicalNode | null | undefined): node is EmojiNode {
  return node instanceof EmojiNode;
}

export function $createEmojiNode(emojiObject: EmojiObject, text: string, size?: number) {
  return new EmojiNode(emojiObject, text, size).setMode('token');
}
