import { Box, Divider, ListItem, ListItemText, Skeleton, Typography } from '@mui/material';
import { Drawer } from '@serenityapp/components-react-web';
import {
  accountNameSx,
  accountWrapperSx,
  lastSyncedSx,
  listItemsWrapperSx,
  sectionDividerSx,
} from './boxCloudStorageAppDrawerStyles';

const BoxCloudStorageAppDrawerSkeleton = () => (
  <>
    <Drawer.Header loading>
      <Skeleton width="60%" />
    </Drawer.Header>
    <Drawer.Content>
      <Typography display="block" variant="overline">
        <Skeleton width="40%" />
      </Typography>
      <Divider sx={sectionDividerSx} />
      <Box sx={listItemsWrapperSx}>
        <ListItem disableGutters>
          <ListItemText
            primary={
              <Box sx={accountWrapperSx}>
                <Skeleton height="24px" variant="rounded" width="24px" />
                <Box sx={accountNameSx}>
                  <Skeleton width="120px" />
                </Box>
              </Box>
            }
            secondary={<Skeleton width="30%" />}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary={
              <Box sx={lastSyncedSx}>
                <Skeleton width="50%" />
              </Box>
            }
            secondary={<Skeleton width="30%" />}
          />
        </ListItem>
      </Box>
    </Drawer.Content>
  </>
);

export default BoxCloudStorageAppDrawerSkeleton;
