import { Box } from '@mui/material';

const Placeholder = () => {
  return <Box sx={placeholderWrapperSx}>Type a message...</Box>;
};

const placeholderWrapperSx = {
  position: 'absolute',
  top: 12,
  left: 12,
  userSelect: 'none',
  display: 'inline-block',
  pointerEvents: 'none',
  color: 'action.disabled',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export default Placeholder;
