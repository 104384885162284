export const toolbarRightSx = {
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'flex-end',
};

export const toolbarLeftSx = {
  display: 'flex',
  flexDirection: 'row',
};

export const accountDataSx = {
  display: { xs: 'none', sm: 'flex' },
  alignItems: 'center',
};

export const listItemButtonSx = {
  maxWidth: 'fit-content',
  pr: 1,
  borderRadius: 1,

  '& .MuiListItemButton-root': {
    '&:hover': {
      borderRadius: 1,
    },
  },
};

export const listItemAvatarSx = {
  minWidth: 40,
};

export const listItemIconSx = {
  minWidth: 24,
  display: { xs: 'none', sm: 'flex' },
};

export const listItemTextSx = {
  display: { xs: 'none', sm: 'block' },
  textAlign: 'end',
  px: 1,
  py: 0,
  flexGrow: 0,
  flexShrink: 0,
  flexBasis: 'auto',
  maxWidth: 200,
  '& > .MuiTypography-root': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export const menuIconSx = { color: 'action.active' };

export const iconButtonSx = { display: { lg: 'none' } };

export const toolbarSx = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  pr: { md: 1, xs: 1 },
  pl: { md: 2, xs: 1 },
};

export const logoSx = {
  alignItems: 'center',
  mx: 1,
};

export const logoBreakpointSx = {
  display: { xs: 'none', sm: 'flex' },
};

export const arrowDropDownIconSx = {
  color: 'action.active',
};

export const roleButtonSx = {
  color: 'text.primary',

  '& .MuiButton-endIcon': {
    color: 'action.active',
  },
};

export const userInfoMenuSx = {
  display: {
    xs: 'block',
    sm: 'none',
  },
};

export const verticalLogoSx = {
  display: {
    md: 'none',
  },
};

export const horizontalLogoSx = {
  display: {
    xs: 'none',
    md: 'block',
  },
};
