import { useNavigate } from 'react-router-dom';
import { Box, Button, Chip, Divider, Skeleton, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { LoadingButton } from '@mui/lab';

import {
  IpAllowListPolicyCreateEditFormConfig,
  IpAllowListPolicyCreateEditFormValues,
  SelectorItemProps,
  SerenityForm,
} from '@serenityapp/components-react-common';
import {
  Drawer,
  FormikField,
  Form,
  AutocompleteChipSelectorField,
  useMakeTestId,
} from '@serenityapp/components-react-web';
import { useState } from 'react';
import ConfirmCloseDialog from '../ConfirmCloseDialog';
import IpRangeField from '../IpRangeField';
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  sectionHeaderSx,
  headerInfoSx,
  secondaryHeaderLineSx,
  activeChipSx,
  actionsButtonIconSx,
  actionsButtonSx,
  headerDividerSx,
  nameFieldSpacingSx,
  fieldBottomSpaceSx,
} from './ipAllowListPolicyCreateEditFormStyles';

type IpAllowListPolicyCreateEditFormProps = {
  title: string;
  initialValues?: Partial<IpAllowListPolicyCreateEditFormValues>;
  handleSubmit: (values: IpAllowListPolicyCreateEditFormValues) => void;
  isLoading?: boolean;
  isSaving?: boolean;
  groupsAsSelectorItems: SelectorItemProps[];
  isEditMode?: boolean;
  isDisabled?: boolean;
  openActionsMenu?: (event: React.MouseEvent<HTMLElement>) => void;
  dataTestId?: string;
};

const IpAllowListPolicyCreateEditForm = ({
  title,
  initialValues,
  handleSubmit,
  isLoading,
  isSaving,
  groupsAsSelectorItems,
  isEditMode,
  isDisabled,
  openActionsMenu,
  dataTestId,
}: IpAllowListPolicyCreateEditFormProps) => {
  const makeTestId = useMakeTestId('IpAllowListPolicyCreateEditForm', dataTestId);
  const mainTestId = makeTestId('');

  const navigate = useNavigate();

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const onCancelConfirmDialogClick = () => setIsConfirmDialogOpen(false);

  const handleClose = () => navigate('..');

  if (isLoading) {
    return (
      <Drawer open onClose={handleClose}>
        <Drawer.Header loading>
          <Typography noWrap variant="h6">
            <Skeleton width="150px" />
          </Typography>
        </Drawer.Header>
        <Drawer.Content>
          <Typography sx={sectionHeaderSx} variant="subtitle1m">
            <Skeleton width="150px" />
          </Typography>
          <Skeleton height="50px" />
          <Typography sx={sectionHeaderSx} variant="subtitle1m">
            <Skeleton width="150px" />
          </Typography>
          <Typography color="textSecondary" variant="body2">
            <Skeleton width="300px" />
          </Typography>
          <Skeleton height="50px" />
        </Drawer.Content>
      </Drawer>
    );
  }

  const renderDrawerBody = ({
    dirty,
    submitForm,
    handleChange,
    validateField,
  }: FormikProps<IpAllowListPolicyCreateEditFormValues>) => {
    const onDrawerCloseClick = () => {
      if (dirty) {
        setIsConfirmDialogOpen(true);
        return;
      }

      handleClose();
    };

    const handleInputChange = async (e: any, fieldName: string) => {
      await handleChange(e);
      validateField(fieldName);
    };

    return (
      <Drawer open onClose={onDrawerCloseClick}>
        <ConfirmCloseDialog
          open={isConfirmDialogOpen}
          onCancelClick={onCancelConfirmDialogClick}
          onDiscardClick={handleClose}
        />
        <Drawer.Header loading={isSaving}>
          <Box sx={headerInfoSx}>
            <Typography noWrap data-testid={makeTestId('title')} variant="h6">
              {title}
            </Typography>
            {isEditMode && (
              <Box data-testid={makeTestId('status')} sx={secondaryHeaderLineSx}>
                {isDisabled ? (
                  <Chip label="Disabled" size="small" />
                ) : (
                  <Chip label="Active" size="small" sx={activeChipSx} />
                )}
              </Box>
            )}
          </Box>
          {isEditMode && (
            <>
              <Button
                color="neutral"
                data-testid={makeTestId('actions')}
                disabled={isLoading}
                endIcon={<KeyboardArrowDown sx={actionsButtonIconSx} />}
                sx={actionsButtonSx}
                onClick={openActionsMenu}
              >
                Actions
              </Button>
              <Divider orientation="vertical" sx={headerDividerSx} />
            </>
          )}
        </Drawer.Header>
        <Drawer.Content>
          <Form disabled={isLoading || isSaving}>
            <FormikField
              dataTestId={makeTestId('name')}
              helperText="Required"
              name="name"
              sx={nameFieldSpacingSx}
              type="text"
              onChange={(e) => handleInputChange(e, 'name')}
            />
            <Typography display="block" sx={sectionHeaderSx} variant="subtitle1m">
              Which user roles should be enrolled into this IP Allow List?
            </Typography>
            <Box sx={fieldBottomSpaceSx}>
              <AutocompleteChipSelectorField
                dataTestId={makeTestId('roles')}
                helperText="Required"
                items={groupsAsSelectorItems}
                label="Roles"
                name="roles"
                placeholder="Add"
                onChange={(e) => handleInputChange(e, 'roles')}
              />
            </Box>
            <Typography display="block" sx={sectionHeaderSx} variant="subtitle1m">
              Allow IP ranges
            </Typography>
            <IpRangeField
              data-testid={makeTestId('ranges')}
              name="ranges"
              sx={fieldBottomSpaceSx}
            />
          </Form>
        </Drawer.Content>
        <Drawer.Footer>
          <Button data-testid={makeTestId('close')} onClick={onDrawerCloseClick}>
            Close
          </Button>
          <LoadingButton
            data-testid={makeTestId('save')}
            disabled={!dirty || isSaving}
            loading={isSaving}
            variant="contained"
            onClick={submitForm}
          >
            Save
          </LoadingButton>
        </Drawer.Footer>
      </Drawer>
    );
  };

  return (
    <SerenityForm
      enableReinitialize
      config={IpAllowListPolicyCreateEditFormConfig}
      data-testid={mainTestId}
      initialValuesOverride={initialValues}
      onSubmit={handleSubmit}
    >
      {(formikProps) => renderDrawerBody(formikProps)}
    </SerenityForm>
  );
};

export default IpAllowListPolicyCreateEditForm;
