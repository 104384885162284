import { Schema } from '@serenityapp/domain';
import { GroupedSelectItem } from './GroupedSelectField';

type OrderedGroupKeys = 'Unassigned rooms' | 'Assigned rooms';

/**
 * Transforms room data into a grouped and sorted format to display in GroupedSelectField.
 *
 * @param {Schema.Organization.Rooms.QueryResult} data
 * @param {boolean} isLoading - Flag to indicate if the data is still loading.
 * @returns {GroupedSelectItem[] | []} An array of grouped and sorted room items. If isLoading is true, returns an empty array.
 *
 * @description
 * This function processes the provided room data, grouping it into 'Unassigned rooms' and 'Assigned rooms'
 * based on their 'isOpen' status. Each group is then sorted alphabetically by the room's display name.
 * If the data is still loading (indicated by the isLoading parameter), the function returns an empty array.
 */
export const groupedRoomsTransformer = (
  data: Schema.Organization.Rooms.QueryResult,
  isLoading: boolean,
): GroupedSelectItem[] | [] => {
  if (isLoading) return [];

  const rooms = data.organization.rooms.edges.map((edge) => edge.room);

  if (rooms.length === 0) return [];

  const groupedRooms: Record<OrderedGroupKeys, Schema.Room.Item[]> = {
    'Unassigned rooms': [],
    'Assigned rooms': [],
  };

  rooms.forEach((room) => {
    const groupKey: OrderedGroupKeys = room.isOpen ? 'Unassigned rooms' : 'Assigned rooms';
    if (!groupedRooms[groupKey].some((r) => r.id === room.id)) {
      groupedRooms[groupKey].push(room);
    }
  });

  // Convert groupedRooms to the desired output format
  return Object.entries(groupedRooms)
    .filter(([_, group]) => group.length > 0) // Filter out empty groups
    .map(([groupName, group]) => {
      // Sort rooms within each non-empty group by displayName
      group.sort((a, b) => {
        const nameA = a.displayName?.toLocaleLowerCase() ?? '';
        const nameB = b.displayName?.toLocaleLowerCase() ?? '';
        return nameA.localeCompare(nameB, 'en', { numeric: true });
      });

      return {
        name: groupName,
        items: group.map((room) => ({
          id: room.id,
          label: room.displayName,
        })),
      };
    });
};
