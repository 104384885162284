import { FileObject, MimeFn } from '@serenityapp/core';

/*
 * A function that takes in two arrays and compares them by the length.
 *
 * @param array1
 * @param array2
 * @returns a negative number if the second array's length is smaller, a positive number if it's
 * larger, and zero if they are equal.
 */
const compareByLength = (array1: Array<any>, array2: Array<any>) => array2.length - array1.length;

/*
 * A function that takes in an array of files (that have been selected in the message entry) and
 * decides what file to discard and what files should be displayed in the entry as selected
 * by following the restriction rules explained below.
 *
 * The restriction logic works the same as in the mobile app - a user can send only one of the
 * following options within a single message:
 *    * up to 9 images
 *    * 1 video
 *    * 1 file of other type
 * These options cannot be mixed up within a single message, for example, user cannot send one
 * image and one video within a single message.
 *
 * @param attachments A list of selected attachments
 * @returns A filtered list of attachments that are passing the restrictions rules
 */
export default function applyFileInputRestrictions(
  attachments: Array<FileObject>,
): Array<FileObject> {
  if (attachments.length === 0) return [];

  // sort all attachments into three groups by type
  // these are the three file groups that we care of when restricting files selection
  const attachmentsByType: { [key: string]: FileObject[] } = {};

  for (const attachment of attachments) {
    const attachmentType = attachment.type;
    // prettier-ignore
    const groupKey = MimeFn.isImage(attachmentType)
      ? 'images'
      : MimeFn.isVideo(attachmentType)
      ? 'videos'
      : 'documents';

    if (!attachmentsByType[groupKey]) {
      attachmentsByType[groupKey] = [];
    }

    attachmentsByType[groupKey].push(attachment);
  }

  // find the largest group (the group that contains the most files)
  const attachmentsByTypeValues = Object.values(attachmentsByType);
  const sortedAttachmentsByTypeValues = attachmentsByTypeValues.sort(compareByLength);
  const largestGroup = sortedAttachmentsByTypeValues[0];

  // Use first file from the largest group to decide what files to display/discard
  const firstAttachmentOfLargestGroup = largestGroup[0];
  const firstAttachmentOfLargestGroupType = firstAttachmentOfLargestGroup.type;

  // If a first file from the largest group is an image, we return up to 9 files from the
  // `images` group. If there were more than 9 images, we discard them.
  // Else, we return first file from the other two groups as, by the restriction rules, we are
  // allowed to return only one file (video or one file of other type) if the file is not an
  // image. Other files get discarded.
  if (MimeFn.isImage(firstAttachmentOfLargestGroupType)) {
    const selectedImages = attachmentsByType.images;

    if (selectedImages.length > 9) {
      return selectedImages.slice(0, 9);
    } else {
      return selectedImages;
    }
  } else {
    return [firstAttachmentOfLargestGroup];
  }
}
