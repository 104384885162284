import { useCallback } from 'react';
import * as R from 'ramda';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { List, ListItem, ListItemText, Switch, Typography } from '@mui/material';

import {
  getOrganizationEnabledFeatures,
  organizationsEnableFeatures,
  RootState,
  snackAdd,
} from '@serenityapp/redux-store';
import { Drawer, useMakeTestId } from '@serenityapp/components-react-web';
import { availableFeatures, RouteParamId } from '@serenityapp/domain';

import { featureNameSx, listItemSx, titleSx } from './organizationFeaturesDrawerStyles';

const OrganizationFeatureToggles = () => {
  const featureTogglesDrawerTestId = useMakeTestId('FeaturesDrawer');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { id } = useParams<RouteParamId>() as RouteParamId;

  const enabledFeatures = useSelector((state: RootState) =>
    getOrganizationEnabledFeatures(state, id),
  );

  const goBack = () => navigate('..');

  const handleToggleClick = useCallback(
    (toggledFeatureId: string) => {
      let updatedEnabledFeatures;

      const isFeatureOn = R.includes(toggledFeatureId, enabledFeatures);

      if (isFeatureOn) {
        updatedEnabledFeatures = R.reject(
          (featureId) => featureId === toggledFeatureId,
          enabledFeatures,
        );
      } else {
        updatedEnabledFeatures = R.append(toggledFeatureId, enabledFeatures);
      }

      dispatch(
        organizationsEnableFeatures({
          variables: { input: { id, enabledFeatures: updatedEnabledFeatures } },
          onSuccess: () => {
            dispatch(snackAdd({ message: 'Enabled features saved', type: 'success' }));
          },
          onFailed: () => {
            dispatch(snackAdd({ message: 'Error saving enabled features', type: 'error' }));
          },
        }),
      );
    },
    [dispatch, enabledFeatures, id],
  );

  const featuresList = availableFeatures.map((feature) => (
    <ListItem key={feature.id} sx={listItemSx}>
      <ListItemText primary={feature.label} secondary={feature.description} sx={featureNameSx} />
      <Switch
        checked={R.includes(feature.id, enabledFeatures)}
        onChange={() => handleToggleClick(feature.id)}
      />
    </ListItem>
  ));

  return (
    <Drawer open data-testid={featureTogglesDrawerTestId} onClose={goBack}>
      <Drawer.Header>
        <Typography sx={titleSx} variant="h6">
          Feature toggles
        </Typography>
      </Drawer.Header>
      <Drawer.Content>
        <List>{featuresList}</List>
      </Drawer.Content>
    </Drawer>
  );
};

export default OrganizationFeatureToggles;
