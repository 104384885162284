import { Box } from '@mui/material';
import { Group } from '@mui/icons-material';

import { EmptyStateView } from '@serenityapp/components-react-web';

const EmptyListView = () => (
  <Box sx={rootSx}>
    <EmptyStateView description="No people to show yet" Icon={Group} title="It's empty here" />
  </Box>
);

const rootSx = {
  display: 'flex',
  height: '100%',
};

export default EmptyListView;
