import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography, styled } from '@mui/material';

import { cardSx, cardContentSx, cardMediaSx } from './adminPageStyles';

export type DashboardCardProps = {
  to: string;
  cardMedia: string;
  description: string;
  title: string;
  dataTestId?: string;
};

const DashboardCard = ({ to, cardMedia, description, title, dataTestId }: DashboardCardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledRouterLink to={to}>
      <Card
        data-testid={dataTestId}
        elevation={isHovered ? 4 : 1}
        sx={cardSx}
        onMouseOut={() => setIsHovered(false)}
        onMouseOver={() => setIsHovered(true)}
      >
        <CardMedia alt={title} component="img" image={cardMedia} sx={cardMediaSx} />
        <CardContent sx={cardContentSx}>
          <Typography variant="h6">{title}</Typography>
          <Typography gutterBottom component="div" variant="body2">
            {description}
          </Typography>
        </CardContent>
      </Card>
    </StyledRouterLink>
  );
};

const StyledRouterLink = styled(Link)(() => ({
  display: 'flex',
  flex: 1,
  textDecoration: 'none',
}));

export default DashboardCard;
