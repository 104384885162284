import { useEffect, useState } from 'react';
import { useParams, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { House, MoreVert } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Skeleton,
  TableCell,
  CircularProgress,
  IconButton,
} from '@mui/material';

import {
  AvatarTableCell,
  BasicTable,
  BasicTableHeadCellProps,
  BasicTableRowDataProps,
  SearchBar,
  useMakeTestId,
  View,
} from '@serenityapp/components-react-web';
import {
  getUsersForOrganization,
  RootState,
  useOrganizationDetail,
  usersByOrganizationList,
  getUsersLoadingState,
} from '@serenityapp/redux-store';
import { StringFn } from '@serenityapp/core';
import { RouteParamId, UserKind, UserProps } from '@serenityapp/domain';

import UserActionsMenu from './UserActionsMenu';
import {
  featuresSectionSx,
  featuresTitleSx,
  headWrapperSx,
  iconSx,
  listSx,
  searchBarSx,
} from './organizationDetailPageStyles';

const OrganizationDetailPage = () => {
  const { id } = useParams<RouteParamId>() as RouteParamId;
  const navigate = useNavigate();

  const { isLoading, organization } = useOrganizationDetail(id);

  const isLoadingUsers: boolean = useSelector(getUsersLoadingState);
  const [searchKey, setSearchKey] = useState('');
  const users: Array<UserProps> = useSelector((state: RootState) =>
    getUsersForOrganization(state, id, searchKey),
  );
  const [actionsMenuAnchorEl, setActionsMenuAnchor] = useState<null | HTMLElement>(null);
  const [currentlySelectedRow, setCurrentlySelectedRow] = useState<null | BasicTableRowDataProps>(
    null,
  );
  const shouldDisplayActionsMenu = !!currentlySelectedRow;

  const shouldShowSkeleton = isLoadingUsers && users.length === 0;
  const makeTestId = useMakeTestId('OrganizationDetailPage');
  const mainTestId = makeTestId('');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      usersByOrganizationList({
        variables: {
          organizationInput: { orgId: id },
          // We don't need to fetch residents, because they are not relevant in the organization's detail
          // and are causing lambda rate limit issues.
          usersInput: { kinds: [UserKind.ADMIN, UserKind.CONTACT, UserKind.STAFF] },
        },
      }),
    );
  }, [dispatch, id]);

  const getAgreedToTerms = (row?: BasicTableRowDataProps): boolean => {
    /**
     * We deprecated user.account.agreedToTerms and moved it to user.agreedToTerms.
     *
     * This helper checks in both places to handle cached data
     */
    if (!row) {
      return false;
    }

    if (row.account === undefined) {
      return row.agreedToTerms ?? false;
    }

    return row.agreedToTerms === true || row.account.agreedToTerms === true;
  };

  const avatarCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    const initials = StringFn.initials(row?.fullName || '');
    return (
      <AvatarTableCell
        key={`avatar-cell-${row?.id}`}
        dataTestId={dataTestId}
        initials={initials}
        name={row?.fullName || '(empty)'}
        selected={row?.id === id}
        showSkeleton={shouldShowSkeleton}
      />
    );
  };

  const booleanCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    const agreesToTerms = getAgreedToTerms(row);

    return (
      <TableCell key={row?.id} data-testid={dataTestId}>
        {shouldShowSkeleton ? (
          <Skeleton variant="text" width="70%" />
        ) : (
          <Checkbox disabled checked={agreesToTerms} />
        )}
      </TableCell>
    );
  };

  const userActionsCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    return (
      <TableCell align="right" data-testid={dataTestId} padding="none">
        <IconButton
          aria-label="Open menu"
          data-testid="UsersActionsMenu"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setActionsMenuAnchor(event.currentTarget);
            setCurrentlySelectedRow(row);
          }}
        >
          <MoreVert />
        </IconButton>
      </TableCell>
    );
  };

  const headCells: BasicTableHeadCellProps[] = [
    { id: 'fullName', label: 'Full name', cellRenderer: avatarCellRenderer },
    { id: 'subtype', label: 'Type' },
    { id: 'email', label: 'Email', sort: false, hide: 'smDown' },
    { id: 'messagesSentLastMonth', label: 'Messages last month' },
    {
      id: 'agreedToTerms',
      label: 'Agreed to terms',
      sort: false,
      cellRenderer: booleanCellRenderer,
    },
    {
      id: 'actions',
      label: 'Actions',
      sort: false,
      align: 'right',
      cellRenderer: userActionsCellRenderer,
    },
  ];

  const openFeaturesDrawer = () => navigate('features');

  return (
    <>
      {shouldDisplayActionsMenu ? (
        <UserActionsMenu
          anchorEl={actionsMenuAnchorEl}
          organizationId={organization.id}
          userId={currentlySelectedRow.id}
          onClose={() => setActionsMenuAnchor(null)}
        />
      ) : null}
      <View dataTestId={mainTestId}>
        <View.Content loading={isLoading && !organization}>
          <List sx={listSx}>
            <Box sx={headWrapperSx}>
              <House sx={iconSx} />
              <Typography variant="h5">{organization?.name}</Typography>
            </Box>
            <Divider />
            <ListItem>
              <ListItemText primary="URL" secondary={organization?.orgUri || '-'} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Description" secondary={organization?.description || '-'} />
            </ListItem>
            <Divider />
            <ListItem sx={featuresSectionSx}>
              <ListItemText primary="Organization's feature toggles" sx={featuresTitleSx} />
              <Button
                data-testid={makeTestId('FeatureButton')}
                variant="contained"
                onClick={openFeaturesDrawer}
              >
                Manage
              </Button>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemText primary="Users" />
            </ListItem>
            <Box>
              <SearchBar
                dataTestId={makeTestId('Filter')}
                disabled={isLoadingUsers}
                sx={searchBarSx}
                value={searchKey}
                onSearchKeyChange={setSearchKey}
              />
            </Box>
            {isLoadingUsers ? (
              <CircularProgress />
            ) : (
              <BasicTable
                aria-label="Users table"
                dataTestId={mainTestId}
                headCells={headCells}
                orderByColumnId="subtype"
                rows={users}
                showSkeleton={shouldShowSkeleton}
              />
            )}
          </List>
        </View.Content>
        <Outlet />
      </View>
    </>
  );
};

export default OrganizationDetailPage;
