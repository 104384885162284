import { useCallback, useState, memo, MouseEvent } from 'react';
import { Box, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ActionsMenu from './ActionsMenu';
import ArchiveChannelDialog from './ArchiveChannelDialog';

type ActionsCellProps = {
  params: GridRenderCellParams;
  makeTestId: (value: string) => string;
};

const ActionsCell = memo(({ params, makeTestId }: ActionsCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleMenuOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleArchiveClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseArchiveDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Box sx={actionCellSx}>
      <IconButton
        data-testid={makeTestId(`${params.value}`)}
        tabIndex={params.hasFocus ? 0 : -1}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      {anchorEl && (
        <ActionsMenu
          anchorEl={anchorEl}
          channelId={params.value}
          onClose={handleMenuClose}
          onArchiveClick={handleArchiveClick}
        />
      )}
      {isOpen && (
        <ArchiveChannelDialog conversationId={params.value} onClose={handleCloseArchiveDialog} />
      )}
    </Box>
  );
});

const actionCellSx = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  justifyContent: 'center',
  height: 'inherit',
};

export default ActionsCell;
