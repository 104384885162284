export const activeChipSx = {
  bgcolor: 'success.outlinedRestingBorder',
};

export const statusCellSx = {
  p: 2,
};

export const tableSx = {
  overflowX: 'initial',
};
