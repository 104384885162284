import { ReactNode, useState, useCallback, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AutoFixHigh, House } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';

import {
  AppLayout,
  ConversationsList,
  ConversationsListItem,
  useTheme,
  useMediaQuery,
  HorizontalSerenityColorLogo,
} from '@serenityapp/components-react-web';

import { MainPage } from '../../common';
import AppBarContent from '../../common/AppBarContent';

const houseIcon = <House />;
const wizardIcon = <AutoFixHigh />;

const drawerItems: Array<ReactNode> = [
  <ConversationsListItem
    key="organizations"
    dataTestId="organizations"
    icon={houseIcon}
    title="Organizations"
    to="organizations"
  />,
  <ConversationsListItem
    key="systemadmin"
    dataTestId="systemadmin"
    icon={wizardIcon}
    title="System Admin"
    to="system"
  />,
];

const SerenityAdminMainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const shouldRenderLogo = useMediaQuery(theme.breakpoints.down('lg'));

  const handleDrawerToggle = useCallback(() => setMobileOpen(!mobileOpen), [mobileOpen]);

  useEffect(() => {
    if (['/serenity-admin', '/serenity-admin/'].includes(location.pathname)) {
      navigate('organizations');
    }
  }, [location, navigate]);

  const renderLogo = () => (
    <>
      <Box sx={logoWrapperSx}>
        <Link to="/serenity-admin">
          <HorizontalSerenityColorLogo height={34} />
        </Link>
      </Box>
      <Divider />
    </>
  );

  return (
    <MainPage>
      <AppLayout>
        <AppLayout.AppBar>
          <AppBarContent onBurgerMenuClick={handleDrawerToggle} />
        </AppLayout.AppBar>
        <AppLayout.Drawer open={mobileOpen} onToggle={handleDrawerToggle}>
          {shouldRenderLogo && renderLogo()}
          <ConversationsList items={drawerItems} />
        </AppLayout.Drawer>
        <AppLayout.Main>
          <Outlet />
        </AppLayout.Main>
      </AppLayout>
    </MainPage>
  );
};

const logoWrapperSx = {
  display: 'flex',
  alignItems: 'center',
  minHeight: '64px',
  px: 3,
};

export default SerenityAdminMainPage;
