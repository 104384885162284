import { ChangeEvent } from 'react';
import { TextField, TextFieldProps, Chip, InputAdornment, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { UserForDM } from '@serenityapp/redux-store';
import { useMakeTestId } from '@serenityapp/components-react-web';
import { noop } from '@serenityapp/core';

export type UsersForDMSearchProps = TextFieldProps & {
  selectedUsers: UserForDM[];
  onChipDelete: (id: string) => void;
  onSearchKeyChange: (value: string) => void;
  onClear: () => void;
};

const UsersForDMSearch = ({
  selectedUsers,
  value,
  onChipDelete,
  onSearchKeyChange,
  onClear,
  disabled,
}: UsersForDMSearchProps) => {
  const makeTestId = useMakeTestId('UsersForDMSearch');
  const mainTestId = makeTestId('');

  const shouldDisplayClearButton = !!value || selectedUsers.length > 0;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearchKeyChange(event.target.value);
  };

  const getChips = () =>
    selectedUsers.map((item: UserForDM) => {
      const onDelete = !disabled ? () => onChipDelete(item.id) : noop;
      return (
        <Chip
          key={item.id}
          data-testid={makeTestId(`Chip-${item.id}`)}
          disabled={disabled}
          label={item.fullName || item.username}
          onDelete={onDelete}
        />
      );
    });

  const InputProps = {
    startAdornment: getChips(),
    endAdornment: (
      <InputAdornment position="end" sx={endAdornmentSx}>
        {shouldDisplayClearButton && (
          <IconButton
            aria-label="Clear search field"
            data-testid={makeTestId('Close')}
            onClick={onClear}
          >
            <Close />
          </IconButton>
        )}
      </InputAdornment>
    ),
  };

  const helperText =
    selectedUsers.length === 0 ? 'Maximum 7 people' : `${selectedUsers.length} out of 7 selected`;

  return (
    <TextField
      autoFocus
      fullWidth
      data-testid={mainTestId}
      disabled={disabled}
      helperText={helperText}
      InputProps={InputProps}
      label="Recipients"
      placeholder="Search..."
      sx={textFieldSx}
      value={value}
      onChange={handleChange}
    />
  );
};

const textFieldSx = {
  '& .MuiInputBase-root': {
    display: 'flex',
    gap: 0.5,
    flexWrap: 'wrap',
    minHeight: 56,
    height: 'auto',
    py: 1,
    pr: 5,

    '& input': {
      minWidth: 20,
      width: 'auto',
      flexGrow: 1,
      textOverflow: 'ellipsis',
      py: 0.5,
    },

    '& .MuiInputAdornment-positionEnd': {
      position: 'absolute',
      top: '50%',
      right: 0,
    },
  },
};

const endAdornmentSx = { width: 40 };

export default UsersForDMSearch;
