import { StringFn } from '@serenityapp/core';

/** Removes header from a data URL returning only the Base64 data string */
const stripDataURLHeader = (dataURL: string): string => dataURL.replace(/^data:(.+),/i, '');

/**
 * Returns Base64 encoded data representation of a file at a given URL.
 * @param url File source URL
 * @returns Base64 encoded file data
 */
export async function getFileAsBase64(url: string): Promise<string> {
  const fileResponse = await fetch(url);
  const fileResponseBlob = await fileResponse.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('error', () => reject(`Failed to read file at ${url}`));
    reader.addEventListener('loadend', (e) => {
      if (typeof reader.result === 'string') {
        const dataURL = reader.result;
        const base64 = stripDataURLHeader(dataURL);
        resolve(base64);
      } else {
        reject(`Could not convert file at ${url} to a base64 string`);
      }
    });
    reader.readAsDataURL(fileResponseBlob);
  });
}

/**
 * Downloads a file from a given URL.
 * @param url File source URL
 * @param name File name to use when downloading
 */
export const downloadFile = (url?: string, name?: string): void => {
  if (!url) return;

  const fileName = name ? StringFn.sanitizeContentDispositionFilename(name) : 'serenity-file';

  const anchor = document.createElement('a');
  const urlWithParameters = new URL(url);
  urlWithParameters.searchParams.append(
    'response-content-disposition',
    `attachment;filename="${fileName}"`,
  );
  anchor.href = urlWithParameters.toString();
  anchor.download = fileName;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
