import { useState, MouseEvent } from 'react';
import Picker from '@emoji-mart/react';
import { Tooltip, IconButton, Box } from '@mui/material';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { icons } from '@serenityapp/components-react-web';
import { EmojiData } from '../../lexical/types';
import { INSERT_EMOJI_COMMAND } from '../../lexical';

const { YellowSmiley, Smiley } = icons;

const EmojiPicker = () => {
  let closeTimeout: ReturnType<typeof setTimeout>;
  const [editor] = useLexicalComposerContext();

  const [isPickerOpen, setIsPickerOpen] = useState<boolean>(false);

  const handleEmojiSelect = (emojiData: EmojiData) => {
    editor.dispatchCommand(INSERT_EMOJI_COMMAND, emojiData);
  };

  const handlePickerOpen = (event: MouseEvent<HTMLElement>) => {
    // prevents handlePickerOutsideClick from firing on picker open click
    event.stopPropagation();

    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
    setIsPickerOpen((isPickerOpen) => !isPickerOpen);
  };

  const handlePickerMouseLeave = () => {
    closeTimeout = setTimeout(() => {
      setIsPickerOpen(false);
    }, 1000);
  };

  const handlePickerMouseOver = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }
  };

  const handlePickerOutsideClick = () => {
    if (isPickerOpen) {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
      }

      setIsPickerOpen(false);
    }
  };

  return (
    <>
      <Tooltip arrow title="Click to choose emojis">
        <IconButton sx={smileyIconWrapperSx} onClick={handlePickerOpen}>
          <YellowSmiley id="filled-smiley" />
          <Smiley id="outlined-smiley" />
        </IconButton>
      </Tooltip>
      {isPickerOpen && (
        <Box
          sx={pickerStylesSx}
          onMouseOut={handlePickerMouseLeave}
          onMouseOver={handlePickerMouseOver}
        >
          <Picker
            autoFocus
            set="google"
            skinTonePosition="search"
            theme="light"
            width="400"
            onClickOutside={handlePickerOutsideClick}
            onEmojiSelect={handleEmojiSelect}
          />
        </Box>
      )}
    </>
  );
};

const smileyIconWrapperSx = {
  display: 'flex',
  p: 0.75,

  '& #outlined-smiley': {
    display: 'block',
  },
  '& #filled-smiley': {
    display: 'none',
  },

  '&:hover': {
    '& #outlined-smiley': {
      display: 'none',
    },
    '& #filled-smiley': {
      display: 'block',
    },
  },
};

const pickerStylesSx = {
  position: 'absolute',
  bottom: 40,
  right: 0,
  zIndex: 1000,

  '& em-emoji-picker': {
    // 0,150,136 is equivalent of 'primary.main' in rgb
    '--rgb-accent': '0,150,136',
  },
};

export default EmojiPicker;
