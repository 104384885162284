import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  Link,
  Typography,
} from '@mui/material';

import { HorizontalSerenityColorLogo, useMakeTestId } from '@serenityapp/components-react-web';
import { getViewerState, viewerAgreeToTerms, viewerSignOut } from '@serenityapp/redux-store';

import {
  cardActionsSx,
  cardHeaderSx,
  cardSx,
  containerSx,
  logoWrapperSx,
  buttonSx,
} from './agreeToTermsPageStyles';

const AgreeToTermsPage = () => {
  const dispatch = useDispatch();

  const makeTestId = useMakeTestId('AgreeToTermsPage');
  const mainTestId = makeTestId('AgreeToTermsContainer');

  const { signedIn, hasAgreedToTerms } = useSelector(getViewerState);

  const handleAgreeToTerms = () => {
    // TODO (Rob): Handle onFailed
    dispatch(viewerAgreeToTerms({ variables: undefined, onFailed: () => undefined }));
  };

  const handleCancel = () => {
    dispatch(viewerSignOut());
  };

  if (hasAgreedToTerms) {
    return <Navigate to="/" />;
  }

  if (!signedIn) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <Container data-testid={mainTestId} sx={containerSx}>
      <Card sx={cardSx}>
        <Box sx={logoWrapperSx}>
          <HorizontalSerenityColorLogo height={50} />
        </Box>
        <CardHeader
          subheader={
            <Typography component="p" variant="body1">
              To continue you must agree to the{' '}
              <Link
                data-testid={makeTestId('link')}
                href="https://serenityapp.com/user-terms-of-service/"
                target="_blank"
              >
                terms of service.
              </Link>
            </Typography>
          }
          sx={cardHeaderSx}
          title="Terms of service"
        />
        <CardActions disableSpacing sx={cardActionsSx}>
          <Button
            fullWidth
            color="primary"
            data-testid={makeTestId('agree')}
            variant="contained"
            onClick={handleAgreeToTerms}
          >
            Agree
          </Button>
          <Button
            fullWidth
            color="neutral"
            data-testid={makeTestId('cancel')}
            sx={buttonSx}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default AgreeToTermsPage;
