import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_ATTACHMENT_COMMAND } from '../customLexicalCommands';

export default function FilePastePlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const handlePaste = (event: ClipboardEvent) => {
      const files: FileList | undefined = event.clipboardData?.files;
      if (files) {
        editor.dispatchCommand(INSERT_ATTACHMENT_COMMAND, Array.from(files));
      }
    };

    const messageEntry = document.getElementById('messageEntry');

    messageEntry?.addEventListener('paste', handlePaste);

    return () => {
      messageEntry?.removeEventListener('paste', handlePaste);
    };
  }, [editor]);

  return null;
}
