import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';

import { useMakeTestId } from '@serenityapp/components-react-web';
import { getViewerState, viewerDeleteAccount } from '@serenityapp/redux-store';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type DeleteAccountDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

const DeleteAccountDialog = ({ isOpen = false, onClose }: DeleteAccountDialogProps) => {
  const makeTestId = useMakeTestId('DeleteAccountDialog');
  const mainTestId = makeTestId('');

  const { deletingAccount, id } = useSelector(getViewerState);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);

  const isLoading = deletingAccount;

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(
      viewerDeleteAccount({
        variables: { input: { id } },
        onSuccess: onClose,
        onFailed: onClose,
      }),
    );
  };

  const onDialogCloseClick = () => {
    if (!isLoading) {
      onClose();
    }
  };

  const handleCheckboxClick = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const checkbox = (
    <Checkbox
      checked={isCheckboxChecked}
      color="primary"
      data-testid="IUnderstandCheckbox"
      onChange={handleCheckboxClick}
    />
  );

  return (
    <Dialog fullWidth data-testid={mainTestId} open={isOpen} onClose={onDialogCloseClick}>
      <DialogTitle>Permanently delete my account</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Are you sure you want to permanently delete your account? This action cannot be undone!
        </Typography>
        <FormControlLabel
          control={checkbox}
          label="I understand and want to permanently delete my account."
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid={makeTestId('close')}
          disabled={isLoading}
          onClick={onDialogCloseClick}
        >
          Close
        </Button>
        <LoadingButton
          data-testid={makeTestId('delete')}
          disabled={!isCheckboxChecked}
          loading={isLoading}
          variant="contained"
          onClick={handleSubmit}
        >
          Delete account
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAccountDialog;
