import { AssertFn } from '@serenityapp/core';
import { TableCell } from '@mui/material';

import {
  BasicTable,
  BasicTableHeadCellProps,
  View,
  AdministrationHeader,
  useMakeTestId,
  BasicTableRowDataProps,
} from '@serenityapp/components-react-web';

import SystemChannelActionsMenu from './SystemChannelActionsMenu';
import SystemUserActionsMenu from './SystemUserActionsMenu';

type SystemAdminItem = {
  name: string;
  description: string;
  id: string;
  key: string;
};

const SystemAdminPage = () => {
  const makeTestId = useMakeTestId('SystemAdminPage');
  const mainTestId = makeTestId('');

  const rows: Array<SystemAdminItem> = [
    {
      name: 'Channel',
      description: 'Connect channel',
      id: 'channel',
      key: 'channel',
    },
    {
      name: 'Users',
      description: 'Reset password, advanced operations, ...',
      id: 'user',
      key: 'user',
    },
  ];

  const chooseMenu = (row?: BasicTableRowDataProps) => {
    const { id = 'none' } = row || {};

    switch (id) {
      case 'channel': {
        return <SystemChannelActionsMenu />;
      }

      case 'user': {
        return <SystemUserActionsMenu />;
      }
      default: {
        AssertFn.fail(filename, `unexpected row id ${id}`);
      }
    }
  };

  const actionsCellRenderer = (row?: BasicTableRowDataProps, dataTestId?: string) => {
    return (
      <TableCell align="right" data-testid={dataTestId} padding="none">
        {chooseMenu(row)}
      </TableCell>
    );
  };

  const headCells: BasicTableHeadCellProps[] = [
    { id: 'name', label: 'Name' },
    { id: 'description', label: 'Description' },
    {
      id: 'actions',
      label: 'Actions',
      sort: false,
      cellRenderer: actionsCellRenderer,
      align: 'right',
    },
  ];

  return (
    <View dataTestId={mainTestId}>
      <View.Header>
        <AdministrationHeader title="System Administration" />
      </View.Header>
      <View.Content>
        <BasicTable
          aria-label="System admin table"
          headCells={headCells}
          rows={rows}
          showSkeleton={false}
        />
      </View.Content>
    </View>
  );
};

const filename = 'SystemAdminPage';

export default SystemAdminPage;
