import { LexicalCommand, createCommand } from 'lexical';
import { EmojiData } from './types';
import { UserProps } from '@serenityapp/domain';

export const OPEN_FILES_COMMAND: LexicalCommand<undefined> = createCommand();
export const CLEAR_ATTACHMENT_COMMAND: LexicalCommand<undefined> = createCommand();
export const INSERT_ATTACHMENT_COMMAND: LexicalCommand<Array<File>> = createCommand();

export const INSERT_EMOJI_COMMAND: LexicalCommand<EmojiData> = createCommand();

export const SEND_MESSAGE_COMMAND: LexicalCommand<undefined> = createCommand();

export const AUTOCOMPLETE_SLASH_COMMAND: LexicalCommand<string> = createCommand();
export const INVOKE_SLASH_COMMAND_COMMAND: LexicalCommand<undefined> = createCommand();

export const SEND_BUTTON_CLICKED_COMMAND: LexicalCommand<undefined> = createCommand();

type InsertMentionCommandPayload = {
  user: UserProps;
  userFullName: string;
};
export const INSERT_MENTION_COMMAND: LexicalCommand<InsertMentionCommandPayload> =
  createCommand();
