import { useState } from 'react';
import { WithKey } from '@serenityapp/domain';
import { MoreVert } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { BasicMenu, BasicMenuItemProps, useMakeTestId } from '@serenityapp/components-react-web';

import { SystemChannelConnectDialog } from './SystemChannelActionsDialogs';

const SystemChannelActionsMenu = () => {
  const makeTestId = useMakeTestId('SystemChannelActionsMenu');
  const mainTestId = makeTestId('');
  const [channelActionsMenuAnchor, setChannelActionsMenuAnchor] = useState<null | HTMLElement>(
    null,
  );

  const [isChannelConnectDialogOpen, setIsChannelConnectDialogOpen] = useState<boolean>(false);

  const openChannelActionsMenuAnchor = Boolean(channelActionsMenuAnchor);

  const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setChannelActionsMenuAnchor(null);
  };

  const handleActionsMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setChannelActionsMenuAnchor(event.currentTarget);
  };

  const channelActionsMenuSection: Array<Array<WithKey<BasicMenuItemProps>>> = [
    [
      {
        key: 'system-channel-connect',
        dataTestId: makeTestId('system-channel-connect'),
        onClick: () => setIsChannelConnectDialogOpen(true),
        label: 'Connect channel',
        handleCloseMenu,
      },
    ],
  ];

  return (
    <>
      <IconButton
        aria-label="Show menu"
        data-testid={mainTestId}
        onClick={handleActionsMenuClick}
      >
        <MoreVert />
      </IconButton>
      <BasicMenu
        key="basic-menu"
        keepMounted
        anchorEl={channelActionsMenuAnchor}
        open={openChannelActionsMenuAnchor}
        sections={channelActionsMenuSection}
        onClose={handleCloseMenu}
      />
      {isChannelConnectDialogOpen && (
        <SystemChannelConnectDialog onClose={() => setIsChannelConnectDialogOpen(false)} />
      )}
    </>
  );
};

export default SystemChannelActionsMenu;
