import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';

import { AutoLinkNode } from '@lexical/link';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';

import { useMakeTestId } from '@serenityapp/components-react-web';

import LexicalEditorTheme from '../lexical/theme/LexicalEditorTheme';
import {
  AutoFocusPlugin,
  AutoLinkPlugin,
  SendMessagePlugin,
  AttachmentsPlugin,
  EmojiPlugin,
  ReduxPlugin,
  AutoEmojiPlugin,
  // TreeViewPlugin,
  EmojiSizePlugin,
  FilePastePlugin,
  FileDragAndDropPlugin,
  FocusPlugin,
  SlashCommandsPlugin,
  MentionsPlugin,
  AutoMentionsPlugin,
  CustomOnPastePlugin,
} from '../lexical/plugins';
import { EmojiNode, MentionNode } from '../lexical/nodes';
import { StyledContentEditable } from '../lexical/components';
import { Placeholder, AttachmentButton, EmojiPicker, SendButton } from './components';
import {
  rootSx,
  innerSx,
  toolbarSx,
  toolbarRightSx,
  contentEditableSx,
  toolbarEmptySx,
} from './messageEntryStyles';

type MessageEntryProps = {
  dataTestId?: string;
  onSendMessage: () => void;
  conversationId: string;
  conversationSubsubtype?: string;
  conversationName?: string;
};

const MessageEntry = ({
  dataTestId,
  conversationId,
  conversationName,
  conversationSubsubtype,
  onSendMessage,
}: MessageEntryProps) => {
  const makeTestId = useMakeTestId('MessageEntry', dataTestId);
  const mainTestId = makeTestId('');

  // allow slash commands and mentions plugins only in Check-In app channel
  const isCheckInAppChannel = conversationSubsubtype === 'checkin';

  const initialEditorConfig = {
    namespace: 'MessageEntry',
    nodes: [AutoLinkNode, EmojiNode, MentionNode],
    onError: (error: Error) => {
      Sentry.addBreadcrumb({
        message: 'Error in lexical editor within MessageEntry',
        data: { error },
      });
    },
    theme: LexicalEditorTheme,
  };

  return (
    <LexicalComposer initialConfig={initialEditorConfig}>
      <Box data-testid={mainTestId} sx={rootSx}>
        {/* <TreeViewPlugin /> */}
        <Box id="messageEntry" sx={innerSx}>
          <Box id="messageEntryInput">
            <ReduxPlugin conversationId={conversationId} />
            <RichTextPlugin
              contentEditable={<StyledContentEditable sx={contentEditableSx} />}
              ErrorBoundary={LexicalErrorBoundary}
              placeholder={<Placeholder />}
            />
            <AutoFocusPlugin />
            <FocusPlugin />
            <ClearEditorPlugin />
            <AutoLinkPlugin />
            <AutoEmojiPlugin />
            <EmojiSizePlugin />
            <HistoryPlugin />
            <FilePastePlugin />
            <FileDragAndDropPlugin conversationName={conversationName || ''} />
            <AttachmentsPlugin conversationId={conversationId} />
            <EmojiPlugin />
            <SendMessagePlugin conversationId={conversationId} onSendMessage={onSendMessage} />
            <CustomOnPastePlugin />
            {isCheckInAppChannel && (
              <>
                <SlashCommandsPlugin conversationId={conversationId} />
                <MentionsPlugin />
                <AutoMentionsPlugin />
              </>
            )}
          </Box>
          <Box sx={toolbarSx}>
            <Box id="messageEntryToolbarEmpty" sx={toolbarEmptySx} />
            <Box sx={toolbarRightSx}>
              <EmojiPicker />
              <AttachmentButton />
              <SendButton conversationId={conversationId} />
            </Box>
          </Box>
        </Box>
      </Box>
    </LexicalComposer>
  );
};

export default MessageEntry;
