import { useCallback, ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { Close } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { COMMAND_PRIORITY_HIGH } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';

import {
  RootState,
  getMessageEntryByConversationId,
  updateMessageEntryAttachments,
} from '@serenityapp/redux-store';
import {
  FileObject,
  MimeLookupIsomorphic,
  getFileSizeFromBytes,
  MimeFn,
  isFileHEIF,
} from '@serenityapp/core';
import { FileGroup, FileProps } from '@serenityapp/components-react-web';
import { WithKey } from '@serenityapp/domain';

import { applyFileInputRestrictions } from '../utils';
import {
  OPEN_FILES_COMMAND,
  CLEAR_ATTACHMENT_COMMAND,
  INSERT_ATTACHMENT_COMMAND,
} from '../customLexicalCommands';

const MimeLookup = new MimeLookupIsomorphic();

type AttachmentsPluginProps = {
  conversationId: string;
};

export default function AttachmentsPlugin({ conversationId }: AttachmentsPluginProps) {
  const dispatch = useDispatch();

  const { attachments = [] } = useSelector((state: RootState) =>
    getMessageEntryByConversationId(state, conversationId),
  );

  const [editor] = useLexicalComposerContext();

  const [pendingFiles, setPendingFiles] = useState<Array<FileObject>>();

  const [showNotSupportedFormatDialog, setShowNotSupportedFormatDialog] =
    useState<boolean>(false);

  const attachFilesRef = useRef<HTMLInputElement>(null);

  const updateAttachments = useCallback(
    (attachments: FileObject[]) => {
      dispatch(
        updateMessageEntryAttachments({
          conversationId,
          attachments,
        }),
      );
    },
    [conversationId, dispatch],
  );

  const checkUnsupportedFileFormat = (file: File) => {
    return MimeFn.isHEIF(file.type) || isFileHEIF(file.name);
  };

  const handleNotSupportedFormatDialogClose = () => setShowNotSupportedFormatDialog(false);
  const handleReplaceDialogClose = () => setPendingFiles(undefined);

  const handleRemoveAttachment = useCallback(
    (key: string) => {
      const result = attachments.filter((attachment) => attachment.key !== key);
      updateAttachments(result);
    },
    [attachments, updateAttachments],
  );

  const handleSelectedFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: Array<File> = Array.from(event?.target?.files ?? []);

    // This ensures that the onChange event will be triggered for the same file as well.
    event.target.value = '';

    insertFiles(selectedFiles);
  };

  const insertFiles = useCallback(
    (selectedFiles: Array<File>) => {
      const isUnsupportedFormat = checkUnsupportedFileFormat(selectedFiles[0]);
      if (isUnsupportedFormat) {
        setShowNotSupportedFormatDialog(true);
        return;
      }

      const files = selectedFiles.map((file) => {
        const url = URL.createObjectURL(file);
        return {
          key: url,
          name: file.name,
          size: file.size,
          type: file.type ? file.type : MimeLookup.lookup(file.name),
          uri: url,
        };
      });

      const restrictedFiles = applyFileInputRestrictions(files);

      // If there are already some files selected (selectedAttachments) than we either:
      //    * set the newly selected files to the pending state so that the replace action
      //      can be prompted.
      //    * or, if the selectedAttachments are images and newly selected files are also images,
      //      and if the newly selected files (together with the files that were already selected -
      //      selectedAttachments) can fit into the restriction count of 9 images per message,
      //      than we merge those two and set them to the currently selected files state
      //      (selectedAttachments) straight away without any further prompts.
      // Else, we set the newly selected files to the currently selected files state
      // (selectedAttachments) straight away without any further prompts.
      if (attachments.length > 0) {
        const areSelectedFilesImages = MimeFn.isImage(attachments[0].type);
        const areNewFilesImages = MimeFn.isImage(restrictedFiles[0].type);
        const totalImagesCount = attachments.length + restrictedFiles.length;

        if (areSelectedFilesImages && areNewFilesImages && totalImagesCount <= 9) {
          updateAttachments([...attachments, ...restrictedFiles]);
        } else {
          setPendingFiles(restrictedFiles);
        }
      } else {
        updateAttachments(restrictedFiles);
      }
      editor.focus();
    },
    [attachments, editor, updateAttachments],
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        OPEN_FILES_COMMAND,
        () => {
          if (attachFilesRef.current) {
            attachFilesRef.current.click();
          }
          return true;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        CLEAR_ATTACHMENT_COMMAND,
        () => {
          updateAttachments([]);

          return true;
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        INSERT_ATTACHMENT_COMMAND,
        (payload) => {
          insertFiles(payload);
          return true;
        },
        COMMAND_PRIORITY_HIGH,
      ),
    );
  }, [conversationId, dispatch, editor, updateAttachments, insertFiles]);
  const handleReplaceFiles = useCallback(() => {
    if (pendingFiles) {
      setPendingFiles(undefined);
      updateAttachments(pendingFiles);
    }
  }, [pendingFiles, updateAttachments]);

  const filesToBeReplaced =
    attachments.length === 1 ? attachments[0].name : 'currently selected files';
  const filesToReplaceWith =
    pendingFiles?.length === 1 ? pendingFiles[0].name : 'newly selected files';
  const replaceText = `Replace ${filesToBeReplaced} with ${filesToReplaceWith}?`;
  const shouldReplaceDialogDisplay = !!pendingFiles;

  const selectedAttachmentsForDisplay: Array<WithKey<FileProps>> = R.map((attachment) => {
    const fileSize = attachment.size ? getFileSizeFromBytes(attachment.size) : undefined;
    const onDismissClick = () => handleRemoveAttachment(attachment.key);

    const dismissTool = {
      key: 'dismiss-tool',
      Icon: Close,
      onClick: onDismissClick,
    };

    return {
      key: attachment.key,
      primaryText: attachment.name,
      secondaryText: fileSize,
      thumbnail: attachment.uri,
      contentType: attachment.type,
      actionTools: [dismissTool],
    };
  }, attachments);

  return (
    <>
      <Box
        ref={attachFilesRef}
        multiple
        component="input"
        sx={fileInputSx}
        type="file"
        onChange={handleSelectedFilesChange}
      />
      <FileGroup files={selectedAttachmentsForDisplay} sx={fileGroupSx} />
      {showNotSupportedFormatDialog && (
        <Dialog open>
          <DialogTitle>Not supported file format</DialogTitle>
          <DialogContent>
            The HEIC/HEIF image formats are not supported in the BETA version.
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={handleNotSupportedFormatDialogClose}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {shouldReplaceDialogDisplay && (
        <Dialog open>
          <DialogTitle>Replace items?</DialogTitle>
          <DialogContent>{replaceText}</DialogContent>
          <DialogActions>
            <Button onClick={handleReplaceDialogClose}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={handleReplaceFiles}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const fileInputSx = {
  display: 'none',
};

export const fileGroupSx = {
  py: 0,
  px: 1.5,
};
