import { Schema } from '@serenityapp/domain';
import { Box, FormControlLabel, Typography } from '@mui/material';
import { FormikField, SwitchField } from '@serenityapp/components-react-web';
import { FormSectionHeader } from './';
import { AssertFn } from '@serenityapp/core';
import { FormikValues } from 'formik';
import { useState } from 'react';
import CommunicationProfileRemoveDialog from '../dialogs/CommunicationProfileRemoveDialog';

type RoomDrawerCommunicationProfilePromptProps = {
  room?: Schema.Room.Detail;
  values: FormikValues;
  createAlexaCommunicationProfile: (
    room: Schema.Room.Detail,
    communicationProfileDisplayName: string,
  ) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

const RoomDrawerCommunicationProfilePrompt = ({
  room,
  values,
  createAlexaCommunicationProfile,
  setFieldValue,
}: RoomDrawerCommunicationProfilePromptProps) => {
  const alexaCommunicationProfile = room?.alexaUnit?.alexaCommunicationProfile;
  AssertFn.isDefined(room);

  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);

  const onCancelRemoveDialogClick = () => {
    setIsRemoveDialogOpen(false);
    setFieldValue('isCommunicationProfileEnabled', true);
  };

  const onConfirmRemoveDialogClick = () => {
    setIsRemoveDialogOpen(false);
    //remove the communication profile
  };

  return (
    <>
      <CommunicationProfileRemoveDialog
        open={isRemoveDialogOpen}
        roomName={room?.name}
        onCancelClick={onCancelRemoveDialogClick}
        onConfirmClick={onConfirmRemoveDialogClick}
      />
      <FormSectionHeader sx={dividerSx} title="Communication Profile" />
      <FormikField name="communicationProfileDisplayName" type="text" />
      <Typography display="block" variant="subtitle1m">
        Should this unit be allowed to participate in calls?
      </Typography>
      <Box sx={{ pb: 2 }}>
        <FormControlLabel
          control={
            <SwitchField
              name="isCommunicationProfileEnabled"
              onChange={(event) => {
                const isEnabled = event.target.checked;
                setFieldValue('isCommunicationProfileEnabled', isEnabled);
                if (isEnabled && !alexaCommunicationProfile?.id) {
                  createAlexaCommunicationProfile(room, values.communicationProfileDisplayName);
                }
                if (!isEnabled && alexaCommunicationProfile?.id) {
                  setIsRemoveDialogOpen(true);
                }
              }}
            />
          }
          label="Enable Communication Profile"
        />
      </Box>
    </>
  );
};

export const dividerSx = {
  mb: 2.25,
};

export default RoomDrawerCommunicationProfilePrompt;
