import { memo } from 'react';
import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { Check } from '@mui/icons-material';

import { UserForDM } from '@serenityapp/redux-store';
import { Avatar } from '@serenityapp/components-react-web';

type UsersForDMListItemProp = UserForDM & {
  dataTestId: string;
  isSelected: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const UsersForDMListItem = ({
  fullName,
  avatarUrl,
  dataTestId,
  initials,
  subtype,
  onClick,
  isSelected,
  disabled,
}: UsersForDMListItemProp) => (
  <ListItemButton
    dense
    data-testid={dataTestId}
    disabled={disabled}
    sx={[listItemButtonSx, isSelected && selectedListItemButtonSx]}
    onClick={onClick}
  >
    <ListItemAvatar>
      <Avatar initials={initials} size="medium" src={avatarUrl} sx={avatarSx} />
    </ListItemAvatar>
    <ListItemText
      primary={fullName}
      primaryTypographyProps={{ sx: isSelected ? selectedItemPrimaryTextSx : undefined }}
      secondary={subtype}
    />
    {isSelected && <Check sx={checkIconSx} />}
  </ListItemButton>
);

const avatarSx = {
  bgcolor: 'grey.400',
};

const checkIconSx = {
  color: 'primary.main',
};

const listItemButtonSx = {
  px: 3,
};

const selectedItemPrimaryTextSx = {
  color: 'primary.main',
};

const selectedListItemButtonSx = {
  bgColor: 'primary.outlinedHoverBackground',
};

export default memo(UsersForDMListItem);
